require('../locales/en');
require('../locales/en-CA');
require('../locales/en-lite');
require('../locales/en-sports');

if(process.env.VUE_APP_BOOKS_LITE === 'true') {
	window.$.extend(true, window.locales.en, window.locales.enLite);
}

if(window.location.host.indexOf('sports') !== -1 || window.location.host.indexOf(':8087') !== -1) {
	window.$.extend(true, window.locales.en, window.locales.enSports);
}