$.LayoutsDB = function(layouts, settings) {
	let loadData = {
		layoutId: layouts.layoutId,
		composer: true,
		fullCategory: true,
		jobId: $.getGETParams().jobId
	};
	let formatId = $.getGETParams().formatId;
	if(formatId) {
		loadData.formatId = formatId;
	}

	var obj = new $.DBQueue($.extend({
		loadUrl: 'ajax/getLayout.php',
		saveUrl: 'ajax/saveLayout.php',
		postData: loadData,
		pageSet: layouts,
		userEvents: $.userEvents,
		allowPartialUpdates: false,
		getSocket: function() {
			var url = 'wss?composer=' + layouts.layoutId + '&token=' + $.PlicToken + '&type=layout';
			if($.LoggedInAsUser) {
				url += '&originalToken=' + $.OriginalUserToken;
			}

			return url;
		},

		addPageFromDefinition: function(layout) {
			var page;
			if(layout.type == 'Ad Template') {
				page = $.AdPage({
					id: 'layout'
				});
				page.loadFromLayout(layout);
				this.pageSet.containImageInParent = true;
				this.pageSet.size = layout.subType || layout.layoutName.replace(' Template', '');
			} else {
				page = new $.LayoutPage({
					layout: layout.definition,
					candids: layout.candids,
					texts: layout.texts
				});
			}

			this.pageSet.pages.push(page);
			page.pageNumber = 1;
			page.pageSet = this.pageSet;
			page.db = this;
			page.layoutType = layout.type;

			if(layout.dynamicStaticGlobalVariables) {
				$.extend($.dynamicStaticGlobalVariables, layout.dynamicStaticGlobalVariables);
			}

			return page;
		},
		getEntirePageData: function(page) {
			return {
				id: page.id,
				pageNumber: page.pageNumber,
				candids: page.candids,
				texts: page.texts,
				theme: page.theme ? page.theme : null,
				type: page.type,
				extras: page.extras ? page.extras : null,
				comments: page.comments,
				grid: page.layout?.grid
			};
		},
		addEntirePage: function (page, inserted, extras) {
			if(!extras) {
				extras = {};
			}

			var pageData = this.getEntirePageData(page);
			if(inserted !== false) {
				pageData.inserted = true;
			}
			if(extras.stripPageNumber) {
				delete pageData.pageNumber;
			}

			this.queueChange({
				scope: 'pages',
				name: page.getId(),
				value: pageData
			}, true);

			return pageData;
		}
	}, settings));

	var _load = obj.load;
	$.extend(obj, {
		loadSinglePage: function (definition, previousPage) {
			let page = $.LayoutPage({
				...definition,
				layout: {
					// Override texts/images but allow them to not be present in definiton and not fail
					texts: {},
					images: {},
					...definition
				}
			});

			return page;
		},
		loadPages: function(pages) {
			this.addPageFromDefinition(pages);
		},
		load: function(events) {
			_load.call(this, $.extend({
				onReady: function(data) {
					$.layoutCategory = data.category;

					if(data.defaultBookFormat) {
						obj.pageSet.layoutDimensions = data.defaultBookFormat.definition;
						$.bookFormat = data.defaultBookFormat;
					}
					if(data.layouts) {
						data.layouts.forEach(function(layout, index) {
							var page = obj.addPageFromDefinition(layout);
							page.pageNumber = index + 1;
							page.id = layout.layoutId;
						});
					} else {
						// NOTE: Breaks %project% in flyers
						// $.ProjectName = data.layout.layoutName;
						obj.addPageFromDefinition(data.layout);
					}

					var chain = new $.ExecutionChain(function() {
						if(events.onReady) {
							events.onReady(data);
						}
					});

					if(data.category && data.category.plicOrgId) {
						chain.add($.getPlicAPI({
							method: 'organizations/' + data.category.plicOrgId,
							success: function(orgData) {
								$.plicProjectOrganizationData = orgData.organization;
							},
							error: function (jqXHR) {
								$.Alert('Error', 'Failed to load organization logo');
							}
						}));
					}

					chain.done();
				}
			}), events);
		}
	});

	$.UserActivityModule(obj, {
		showLastSeen: false,
		activitySubjectLabel: 'Layout'
	});
	return obj;
};