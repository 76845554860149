$.SubjectDirectory = function() {
	var obj = new $.FlowPageSet({});

	$.extend(obj, {
		generateInitialPages: function() {
			var perPage = this.SUBJECT_COLUMNS * this.SUBJECT_ROWS;
			this.classes.forEach(function(batch) {
				for(var i = 0; i < batch.subjects.length; i += perPage) {
					var subjects = batch.subjects.slice(i, i + perPage);
					obj.createPage(batch, subjects);
				}
			});
		},
		createPage: function(batch, subjects) {
			var title = 'Subject Directory: ' + batch.name;

			var page = new $.FlowPage({
				title: {
					lines: {
						text: title
					}
				},
				theme: {
					type: 'Preview',
					Background: {
						id: 'test',
						cdnUrl: 'https://s3.amazonaws.com/plic-books/public/font-book-background.png'
					}
				},
				layout: {}
			});

			var height = 10.5;
			var startHeight = 0.6;
			var rowHeight = (height - startHeight) / this.SUBJECT_ROWS;

			var width = 8;
			var startWidth = 0.25;
			var columnWidth = width / this.SUBJECT_COLUMNS;

			var projectBackgroundId = this.projectBackgroundId;
			var projectBackgroundCdnUrl = this.projectBackgroundCdnUrl;
			var photos = subjects.reduce(function(array, subject, i) {
				var photo = subject.yearbookPhoto;
				if(!photo) {
					return array;
				}

				var x = i % obj.SUBJECT_COLUMNS;
				var y = Math.floor(i / obj.SUBJECT_COLUMNS);

				var width = 1;
				var height = width / 0.8;

				var crop = null;
				if(photo.yearbookCrop) {
					var cropWidth = 1 / photo.yearbookCrop.width;
					var cropHeight = 1 / photo.yearbookCrop.height;

					crop = {
						width: cropWidth,
						height: cropHeight,
						left: -(photo.yearbookCrop.x * cropWidth),
						top: -(photo.yearbookCrop.y * cropHeight),
						percentage: true
					};
				}

				var xPadd = 0;
				var yPadd = 0;
				if(width < columnWidth) {
					xPadd = (columnWidth - width) / 2;
				}
				if(height < rowHeight) {
					yPadd = (rowHeight - height) / 2;
				}

				array.push({
					photo: photo.id,
					existingUrl: photo.cdn_url,
					width: width,
					height: height,
					x: x * columnWidth + startWidth + xPadd,
					y: y * rowHeight + startHeight + yPadd,
					subject: subject,
					zIndex: 2,
					crop: crop
				});

				if(projectBackgroundId && photo.chroma_key === 'processed') {
					array.push({
						photo: projectBackgroundId,
						existingUrl: projectBackgroundCdnUrl,
						width: width,
						height: height,
						x: x * columnWidth + startWidth + xPadd,
						y: y * rowHeight + startHeight + yPadd,
						zIndex: 1
					});
				}

				return array;
			}, []);
			page.setCandidArray(photos);

			var texts = photos.map(function(photo) {
				var subject = photo.subject;
				if(!subject) {
					return null;
				}

				var name = '';
				if(subject['First Name'] && subject['Last Name']) {
					name = subject['Last Name'] + ', ' + subject['First Name'];
				} else if(subject['First Name']) {
					name = subject['First Name'];
				} else if(subject['Last Name']) {
					name = subject['Last Name'];
				}

				return {
					position: {
						left: photo.x,
						top: photo.y + photo.height
					},
					manualSize: {
						width: photo.width,
						height: 0.4
					},
					lines: {
						text: name,
						align: 'center',
						'font-size': 'auto'
					},
					maxFontSize: '8pt'
				};
			}).filter(function(text) {
				return !!text;
			});
			page.setTextArray(texts);

			this.addPage(page, null, false);
		},

		getPageNumberPosition: function () {
			return 'center bottom';
		},
		getPageNumberCSS: function () {
			return new $.CSSBundle();
		},
		getPageNumberFontSize: function() {
			return 8;
		},
		getPageNumberPadding: function() {
			return 0.10;
		},

		SUBJECT_COLUMNS: 7,
		SUBJECT_ROWS: 6
	});

	return obj;
};