$.AdPage = function(settings) {
	var page = new $.FlowPage($.extend({
		type: 'ad',
		getTitle: function() {
			return null;
		},
		setTitle: function() {},
		updatePageLabel: function () {
			var sidebar = [];

			if(this.theme && this.isThemeValid()) {
				sidebar.push({
					name: 'Backgrounds',
					icon: 'setting',
					popup: 'Change settings on backgrounds',
					onClick: function (page, layout) {
						layout.openThemeSettings();
					}
				});

				sidebar.push({
					name: 'Revert Background',
					icon: 'picture',
					popup: 'Revert background back to theme',
					onClick: function (page, layout) {
						layout.clearBackground();
					}
				});
			}

			if(sidebar.length) {
				this.pageLabel = {
					display: '',
					popup: 'Options',
					sidebar: sidebar
				};
			} else {
				this.pageLabel = null;
			}
		},
		setLayout: function() {},
		getLayout: function() {
			var layout = {};

			var dimensions = this.pageSet.getLayoutDimensions();
			var bleed = this.pageSet.getBleedFromDimensions();
			var safeSpace = this.pageSet.getSafeSpace();

			// Do separate bleed calculation since 11 + 0.2 + 0.2 = 11.399999 but 11 + 0.4 = 11.4
			var bleedWidth = bleed.left + bleed.right;
			var width = dimensions.cropWidth + bleedWidth;
			var bleedHeight = bleed.top + bleed.bottom;
			var height = dimensions.cropHeight + bleedHeight;

			bleed.left += safeSpace.left;
			bleed.right += safeSpace.right;
			bleed.top += safeSpace.top;
			bleed.bottom += safeSpace.bottom;

			var smallAdPadding = 0.125;
			if(this.pageSet.size === 'Half Page') {
				updateBleed(dimensions, bleed, safeSpace, 1, 2, smallAdPadding);
			} else if(this.pageSet.size === 'Quarter Page') {
				updateBleed(dimensions, bleed, safeSpace, 2, 2, smallAdPadding);
			} else if(this.pageSet.size === '1/8th Page') {
				updateBleed(dimensions, bleed, safeSpace, 2, 4, smallAdPadding);
			} else if(this.pageSet.size === '1/9th Page') {
				updateBleed(dimensions, bleed, safeSpace, 3, 3, smallAdPadding);
			} else if(this.pageSet.size === '1/10th Page') {
				updateBleed(dimensions, bleed, safeSpace, 2, 5, smallAdPadding);
			} else if(this.pageSet.size === '1/12th Page') {
				updateBleed(dimensions, bleed, safeSpace, 2, 6, smallAdPadding);
			} else if(this.pageSet.size === '1/14th Page') {
				updateBleed(dimensions, bleed, safeSpace, 2, 7, smallAdPadding);
			} else if(this.pageSet.size === '1/16th Page') {
				updateBleed(dimensions, bleed, safeSpace, 2, 8, smallAdPadding);
			}

			$.extend(layout, {
				grid: {
					bleed: bleed,
					width: width,
					height: height,
					safeSpace: safeSpace
				}
			});

			return layout;
		},
		getBackgroundOnlyInContainer: function() {
			return this.pageSet.size !== 'Full Page';
		},

		loadFromLayout: function(layout) {
			if($.isPlainObject(layout.definition.images)) {
				this.candids = layout.definition.images;
			}
			if($.isPlainObject(layout.definition.texts)) {
				this.texts = layout.definition.texts;
			}
			if($.isPlainObject(layout.definition.grid)) {
				this.grid = layout.definition.grid;
			}
			if($.isPlainObject(layout.definition.theme)) {
				this.theme = layout.definition.theme;
			}
			if($.isPlainObject(layout.definition.extras)) {
				this.extras = layout.definition.extras;
			}
		},
		getPageMargins: null,
		setPageMargins: null
	}, settings));

	return page;
};

function updateBleed(dimensions, bleed, safeSpace, columns, rows, smallAdPadding) {
	// Total padding is only 3 gaps of 0.125 and not 0.125 for 4 columns so we have the same size gaps between left/right and top/bottom
	let columnGaps = columns - 1;
	let totalColumnPadding = smallAdPadding * columnGaps;
	bleed.right += (dimensions.cropWidth - safeSpace.left - safeSpace.right) / columns * columnGaps + totalColumnPadding / columns;

	let rowGaps = rows - 1;
	let totalRowPadding = smallAdPadding * rowGaps;
	bleed.bottom += (dimensions.cropHeight - safeSpace.top - safeSpace.bottom) / rows * rowGaps + totalRowPadding / rows;
}