<template>
	<v-btn :color="buttonColor" :dark="dark" :loading="loading" @click="selectPhoto" @drop.prevent="droppedFiles" @dragover.prevent :class="addClasses">
		<v-icon left v-if="icon">
			{{ icon }}
        </v-icon>

		{{ buttonLabel }}

		<input ref="fileInput" type="file" :multiple="allowMultiple" :accept="acceptedFiles" class="hiddenInput" @change="selectedFiles"/>	
	</v-btn>
</template>

<script>
import loadRequirements from './utils/load-requirements';
import chooseFiles from './mixins/choose-files';
import processUploads from './mixins/process-uploads';
import runUpload from './mixins/run-upload';
import getDefaultFileExtensions from './utils/get-default-file-extensions';
import createUpload from './utils/create-upload';
import { plicAxios, shouldRetryAll } from '../utils/axios';

export default {
	props: {
		allowMultiple: {
			default: () => false
		},
		color: {
			default: () => 'primary'
		},
		dark: {
			default: () => true
		},
		label: {
			default: () => 'Upload Image'
		},
		startAlbumId: null,
		loadAlbumOptions: null,
		dropTarget: null,
		addClasses: null,
		icon: {
			default: () => 'upload'
		},
		photoCategory: {
			default: () => 'individual_category'
		}
	},
	data: () => {
		let { acceptedFileExtensions, photoConverters } = getDefaultFileExtensions();
		// Automatically append global additions like Yearbooks HEIC support
		if(window.webpack_acceptedFileExtensions) {
			acceptedFileExtensions = [...acceptedFileExtensions, ...window.webpack_acceptedFileExtensions];
		}
		if(window.webpack_photoConverters) {
			photoConverters = {...photoConverters, ...window.webpack_photoConverters};
		}

		return {
			loading: false,
			imageRequirements: {
				validTypes: ['image/jpeg', 'image/png'],
				maxSize: 26214400
			},
			acceptedFileExtensions,
			photoConverters,
			uploads: [],
			albumImportId: null,
			errorMessage: null,
			album: null
		};
	},
	mixins: [chooseFiles, processUploads, runUpload],
	computed: {
		albumId() {
			return this.startAlbumId ?? this.album?.id ?? null;
		},
		buttonColor() {
			if(this.errorMessage) {
				return 'error';
			} else {
				return this.color;
			}
		},
		buttonLabel() {
			if(this.errorMessage) {
				return this.errorMessage;
			} else {
				return this.label;
			}
		}
	},
	watch: {
		dropTarget(newValue, oldValue) {
			if(newValue == oldValue || !newValue || !newValue.addEventListener) {
				return;
			}

			this.dropTarget.addEventListener('drop', event => {
				this.droppedFiles(event);
				event.preventDefault();
			});
			this.dropTarget.addEventListener('dragover', event => {
				event.preventDefault();
			});
		}
	},
	methods: {
		async loadRequirements() {
			this.loading = true;
			let requirements = await loadRequirements();
			this.imageRequirements.validTypes = requirements.valid_content_types;
			this.imageRequirements.maxSize = requirements.max_size;

			if(this.startAlbumId) {
				this.loading = false;
			} else if(this.loadAlbumOptions) {
				this.loadAlbum();
			} else {
				this.errorMessage = 'Must pass in either an albumId or loadAlbumOptions';
				this.loading = false;
			}
		},
		selectPhoto() {
			this.$refs.fileInput.click();
		},
		selectedFiles(event) {
			this.filesSelected(event.target.files);
		},
		filesSelected: async function(files) {
			this.loading = true;
			this.errorMessage = null;
			files = await this.loadSelectedFiles(files);
			if(files.length !== 1) {
				this.onFailedUpload(null, this.i18n('uploader.errors.onePhoto'));
				return;
			}
			this.uploads = files.map((file) => {
				return createUpload({
					file,
					description: file.name
				});
			});
			this.processUploads();

			// Proccess uploads starts conversion process but does not hard wait on it - normal uploader is fine with converting during upload
			for(let i = 0; i < this.uploads.length; i++) {
				let upload = this.uploads[i];
				if(upload.checkingFormat) {
					await upload.checkingFormat;
				}
				if(upload.converting) {
					try {
						await upload.converting;
					} catch(error) {
						this.onFailedUpload(upload, error.message);
					}
				}
			}
			if(!this.uploads.length) {
				return;
			}

			// TODO: Upload all selected photos
			let upload = this.uploads[0];
			if(upload.rejectReason) {
				console.error('Attempting to upload invalid photo: ', upload.rejectReason, upload);
				this.onFailedUpload(upload, upload.rejectReason);
				return;
			}

			try {
				this.$emit('uploads-started', this.uploads);
				let albumImportResponse = await plicAxios.post(`albums/${this.albumId}/album-imports.json`, null, {
					raxConfig: {
						shouldRetry: shouldRetryAll
					}
				});
				this.albumImportId = albumImportResponse.data.album_import.id;
				this.startUpload(upload);
			} catch(error) {
				this.onFailedUpload(upload, error.message);
			}
		},
		onFinishUploadComplete: async function(upload) {
			try {
				await plicAxios.patch(`album-imports/${this.albumImportId}/finish.json`);

				this.$emit('uploads-finished', this.uploads);
			} catch(error) {
				this.onFailedUpload(upload, error.message);
			}
			this.$refs.fileInput.value = '';
			this.uploads = [];
			this.loading = false;
		},
		onFailedUpload(upload, errorMessage) {
			this.errorMessage = errorMessage;
			this.$emit('upload-failed', upload, errorMessage);
			
			this.$refs.fileInput.value = '';
			this.uploads = [];
			this.loading = false;
		},

		async loadAlbum() {
			try {
				let response = await plicAxios.get(`albums?parent_resource_type=${this.loadAlbumOptions.resourceType}&parent_resource_id=${this.loadAlbumOptions.resourceId}&tag=${encodeURIComponent(this.loadAlbumOptions.tag)}`);
				let albums = response.data.albums;
				if(albums.length === 0) {
					let createResponse = await plicAxios.post('albums', {
						album: {
							name: this.loadAlbumOptions.tag,
							tags: [
								this.loadAlbumOptions.tag
							]
						},
						parent_resource_type: this.loadAlbumOptions.resourceType,
						parent_resource_id: this.loadAlbumOptions.resourceId
					});
					this.album = createResponse.data.album;
				} else {
					this.album = albums[0];
				}
			} catch(error) {
				this.errorMessage = error;
			}
			this.loading = false;
		}
	},
	mounted() {
		this.loadRequirements();

		if(this.dropTarget) {
			this.dropTarget.addEventListener('drop', event => {
				this.droppedFiles(event);
				event.preventDefault();
			});
			this.dropTarget.addEventListener('dragover', event => {
				event.preventDefault();
			});
		}
	}
};
</script>

<style scoped>
.hiddenInput {
	display: none;
}
</style>