$.YearbookCoverPage = function(startSettings, side) {
	var obj = new $.YearbookCandidPage();
	obj._setLocked = obj.setLocked;
	obj._updatePageLabel = obj.updatePageLabel;

	$.extend(obj, {
		setLocked: function (locked) {
			this._setLocked(locked);
			this.updatePageLabel();
		},
		// Whether we can drag a theme onto the page while locked
		canThemeWhileLocked: function() {
			return true;
		},
		// If false (default) we will edit the locked page's background to match the old theme so it remains the same
		// Inter-State uses this option where they have a "fake" cover that they want updated on the front even though it is "locked"
		canThemePageWhileLocked: function() {
			return $.getStudioSetting('coverLocked', false);
		},
		hasPrimarySubject: function() {
			return !!this.getSubjects();
		},
		getSubjects: function() {
			if(this.pageSet && this.pageSet.globalPrimarySubjectData) {
				if(!this.pageSet.globalPrimarySubjectData.yearbookPhoto) {
					$.SubjectManagement.populateSubjectsWithPrimaryPhoto([this.pageSet.globalPrimarySubjectData]);
				}
				return [this.pageSet.globalPrimarySubjectData];
			} else {
				return null;
			}
		},
		updatePageLabel: function () {
			if(this.rootPage) {
				var coverFormat = this.getCoverFormat();

				if(coverFormat) {
					this.pageLabelDisplay = this.rootPage.pageLabelDisplay + ' <span style="color: var(--color-future-blue-900)">(Viewing ' + coverFormat.name + ')</span>';
				} else {
					this.pageLabelDisplay = this.rootPage.pageLabelDisplay;
				}
			}

			if(this.locked) {
				var displayLabel = this.pageLabelDisplay + ' <span style="color: red">(Locked)</span>';
				var icon = null;
				if($.getStudioSetting('coverLocked', false)) {
					var popup = $.getStudioSetting('coverLockedMessage', '');
					if(popup) {
						var elem = $('<div>').text(popup);
						elem.linkify({
							target: '_blank'
						});

						this.pageLabelPopup = {
							html: elem.html(),
							hoverable: true
						};
					} else {
						this.pageLabelPopup = null;
					}
					
					displayLabel = '';
					icon = 'lock';
				} else {
					this.pageLabelPopup = null;
				}

				if(this.pageLabelPopup) {
					this.pageLabel = {
						display: displayLabel,
						popup: this.pageLabelPopup,
						forcePopup: true,
						icon: icon
					};

					if($.getStudioSetting('coverLocked', false) && this.pageSet && this.pageSet.getTheme() && this.pageSet.hasThemePart(this.getThemePartName())) {
						this.pageLabel.sidebar = [
							{
								name: 'Themes',
								icon: 'setting',
								popup: 'Change settings on theme',
								onClick: function (page, layout) {
									layout.openThemeSettings();
								}
							},
							{
								name: 'Clear Theme',
								icon: 'picture',
								popup: 'Clear theme on book',
								onClick: function (page, layout) {
									layout.clearTheme();
								}
							}
						];
						this.pageLabel.forceSidebar = true;
					}
				} else if($.userPermissions.editCoverLocked && this.pageSet && !this.pageSet.isSubmitted()) {
					var sidebar = [
						{
							name: 'Unlock Covers',
							icon: 'unlock',
							onClick: function(page, layout) {
								page.unlockCovers(layout);
							}
						}
					];

					let previewOption = {
						name: 'Preview Cover',
						icon: 'location arrow',
						popup: 'Preview PDF of just the covers',
						onClick: function() {
							obj.renderCovers();
						}
					};
					if($.userPermissions.productionPDF) {
						$.extend(previewOption, {
							name: 'Render Cover',
							popup: 'Render a production copy of just the covers',
						});
					}
					sidebar.unshift(previewOption);

					this.pageLabel = {
						display: displayLabel,
						popup: 'Options',
						forcePopup: true,
						forceSidebar: true,
						sidebar: sidebar,
						icon: icon
					};
				} else {
					this.pageLabel = displayLabel;
				}
			} else {
				this._updatePageLabel();
				if(this.pageSet && this.pageSet.bookFormat && !this.rootPage && (this.pageSet.alternativeCoverFormats || []).length) {
					this.pageLabel.display = this.pageLabelDisplay + ' <span style="color: var(--color-future-blue-900)">(Viewing ' + this.pageSet.bookFormat.name + ')</span>';
				}
				if (this.pageLabel && this.pageLabel.sidebar) {
					this.pageLabel.popup = 'Options' + this.getLockedPopupMessage();
				} else {
					this.pageLabel.popup = '';
				}

				if(!$.getStudioSetting('coverLocked', false)) {
					var editSpreadLabel = 'Edit Spread';
					var editSpreadPopup = 'Enter design mode for the full cover spread';
					if($.flowLayout && $.flowLayout.wrapPages) {
						editSpreadLabel = 'Disable Spread';
						editSpreadPopup = null;
					}
					this.pageLabel.sidebar.push({
						name: editSpreadLabel,
						popup: editSpreadPopup,
						icon: 'edit',
						onClick: function() {
							$.flowLayout.wrapPages = !$.flowLayout.wrapPages;
							$.flowLayout.updatePagesAfterChange(true);
						}
					});

					if(this.pageSet) {
						obj = this;
						sidebar = this.pageLabel.sidebar;

						let altCovers = this.pageSet.alternativeCoverFormats || [];
						altCovers.forEach(function(coverFormat) {
							if(obj.versionId === coverFormat.formatId && obj.pageSet.bookFormat) {
								sidebar.push({
									name: obj.pageSet.bookFormat.name + ' Cover',
									popup: 'View and edit the alternative cover for ' + obj.pageSet.bookFormat.name,
									icon: 'edit',
									onClick: function() {
										obj.pageSet.pages.forEach(function(page) {
											if(page.type === 'cover') {
												page.viewingVersion = null;
											}
										});
										$.flowLayout.wrapPages = true;
										$.flowLayout.updatePagesAfterChange(true);
									}
								});
							} else {
								sidebar.push({
									name: coverFormat.name + ' Cover',
									popup: 'View and edit the alternative cover for ' + coverFormat.name,
									icon: 'edit',
									onClick: function() {
										obj.pageSet.pages.forEach(function(page) {
											if(page.type === 'cover') {
												page.viewingVersion = coverFormat.formatId;
											}
										});
										$.flowLayout.wrapPages = true;
										$.flowLayout.updatePagesAfterChange(true);
									}
								});
							}
						});

						if(altCovers.length) {
							let coversToDuplicateTo;
							if(this.versionId) {
								coversToDuplicateTo = [this.pageSet.bookFormat, ...altCovers.filter(altCover => altCover.formatId !== this.versionId)];
							} else {
								coversToDuplicateTo = altCovers;
							}
							let coverNamesToDuplicateTo = coversToDuplicateTo.map(coverFormat => coverFormat.name).join(', ');
							sidebar.push({
								name: 'Duplicate content',
								popup: 'Duplicate content on this cover to: ' + coverNamesToDuplicateTo,
								icon: 'clone',
								onClick: function(page, layout) {
									$.Confirm('Confirm', 'This will erase and completely replace the contents on covers for ' + coverNamesToDuplicateTo + ' with that is on this cover', function() {
										obj.copyContentsToAltVersions(coversToDuplicateTo, layout);
									});
								}
							});
						}
					}

					let previewOption = {
						name: 'Preview Cover',
						icon: 'location arrow',
						popup: 'Preview PDF of just the covers',
						onClick: function() {
							obj.renderCovers();
						}
					};
					if($.userPermissions.productionPDF) {
						$.extend(previewOption, {
							name: 'Render Cover',
							popup: 'Render a production copy of just the covers',
						});
					}
					this.pageLabel.sidebar.push(previewOption);

					if($.userPermissions.submitYearbook) {
						this.pageLabel.sidebar.push({
							name: 'Submit Cover',
							icon: 'location arrow',
							popup: 'Finalize and Submit Cover',
							onClick: function (page, layout) {
								if($.getProjectSetting('isDemo')) {
									$.Alert('Warning', 'This book is in demo mode.  Contact your studio to be able to finalize and submit your book.');
								} else {
									$.Confirm('Finalize and Submit Cover', 'Are you sure you want to submit the cover?', function() {
										page.submitCovers(layout);
									});
								}
							}
						});
					}
				}
			}
		},
		unlockCovers: function(layout) {
			$.ajax({
				url: 'ajax/saveProjectSettings.php',
				type: 'post',
				data: {
					jobId: $.getGETParams().jobId,
					settings: [
						{
							id: 2,
							value: false
						}
					]
				},
				error: function() {
					$.Alert('Error', 'Failed to save cover status');
				}
			});

			this.pageSet.setCoverLocked(false);
			layout.parent.updatePagesAfterChange(true);

			if ($.userEvents) {
				$.userEvents.addEvent({
					context: [this.pageSet, 'coverLocked'],
					action: 'update',
					args: [true, false],
					permanent: true
				});
			}
		},

		renderCovers: function() {
			var isProduction = !!$.userPermissions.productionPDF;
			var render = new $.PageRender({
				covers: $.yearbook.getCoverRenderTarget()
			}, {
				production: isProduction,
				flattenPDF: $.getProjectSetting('renderFlatPdf', false),
				batchName: 'Cover Preview',
				blockEmail: true,
				displayCropMarks: (isProduction ? $.getStudioSetting('renderCropMarks', false) : false),
				backgroundRender: !$.getGETParams().debugPrince,
				includeBatches: true,
				versionId: this.versionId,
				onError: function () {
					$.Alert('Error', 'Failed to render covers');
				}
			});
			render.showPDFDialog();
		},
		submitCovers: function (layout) {
			var preview = new $.PageRender(this.pageSet.getCoverSubmitTargets(), {
				production: true,
				flattenPDF: $.getProjectSetting('renderFlatPdf', false),
				completeAfterWorkerStarts: true,
				batchName: 'Covers',
				backgroundRender: true,
				includeBatches: true,
				onError: function () {
					$('#pageLoading').removeClass('active');
					$.Alert('Error', 'Failed to render covers');
				}
			});

			var me = this;

			$('#pageLoading').addClass('active');
			preview.generatePDFFromPages(function () {
				$('#pageLoading').removeClass('active');
				$.ajax({
					url: 'ajax/saveProjectSettings.php',
					type: 'post',
					data: {
						jobId: $.getGETParams().jobId,
						settings: [
							{
								id: 2,
								value: true
							}
						]
					},
					error: function() {
						$.Alert('Error', 'Failed to submit cover');
					}
				});
				me.pageSet.setCoverLocked(true);
				layout.parent.updatePagesAfterChange(true);

				if ($.userEvents) {
					$.userEvents.addEvent({
						context: [me.pageSet, 'coverLocked'],
						action: 'update',
						args: [false, true],
						permanent: true
					});
				}

				$.Alert('Success', 'Book covers submitted for rendering.  You will receive an email when it is finished and ready to be downloaded.', null, {
					allowMultiple: true
				});
			});
		},
		shouldHideInsidePageBorder: function() {
			if(!$.flowLayout) {
				return false;
			} else {
				return $.flowLayout.wrapPages;
			}
		},
		getPageNumberDisplay: function() {
			if(this.side === 'front') {
				return 'Front Cover';
			} else {
				return 'Back Cover';
			}
		},
		fixSpineTextOnFirstLoad: function(textNode) {
			var textRect = textNode.getBoundingClientRect();
			var wrapperRect = textNode.wrapper.getBoundingClientRect();

			var leftDiff = textRect.left - wrapperRect.left;
			if(leftDiff < 0) {
				return;
			}

			// Want to scoot this over to be in the middle of both pages
			textNode.changeInstanceProperty('position', {
				left: textNode.instance.position.left - (leftDiff / textNode.ratio) - (textRect.width / 2 / textNode.ratio),
				top: textNode.instance.position.top
			});
			textNode.setupPosition(textNode.instance.position);

			// TODO: Fix needing to to be in wrap mode for the overflow check to work
			var flowLayoutSet = textNode.wrapper.parent;
			if(flowLayoutSet && !flowLayoutSet.wrapPages) {
				flowLayoutSet.wrapPages = true;
				flowLayoutSet.updatePagesAfterChangeImmediately();
			}
			textNode.checkOverflowToLinkedLayouts();
		},

		copyContentsToAltVersions: function(coversToDuplicateTo, layout) {
			this.pageSet.pages.forEach(page => {
				if(page.type !== 'cover') {
					return;
				}

				let copyFromPage;
				if(this.versionId) {
					copyFromPage = page.alternativeVersions[this.versionId];
				} else {
					copyFromPage = page;
				}

				coversToDuplicateTo.forEach(coverFormat => {
					let copyToPage = page;
					if(copyToPage.alternativeVersions[coverFormat.formatId]) {
						copyToPage = copyToPage.alternativeVersions[coverFormat.formatId];
					}
					copyToPage.copyPage(copyFromPage);
				});
			});

			layout.parent.sendUserNotification('success', 'Copied contents from this cover to other covers: ' + coversToDuplicateTo.map(coverFormat => coverFormat.name).join(', '));
		},

		layout: null,
		side: side,
		type: 'cover',
		pageLabelDisplay: (side == 'front' ? 'Front' : 'Back') + ' Cover',
		pageLabelPopup: ''
	}, startSettings);
	$.FlowCoverPageCommon(obj);

	obj.updatePageLabel();
	return obj;
};