$.FlowLayoutSubjectGrid = function(options) {
	var div = document.createElement('div');
	div.className = 'flowPageCanvas';

	var CELL_DEFINITION_CHECK_FIELDS = [
		'height',
		'width',
		'padding',
		'leftPadding',
		'rightPadding',
		'topPadding',
		'bottomPadding',
		'leftExtraPadding',
		'rightExtraPadding',
		'topExtraPadding',
		'bottomExtraPadding',
		'outsidePadding',
		'insideVerticalPadding',
		'outsideVerticalPadding',
		'primaryPose',
		'waveEndCells'
	];
	var DEFAULT_CELL_WIDTH = 1.0;
	var DEFAULT_CELL_FONT_SIZE = 6;
	var DEFAULT_CELL_FONT_INCREMENT = 2 / 0.25;

	$.extend(div, {
		////// Generic stuff that should be passed from parent
		setEditable: function(editable) {
			this.editable = editable;

			this.rows.forEach(function(row) {
				row.cells.forEach(function(cell) {
					cell.setEditable(editable);
				});
			});
		},
		setRatio: function(ratio) {
			this.ratio = ratio;
		},
		isMain: function() {
			return this === this.flowLayout.canvas;
		},



		///// Editing displayed layout
		setGrid: function(grid) {
			this.horizontal = grid ? grid.horizontal : null;
			this.vertical = grid ? grid.vertical : null;
		},
		addCellsToLayout: function(definition, options) {
			options = $.extend(true, {
				updateFrames: true
			}, options);

			var horizontalMax = this.horizontal;
			if(definition.grid && definition.grid.vertical && definition.grid.horizontal) {
				this.vertical = definition.grid.vertical;
				horizontalMax = this.horizontal = definition.grid.horizontal;
			}
			if(definition.grid && definition.grid.horizontalStart) {
				horizontalMax = definition.grid.horizontalStart;
			}
			if(this.vertical == Infinity || this.horizontal == Infinity) {
				console.error('Cells should never be infinity');
				return;
			}

			// Update font if scaledNameSize changed
			if(definition.cell && definition.name && definition.cell.scaledNameSize) {
				var fontSize = definition.cell.scaledNameSize * $.PRODUCTION_RATIO / $.FONT_MULTIPLIER;
				this.studentLabelCSS.setCSS('font-size', fontSize + 'pt', true);
			}

			if(definition.topPadding) {
				let margin = definition.topPadding * this.ratio;
				$(this).css({
					marginTop: margin + 'px'
				});
			} else {
				$(this).css({
					marginTop: ''
				});
			}
			if(definition.bottomPadding) {
				let margin = definition.bottomPadding * this.ratio;
				$(this).css({
					marginBottom: margin + 'px'
				});
			} else {
				$(this).css({
					marginBottom: ''
				});
			}

			// First run just add as many as necessary
			var row;
			if(this.rows.length == 0) {
				for(let i = 0; i < this.vertical; i++) {
					row = new $.FlowLayoutRow($.extend({wrapper: this.flowLayout}, definition.row), this.ratio, {
						rowIndex: this.rows.length
					});
					this.rows.push(row);
					this.appendChild(row);
				}

				for(let i = 0; i < this.vertical; i++) {
					row = this.rows[i];
					for(let j = 0; j < horizontalMax; j++) {
						let cellDefinition = $.extend({wrapper: this.flowLayout}, definition.cell);
						row.addCell(new $.FlowLayoutCell(cellDefinition, this.ratio, {
							cellIndex: row.cells.length,
							row: row
						}));
					}

					// If decrement is set, take one off for each row
					if(definition.grid && definition.grid.horizontalDecrement) {
						horizontalMax -= definition.grid.horizontalDecrement;
					}
				}

				this.rows.forEach(function(row) {
					row.updateCellsHidden();
				});
			} else {
				// Remove any extra rows
				var updateRows = false;
				// If we are deleting rows and we have outside padding, we will need to update cell definitions
				if(this.rows.length > this.vertical) {
					if(definition.cell.outsideVerticalPadding) {
						updateRows = true;
					}
				}
				while(this.rows.length > this.vertical) {
					let row = this.rows.pop();
					row.destroy();
					$(row).remove();
				}

				// Figure out whether we have to do a wholesale update or can just change cell count
				for(let i = 0; i < CELL_DEFINITION_CHECK_FIELDS.length; i++) {
					var prop = CELL_DEFINITION_CHECK_FIELDS[i];
					if(definition.cell[prop] != this.cellDefinition[prop]) {
						updateRows = true;
						break;
					}
				}
				if(definition.cell.studentLabelCSS && definition.cell.studentLabelCSS.css &&
						definition.cell.studentLabelCSS.css['font-size-multiplier'] != this.cellDefinition.studentLabelCSS.css['font-size-multiplier']) {
					updateRows = true;
				}

				// Add new rows
				var updateRowCount = this.rows.length;
				while(this.vertical > this.rows.length) {
					row = new $.FlowLayoutRow($.extend({wrapper: this.flowLayout}, definition.row), this.ratio, {
						rowIndex: this.rows.length
					});
					this.rows.push(row);
					this.appendChild(row);
					for(let j = 0; j < horizontalMax; j++) {
						let cellDefinition = $.extend({wrapper: this.flowLayout}, definition.cell);
						row.addCell(new $.FlowLayoutCell(cellDefinition, this.ratio, {
							cellIndex: row.cells.length,
							row: row
						}));
					}

					// If decrement is set, take one off for each row
					if(definition.grid && definition.grid.horizontalDecrement) {
						horizontalMax -= definition.grid.horizontalDecrement;
					}
					row.updateCellsHidden();
				}

				// Go through each row and update it's definition to match changes
				// Want to update after adding rows so isLastRow is correct in padding calculations
				for(let i = 0; i < updateRowCount; i++) {
					if(updateRows) {
						this.rows[i].changeCells(horizontalMax, definition.cell);
					} else {
						// This is much faster to call if there are no changes to make
						this.rows[i].changeCellCount(horizontalMax, definition.cell);
					}

					// If decrement is set, take one off for each row
					if(definition.grid && definition.grid.horizontalDecrement) {
						horizontalMax -= definition.grid.horizontalDecrement;
					}
				}
			}
			this.flowLayout.applySubjectEffects();

			// Keep track of the cell definition to see if we need to update cell's
			this.cellDefinition = $.extend(true, {}, definition.cell ? definition.cell : {});

			// Recalculate hidden area for each cell after change
			var cells = $(this).find('.flowCell:not(.rowHeader)');

			// This must be before updateFrames because that exists prematurely
			this.maxUsers = cells.length;
			var page = this.flowLayout.getPage();
			if(page && page.setMaxCells) {
				page.setMaxCells(this.maxUsers);
			}

			if(options.updateFrames) {
				var frames = this.getBlockingContent();
				if(frames.length) {
					var invisibleCells = cells.filter('.invisibleCell').removeClass('invisibleCell');
					var diff = 0;
					frames.each(function() {
						// If we pass in only the sub-grids cells, then we will un-hide something that is blocking the main canvas cells
						let cells = $(this.wrapper).find('.flowCell:not(.rowHeader)').not($(this).find('.flowCell:not(.rowHeader)'));
						diff += (this.hideOverlap(cells) || 0);
					});

					if(diff) {
						return false;
					} else if(cells.length) {
						invisibleCells.addClass('invisibleCell');
					}
				}
			}

			// Only hide sub grid's matching on main grid
			if(this.isMain()) {
				this.flowLayout.extraSubjectGrids.forEach(function(subjectGrid) {
					subjectGrid.hideOverlap(cells);
				});
			}

			if(definition.cell && definition.cell.horizontalOverlap) {
				if(!this.mouseMoveGridHandler) {
					this.addEventListener('mousemove', this.mouseMoveGridHandler = (event) => {
						$(this).find('.flowContentHovering').removeClass('flowContentHovering');
						if($(this).find('.ui-draggable-dragging').length) {
							return;
						}
						var topElement = document.elementFromPoint(event.clientX, event.clientY);
						if(topElement.rootNode) {
							topElement  = topElement.rootNode;
						}
						if(!$(topElement).hasClass('flowCell') || !topElement.getClosestOverlap) {
							return;
						}

						let closestOverlap = topElement.getClosestOverlap(event);
						if(closestOverlap && closestOverlap !== topElement) {
							$(closestOverlap).addClass('flowContentHovering');
						} else {
							$(topElement).addClass('flowContentHovering');
						}
					});
					this.addEventListener('mouseout', this.mouseOutGridHandler = (event) => {
						$(this).find('.flowContentHovering').removeClass('flowContentHovering');
					});
				}
			} else if(this.mouseMoveGridHandler) {
				this.removeEventListener('mousemove', this.mouseMoveGridHandler);
				this.removeEventListener('mouseout', this.mouseOutGridHandler);
				this.mouseMoveGridHandler = this.mouseOutGridHandler = null;
			}

			return true;
		},
		updateCellDefinition: function(definition) {
			this.calculateCells(definition, true);
			this.horizontal = definition.grid.horizontal;
			this.vertical = definition.grid.vertical;
			this.addCellsToLayout(definition);
		},
		
		checkUpdateRowCount: function(options = {}) {
			// Make parent recalculate how many rows we should have
			var definition = $.extend(true, {}, this.flowLayout.definition);
			if(!definition || !definition.grid) {
				return;
			}

			var startRows = this.rows.length;
			this.calculateCells(definition);
			var endRows = definition.grid.vertical;

			// Save layout if there was a change
			if(startRows != endRows) {
				// Skip member set function since we don't want to save this change
				var page = this.flowLayout.getPage();
				page.layout = definition;
				this.flowLayout.definition = definition;

				// Ask parent to remove the last rows
				for(let i = endRows; i < startRows; i++) {
					this.removeLastRow();
				}

				// Add new rows
				for(let i = startRows; i < endRows; i++) {
					this.addLastRow();
				}

				if(options.triggerOverflowPrompt) {
					this.flowLayout.addUsersWithRollbackPrompt();
				} else {
					this.flowLayout.addUsers();
				}
			}
		},
		addLastRow: function(definition) {
			if(!definition) {
				definition = this.flowLayout.definition;
			}

			let row = new $.FlowLayoutRow($.extend({wrapper: this.flowLayout}, definition.row), this.ratio, {
				rowIndex: this.rows.length
			});
			this.rows.push(row);
			this.appendChild(row);
			for(let j = 0; j < this.horizontal; j++) {
				let cellDefinition = $.extend({wrapper: this.flowLayout}, definition.cell);
				row.addCell(new $.FlowLayoutCell(cellDefinition, this.ratio, {
					cellIndex: row.cells.length,
					row: row
				}));
			}
			this.vertical++;
		},
		removeLastRow: function() {
			if(this.rows.length > 0) {
				var lastRow = this.rows[this.rows.length - 1];
				$(lastRow).remove();
				lastRow.destroy();
				this.rows.splice(this.rows.indexOf(lastRow), 1);
				this.vertical--;
				return lastRow;
			} else {
				console.warn('trying to delete missing row');
			}
		},

		removeCurrentGrid: function() {
			while(this.rows.length > 0) {
				let row = this.rows.pop();
				row.destroy();
				this.removeChild(row);
			}

			delete this.lastManualFontSize;
		},




		///// Laying subjects out on grid
		addUsers: function(users, allowOverflow, params) {
			var page = this.flowLayout.getPage();
			var initUsers = $.merge([], users || []);
			if(!params) {
				params = {
					addUsersDepth: 1
				};
			} else {
				params = $.extend({}, params);

				if(params.addUsersDepth) {
					params.addUsersDepth++;
				} else {
					params.addUsersDepth = 1;
				}
			}

			// Get first valid individualized subject if that is what we are on
			if(this.flowLayout.largeCellUser == -1) {
				var isIndividualizedLayout = page.isIndividualizedLayout() || (this.largeCell && this.largeCell.require === '*');
				if(this.largeCell || isIndividualizedLayout) {
					if(page.getNextIndividualIndex && isIndividualizedLayout) {
						this.flowLayout.largeCellUser = page.getNextIndividualIndex(-1);
					} else {
						this.flowLayout.largeCellUser = 0;
					}
					this.flowLayout.refreshFilteredTexts((textNode) => {
						const text = textNode.getInstanceText();
						return text.includes('1%');
					});
				}
			}

			// Reset invisible status of all cells
			$(this).find('.invisibleCell').removeClass('invisibleCell');
			$(this).find('.justifySpaceAround').removeClass('justifySpaceAround');

			var layout = this.flowLayout.getLayout(), reCalculate = false;
			// Layout is null when we are focused on the title during transition
			if(!layout) {
				return;
			}
			if(!this.isMain() && params.addUsersDepth <= 1) {
				layout = $.extend(true, {}, layout);

				if(layout.cell && layout.name) {
					if(layout.cell.studentLabelCSS) {
						$.extend(this.studentLabelCSS.css, layout.cell.studentLabelCSS.css);
					}
					layout.cell.studentLabelCSS = this.studentLabelCSS;
				}
				if(layout.row && layout.row.position && layout.name) {
					layout.row.studentLabelCSS = this.studentLabelCSS;
				}
			}

			if(layout.row && layout.row.groupBy && layout.row.groupByOrder === -1) {
				users = $.merge([], users);
				var groupBy = layout.row.groupBy;
				var appendUsers = [];
				for(let i = 0; i < users.length; i++) {
					var user = users[i];

					var matchUserGroup = user[groupBy];
					if(groupBy == 'Teacher Priority') {
						matchUserGroup = !!matchUserGroup;
					}

					if(matchUserGroup) {
						appendUsers.push(user);
						users.splice(i, 1);
						i--;
					}
				}

				$.merge(users, appendUsers);
			}

			// If this layout is defined to cell size: fit, then recalculate based off of number of users
			if(layout.cell && layout.cell.size == 'fit' && arguments.callee.caller != this.addUsers) {
				// Try to make sure we recalculate everything to fit
				this.calculateCellsToFit(layout, users, undefined, {
					isForcedRetry: params.isForcedRetry
				});
				// Lets try this again if the first time this returns a difference in frames cells overlap
				reCalculate = !this.addCellsToLayout(layout);
			} else if(page.layoutChanged && page.getType() == 'classOverflow') {
				// We changed the layout from a non-root page, start from beginning
				var rootPage = page.getRootPage();
				this.flowLayout.updateOverflowPages(rootPage, rootPage.getKids().slice(this.maxUsers), true, params);
				users = page.getKids();
				allowOverflow = false;
			} else if(allowOverflow !== false && page.getType() == 'classOverflow') {
				// Random change on overflow page, users = this's overflow + all kids past this
				users = $.merge([], users);
				let overflowPage = page.getOverflowPage();
				while(overflowPage) {
					let overflowKids = overflowPage.getKids();
					if(overflowKids) {
						users.push(...overflowKids.filter(overflowKid => !users.includes(overflowKid)));
					}
					overflowPage = overflowPage.getOverflowPage();
				}
			}
			// Want to keep track of users including overflow additions that might be used to get list of usedUsers
			let passedToRowsUsers = $.merge([], users);

			// Go through and clear existing large cells so they don't interfere with new positioning
			if(this.largeCell) {
				var largeCells = $(this).find('.flowCell.largeCell');
				for(let i = 0; i < largeCells.length; i++) {
					largeCells[i].setLargeCell(false);
				}

				// Reset large cell position
				if(page && page.getLargeCellPosition && page.getLargeCellPosition()) {
					let largeCellPosition = page.getLargeCellPosition();
					if($.isInit(largeCellPosition.col) && this.largeCell.col != 'center') {
						this.largeCell.col = largeCellPosition.col;

						if(typeof this.largeCell.col === 'number' && this.largeCell.col < 0) {
							this.largeCell.col = 0;
						}
					}

					if($.isInit(largeCellPosition.row)) {
						this.largeCell.row = largeCellPosition.row;
						if(typeof this.largeCell.row === 'number' && this.largeCell.row < 0) {
							this.largeCell.row = 0;
						}
					}
				}
			}

			let startParams, startParamsRow = -1, largeCellCount = 0;
			let largeCellPosition;
			if(page.getLargeCellPosition && this.largeCell) {
				largeCellPosition = page.getLargeCellPosition();
			} else {
				largeCellPosition = this.largeCell;
			}

			var startUsers = users || [];
			this.getCells().filter('.hiddenCell.tempHiddenCell:not(.behindFrame, .forceHiddenCell)').removeClass('hiddenCell tempHiddenCell');
			var retriedFromStart = false;
			for(let i = 0; i < this.rows.length; i++) {
				let row = this.rows[i];

				// Figure out where large cell should be before starting
				var largeCellCol, largeCellRow;
				if(users.length && largeCellPosition) {
					if(users[0].classBreak && largeCellPosition.extras && largeCellPosition.extras[users[0].classBreak.id]) {
						var mergedBatchPosition = largeCellPosition.extras[users[0].classBreak.id];
						largeCellCol = mergedBatchPosition.col;
						largeCellRow = mergedBatchPosition.row;
					} else {
						largeCellCol = largeCellPosition.col || 0;
						largeCellRow = largeCellPosition.row || 0;
					}
				}

				if(largeCellRow >= (this.rows.length - 1)) {
					largeCellRow = this.rows.length - this.largeCell.rowSpan;
				}
				if(largeCellRow > 0 && (this.largeCell.require != 'teacher' || (users.length && users[0]['Teacher Priority'])) && (!this.largeCell.max || largeCellCount < this.largeCell.max)) {
					var startLargeCellCount = largeCellCount, blockRemoveTitleBreak = false;
					// Merged batches need to start at whatever row this is as 0
					for(let j = largeCellRow + i; j <= (this.rows.length - this.largeCell.rowSpan) && largeCellCount === startLargeCellCount; j++) {
						startParams = {};
						let options = {
							allowBreak: i != 0,
							onlyFillLargeCell: true,
							largeCellCount: largeCellCount,
							largeCellUser: this.flowLayout.largeCellUser,
							classBreakRow: row
						};
						if(j != (largeCellRow + i)) {
							options.largeCellCol = 0;
						} else {
							options.largeCellCol = largeCellCol;
						}

						users = this.rows[j].addUsers(users, options, startParams);
						largeCellCount = options.largeCellCount;
						startParamsRow = j;
					}

					if(startLargeCellCount === largeCellCount) {
						largeCellCount++;
						startParamsRow = -1;

						// Failed to find a spot for largeCell.  Wrap around at 0
						largeCellCol = 0;
					} else {
						blockRemoveTitleBreak = true;
					}
				}

				// Add users to each row, only allowing breaks if not the first line
				let options = {
					allowBreak: i != 0,
					largeCellCount: largeCellCount,
					largeCellUser: this.flowLayout.largeCellUser,
					lastRow: i == (this.rows.length - 1),
					largeCellCol: largeCellCol,
					layout: layout,
					side: this.flowLayout.side,
					blockRemoveTitleBreak: blockRemoveTitleBreak,
					isTitleBreak: false
				};

				// Optionally fill in start params
				if(i === startParamsRow) {
					options['startParams'] = startParams;
				}

				startUsers = users || [];
				users = row.addUsers(users, options);

				// If we had to go down to a lower row to place large cell, try restarting back at the top again to make sure we don't have blank rows
				if(largeCellCount === 0 && options.largeCellCount > 0 && i > 0 && !largeCellRow && !retriedFromStart && !options.isTitleBreak) {
					var largeCell = $(row).find('.flowCell.largeCell')[0];
					var largeCellIndex = row.cells.indexOf(largeCell);
					if(largeCellIndex === -1 || largeCellIndex >= row.cells.length - 1) {
						largeCellIndex = 0;
					}
					largeCellPosition = $.extend(true, {
						col: largeCellIndex,
						row: i
					}, largeCellPosition);
					largeCellPosition.row = i;
					users = $.merge([], initUsers);
					i = -1;
					$(this).find('.flowCell.largeCell').each(function() {
						this.setLargeCell(false);
					});
					retriedFromStart = true;
					continue;
				}
				largeCellCount = options.largeCellCount;

				// On last row, check centered correctly
				if(row.centered) {
					var cells = $(row).find('.flowCell');
					var behindFrames = cells.filter('.behindFrame');
					// Make sure nothing is overlapped
					var startValid = true;
					var contentOverlapSubjectLabels = !page || page.doesContentOverlapSubjectLabels();
					if(behindFrames.length) {
						behindFrames.each(function() {
							if(!$(this).isAttached()) {
								return;
							}
							
							$.each($.unique($(this).data('behindFrames')), function() {
								var overlaps = $(this).overlaps(cells, {
									includeBaseLabelHeight: !contentOverlapSubjectLabels,
									ratio: div.ratio
								});
								if(overlaps.not('.invisibleCell, .behindFrame').length) {
									startValid = false;
									return false;
								}
							});

							if(!startValid) {
								return false;
							}
						});

						if(!startValid) {
							users = this.checkIfShouldCenterBottomRow(row, i, behindFrames, startUsers);
						}

						// Try making them invisible as well and see if we are still valid
						var emptyValidCells = $(row).find('.flowCell.invisibleCell:not(.hiddenCell)');
						if((i == (this.rows.length - 1) || emptyValidCells.length > 0) && startValid) {
							behindFrames.addClass('invisibleCell');
							if($(row.cells).filter('.invisibleCell').length === row.cells.length) {
								behindFrames.removeClass('invisibleCell');
							} else {
								// Check still valid
								var valid = true;
								behindFrames.each(function() {
									$.each($.unique($(this).data('behindFrames')), function() {
										var overlaps = $(this).overlaps(cells, {
											includeBaseLabelHeight: !contentOverlapSubjectLabels,
											ratio: div.ratio
										});
										if(overlaps.not('.invisibleCell').length) {
											valid = false;
											return false;
										}
									});

									if(!valid) {
										return false;
									}
								});

								if(!valid) {
									behindFrames.removeClass('invisibleCell');
								}
							}
						}

						if($.getProjectSetting('moveSubjectsToKeepRowsEven', true) && i > 0 && behindFrames.not('.invisibleCell').length && $(row).find('.flowCell:not(.rowHeader, .hiddenCell)').filter(function() {
							return !this.user;
						}).length % 2 === 1) {
							users = this.checkIfShouldCenterBottomRow(row, i, behindFrames, startUsers);
						}
					} else if($.getProjectSetting('moveSubjectsToKeepBottomRowFilled', false) && i === this.rows.length - 1) {
						this.checkIfShouldShiftToBottomRow(row, options);
					}
				}

				if(options.skipNextRow) {
					i++;
				}
			}
			// Need to have all subjects added for z-index: single-wave to work
			this.setupCellZIndex();

			// Calculate what this page's kids are if before it was a guessed count since now we know the exact
			// Don't update until after the overflow logic since it needs to think this is a guessed count for it to work
			var usersUsed = null;
			if(page.isGuessedCount) {
				usersUsed = $.makeArray($(passedToRowsUsers).not(users));
			}

			var startsFromRoot = page.getType() == 'class';
			var errorOnMissingSubjects = false;
			if(users.length) {
				if(page && page.getOverflowPage) {
					// Create new overflow page
					if(!page.getOverflowPage()) {
						// If in here on non-editable page, it's a bug and don't let it screw things up!
						if(this.editable) {
							if(params.allowCreatingPages !== false) {
								var currentPage = page;
								// It's a bug if maxUsers is 0, but we need to not freeze
								if(this.maxUsers <= 0) {
									$.fireErrorReport(null, 'Invalid maxUsers', 'maxUsers cannot be 0', {
										currentPage: this.flowLayout.getSnapshot(),
										maxUsers: this.maxUsers,
										remainingUsers: users.length
									});
								}

								if(params.onCreatePage) {
									if(usersUsed) {
										// Put unused users back on the list so if we call this later we aren't just losing them
										$.merge(usersUsed, users);
									}

									params.onCreatePage({
										success: () => {
											this.flowLayout.addUsers();
										}
									});
								} else {
									var pageAdded = false;
									while(users.length && this.maxUsers) {
										let overflowPage = currentPage.createOverflowPage(users.slice(0, this.maxUsers));
										pageAdded = this.flowLayout.getPageSet().addPage(overflowPage, currentPage.getPageNumber() - 1);
										if(pageAdded) {
											currentPage.setOverflowPage(overflowPage);
										} else {
											currentPage.setOverflowPage(null);
											break;
										}

										// Cleanup for next round
										currentPage = overflowPage;
										users = users.slice(this.maxUsers);
									}

									if(pageAdded) {
										this.flowLayout.setOverflowPage(page.getOverflowPage());
										this.flowLayout.onOverflowPageAdded();
									} else {
										console.warn('users with page assignments can\'t add overflow pages properly');
									}
								}
							} else if(usersUsed) {
								// Put unused users back on the list so if we call this later we aren't just losing them
								$.merge(usersUsed, users);
							}
						} else {
							console.warn('overflow users on page', users);
							if(this.flowLayout.errorOnMissingSubjects) {
								errorOnMissingSubjects = true;
							} else {
								this.flowLayout.setLabelError('Failed to add ' + users.length + ' subjects to layout on page ' + page.getPageReference());
							}
							
							if(usersUsed) {
								$.merge(usersUsed, users);
							}
						}
					} else {
						// If we have not already added extra from other pages, add them now since we are re-doing overflows
						if(allowOverflow === false) {
							let overflowPage = page.getOverflowPage();
							var checkUsers = $.merge([], startUsers);
							while(overflowPage) {
								let overflowKids = overflowPage.getKids();
								if(overflowKids) {
									// Merge until we get to duplicates
									overflowKids.forEach(function(overflowKid, i) {
										if(checkUsers.indexOf(overflowKid) != -1) {
											return false;
										} else {
											users.push(overflowKid);
											checkUsers.push(overflowKid);
										}
									});
								}
								overflowPage = overflowPage.getOverflowPage();
							}
						}

						this.flowLayout.updateOverflowPages(page, users, startsFromRoot, params);

						if(this.flowLayout.childLayout && !page.layoutChanged) {
							this.flowLayout.childLayout.addUsers(null, false, {
								allowCreatingPages: params.allowCreatingPages
							});
						}
					}
				}
			} else if(page && page.getOverflowPage && page.getOverflowPage() && (allowOverflow !== false || startsFromRoot) && this.editable && params.allowCreatingPages !== false) {
				// TODO: Figure out if this is still needed, causes issue where add overflow to next page, then take it back and doesn't remove
				// If parentPage and parentLayout is set, previous page call to this already did necessary logic
				// if(!page.parentPage) {
				this.flowLayout.setOverflowPage(null);
				// }
			}

			// Update number of kids if it is different then what we thought it would be
			if(usersUsed) {
				page.setKids(usersUsed);
			}
			if(page.setViableCells && (this.editable || this.flowLayout.editable)) {
				var viableCells = $(this).find('.flowCell:not(.rowHeader, .hiddenCell, .behindFrame, .invisibleCell)').length;

				// For merged batches remove empty cells above this as valid cells
				// Otherwise we get into cases where we have 40 viable cells, but 5 of them are blocked by the new batch in reality
				var extraTitles = $(this).find('.flowTitle').not(this.flowLayout.titleDiv);
				extraTitles.each(function() {
					var previousRow = this.previousSibling;
					if(previousRow) {
						var emptyCells = $(previousRow).find('.flowCell.emptyCell:not(.rowHeader, .hiddenCell, .behindFrame, .invisibleCell)').length;
						viableCells -= emptyCells;
					}
				});

				// If next this batch ends the page but we have more empty spots, make sure to count those
				if(users && users.length && users[0].classBreak) {
					var lastRow = this.rows[this.rows.length - 1];
					var emptyCells = $(lastRow).find('.flowCell.emptyCell:not(.rowHeader, .hiddenCell, .behindFrame, .invisibleCell)').length;
					viableCells -= emptyCells;
				}

				var startViableCells = page.getViableCells();
				page.setViableCells(viableCells);

				// If we guessed viableCells too low and we have an overflow page to take users from
				if(startViableCells && viableCells > startViableCells && users.length === 0 && page.getOverflowPage && page.getOverflowPage() && allowOverflow === false && arguments.callee.caller != this.addUsers) {
					this.addUsers(initUsers, true, params);

					if(!$.isRecursive() && layout.name && layout.cell && layout.cell.name !== 'none') {
						this.updateLabelCSS();
						this.normalizeSubjectLabels();
					}
					return;
				}
			}
			if(page.layoutChanged) {
				page.layoutChanged = false;
			}

			// TODO: Figure out a way around this crappy hack!
			// After adding users, layouts with row header will have changed everything relative to frames
			// Recalculate hidden cells, then have to re add users again to only visible cells
			// Similar situation with adding title breaks
			if(($(this).has('.rowHeader').length || (layout.row && layout.row.centered)) && arguments.callee.caller != this.addUsers) {
				if(this.flowLayout.hideFrameOverflow()) {
					let redoUsers = this.addUsers(initUsers, null, params);
					if(redoUsers && $.isArray(redoUsers)) {
						users = redoUsers;

						var startingUsedCells = this.getUsedCells();
						if(this.flowLayout.hideFrameOverflow()) {
							if(layout.cell && layout.cell.size == 'fit') {
								reCalculate = true;
							} else if(!reCalculate) {
								let endingUsedCells = this.getUsedCells();
								if(startingUsedCells.length !== endingUsedCells.length || !startingUsedCells.is(endingUsedCells)) {
									if(this.flowLayout.errorOnMissingSubjects) {
										throw 'Missing subjects on page ' + page.getPageReference();
									} else if(startingUsedCells.length > endingUsedCells.length) {
										this.flowLayout.setLabelError('Failed to add ' + (startingUsedCells.length - endingUsedCells.length) + ' subjects to layout on page ' + page.getPageReference());
									}
								}
							}
						} else {
							reCalculate = false;
						}
					}
				}
			}

			// Do recalculation after users are added since centering can throw things off
			if(reCalculate && !$.isRecursive()) {
				var startDefinition, startVertical, startHorizontal;
				if(users.length === 0) {
					startDefinition = $.extend(true, {}, layout);
					startHorizontal = this.horizontal;
					startVertical = this.vertical;
				}
				var layoutDifference = this.calculateCellsToFit(layout, initUsers, undefined, {
					isForcedRetry: params.isForcedRetry
				});
				var frameDifference = this.addCellsToLayout(layout);
				if(!(layoutDifference || !frameDifference) && users.length) {
					layoutDifference = this.calculateCellsToFit(layout, initUsers, undefined, {
						isForcedRetry: 2
					});
					frameDifference = this.addCellsToLayout(layout);

					if(!(layoutDifference || !frameDifference)) {
						layoutDifference = this.calculateCellsToFit(layout, initUsers, undefined, {
							isForcedRetry: 4
						});
						frameDifference = this.addCellsToLayout(layout);
					}
				}

				if(layoutDifference || !frameDifference) {
					this.addUsers(initUsers, null, {
						addUsersDepth: params.addUsersDepth,
						revertDefinition: startDefinition,
						revertHorizontal: startHorizontal,
						revertVertical: startVertical
					});
				} else if(users.length) {
					$.fireErrorReport(null, 'Overflow on fit', 'There should never be overflow on fit pages. Recalculate failed after single retry: (' + users.length + ')', {
						snapshot: this.flowLayout.getSnapshot()
					});

					this.flowLayout.setLabelError('Failed to add ' + users.length + ' subjects to layout on page ' + page.getPageReference());
				} else if(layout.cell && layout.cell.size == 'fit' && startingUsedCells) {
					// Can be cases due to bugs that cause the reCalculate = true check above to hide subjects so we need to check on actual laid out users and not just users.length
					let endingUsedCells = this.getUsedCells();
					var missingSubjects = startingUsedCells.length - endingUsedCells.length;
					if(missingSubjects > 0) {
						$.fireErrorReport(null, 'Overflow on fit', 'There should never be overflow on fit pages. Recalculate failed after checking if any overlap: (' + missingSubjects + ')', {
							snapshot: this.flowLayout.getSnapshot()
						});
	
						this.flowLayout.setLabelError('Failed to add ' + missingSubjects + ' subjects to layout on page ' + page.getPageReference());
					}
				}
			} else if(users.length && (!page || !page.getOverflowPage)) {
				var underMaxRecursion = params.addUsersDepth && params.addUsersDepth < 5 && layout.cell && layout.cell.size == 'fit';

				if(params && params.revertDefinition && !underMaxRecursion) {
					if(!params.revertedDefinition) {
						// On retry we guessed wrong, so let's go back to last known definition with no overflow
						$.extend(layout, params.revertDefinition);
						this.horizontal = params.revertHorizontal;
						this.vertical = params.revertVertical;
						this.addCellsToLayout(layout);

						params.revertedDefinition = true;
						this.addUsers(initUsers, null, params);
					} else {
						$.fireErrorReport(null, 'Overflow on fit', 'There should never be overflow on fit pages. Recalculate failed and reverted: (' + users.length + ')', {
							snapshot: this.flowLayout.getSnapshot()
						});

						this.flowLayout.setLabelError('Failed to add ' + users.length + ' subjects to layout on page ' + page.getPageReference());
					}
				} else if(underMaxRecursion) {
					// With multiple group composites ended up with a case where one of the recursions failed but a different recursion path worked, so reset label error on retry
					this.flowLayout.setLabelError(false);
					var difference = this.calculateCellsToFit(layout, initUsers, undefined, {
						isForcedRetry: params.isForcedRetry
					});
					this.addCellsToLayout(layout);
					if(difference) {
						if(params.addUsersDepth > 2 && !params.isForcedRetry) {
							params.isForcedRetry = 1;
						}
						let redoUsers = this.addUsers(initUsers, null, params);
						if(redoUsers && $.isArray(redoUsers)) {
							users = redoUsers;
						}
					} else {
						difference = this.calculateCellsToFit(layout, initUsers, undefined, {
							isForcedRetry: 2
						});
						this.addCellsToLayout(layout);
						if(difference) {
							params.isForcedRetry = 2;
							let redoUsers = this.addUsers(initUsers, null, params);
							if(redoUsers && $.isArray(redoUsers)) {
								users = redoUsers;
							}
						} else {
							difference = this.calculateCellsToFit(layout, initUsers, undefined, {
								isForcedRetry: 4
							});
							this.addCellsToLayout(layout);
							if(difference) {
								params.isForcedRetry = 4;
								let redoUsers = this.addUsers(initUsers, null, params);
								if(redoUsers && $.isArray(redoUsers)) {
									users = redoUsers;
								}
							} else {
								$.fireErrorReport(null, 'Overflow on fit', 'There should never be overflow on fit pages. Recalculate failed with no change after retry: (' + users.length + ')', {
									snapshot: this.flowLayout.getSnapshot()
								});

								this.flowLayout.setLabelError('Failed to add ' + users.length + ' subjects to layout on page ' + page.getPageReference());
							}
						}
					}
				} else {
					$.fireErrorReport(null, 'Overflow on fit', 'There should never be overflow on fit pages. Recalculate failed after max attempts: (' + users.length + ')', {
						snapshot: this.flowLayout.getSnapshot()
					});

					this.flowLayout.setLabelError('Failed to add ' + users.length + ' subjects to layout on page ' + page.getPageReference());
				}
			} else if(users.length && errorOnMissingSubjects) {
				throw 'Missing subjects on page ' + page.getPageReference();
			}

			// Update title in case a page break changed it
			this.flowLayout.titleDiv.setTitle(page.getTitle());

			// Check if any of the subject fonts had to be shrunk, and if they have apply them across the board
			if(!$.isRecursive() && layout.name && layout.cell && layout.cell.name !== 'none') {
				this.updateLabelCSS();
				this.normalizeSubjectLabels();
			}

			if(this.editable && this.horizontal  && this.isMain()) {
				var cachedGrid = page.getExtraProperty('cachedGrid');
				if(!cachedGrid || cachedGrid.horizontal != this.horizontal || cachedGrid.vertical != this.vertical) {
					page.setExtraProperty('cachedGrid', {
						horizontal: this.horizontal,
						vertical: this.vertical
					}, {
						permanent: true
					});
				}
			}

			return users;
		},
		checkIfShouldCenterBottomRow: function(row, rowIndex, behindFrames, startUsers) {
			// If invalid, remove invisible cells and resetup everything on this row
			$(row).find('.invisibleCell').removeClass('invisibleCell');

			var allCells = $(this).find('.flowCell:not(.rowHeader)');
			behindFrames.each(function() {
				$.each($.unique($(this).data('behindFrames')), function() {
					if($(this).hasClass('flowLayoutFrame') || $(this).hasClass('flowFreeText')) {
						this.hideOverlap(allCells);
					}
				});
			});

			var users = row.addUsers(startUsers, $.extend({
				blockHide: true
			}, options));

			// Try to recenter cells around nodes blocking stuff
			var validCells = $(row).find('.flowCell:not(.rowHeader, .hiddenCell)');
			var emptyValidCells = validCells.filter(function() {
				return !this.user;
			});

			// Try to grab cell from previous row to make it even
			if($.getProjectSetting('moveSubjectsToKeepRowsEven', true) && rowIndex > 0 && emptyValidCells.length % 2 === 1) {
				// Make sure nothing blocking row above us
				var previousRow = this.rows[rowIndex - 1];
				var hiddenCellsInPreviousRow = $(previousRow).find('.flowCell.hiddenCell, .flowCell.invisibleCell');

				// Want to make sure that we don't move kids down into a teacher only row
				if(hiddenCellsInPreviousRow.length === 0 && previousRow.groupByValue == row.groupByValue) {
					var validCellsInPreviousRow = $(previousRow).find('.flowCell:not(.rowHeader, .hiddenCell)');
					var lastCell = validCellsInPreviousRow[validCellsInPreviousRow.length - 1];
					var lastCellUser = lastCell.user;

					lastCell.setUser(null);
					$(lastCell).addClass('invisibleCell');

					for(let j = validCells.length - 1; j >= 0; j--) {
						var copyIndex = j - 1;
						var copyUser = copyIndex >= 0 ? validCells[copyIndex].user : null;

						validCells[j].setUser(copyUser);
					}
					validCells[0].setUser(lastCellUser);

					emptyValidCells = validCells.filter(function(cell) {
						return !this.user;
					});
				}
			}

			if(emptyValidCells.length > 1) {
				var shiftCellsOver = Math.floor(emptyValidCells.length / 2);

				for(let j = validCells.length - 1; j >= 0; j--) {
					copyIndex = j - shiftCellsOver;
					copyUser = copyIndex >= 0 ? validCells[copyIndex].user : null;

					validCells[j].setUser(copyUser);
				}
			}

			return users;
		},
		checkIfShouldShiftToBottomRow: function(row, rowOptions) {
			if(row.getFilledCells().length >= (row.cells.length / 4 * 3)) {
				return;
			}

			var previousFullRows = [];
			var previousRow = row.previousSibling;
			while(previousRow && $(previousRow).hasClass('flowRow')) {
				if(previousRow.getFilledCells().length === previousRow.cells.length) {
					previousFullRows.push(previousRow);
				} else {
					break;
				}

				previousRow = previousRow.previousSibling;
			}
			previousFullRows.reverse();

			var startPreviousRowsLength = row.cells.length;
			var previousRowsLength = startPreviousRowsLength;
			var bottomRowFilledCells = row.getFilledCells().length;
			var emptySpots = row.getValidCells() - bottomRowFilledCells;
			
			// We want to take as many cells as possible while keeping bottom row less than or even with previous rows
			var takeFromRows = 0;
			while(emptySpots > 0 && (bottomRowFilledCells + previousFullRows.length) < previousRowsLength) {
				emptySpots -= previousFullRows.length;
				bottomRowFilledCells += previousFullRows.length;

				previousRowsLength--;
				takeFromRows++;
			}
			if(takeFromRows === 0) {
				return;
			}

			var users = [];
			previousFullRows.forEach(function(row) {
				$.merge(users, row.getUsers());
			});
			$.merge(users, row.getUsers());

			var justifySpaceAround = true;
			if(this.flowLayout.definition.cell.distributeSpacingOutsideLayout) {
				justifySpaceAround = false;
			}
			var previousRowsJustified = previousFullRows.map(function(row) {
				return $(row).hasClass('justifySpaceAround');
			})
			var rowHasJustified = $(row).hasClass('justifySpaceAround');
			previousFullRows.forEach(function(previousRow, rowIndex) {
				var startIndex = rowIndex * previousRowsLength;
				previousRow.addUsers(users.slice(startIndex, startIndex + previousRowsLength), rowOptions);

				if(justifySpaceAround) {
					$(previousRow).addClass('justifySpaceAround');
				}
			});
			var startIndex = previousFullRows.length * previousRowsLength;
			row.addUsers(users.slice(startIndex, startIndex + bottomRowFilledCells), rowOptions);

			// Want to double check that we aren't moving subjects into bottom row when they don't fit due to something blocking some of the bottom row
			var frames = this.getBlockingContent();
			var invisibleCells = $(row.cells).filter('.invisibleCell');
			if(frames.length && invisibleCells.length) {
				var cells = $(this).find('.flowCell:not(.rowHeader)');
				var blockedCells = $(row.cells).find('.hiddenCell');
				invisibleCells.removeClass('invisibleCell');
				var diff = 0;
				frames.each(function() {
					diff += (this.hideOverlap(cells) || 0);
				});

				if(diff) {
					$(row.cells).filter('.hiddenCell').not(blockedCells).removeClass('hiddenCell behindFrame');
					let overflowIndexes = 0;
					previousFullRows.forEach(function(previousRow, rowIndex) {
						var startIndex = rowIndex * startPreviousRowsLength - overflowIndexes;
						let remainingUsers = previousRow.addUsers(users.slice(startIndex, startIndex + startPreviousRowsLength), rowOptions);
						overflowIndexes += remainingUsers.length;
		
						if(previousRowsJustified[rowIndex]) {
							$(previousRow).addClass('justifySpaceAround');
						}
					});
					startIndex = previousFullRows.length * startPreviousRowsLength - overflowIndexes;
					let remainingUsers = row.addUsers(users.slice(startIndex), rowOptions);
					if(remainingUsers.length) {
						let page = this.flowLayout.getPage();
						if(this.flowLayout.errorOnMissingSubjects) {
							throw 'Missing subjects on page ' + page.getPageReference();
						} else {
							this.flowLayout.setLabelError('Failed to add ' + remainingUsers.length + ' subjects to layout on page ' + page.getPageReference());
						}
					}

					frames.each(function() {
						this.hideOverlap(cells);
					});
					justifySpaceAround = rowHasJustified;
				} else {
					invisibleCells.filter(function() {
						return !this.user;
					}).addClass('invisibleCell');
				}
			}

			if(justifySpaceAround) {
				$(row).addClass('justifySpaceAround');
			}
		},
		clearUsers: function() {
			if(this.largeCell && this.rows.length && this.rows[0].cells.length) {
				let row = this.rows[0], cell;
				if(this.largeCell.col == -1) {
					cell = row.cells[row.cells.length - 2];
				} else {
					cell = row.cells[0];
				}

				if(cell) {
					cell.setLargeCell($.extend(true, {}, this.largeCell, {
						movable: false
					}));
				}
			}

			// Clear out any existing cells
			this.rows.forEach(function(row) {
				row.addUsers([], {});
			});
		},
		normalizeSubjectLabels: function(updateAllLabels, minManualFontSize) {
			var argManualFontSize = minManualFontSize;
			var previousLayout = this.flowLayout.parent && this.flowLayout.parent.getPreviousVisiblePage(this.flowLayout);
			if(previousLayout && previousLayout.canvas.lastManualFontSize && !minManualFontSize) {
				minManualFontSize = previousLayout.canvas.lastManualFontSize;
			}

			var manualFontSize = minManualFontSize;
			if(updateAllLabels) {
				this.updateLabelCSS();
			}

			var cellsWithManualFontSize = $(this).find('.flowCell').filter(function () {
				return this.manualFontSize != null;
			});

			var dontResizeCells = [];
			var startFontSize = parseFloat(this.studentLabelCSS.getCSS('font-size').replace('pt', ''));
			var threshold = ($.projectSettings && $.projectSettings.textResizeThreshold) ? parseInt($.projectSettings.textResizeThreshold.value) : 75;
			if(threshold == 100) {
				return;
			}
			for(let i = 0; i < cellsWithManualFontSize.length; i++) {
				var cell = cellsWithManualFontSize[i];
				var fontSize = cell.manualFontSize;
				if(fontSize && !cell.overrideFontSize) {
					var percentageOfStart = fontSize / startFontSize * 100;

					if(percentageOfStart > threshold && !cell.largeCell) {
						if(!manualFontSize || fontSize < manualFontSize) {
							manualFontSize = fontSize;
						}
					} else {
						dontResizeCells.push(cell);
					}
				}
			}

			var page = this.flowLayout.getPage();
			var cachedManualFontSize;
			cachedManualFontSize = page.getExtraProperty('cachedManualFontSize');
			if(!this.editable && cachedManualFontSize) {
				manualFontSize = cachedManualFontSize;
			}

			if(manualFontSize) {
				var startManualFontSize = manualFontSize;
				if(this.studentLabelCSS.css['font-size-multiplier'] && this.studentLabelCSS.css['font-size-multiplier'] > 0) {
					manualFontSize = manualFontSize * (1 + this.studentLabelCSS.css['font-size-multiplier']);
				}

				var cells = $(this).find('.flowCell').filter(function () {
					return (this.user != null && dontResizeCells.indexOf(this) == -1 && !this.largeCell);
				});

				cells.each(function() {
					var label = this.label;

					// Give a little wiggle room to allow font normalization
					if(this.manualFontSize && this.manualFontSize < manualFontSize) {
						// Make cell display overflowed content correctly
						$(this).addClass('hasFontMultiplier');
					}

					label.addStyleToEntireInstance('font-size', manualFontSize + 'pt');
				});

				if(this.shouldNormalizeBetweenPages(previousLayout) && startManualFontSize != minManualFontSize && !argManualFontSize) {
					previousLayout.canvas.normalizeSubjectLabels(false, startManualFontSize);
				}

				var nextLayout = this.flowLayout.parent && this.flowLayout.parent.getNextVisiblePage(this.flowLayout);
				if(this.shouldNormalizeBetweenPages(nextLayout) && startManualFontSize != minManualFontSize && !argManualFontSize) {
					nextLayout.canvas.normalizeSubjectLabels(false, startManualFontSize);
				}

				if((!cachedManualFontSize || !$.isWithinDiff(startManualFontSize, cachedManualFontSize, 0.01)) && this.editable && this.isMain()) {
					page.setExtraProperty('cachedManualFontSize', startManualFontSize, {
						private: true
					});
				}
			} else if(cachedManualFontSize && this.isMain()) {
				page.setExtraProperty('cachedManualFontSize', null, {
					private: true
				});
			}
			this.lastManualFontSize = manualFontSize;
		},
		shouldNormalizeBetweenPages: function(otherLayout) {
			if(!otherLayout) {
				return false;
			}

			var mainPage = this.flowLayout.getPage();
			var otherPage = otherLayout.getPage();
			if(!otherPage || !mainPage) {
				return false;
			} else if(mainPage.getType().indexOf('class') === -1 || otherPage.getType().indexOf('class') === -1) {
				return false;
			} else if(!this.studentLabelCSS || !otherLayout.canvas.studentLabelCSS) {
				return false;
			}

			

			// Always normalize between overflow pages
			if((otherPage.getParentPage && otherPage.getParentPage() == mainPage)
				|| (mainPage.getParentPage && mainPage.getParentPage() == otherPage)) {

				return true;
			}

			var mainFontSize = parseFloat(this.studentLabelCSS.getCSS('font-size').replace('pt', ''));
			var otherFontSize = parseFloat(otherLayout.canvas.studentLabelCSS.getCSS('font-size').replace('pt', ''));
			return $.isWithinDiff(mainFontSize, otherFontSize, 0.001);
		},
		updateLayoutAfterPageMargins: function() {
			var layout = this.flowLayout.getLayout();
			if(layout && layout.cell) {
				if(layout.cell.size == 'fit') {
					var me = this;
					if(this.updatePageMarginsTask) {
						window.clearTimeout(this.updatePageMarginsTask);
					}

					var delayTime = 10;
					var thisObj = this.flowLayout.getPage();
					if(thisObj && thisObj.getKids && thisObj.getClass() && thisObj.getKids()) {
						var kidCount = thisObj.getKids().length;
						if(kidCount > 80) {
							delayTime = 400;
						} else if(kidCount > 30) {
							delayTime = 100;
						}
					}

					this.updatePageMarginsTask = window.setTimeout(function () {
						me.flowLayout.addUsers();
						me.updatePageMarginsTask = null;
					}, delayTime);
				} else if(layout.cellCols) {
					this.calculateCellsToColumns(layout, layout.cellCols, null, true);
				} else {
					// Get new cell layout
					this.calculateCells(layout, true);
					var cellCountDifference = this.horizontal != layout.grid.horizontal || this.vertical != layout.grid.vertical;

					// Update cells
					this.horizontal = layout.grid.horizontal;
					this.vertical = layout.grid.vertical;
					var frameOverflowChange = !this.addCellsToLayout(layout);

					// If there was supposed to be a change, call addUsers
					if(cellCountDifference || frameOverflowChange) {
						this.flowLayout.addUsers();
					} else if($(this).has('.rowHeader').length) {
						this.normalizeSubjectLabels(true);
					}
				}
			}
		},





		////// Change existing cells
		setLabelCSS: function(name, value) {
			this.rows.forEach(function(row) {
				row.updateLabelCSS(name, value);
			});
			this.normalizeSubjectLabels(false);
		},
		updateLabelCSS: function() {
			this.rows.forEach(function(row) {
				row.updateLabelCSS();
			});
		},
		onUpdateCurrentSelectionDiv: function(selectedDiv, currentSelection) {
			this.rows.forEach(function(row) {
				row.cells.forEach(function(cell) {
					if(cell.user && cell.user.id == currentSelection.id) {
						cell.initToolbar();

						switch(currentSelection.selection) {
							case 'label':
								selectedDiv = cell.label;
								break;
							case 'image':
								selectedDiv = cell;
								break;
							default:
								cell.texts.forEach(function(text) {
									if(text.cellId == currentSelection.selection) {
										selectedDiv = text;
									}
								});
								break;
						}
					}
				});
			});

			return selectedDiv;
		},
		setCellTextCSS: function(id, name, value) {
			this.rows.forEach(function(row) {
				row.setCellTextCSS(id, name, value);
			});
		},
		updateCellTextCSS: function(id) {
			this.rows.forEach(function(row) {
				row.updateCellTextCSS(id);
			});
		},
		applySubjectEffects: function(effectsSettings) {
			this.rows.forEach(function(row) {
				row.applyFilters(effectsSettings);
			});
		},
		updateCropSelection: function() {
			this.rows.forEach(function(row) {
				row.cells.forEach(function(cell) {
					if(cell.user) {
						cell.setUserCrop(cell.user);
					}
				});
			});
		},





		////// Seeing what the grid should be
		calculateCells: function(definition, forceNewCell) {
			if(!definition) {
				definition = this.flowLayout.getLayout();
			}

			// If we have already determined what it should look like while editing, don't mess with it during print
			var page = this.flowLayout.getPage();
			var beforeHorizontal = definition.grid.horizontal;
			var beforeVertical = definition.grid.vertical;
			if(!this.editable && page && page.getExtraProperty('cachedGrid')) {
				var cachedGrid = page.getExtraProperty('cachedGrid');
				this.flowLayout.definition.grid.horizontal = cachedGrid.horizontal;
				this.flowLayout.definition.grid.vertical = cachedGrid.vertical;
			} else {
				// Try to use existing rows/cells if this is a recalc
				let row = $(this).find('.flowRow')[0];
				if(!row || forceNewCell) {
					var defaultNameContainerSize = 1.4;
					if(definition.schema >= 3) {
						defaultNameContainerSize = definition.cell.width * 1.4;
					}

					row = new $.FlowLayoutRow($.extend({width: defaultNameContainerSize, wrapper: this.flowLayout}, definition.row), this.ratio, {
						rowIndex: this.rows.length
					});
				}
				var cell = $(this).find('.flowCell:not(.rowHeader)')[0];
				if(!cell || forceNewCell) {
					cell = new $.FlowLayoutCell($.extend({wrapper: this.flowLayout}, definition.cell), this.ratio, {
						cellIndex: 0,
						row: row
					});
				}
				var cellWidth = $(cell).getFloatStyle('width') + $(cell).getFloatStyle('marginLeft') + $(cell).getFloatStyle('marginRight');
				let cellHeight = $(cell).getFloatStyle('height') + $(cell).getFloatStyle('marginTop') + $(cell).getFloatStyle('marginBottom');

				// Need to user container instead of parent so we can get space minus bleed
				var canvasMarginsRect = this.parentNode.getBoundingClientRect();
				// With margins excluded FF fails the test "Skip straight to overflow page" => "With merged batches"
				var calcHeight = canvasMarginsRect.height - this.flowLayout.canvasBorderWidth * 2;
				var calcWidth = canvasMarginsRect.width - this.flowLayout.canvasBorderWidth * 2;
				if(definition.row) {
					if(definition.row.insidePadding) {
						calcWidth -= definition.row.insidePadding * this.ratio;
					} else if(definition.row.sidePadding) {
						calcWidth -= definition.row.sidePadding * this.ratio;
					}

					if(definition.row.outsidePadding) {
						calcWidth -= definition.row.outsidePadding * this.ratio;
					} else if(definition.row.sidePadding) {
						calcWidth -= definition.row.sidePadding * this.ratio;
					}
				}

				if(definition.sidePadding) {
					calcWidth -= definition.sidePadding * 2 * this.ratio;
				}
				if(definition.topPadding) {
					calcHeight -= definition.topPadding * this.ratio;
				}
				if(definition.bottomPadding) {
					calcHeight -= definition.bottomPadding * this.ratio;
				}

				if(row.nameContainer) {
					var outerContainerWidth = $(row.nameContainer).outerWidth(true)
					var maxContainerWidth = $(row.nameContainer).getFloatStyle('max-width');
					calcWidth -= Math.max(outerContainerWidth, maxContainerWidth);
				}
				$(this.flowLayout).find('.flowTitle').each(function() {
					if(this.instance && this.calculatedRenderedHeight && this.style.display != 'none') {
						// Use calculated height so rounding errors don't cause browser differences!
						var titleHeight = this.calculatedRenderedHeight + $(this).getFloatStyle('paddingBottom') + $(this).getFloatStyle('paddingTop');
						calcHeight -= titleHeight;
					}
				});
				if(this.flowLayout.pageNumberDiv && (!definition.grid || definition.grid.horizontalDecrement != 1)) {
					var pageSet = this.flowLayout.getPageSet();
					var allowOverlap = pageSet.canSubjectsOverlapPageNumber && pageSet.canSubjectsOverlapPageNumber();

					// We are doing height + only padding bottom
					// We do not want to force padding top since it can easily lead to large gaps where not really necessary
					if(!allowOverlap && this.flowLayout.pageNumberDiv.instance && this.flowLayout.pageNumberDiv.calculatedRenderedHeight && this.flowLayout.pageNumberDiv.style.display != 'none') {
						// Use calculated height so rounding errors don't cause browser differences!
						var pageNumberSize = this.flowLayout.pageNumberDiv.calculatedRenderedHeight + $(this.flowLayout.pageNumberDiv).getFloatStyle('paddingBottom');
						calcHeight -= pageNumberSize;
					}
				}

				var rawHorizontal = calcWidth / cellWidth;
				if($.isWithinDiff(rawHorizontal, Math.ceil(rawHorizontal), 0.03)) {
					rawHorizontal = Math.ceil(rawHorizontal);
				}
				definition.grid.horizontal = Math.max(1, Math.floor(rawHorizontal));
				definition.grid.vertical = this.getRowsFromDimensions(calcHeight, cellHeight);
			}

			return beforeHorizontal != definition.grid.horizontal || beforeVertical != definition.grid.vertical;
		},
		getPixelDimensions: function() {
			var canvasMarginsRect = this.parentNode.getBoundingClientRect();
			var pageHeight = canvasMarginsRect.height - this.flowLayout.canvasBorderWidth * 2;
			var pageWidth = canvasMarginsRect.width - this.flowLayout.canvasBorderWidth * 2;

			return {
				width: pageWidth,
				height: pageHeight
			};
		},
		calculateCellsToColumns: function(definition, columns, rows, updateCurrent) {
			var page = this.flowLayout.getPage();
			if(!page) {
				console.error('calculateCellsToColumns with no page');
				return;
			}

			var rootPage = page;
			if(page.getRootPage) {
				rootPage = page.getRootPage();
			}

			if(typeof columns == 'string') {
				columns = parseInt(columns);
			}

			var passedColumns = columns;
			var canvasWidth = this.flowLayout.getCanvasInchWidth(definition);
			if(definition.sidePaddingDynamic && definition.sidePadding) {
				var realCanvasWidth = canvasWidth + definition.sidePadding * 2;
				var sidePaddingPercentage = parseFloat(definition.sidePaddingDynamic.replace('%', '')) / 100;

				definition.sidePadding = realCanvasWidth * sidePaddingPercentage;
				if(definition.schema >= 4 && this.flowLayout.inchSafeSpace) {
					let maxSafeSpace = Math.max(this.flowLayout.inchSafeSpace.left, this.flowLayout.inchSafeSpace.right);
					definition.sidePadding = Math.max(0, definition.sidePadding - maxSafeSpace);
				}
				canvasWidth = this.flowLayout.getCanvasInchWidth(definition);
			}

			var canvasHeight = this.flowLayout.getCanvasInchHeight(definition);

			// Handle side layouts
			if(definition.row && definition.row.position) {
				if(definition.schema >= 3) {
					columns += 1.4;
				} else {
					columns++;
				}
			}

			// Update definition
			var cell = definition.cell;
			if(!cell) {
				$.fireErrorReport('Error setting number of columns', 'Error in calculateCellsToColumns', 'No cell definition', {
					definition: $.removeFunctions(definition),
					snapshot: this.flowLayout.getSnapshot()
				});
				return;
			}

			var cellRatio = cell.ratio || 0.8;
			if(!cell.padding) {
				cell.padding = this.flowLayout.DEFAULT_CELL_PADDING;
			}

			var cellWidth = canvasWidth / columns;
			if(cell.leftPadding) {
				// On quote under layout, we want to match the padding as closely to one of the pre-built options as possible
				if(definition.cells && cell.rightPadding) {
					let cellOptions = Object.values(definition.cells);
					cellOptions.sort((a, b) => a.columns - b.columns);
					let bestOption = cellOptions.find(option => columns <= option.columns) || cellOptions[cellOptions.length - 1];
					if(bestOption.leftPadding && bestOption.rightPadding) {
						cell.leftPadding = bestOption.leftPadding;
						cell.rightPadding = bestOption.rightPadding;
					}
				}

				cellWidth -= cell.leftPadding - cell.padding;
			}
			if(cell.rightPadding) {
				cellWidth -= cell.rightPadding - cell.padding;
			}
			if(cell.outsidePadding) {
				if(rows) {
					let maxHeight = canvasHeight / rows;
					let maxWidth = maxHeight * cellRatio;
					let minOutsidePadding = canvasWidth * 0.29;
					let outsidePadding = Math.max(minOutsidePadding, cellWidth - maxWidth);
					
					cell.outsidePadding = outsidePadding;
				}

				cellWidth -= cell.outsidePadding - cell.padding;
			}
			if(cell.insidePadding) {
				if(rows) {
					let maxHeight = canvasHeight / rows;
					let maxWidth = maxHeight * cellRatio;
					let minInsidePadding = canvasWidth * (0.25 - Math.max(0, columns - 2) * 0.05);
					let insidePadding = Math.max(minInsidePadding, cellWidth - maxWidth);

					cell.insidePadding = insidePadding;
				}

				cellWidth -= cell.insidePadding - cell.padding;
			}
			cellWidth -= cell.padding * 2;

			let cellHeight = cellWidth / cellRatio;
			var newWidth = cellWidth + cell.padding * 2;
			var newHeight = cellHeight + cell.padding * 2;

			// Scale the text size
			// Currently it is going up in increments of 2 / 0.25 width increase
			var newMinDimension = Math.min(newWidth, newHeight);
			var newNameSize = DEFAULT_CELL_FONT_SIZE + (newMinDimension - DEFAULT_CELL_WIDTH) * DEFAULT_CELL_FONT_INCREMENT;

			if(definition.cell.nameSizeMultiplier) {
				newNameSize *= parseFloat(definition.cell.nameSizeMultiplier);
			}
			var oldCellDefinition = {
				cell: $.extend(true, {}, definition.cell),
				cellCols: definition.cellCols,
				cellSize: definition.cellSize
			};

			cell.width = newWidth;
			cell.height = newHeight;
			if(definition.cell.columns != passedColumns) {
				definition.cellCols = passedColumns;
				definition.cellSize = null;
			}
			cell.nameSize = newNameSize;
			if(!cell.name && (!definition.row || !definition.row.position)) {
				cell.name = 'bottom';
			}

			if(updateCurrent) {
				var fontSize = cell.nameSize * $.PRODUCTION_RATIO / $.FONT_MULTIPLIER;
				this.studentLabelCSS.setCSS('font-size', fontSize + 'pt', !this.editable);
			}

			// Extra padding is only used for this.  If we want to have padding on the root layout use left/right/bottom/top padding instead
			cell.leftExtraPadding = cell.rightExtraPadding = cell.topExtraPadding = cell.bottomExtraPadding = null;
			if(rows) {
				this.resizeCellsToFitRows(definition, rows);
				definition.cellRows = rows;
			} else {
				definition.cellRows = null;
			}

			if(updateCurrent) {
				if($.userEvents) {
					$.userEvents.startGroupedEvents();
					$.userEvents.addEventDelayed({
						context: [rootPage, 'layout'],
						action: 'update'
					}, {
						argsChanged: true,
						onComplete: function(event) {
							event.args = [oldCellDefinition, {
								cell: definition.cell,
								cellCols: passedColumns,
								cellSize: null
							}];
						}
					});
				}

				this.flowLayout.updateCellDefinition(definition, {
					triggerOverflowPrompt: true
				});
				if($.userEvents && !this.flowLayout?.parent?.userPromptedForRollback) {
					$.userEvents.stopGroupedEvents();
				}
			}
		},
		resizeCellsToFitRows: function(definition, expectedRows) {
			let gridHeight = this.flowLayout.getCanvasInchHeight(definition);
			let cellHeight = this.getOuterInchCellHeight(definition.cell);
			let currentRows = this.getRowsFromDimensions(gridHeight, cellHeight);

			let cellDefinition = definition.cell;
			let cellRatio = cellDefinition.ratio || 0.8;
			if(currentRows < expectedRows) {
				// Shrink cell and add horizontal padding to fit more rows
				let missingRows = expectedRows - (gridHeight / cellHeight);
				let missingRowSpace = missingRows * cellHeight;

				cellHeight = cellDefinition.height - (cellDefinition.padding * 2);
				cellHeight = cellHeight - (missingRowSpace / expectedRows);

				cellDefinition.height = cellHeight + (cellDefinition.padding * 2);

				let oldCellWidth = cellDefinition.width;
				let newCellWidth = (cellHeight * cellRatio) + (cellDefinition.padding * 2);
				cellDefinition.width = newCellWidth;

				let cellWidthDiff = oldCellWidth - newCellWidth;
				cellDefinition.leftExtraPadding = cellDefinition.rightExtraPadding = (cellWidthDiff / 2);
			} else if(currentRows > expectedRows) {
				// Just add vertical spacing
				let extraRows = (gridHeight / cellHeight) - expectedRows;
				let extraRowSpace = extraRows * cellHeight;

				// For quote layouts it is much better to put all of the extra space below for the quote then center the cells
				if(cellDefinition.bottomPadding) {
					cellDefinition.bottomExtraPadding = extraRowSpace / expectedRows;
					cellDefinition.topExtraPadding = null;
				} else {
					cellDefinition.topExtraPadding = cellDefinition.bottomExtraPadding = (extraRowSpace / expectedRows / 2);
				}
			}
		},
		getRowsFromDimensions: function(gridHeight, cellHeight) {
			var rawCurrentRows = gridHeight / cellHeight;
			// There are cases where we are really close to page number and we should allow more rows
			if($.isWithinDiff(rawCurrentRows, Math.ceil(rawCurrentRows), 0.03) && this.flowLayout.pageNumberDiv) {
				rawCurrentRows = Math.ceil(rawCurrentRows);
			}

			return Math.floor(rawCurrentRows);
		},
		getOuterInchCellHeight: function(cellDefinition) {
			var height = cellDefinition.height;

			if(cellDefinition.topPadding) {
				height = height + cellDefinition.topPadding - cellDefinition.padding;
			}

			if(cellDefinition.bottomPadding) {
				height = height + cellDefinition.bottomPadding;
			}
			if(cellDefinition.primaryPose && cellDefinition.primaryPose.bottomPadding) {
				height += cellDefinition.primaryPose.bottomPadding;
			}

			if(cellDefinition.name && cellDefinition.name === 'bottom') {
				var lines = 1;
				if(cellDefinition.nameOrder2 || cellDefinition.teacherPrefixOrder2 || cellDefinition.overflowLabel == 'wrap') {
					lines++;
				}
				for(let i = 3; cellDefinition['nameOrder' + i] || cellDefinition['teacherPrefixOrder' + i]; i++) {
					lines++;
				}
				if(cellDefinition.overflowLabel && cellDefinition.overflowLabel.indexOf('wrapLines') !== -1) {
					var wrapTillLines = parseInt(cellDefinition.overflowLabel.replace('wrapLines', ''));
					lines = Math.max(lines, wrapTillLines);
				}

				var fontSize = cellDefinition.nameSize / $.FONT_MULTIPLIER;
				var fontSizePx = $.convertToPx(fontSize, true);

				var fontHeightPadding;
				if(lines > 1) {
					fontHeightPadding = (fontSizePx * 1.33 * lines);
				} else {
					fontHeightPadding = fontSizePx;
				}

				height += fontHeightPadding;
			}

			return height;
		},

		setSize: function(cellSize, onComplete) {
			var flowLayout = this.flowLayout;
			var page = flowLayout.getPage();
			if(!page) {
				return;
			}

			var rootPage = page;
			if(rootPage.getRootPage) {
				rootPage = rootPage.getRootPage();
			}

			var definition = rootPage.getLayout();
			if(this.flowLayout.cells && definition.cell) {
				var newCell = this.flowLayout.cloneCell(definition.cell, this.flowLayout.cells[cellSize], ['studentLabelCSS']);

				if(newCell && definition.cell != newCell) {
					var oldDefinition = {
						cell: definition.cell,
						cellSize: definition.cellSize,
						cellCols: definition.cellCols
					};

					definition.cell = newCell;
					if(definition.grid.horizontal != 1) {
						definition.grid.horizontal = null;
					}
					definition.grid.vertical = null;
					definition.cellCols = null;
					rootPage.setSize(cellSize);

					if(oldDefinition.cell && oldDefinition.cell.name === 'none' && definition.cell) {
						definition.cell.name = 'none';
					}

					if(definition.cell.columns) {
						this.calculateCellsToColumns(definition, definition.cell.columns, definition.cell.rows, false);
					}

					if($.userEvents) {
						$.userEvents.startGroupedEvents();
						$.userEvents.addEventDelayed({
							context: [rootPage, 'layout'],
							action: 'update'
						}, {
							argsChanged: true,
							onComplete: function(event) {
								event.args = [oldDefinition, {
									cell: flowLayout.definition.cell,
									cellSize: cellSize,
									cellCols: null
								}];
							}
						});
					}

					this.flowLayout.setupDefinitionForUse(definition);
					this.flowLayout.updateCellDefinition(definition, {
						triggerOverflowPrompt: true
					});

					// Fix with trigger overflow
					if($.userEvents && !this.flowLayout?.parent?.userPromptedForRollback) {
						$.userEvents.stopGroupedEvents();
					}

					if(onComplete) {
						onComplete();
					}
				}
			}
		},
		getBlockingContent: function() {
			var content = $(this.flowLayout.container).children('.flowContent');
			if(this.isMain()) {
				content = content.add(this.flowLayout.extraSubjectGrids);
			}
			content = content.filter(function() {
				return !!this.hideOverlap;
			});

			return content;
		},

		getCells: function() {
			return $(this).find('.flowCell:not(.rowHeader)');
		},
		getLargeCells: function() {
			return this.getCells().filter('.largeCell');
		},
		getUsedCells: function() {
			return this.getCells().not('.hiddenCell, .invisibleCell').filter(function() {
				return !!this.user;
			});
		},

		setupCellZIndex: function() {
			var definition = this.flowLayout.definition.cell;
			if(definition && definition.zIndex) {
				if(definition.zIndex == 'single-wave') {
					this.setupCellWaveZIndex();
				}
			}
		},
		setupCellWaveZIndex: function() {
			var allCells = this.getUsedCells().toArray();
			var rows = [];
			var marginOfError = this.ratio / 3;
			allCells.forEach(function(cell, cellIndex) {
				var matchingRow = rows.find(function(row) {
					var firstCell = row[0];
					return Math.abs((cell.offsetTop + cell.imgWrapper.offsetTop) - (firstCell.offsetTop + firstCell.imgWrapper.offsetTop)) < marginOfError;
				});
				if(matchingRow) {
					matchingRow.push(cell);
				} else {
					rows.push([cell]);
				}
			});
			rows.sort(function(a, b) {
				return (a[0].offsetTop + a[0].imgWrapper.offsetTop) - (b[0].offsetTop + b[0].imgWrapper.offsetTop);
			});

			var totalCells = rows.reduce(function(max, row) {
				return Math.max(max, row.length);
			}, 0);
			rows.forEach(function(cells, rowIndex) {
				var halfWayPoint = cells.length / 2;

				// In virtual group layouts these cells can be moved around, so need to check where they visually are in the layout
				cells.sort(function(a, b) {
					return (a.offsetLeft + a.imgWrapper.offsetLeft) - (b.offsetLeft + b.imgWrapper.offsetLeft);
				});

				cells.forEach(function(cell, cellIndex) {
					var zIndex = 0;
					if(cellIndex <= halfWayPoint) {
						zIndex = cellIndex;
					} else {
						zIndex = Math.abs(cellIndex - cells.length + 1);
					}

					// Work around for Prince not respecting z-index across rows
					zIndex += rowIndex * totalCells;
					cell.style.zIndex = zIndex;
				});
			});
		},

		destroy: function() {
			this.rows.forEach(function(row) {
				row.destroy();
			});

			if(this.mouseMoveGridHandler) {
				this.removeEventListener('mousemove', this.mouseMoveGridHandler);
				this.removeEventListener('mouseout', this.mouseOutGridHandler);
				this.mouseMoveGridHandler = this.mouseOutGridHandler = null;
			}
		},

		rows: [],
		editable: false
	}, options);
	$.FlowLayoutSubjectGridFit(div);

	return div;
};