$.LayoutBook = function() {
	var obj = new $.FlowPageSet({});

	$.extend(obj, {
		setupLayoutPages: function() {
			let perPage = this.layoutColumns * this.layoutRows;

			this.categories.forEach(category => {
				for(let i = 0; i < category.layouts.length; i += perPage) {
					let layouts = category.layouts.slice(i, i + perPage);
					obj.createPage(category, layouts);
				}
			});
		},
		createPage: function(category, layouts) {
			let title = 'Captura Yearbooks ';
			if(category.name) {
				title += category.name;
			} else {
				title += this.title;
			}

			let page = new $.FlowPage({
				title: {
					lines: {
						text: title
					}
				},
				theme: {
					type: 'Preview',
					Background: {
						id: 'test',
						cdnUrl: 'https://s3.amazonaws.com/plic-books/public/font-book-background.png'
					}
				},
				layout: {}
			});

			let pageWidth = 8;
			let pageHeight = 10.5;

			let startWidth = 0.25;
			let startHeight = 1;

			let columnWidth = (pageWidth - startWidth) / this.layoutColumns;
			let rowHeight = (pageHeight - startHeight) / this.layoutRows;
			const padding = 0.25;
			const titleHeight = 0.2;
			const titlePadding = 0.1;

			let images = [];
			let texts = [];
			for(let i = 0; i < layouts.length; i++) {
				let layout = layouts[i];
				let x = i % obj.layoutColumns;
				let y = Math.floor(i / obj.layoutColumns);

				let xPadd = startWidth + x * columnWidth;
				let yPadd = startHeight + y * rowHeight;

				texts.push({
					lines: {
						text: layout.layoutName,
						'font-size': 'auto',
						align: 'center'
					},
					position: {
						left: xPadd,
						top: yPadd
					},
					manualSize: {
						width: columnWidth,
						height: titleHeight
					}
				});
				
				let definition = layout.definition;
				const grid = definition.grid || {
					width: $.PAGE_WIDTH - ($.PAGE_BLEED * 2),
					height: $.PAGE_HEIGHT - ($.PAGE_BLEED * 2)
				};
				if(typeof grid.width === 'string') {
					grid.width = parseFloat(grid.width);
				}
				if(typeof grid.height === 'string') {
					grid.height = parseFloat(grid.height);
				}
				const layoutRatio = grid.width / grid.height;
				let layoutWidth = columnWidth - padding;
				let layoutHeight = rowHeight - padding - titleHeight - titlePadding;
				if(layoutRatio < 1) {
					layoutWidth = layoutHeight * layoutRatio;
				} else {
					layoutHeight = layoutWidth / layoutRatio;
				}

				if(layoutWidth < columnWidth) {
					xPadd += (columnWidth - layoutWidth) / 2;
				}
				yPadd += titleHeight;

				definition = page.getScaledLayoutImpl(definition, grid, {
					width: layoutWidth,
					height: layoutHeight
				});

				if(definition.images) {
					images.push(...this.offsetImage(definition.images, xPadd, yPadd));
				}
				if(definition.candids) {
					images.push(...this.offsetImage(definition.candids, xPadd, yPadd));
				}
				if(definition.texts) {
					texts.push(...this.offsetText(definition.texts, xPadd, yPadd));
				}

				if(definition.theme && definition.theme.Background) {
					let layoutBackground = {
						photo: definition.theme.Background.id,
						existingUrl: definition.theme.Background.cdnUrl,
						x: xPadd,
						y: yPadd,
						width: layoutWidth,
						height: layoutHeight,
						zIndex: -100000
					};
					Object.keys(definition.extras?.backgroundSettings ?? {}).forEach(key => {
						if(key === 'crop') {
							layoutBackground.crop = $.extend(true, {
								percentage: true
							}, definition.extras.backgroundSettings.crop);
						} else {
							layoutBackground[key] = definition.extras.backgroundSettings[key];
						}
					});
					images.push(layoutBackground);
				} else {
					images.push({
						id: $.getUniqueId(),
						x: xPadd,
						y: yPadd,
						width: layoutWidth,
						height: layoutHeight,
						shape: true,
						color: '#d3d3d3',
						zIndex: -100000
					});
				}
			}
			page.setCandidArray(images);
			page.setTextArray(texts);

			this.addPage(page, null, false);
		},
		offsetImage(content, xPadd, yPadd) {
			if(!Array.isArray(content)) {
				content = Object.values(content);
			}

			content.forEach(content => {
				content.x += xPadd;
				content.y += yPadd;

				if(!content.photo && !content.field && !content.shape) {
					content.photo = 'id';
					content.existingUrl = 'https://plicbooks.com/css/images/image-placeholder.png';
				}
			});

			return content;
		},
		offsetText(content, xPadd, yPadd) {
			if(!Array.isArray(content)) {
				content = Object.values(content);
			}

			content.forEach(content => {
				content.position.left += xPadd;
				content.position.top += yPadd;
			});

			return content;
		}
	});

	return obj;
};