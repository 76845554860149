$.PersonalizedPage = function(settings) {
	var page = new $.FlowPage($.extend({
		type: 'personalized-page',
		getTitle: function() {
			return null;
		},
		setTitle: function() {},
		updatePageLabel: function () {
			var sidebar = [];

			if(this.theme && this.isThemeValid()) {
				sidebar.push({
					name: 'Backgrounds',
					icon: 'setting',
					popup: 'Change settings on backgrounds',
					onClick: function(page, layout) {
						layout.openThemeSettings();
					}
				});

				sidebar.push({
					name: 'Revert Background',
					icon: 'picture',
					popup: 'Revert background back to theme',
					onClick: function(page, layout) {
						layout.clearBackground();
					}
				});
			}

			if(sidebar.length) {
				this.pageLabel = {
					display: '',
					popup: 'Options',
					sidebar: sidebar
				};
			} else {
				this.pageLabel = null;
			}
		},
		setLayout: function() {},
		getLayout: function() {
			return {};
		},
		getThemePartName: function() {
			return 'Candid';
		},
		getPageMargins: null,
		setPageMargins: null
	}, settings));

	return page;
};
