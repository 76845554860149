$.primarySubjectPoseFlag = 'yearbook';

$.bindingTypes = [
	'Soft Cover',
	'Hard Cover'
];

$.projectTypeFieldSets = {
	'Church Directory': {
		editableFields: [
			{
				name: 'Spouse Name',
				group: false
			},
			{
				name: 'Child 1',
				group: false
			},
			{
				name: 'Child 2',
				group: false
			},
			{
				name: 'Child 3',
				group: false
			},
			{
				name: 'Child 4',
				group: false
			},
			{
				name: 'Child 5',
				group: false
			},
			{
				name: 'Child 6',
				group: false
			},
			{
				name: 'Street',
				group: false
			},
			{
				name: 'City',
				group: false
			},
			{
				name: 'State',
				group: false
			},
			{
				name: 'Zip',
				group: false
			},
			{
				name: 'Phone Number 1',
				group: false
			},
			{
				name: 'Phone Number 2',
				group: false
			}
		],
		filterByOptions: []
	},
	'Sports Book': {
		editableFields: [
			{
				name: 'Team',
				group: true
			},
			{
				name: 'Player Number',
				group: false
			}
		],
		defaultFilter: 'Team',
		filterByOptions: [
			{
				name: 'Default (Team)',
				color: 'green'
			}
		]
	}
};