window.locales.enLite = {
	management: {
		dashboard: {
			totalYearbookProjects: 'Total Book Projects',
			yearbooksInProgress: 'Books in Progress',
			yearbooksPastDue: 'Books Past Due Date',
			yearbooksSubmitted: 'Books Submitted'
		},
		yearbooks: {
			createYearbookButton: 'Create Book',
			goToYearbookButton: {
				title: 'Go to Book',
				tooltip: 'Go to book'
			}
		}
	},
	projects: {
		name: 'Book Name',
		project: 'Book',
		projectInformation: 'Basic book information',
		projects: 'Books',
		projectSettings: 'Book Settings',
		projectSettingsDescription: 'Advanced book settings'
	},
	tabs: {
		projectWizard: 'Create Book',
		yearbooks: 'Books'
	},
	uploader: {
		guest: {
			selectAlbum: 'Select album to upload to',
			subtitle: 'Upload your candids for the %{schoolName} book album %{albumName}',
			tags: 'Use photo tags to describe the image(s) you are uploading. This will help organization significantly when adding to your book.'
		}
	}
};