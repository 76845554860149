$.YearbookClassPageOverflow = function(parentPage, kids, guessedCount) {
	var obj = new $.YearbookClassPage();
	obj._setTitle = obj.setTitle;
	var getPageMargins = obj.getPageMargins;
	var _getExtraProperty = obj.getExtraProperty;
	var _setExtraProperty = obj.setExtraProperty;

	$.extend(obj, {
		getTitle: function () {
			if(this.title) {
				if(this.title === '_unset_') {
					return null;
				} else {
					return this.title;
				}
			} else if(this.parentPage) {
				return this.parentPage.getLastTitle();
			} else {
				return null;
			}
		},
		hasTitle: function () {
			return this.title != null;
		},
		setTitle: function (title) {
			// Overflow matches parent
			if(title == this.parentPage.getTitle() || title == this.parentPage.getLastTitle()) {
				// If title was previously set, null it
				if (this.title) {
					this._setTitle(null);
				}
			} else {
				// This is a overwrite of the overflow's title
				if(title === null) {
					this._setTitle('_unset_');
				} else {
					this._setTitle(title);
				}
			}
		},
		getExtraTitle: function (id) {
			return this.parentPage.getExtraTitle(id);
		},
		setExtraTitle: function (id, title) {
			this.parentPage.setExtraTitle(id, title);
		},
		setKids: function (kids, guessedCount) {
			this.subjects = kids;
			this.guessedCount = guessedCount;
		},
		getKids: function () {
			return this.subjects;
		},
		isGuessedCount: function () {
			return this.guessedCount;
		},
		getLayout: function () {
			if (this.layout && $.getObjectCount(this.layout) > 0) {
				return this.layout;
			} else {
				return this.parentPage.getLayout();
			}
		},
		setStudentLabelCSS: function (studentLabelCSS) {
			this.parentPage.setStudentLabelCSS(studentLabelCSS);
		},
		getStudentLabelCSS: function () {
			return this.parentPage.getStudentLabelCSS();
		},
		getParentPage: function () {
			return this.parentPage;
		},
		getRootPage: function () {
			var page = this;
			while (page.getParentPage && page.getParentPage()) {
				page = page.getParentPage();
			}

			return page;
		},
		setLayout: function () {

		},
		getClass: function () {
			return this.parentPage && this.parentPage.getClass();
		},
		getSubjects: function() {
			return this.parentPage.getSubjects();
		},
		getExtraClasses: function () {
			return this.parentPage.getExtraClasses();
		},
		getFieldRep: function () {
			return this.parentPage.getFieldRep.apply(this.parentPage, arguments);
		},
		setLargeCellPosition: function (largeCellPosition, forceUpdate) {
			this.parentPage.setLargeCellPosition(largeCellPosition, forceUpdate);
		},
		getLargeCellPosition: function () {
			return this.parentPage.getLargeCellPosition();
		},
		getSubjectCellData: function(subjectId) {
			return this.parentPage.getSubjectCellData(subjectId);
		},
		getSubjectCellDataValue: function(subjectId, name) {
			return this.parentPage.getSubjectCellDataValue(subjectId, name);
		},
		setSubjectCellData: function(subjectId, value) {
			this.parentPage.setSubjectCellData(subjectId, value);
		},
		setSubjectCellDataValue: function(subjectId, name, value) {
			this.parentPage.setSubjectCellDataValue(subjectId, name, value);
		},
		getPageMargins: function() {
			var pageMargins = getPageMargins.call(this);
			if(pageMargins) {
				return pageMargins;
			} else if(this.parentPage) {
				return this.parentPage.getPageMargins();
			}
		},
		setExtraProperty: function(name, value) {
			if(this.updateRootPageProperties?.includes(name)) {
				return this.parentPage.setExtraProperty(name, value);
			} else {
				return _setExtraProperty.apply(this, arguments);
			}
		},
		getExtraProperty: function(name, defaultValue) {
			if(this.updateRootPageProperties?.includes(name)) {
				return this.parentPage.getExtraProperty(name, defaultValue);
			} else {
				return _getExtraProperty.apply(this, arguments);
			}
		},
		shouldPromptToSave: function() {
			return $.getObjectCount(this.getCandids()) + $.getObjectCount(this.getTexts()) > 3;
		},

		classObj: null,
		parentPage: parentPage,
		subjects: kids,
		type: 'classOverflow',
		guessedCount: guessedCount,
		updateRootPageProperties: ['grayscale']
	});

	if(parentPage) {
		if(parentPage.setOverflowPage) {
			parentPage.setOverflowPage(obj);
		} else {
			// For undoing overflow removal before the origin page is restored
			parentPage.overflowPage = obj;
		}
	}

	return obj;
};