import Vue from 'vue';
if(window.Vue) {
	Vue = window.Vue;
} else {
	window.Vue = Vue;
}
import vuetify from '../js/plugins/vuetify';
import Vuelidate from 'vuelidate';
import '../js/mixins/index';
require('intersection-observer-polyfill/dist/IntersectionObserver.global');
import '../PlicCanvas/vue/utils/common-css.css';
import '../PlicCanvas/vue/utils/external-scripts';
import '../PlicCanvas/vue/utils/registerServiceWorker';
import '../PlicCanvas/vue/common/common-libs';
import '../PlicCanvas/vue/common/common-components';
import '../PlicCanvas/vue/common/legacy/flow-layout';
import './legacy/pages';
import $ from 'jquery';
if(!window.$) {
	window.$ = window.jQuery = $;
}

Vue.config.productionTip = Vue.config.devtools = false;
Vue.use(Vuelidate);

import Uploader from '../PlicCanvas/vue/uploader/Uploader.vue';
import { acceptedFileExtensions, photoConverters } from '../vue/utils/photo-converters';
Vue.component('candid-uploader', Uploader);

import ButtonUploader from '../PlicCanvas/vue/uploader/ButtonUploader';
Vue.component('button-uploader', ButtonUploader);

window.Vuetify = vuetify;
window.webpack_acceptedFileExtensions = acceptedFileExtensions;
window.webpack_photoConverters = photoConverters;

import Papa from 'papaparse';
window.Papa = Papa;

import pdfjsLib from 'pdfjs-dist';
window.pdfjsLib = pdfjsLib;

import { rotateImage } from '../PlicCanvas/vue/utils/rotate-image';
window.rotateImage = rotateImage;

import DictionaryManagement from '../PlicCanvas/vue/project-management/DictionaryManagement';
Vue.component('dictionary-management', DictionaryManagement);

import 'jsbarcode';
// Couldn't get a working version on npm - probably need to port to a separate library to get updating
import '../PlicCanvas/js/lib/qrcode';
import 'linkify-jquery';

import '../js/changelog.js';
import '../js/tutorials.js';
import '../js/common/cache-subject-fields';
import '../js/common/globals';

import '../css/common.css';

// Used in parent-subject-upload-card as well
import { diff_match_patch } from 'diff-match-patch';
window.diff_match_patch = diff_match_patch;