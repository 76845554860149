$.PersonalizedPageSet = function(settings) {
	var obj = new $.FlowPageClassSet($.extend({
		load: function (events) {
			this.db.load($.extend({
				onLoadGlobals: function(data) {
					obj.size = data.size;
					if(data.bookFormat) {
						obj.layoutDimensions = data.bookFormat.definition;
						$.bookFormat = data.bookFormat;
					}
				}
			}, events));
		},
		setStatus: function(status) {
			if(status != this.status) {
				this.db.queueChange({
					scope: 'yearbook',
					name: 'status',
					value: status
				});

				if(this.userEvents) {
					this.userEvents.addEvent({
						context: [this, 'status'],
						action: 'update',
						args: [this.status, status]
					});
				}

				this.db.pushChanges();
				this.db.setOnlyCommentsAllowed(true);
			}

			this.status = status;
		},
		onLoadGlobalData: function(data) {
			if(data.theme) {
				this.theme = data.theme;
			}
			if(data.pageNumberPosition) {
				this.pageNumberPosition = data.pageNumberPosition;
			}
			if(data.pageNumberCSS && !$.isArray(data.pageNumberCSS)) {
				this.pageNumberCSS.css = data.pageNumberCSS;
			}
			if($.projectSettings.pageNumberHidden && $.projectSettings.pageNumberHidden.value) {
				this.getPageNumberPosition = null;
			}

			if(data.bookFormat) {
				$.bookFormat = data.bookFormat;
				this.layoutDimensions = data.bookFormat.definition;
			}
		},
		isAllowedToAddPages: function() {
			return false;
		},

		personalizationId: $.getGETParams().personalizationId,
		bleedMask: true
	}, settings));
	$.BookPageNumbersModule(obj, settings);

	obj.db = new $.PersonalizedPageDB(obj, settings);

	return obj;
};
