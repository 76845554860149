$.managementTabTutorials = {
	'Snapshot.html': {
		id: 'tourDashboard',
		steps: [
			{
				target: '#app .mdc-toolbar-title',
				title: 'Welcome to your Captura Yearbooks Dashboard',
				content: 'Howdy! Welcome to your Dashboard. This page will help you get a quick view of your most important statistics so you don’t have to go Menu-Hopping. <b>Clicking</b> on the Stat will <b>Filter</b> the results for you in the <b>Schools</b> page.',
				placement: 'right'
			},
			{
				target: '#totalBooksMessage > .statistic',
				title: 'Total number of Projects',
				content: 'This Statistic shows how many School Projects you have in the system, regardless of where they are in the book-making progress.',
				placement: 'right'
			},
			{
				target: '#progressBooksMessage > .statistic',
				title: 'YearBooks in Progress',
				content: 'How active are your Schools being? This Statistic shows how many School Projects that are being actively worked-on. Keep tabs on them. Crack the whip!',
				placement: 'left'
			},
			{
				target: '#overdueBooksMessage > .statistic',
				title: 'YearBooks past their Due Date',
				content: 'Are your Schools really slacking? Are they having issues getting their books created? Keep track of the Schools that need some motivatuion to make their submission deadline.',
				placement: 'right'
			},
			{
				target: '#submittedBooksMessage > .statistic',
				title: 'YearBooks submitted successfully',
				content: 'This Statistic shows you how many Yearbooks were completed and submitted for the final product. You also have emails that will back this stat up :).',
				placement: 'left'
			}			
		],
		showPrevButton: true,
		scrollTopMargin: 100
	},
	'AdvisorDashboard.html': {
		id: 'tourDashboard',
		steps: [
			{
				target: '#advisor-dashboard',
				title: 'Welcome to your Captura Yearbooks Dashboard',
				content: 'Howdy! Welcome to your Dashboard. This page will help you get a quick view of your Book\'s current status.',
				placement: 'bottom',
				xOffset: 'center',
				arrowOffset: 'center'
			},
			{
				target: '#advisor-dashboard .book-details-header-text',
				title: 'Book Overview',
				content: 'Here you will see some basic information about your book such as what state it is in, when your deadline is, and how many pages your book has. ',
				placement: 'right'
			},
			{
				target: '#app .manager-drawer-group > .mdc-drawer-item[href="#SubjectManagement.html"]',
				title: 'Subjects',
				content: 'You can start by looking over your Subjects and their Classes to make sure everyone is who they\'re supposed to be and make sure they are Where they\'re supposed to be.',
				placement: 'right'
			},
			{
				target: '#app .manager-drawer-group > .mdc-drawer-item[href="#Users.html"]',
				title: 'Users',
				content: 'Next, try adding a few people to help make your Book journey complete. It\'s always more fun with friends.',
				placement: 'right'
			},
			{
				target: '#app .manager-drawer-group > .mdc-drawer-item[href="#Candids.html"]',
				title: 'Candid Photos',
				content: 'Here you can upload candid photos, as well as send out a link to parents so they can upload photos for use in your Book.',
				placement: 'right'
			},
			{
				target: '#advisor-dashboard .go-to-book-btn',
				title: 'Go to Composer',
				content: 'Click on this button to start working on your Book.',
				placement: 'right'
			}
		],
		showPrevButton: true,
		scrollTopMargin: 100
	},
	'WorkflowProgress.html': {
		id: 'tourDashboard',
		notPermission: 'manageCustomers',
		steps: [
			{
				target: '#workflowProgressContainer',
				title: 'Welcome!',
				content: 'Welcome to Captura Yearbooks! This is your Dashboard where you can see a snapshot of your Book progress, Manage your Subjects, Users and Candids.',
				placement: 'bottom',
				xOffset: 'center',
				arrowOffset: 'center'
			},
			{
				target: '#app .manager-drawer-group > .mdc-drawer-item[href="#SubjectManagement.html"]',
				title: 'Start',
				content: 'You can start by looking over your Subjects and their Classes to make sure everyone is who they\'re supposed to be and make sure they are Where they\'re supposed to be.',
				placement: 'right'
			},
			{
				target: '#app .manager-drawer-group > .mdc-drawer-item[href="#Users.html"]',
				title: 'Users',
				content: 'Next, try adding a few people to help make your Yearbook journey complete. It\'s always more fun with friends.',
				placement: 'right'
			},
			{
				target: '#app .go-to-school-button',
				title: 'Go To Book',
				content: 'Once you\'re ready, start Composing your book. Remember the #1 rule... have fun!',
				placement: 'bottom',
				xOffset: 'center',
				arrowOffset: 'center'
			}
		],
		showPrevButton: true,
		scrollTopMargin: 100
	},
	'Schools.html#loaded': {
		id: 'tourDashboard',
		steps: [
			{
				target: '#organizationTable tr:first',
				title: 'Books',
				content: 'Here is where you manage your all of your Books and their project settings.',
				placement: 'top',
				xOffset: 'center',
				arrowOffset: 'center'
			},
			{
				target: '.manager-season-picker',
				title: 'Seasons',
				content: 'If you have more than one season, you can switch between seasons from here.',
				placement: 'bottom',
				xOffset: 'center',
				arrowOffset: 'center'
			},
			{
				target: '#createBookButton',
				title: 'Creating a Book',
				content: 'Click on Create Yearbook to get started creating your first project. ' +
					'You will be walked through the process of choosing or creating a school, name, and various project settings. ' +
					'For more information visit <a href="https://iqplsupport.com/en/articles/4269637-plic-books-creating-books" target="_blank">this link</a>.',
				placement: 'right'
			}
		],
		showPrevButton: true,
		scrollTopMargin: 100
	},
	'SubjectManagement.html#loaded': {
		id: 'tourDashboard',
		steps: [
			{
				target: '.subjectManagementBatch',
				title: 'Subject Management',
				content: 'This is where all the data magic happens!<br>Search, correct spellings, move subjects to different classes, add staff options and more...',
				placement: 'top',
				xOffset: 'center',
				arrowOffset: 'center'
			},
			{
				target: '#subjectBatchesSlider',
				title: 'Batches or (Classes)',
				content: 'Your teacher classes or grade classes are listed here. The label shows the total in the batch. You can rename, add [+] or delete batches',
				placement: 'bottom',
				xOffset: 'center',
				arrowOffset: 'center'
			},
			{
				target: '#subjectManager .subjectManagementBatch .subjectCard:first',
				title: 'Subject Cards',
				content: 'To move or copy subjects from one batch to another, drag their image to the desired batch title.<br><b>Note:</b>  To move or copy a group of subjects, simply hold down the CTRL key and select each student you wish to move before dragging.',
				placement: 'right'
			},
			{
				target: '#subjectManager .subjectManagementBatch .subjectCard:first .startButton:last',
				title: 'Edit Subjects and Options',
				content: 'You can change name spellings or change the batch using the [Edit] button.<br>Assign a subject as Staff by using the [Options] button.',
				placement: 'right'
			},
			{
				target: '#subjectManager input[type="search"]',
				title: 'Search!',
				content: 'Find that kid! Use search to filter the one(s) you need to find from all batches.',
				placement: 'left'
			},
			{
				target: '#subjectManager .subjectManagementBatch .subjectAddCard',
				title: 'Add Subjects',
				content: 'You can add a subject and their portrait easily. They are added to the current batch',
				placement: 'top'
			}
		],
		showPrevButton: true,
		scrollTopMargin: 100
	},

	'Candids.html#loaded': {
		id: 'tourDashboard',
		steps: [
			{
				target: '#candidPhotos .photoPicker',
				title: 'Incoming Candids',
				content: 'Do you need photos uploaded from an event, but do not want to give yearbook access?<br>You can use our Guest Uploader URL so anyone (with the link) can upload candids for you to approve <i>Before</i> they go into the yearbook.',
				placement: 'top',
				xOffset: 'center'
			},
			{
				target: '#candidPhotos .copy.icon',
				title: 'Copy and Paste it',
				content: 'Click on the copy button to copy the URL and paste into your email.  Each album has it\'s own unique url.',
				placement: 'left'
			},
			{
				target: '#candidPhotos .checkmark.icon',
				title: 'Approve or Disapprove',
				content: 'After users upload candids, you can approve or disapprove them from here',
				placement: 'bottom',
				xOffset: 'center'
			}
		],
		showPrevButton: true,
		scrollTopMargin: 100
	},
	'Seasons.html#loaded': {
		id: 'tourDashboard',
		steps: [
			{
				target: '#seasonsTable_wrapper',
				title: 'Seasons Management',
				content: 'Here you can manage your seasons.  Seasons are a great way to manage your projects between different school years.',
				placement: 'bottom'
			},
			{
				target: '#seasonsTable_length',
				title: 'Create Season',
				content: 'We recommend you create a season for this current school season before you begin.  If you have old projects from the previous season, you should also create a season for them as well.',
				placement: 'bottom'
			},
			{
				target: '#seasonsTable_wrapper .bulkAddProjectsButton:first',
				backupTarget: '#seasonsTable_wrapper',
				title: 'Edit which projects are in a season',
				content: 'After you have created your first season, you can edit the season a project belongs to from Project Settings.  You can also bulk import projects into a season with the blue transfer button.',
				placement: 'bottom'
			},
			{
				target: '#seasonsTable_wrapper .filterSeasonButton:first',
				backupTarget: '#seasonsTable_wrapper',
				title: 'View old seasons',
				content: 'After you move to a new season, you may want to go back and view the projects from an old season.  You may do this by clicking on the gray filter button.',
				placement: 'bottom'
			}
		]
	},
	'Workflows.html#loaded': {
		id: 'tourDashboard',
		steps: [
			{
				target: '#workflowsTable_wrapper',
				title: 'Workflows Management',
				content: 'Here you can manage your workflows.  Workflows are a great way to manage your progress creating and printing Yearbooks.',
				placement: 'bottom'
			},
			{
				target: '#workflowsTable_wrapper tbody tr:first',
				title: 'Default Workflow',
				content: 'We have created a default Workflow for you.  This workflow is tied into the Status of the Project.',
				placement: 'bottom'
			},
			{
				target: '#workflowsTable_wrapper .workflowInfoButton:first',
				title: 'Edit Workflows',
				content: 'You can edit the default workflow and change the steps to suite your needs.',
				placement: 'left'
			},
			{
				target: '#workflowsTable_length',
				title: 'Create Worfklows',
				content: 'You can create additional workflows which will show up.  Only the default workflow will be automatically added to new projects.',
				placement: 'bottom'
			}
		]
	},
	'BookFormats.html#loaded': {
		id: 'tourDashboard',
		steps: [
			{
				target: '#bookFormatsTable',
				title: 'Book Formats',
				content: 'Here you can manage your Book Formats.  The Book Format defines details such as the physical size of the book, the bleed around the edges of the book, and the spine or center bleed for the Book.',
				placement: 'bottom'
			},
			{
				target: '#bookFormatsTable_length',
				title: 'Create Book Format',
				content: 'If you have any custom specifications for different Book Formats you can create them by clicking on this button.',
				placement: 'bottom'
			}
		]
	},
	'AdPages.html': {
		id: 'tourDashboard',
		steps: [
			{
				target: '#adsTable_wrapper .ui.blue.button',
				title: 'Ads Management',
				content: 'Here you can manage both cash ads and those created through the Captura Yearbooks Store.',
				placement: 'right'
			},
			{
				target: '#adsTable_wrapper .ui.blue.button',
				title: 'Create cash orders',
				content: 'Click on Create Ad to create an ad from a cash order',
				placement: 'right'
			},
			{
				target: '#adsTable',
				title: 'More information',
				content: 'For more information visit <a href="https://iqplsupport.com/en/articles/4360683-plic-books-purchased-ads" target="_blank">this link</a>.',
				placement: 'top'
			}
		],
		showPrevButton: true,
		scrollTopMargin: 100
	},
	'YearbookPersonalizations.html': {
		id: 'tourDashboard',
		steps: [
			{
				target: '#personalizationsTable_wrapper .ui.button',
				title: 'Book Personalization Management',
				content: 'Here you can manage both cash personalizations and those created through the Captura Yearbooks Store.  You can see the status of personalized pages parents are working on, as well as see and render names on cover personalizations.',
				placement: 'right'
			},
			{
				target: '#personalizationsTable_wrapper .ui.blue.button',
				title: 'Create cash orders',
				content: 'Click on Create Personalization to create an personalization from a cash order',
				placement: 'right'
			},
			{
				target: '#personalizationsTable',
				title: 'More information',
				content: 'For more information visit <a href="https://iqplsupport.com/en/articles/4623165-plic-books-personalized-pages-and-covers" target="_blank">this link</a>.',
				placement: 'top'
			}
		],
		showPrevButton: true,
		scrollTopMargin: 100
	},
	'ContentBooks.html': {
		id: 'tourDashboard',
		steps: [
			{
				target: '.content-book-type',
				title: 'Pick content type',
				content: 'Click on dropdown to select which type of content book you want to generate.',
				placement: 'bottom'
			},
			{
				target: '.generate-book-button',
				title: 'Click generate',
				content: 'Click the Generate Book button to start generating a pdf of the content you selected.',
				placement: 'bottom'
			},
			{
				target: '.content-book-type',
				title: 'More information',
				content: 'For more information visit <a href="https://iqplsupport.com/en/articles/5336683-plic-books-creating-content-books" target="_blank">this link</a>.',
				placement: 'bottom'
			}
		],
		showPrevButton: true,
		scrollTopMargin: 100
	},
};
$.runManagementTabTutorial = function(path) {
	var tutorial = $.managementTabTutorials[path];
	if(tutorial && (!tutorial.notPermission || !$.userPermissions[tutorial.notPermission])) {
		$.runTutorial(path, tutorial);

		// Add a help button regardless of whether this was run or not
		var index = path.indexOf('#')
		if(index != -1) {
			path = path.substr(0, index);
		}
		var tab = $('#tabs > div[data-tab="' + path + '"]');
		var header = tab.children('h2.header').first();
		if(header.length && !header.hasClass('left')) {
			header.addClass('left floated');

			var help = $('<h2 class="ui right floated header" style="cursor: pointer"><i class="help circle green icon"></i></h2>').click(function() {
				$.runTutorial(path, tutorial, true);
			}).insertAfter(header);
			help.next().css('clear', 'both');
		}
	}
};
$.runTutorial = function(name, tutorial, manualRun) {
	if(tutorial) {
		// Already ran once
		if($.userExtras['tutorials'] && $.userExtras['tutorials'][name] && !$.userExtras['debugTutorials'] && !manualRun) {
			return;
		}

		for(var i = 0; i < tutorial.steps.length; i++) {
			var step = tutorial.steps[i];
			if(step.backupTarget && !$(step.target).length) {
				step.target = step.backupTarget;
			}
		}

		window.setTimeout(function () {
			window.hopscotch.startTour(tutorial, 0);

			if(!manualRun) {
				if(!$.userExtras['tutorials']) {
					$.userExtras['tutorials'] = {};
				}

				$.userExtras['tutorials'][name] = true;
				$.ajax({
					url: 'ajax/tutorialRead.php',
					dataType: 'json',
					data: {
						tutorial: name
					},
					type: 'POST'
				});
			}
		}, 10);
	}
};

$.composerTutorial = {
	id: 'tourDashboard',
	steps: [
		{
			target: '#rightPage',
			title: 'Composer',
			content: 'Welcome to the composer.  Here you will be able to create your yearbook.',
			placement: 'right',
			xOffset: 'center',
			yOffset: 'center'
		},
		{
			target: '#layoutCategoryWrapper',
			title: 'Themes',
			content: 'Start by dragging a theme onto your page.',
			placement: 'right'
		},
		{
			target: '#classesWrapper',
			title: 'Batches',
			content: 'Next you can drag a batch onto your book.',
			placement: 'right',
			xOffset: 'center',
			yOffset: 0
		},
		{
			target: '#layoutCategoryWrapper',
			title: 'Class Layouts',
			content: 'Change the look of your class by dragging a Class Layout onto your page.',
			placement: 'right'
		},
		{
			target: '#candidsCategoryWrapper',
			title: 'Candids',
			content: 'Drag your candids or clip art onto pages.  Afterwords you can move, resize, and add effects to them.',
			placement: 'left'
		},
		{
			target: '#previewBookButton',
			title: 'Page buttons',
			content: 'Use these buttons to navigate around your book.',
			placement: 'top',
			yOffset: 0,
			arrowOffset: 'left'
		},
		{
			target: '#rightPage',
			title: 'More information',
			content: 'For more information visit <a href="https://iqplsupport.com/en/articles/5278481-plic-books-designing-your-book" target="_blank">this link</a>.',
			placement: 'right',
			xOffset: 'center',
			yOffset: 'center'
		}
	],
	showPrevButton: true,
	scrollTopMargin: 100
};

$.pageLadderTutorial = {
	id: 'tourDashboard',
	steps: [
		{
			target: '#pageLadder',
			title: 'Book Preview',
			content: 'In this Book Preview you can move around, delete, and navigate to different sections of the Book',
			placement: 'right',
			xOffset: 'center',
			yOffset: 'center'
		},
		{
			target: '#pageLadder .pageThumbnail:eq(2)',
			title: 'Page Thumbnails',
			content: 'Hover over a thumbnail for more options.  You can move the page around by dragging and dropping it where you want it to go.',
			placement: 'right'
		},
		{
			target: '#pageLadder .pageThumbnail:eq(2) .pageThumbnailTitle',
			title: 'Page Title',
			content: 'Edit the page titles to storyboard what each page is going to be for.',
			placement: 'right'
		},
		{
			target: '#pageLadder .page-ladder-new-page-button',
			title: 'Add Pages',
			content: 'Click on the plus button in the lower right corner to add additional pages.  This button will only be visible when you are under your page limit',
			placement: 'left'
		},
		{
			target: '.pageLadderModal .actions .positive.button',
			title: 'Preview PDF',
			content: 'Click the Preview PDF button to generate a preview of what the Book will look like when it is printed.',
			placement: 'top'
		},
		{
			target: '.pageLadderModal .actions .orange.button',
			title: 'Submit PDF',
			content: 'When you are all done and ready to submit your Book to your Publisher, click on Finalize and Submit.  Don\'t press this unless you are really done!',
			placement: 'top'
		}
	],
	showPrevButton: true,
	scrollTopMargin: 100
};