$.FontBook = function() {
	var obj = new $.FlowPageSet({});

	$.extend(obj, {
		// Want to make sure this is called after globals setup so we can grab customer fonts
		generateInitialPages: function(pageSetData) {
			this.setupFontPages(pageSetData);
		},
		setupFontPages: function(pageSetData) {
			var fonts = pageSetData.fonts || $.GoogleFontList;
			var perPage = this.FONT_COLUMNS * this.FONT_ROWS;
			for(var i = 0; i < fonts.length; i += perPage) {
				this.createPage(fonts.slice(i, i + perPage));
			}
		},
		createPage: function(fonts) {
			var page = new $.FlowPage({
				title: {
					lines: {
						text: 'Captura Yearbooks Fonts'
					}
				},
				theme: {
					type: 'Preview',
					Background: {
						id: 'test',
						cdnUrl: 'https://s3.amazonaws.com/plic-books/public/font-book-background.png'
					}
				},
				layout: {}
			});

			var height = 10.5;
			var startHeight = 1;
			var rowHeight = (height - startHeight) / this.FONT_ROWS;

			var width = 8;
			var startWidth = 0.4;
			var columnWidth = width / this.FONT_COLUMNS;

			var texts = fonts.map(function(font, i) {
				var x = i % obj.FONT_COLUMNS;
				var y = Math.floor(i / obj.FONT_COLUMNS);

				return {
					lines: {
						text: font,
						fontFamily: font,
						'font-size': '12pt'
					},
					position: {
						left: x * columnWidth + startWidth,
						top: y * rowHeight + startHeight
					}
				};
			});
			page.setTextArray(texts);

			this.addPage(page, null, false);
		},
		FONT_COLUMNS: 3,
		FONT_ROWS: 20
	});

	return obj;
};