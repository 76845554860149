$.AdPageSet = function(settings) {
	var obj = new $.FlowPageClassSet($.extend({
		load: function (events) {
			this.db.load($.extend({
				onLoadGlobals: function(data) {
					obj.size = data.size;
					if(data.bookFormat) {
						obj.layoutDimensions = data.bookFormat.definition;
						obj.layoutDimensions.hideBleed = false;
						$.bookFormat = data.bookFormat;
					}
				}
			}, events));
		},
		setStatus: function(status) {
			if(status != this.status) {
				this.db.queueChange({
					scope: 'yearbook',
					name: 'status',
					value: status
				});

				if(this.userEvents) {
					this.userEvents.addEvent({
						context: [this, 'status'],
						action: 'update',
						args: [this.status, status]
					});
				}

				this.db.pushChanges();
				this.db.setOnlyCommentsAllowed(true);
			}

			this.status = status;
		},

		adId: $.getGETParams().adId,
		containImageInParent: true
	}, settings));

	obj.db = new $.AdDB(obj, settings);

	return obj;
};
