import FrameDynamicGraphicsEditor from '../../vue/flow-layout/dynamic-graphics/FrameDynamicGraphicsEditor.vue';

$.FlowLayoutFrame = function(wrapper, definition, ratio, options) {
	var frame = new $.FlowLayoutFlowContent({
		wrapper: wrapper,
		editTools: function() {
			if(this.instance.locked && this.canToggleLock) {
				return [this.getToggleLockButton()];
			}

			var cropGroup = {
				addClass: 'icon',
				group: [
					'crop',
					'flip horizontal',
					'flip vertical'
				]
			};
			if(((this.secondaryFocusedElements.length && !this.secondaryFocusedElements.find(secondaryElement => secondaryElement.wrapper !== this.wrapper)) || this.primaryFocusedElement) && !this.keepLayoutSizedTogether) {
				cropGroup.group.push(this.getGroupAlignmentButton());
			}
			if(this.isPlaceholder() || this.isShape()) {
				cropGroup.group.push('split');
			}

			var tagGroup = {
				addClass: 'icon',
				group: [
					'applyToAll',
					'copy',
					'tags'
				]
			};

			if(this.instance.photoVersions && this.instance.photoVersions.length > 1 && $.userPermissions.editPhotoVersions && $.primaryGraphicPhotoVersion !== 'original') {
				tagGroup.group.push('edit versions');
			}

			if($.getGETParams().debugPhotoReplacement || $.getGETParams().debugReplacePhoto) {
				tagGroup.group.push('replace');
			}
			if(this.keepLayoutSizedTogether) {
				tagGroup.group.removeItem('copy');
			}

			var filterGroup = [
				'invert',
				'grayscale',
				'blur',
				'opacity',
				'saturate',
				'contrast',
				'brightness',
				'hue'
			];
			if(this.wrapper && this.wrapper.page && this.wrapper.page.forceGrayscale()) {
				filterGroup.removeItem('grayscale');
			}

			var effectsGroup = [
				'stroke',
				'drop shadow'
			];

			var hasGroup = this.instance && this.instance.groupedElements && this.instance.groupedElements.length > 0;
			if(hasGroup) {
				effectsGroup.push(this.getGroupBorderButton());
			}

			var tools = [
				{
					addClass: 'icon',
					group: [
						{
							icon: 'arrow down',
							popup: 'Move image back',
							onClick: function(selection) {
								this.lowerZIndex();
								this.updateToolbarDisplay();
							},
							updateDisplay: function(selection) {
								if(selection.behindCells) {
									this.setActive(true);
									$(this).attr('data-tooltip', 'Move image back (currently behind subject images)');
								} else {
									this.setActive(false);
									$(this).attr('data-tooltip', 'Move image back');
								}
							}
						},
						{
							icon: 'arrow up',
							popup: 'Move image forward',
							onClick: function(selection) {
								this.raiseZIndex();
								this.updateToolbarDisplay();
							},
							updateDisplay: function(selection) {
								if(selection.overlayCells) {
									this.setActive(true);
									$(this).attr('data-tooltip', 'Move image forward (currently over subject images)');
								} else {
									this.setActive(false);
									$(this).attr('data-tooltip', 'Move image forward');
								}
							}
						}
					]
				},
				{
					addClass: 'icon',
					group: effectsGroup
				},
				{
					addClass: 'icon',
					group: filterGroup
				},
				cropGroup
			];

			if(!this.isSpecialFrame()) {
				tools.push(tagGroup);
			}

			// Remove options which make no sense in a placeholder
			if(this.isNormalImage() && this.instance.photo_name) {
				cropGroup.group.push('caption');
			}
			if(this.isDynamicGraphic()) {
				cropGroup.group.push('dynamic graphic');
			}
			tagGroup.group.push('edit dimensions');
			if(this.canToggleLock && !this.keepLayoutSizedTogether) {
				tagGroup.group.push(this.getToggleLockButton());
			}

			if(!this.isNormalImage()) {
				cropGroup.group.splice(0, 1);
				tagGroup.group.splice(2, 1);
			}

			if(this.isShape()) {
				cropGroup.group = ['split'];
				effectsGroup.unshift('color');
			}

			let pageSet = this.wrapper.getPageSet();
			if(this.isSubjectField()) {
				cropGroup.group.unshift('green screen background');

				var primarySubject = this.getPrimarySubject();
				if(primarySubject && primarySubject.photo) {
					cropGroup.group.unshift('crop pose');
				}
				tools.push('pose selection');
				tools.push('crop selection');
				tools.push({
					addClass: 'icon',
					group: [
						'horizontal crop alignment',
						'vertical crop alignment'
					]
				});
			} else if(this.instance.chroma_key === 'processed' && (!pageSet || pageSet.projectBackgroundId)) {
				cropGroup.group.unshift('green screen background');
			}

			if(((this.secondaryFocusedElements.length && !this.secondaryFocusedElements.find(secondaryElement => secondaryElement.wrapper !== this.wrapper)) || this.primaryFocusedElement) && !this.keepLayoutSizedTogether) {
				tools.push(this.getGroupButton());
			}
			if(!this.isSpecialFrame()) {
				tools.push('remove');
			}

			return tools;
		}
	});
	frame._setFocused = frame.setFocused;
	frame._setEditable = frame.setEditable;
	var _refreshFromOriginalInstance = frame.refreshFromOriginalInstance;
	var _changeSecondaryInstanceProperty = frame.changeSecondaryInstanceProperty;
	$(frame).addClass('frame imageWrapper flowAlignTarget flowLayoutFrame');

	$.extend(frame, {
		onChangeInstanceProperty: function(name, value, extras) {
			if(!extras) {
				extras = {};
			}

			var page = this.parent.getPage();
			if(!page) {
				return;
			}

			if (!page.setCandidProperty(this.instance.id, name, value)) {
				console.error('Failed to find candid id ' + this.instance.id, {
					id: this.instance.id,
					candids: page.getCandids(),
					this: this.instance,
					snapshot: this.wrapper.getSnapshot(),
					change: {
						name: name,
						value: value
					}
				});
			}

			if(!extras.linkedInstanceChange) {
				this.changeLinkedInstanceProperty(name, value);
			}
		},
		changeLinkedInstanceProperty: function(name, value) {
			if(name.indexOf('x') != -1 || name.indexOf('y') != -1) {
				return;
			}

			this.withLinkedElem(function(layout, frame) {
				frame.flushDelayedSaveQueue();
				frame.onChangeInstanceProperty(name, value, {
					linkedInstanceChange: true
				});
				frame.refreshFromOriginalInstance();
			});
		},
		checkLinkedInstance: function() {
			if(!this.instance) {
				return;
			}

			var linkedLayout = this.wrapper.getLinkedLayout('previous');
			if(linkedLayout) {
				var linkedFrame = linkedLayout.getFrame(this.instance.id);

				if(linkedFrame) {
					if(this.instance.duplicateInBleed || this.instance.duplicateInBleed === undefined) {
						// Changed from true -> false
						if(!this.duplicateLinkedLayoutsInBleed && !linkedFrame.duplicateLinkedLayoutsInBleed) {
							this.changeInstanceProperty(['x', 'duplicateInBleed'], [this.instance.x - this.wrapper.inchBleed.left, false]);
							linkedFrame.changeInstanceProperty(['x', 'duplicateInBleed'], [linkedFrame.instance.x + linkedLayout.inchBleed.right, false]);
							linkedFrame.refreshInstance();
						}
					} else {
						// Changed from false -> true
						if(this.duplicateLinkedLayoutsInBleed && linkedFrame.duplicateLinkedLayoutsInBleed) {
							this.changeInstanceProperty(['x', 'duplicateInBleed'], [this.instance.x + this.wrapper.inchBleed.left, true]);
							linkedFrame.changeInstanceProperty(['x', 'duplicateInBleed'], [linkedFrame.instance.x - linkedLayout.inchBleed.right, true]);
							linkedFrame.refreshInstance();
						}
					}
				}
			}
		},

		setInstance: function(instance) {
			if(instance) {
				this.instance = this.definition = $.extend(true, {}, instance);

				this.sanitizeInstance(this.instance);
				if(instance.id) {
					$(this).attr('id', 'flow-content-' + instance.id);
				}
				this.checkLinkedInstance();
			} else {
				this.instance = null;
				$(this).removeAttr('id');
			}

			// Definition is for compat with FlowLayoutFlowContent methods
			this.definition = this.instance;
			this.origInstance = instance;

			this.refreshInstance();
		},
		sanitizeInstance: function(instance) {
			if(instance.border && !instance.border.thickness) {
				instance.border = null;
			}
			if(instance.dropShadow && !instance.dropShadow.intensity) {
				instance.dropShadow = null;
			}
		},
		refreshInstance: function() {
			// Setup width/height
			this.width = this.instance.width * ratio;
			this.height = this.instance.height * ratio;
			$(this).width(this.width).height(this.height);
			// Subtract 1 border since it isn't included in overlap calculation
			// Add the canvas border if shown since the cells are offset by it
			this.x = (this.instance.x || 0) * ratio - 1 + wrapper.canvasBorderWidth;
			this.y = (this.instance.y || 0) * ratio - 1 + wrapper.canvasBorderWidth;
			this.movementLocked = false;
			this.position();
			this.updateEditToolbar();

			if(this.instance.photo) {
				var backgroundPhoto;
				if(this.instance.useGreenScreenBackground) {
					backgroundPhoto = this.getSubjectBackground(this.instance);
				}

				this.setImage(this.instance.photo, this.instance.existingUrl, backgroundPhoto);
				$(this).data('photo', this.instance.photo);
				$(this).removeClass('imageWrapper');
			} else if(this.instance.photoFieldMap) {
				this.setupDynamicImage(this.instance.photoFieldMap);
			} else if(this.isSubjectField()) {
				this.setSubjectField(this.instance.field);
			} else if(this.instance.shape) {
				this.setupShape();
			} else {
				this.setupImageElement();
				$(this.imgElem).removeAttr('src');
				$(this).addClass('imageWrapper');

				if(this.backgroundImage) {
					$(this.backgroundImage).remove();
					this.backgroundImage = null;
				}
			}
			if(this.instance.transform) {
				this.style.transform = this.style['-webkit-transform'] = this.instance.transform;
			} else {
				this.style.transform = this.style['-webkit-transform'] = '';
			}
			this.maskedBorder = true;
			this.applyMask(this.instance.mask);
			this.ratio = ratio;
			this.setEditable(wrapper.editable);
			this.applyFilters(this.instance);
			this.applyGroupBorders();
			this.applyCrop();
			this.applyZIndex();
			this.updateLockedTooltip();
			this.updateOtherTooltip();
			if((this.focused || this.secondaryFocused)) {
				if(this.resizable) {
					if(this.instance.locked) {
						this.setResizable(false);
					} else {
						this.setResizable(true);
					}
				}
				if(this.movable) {
					if(this.instance.locked) {
						this.lockedMovement = true;
					} else {
						this.lockedMovement = false;
					}
					this.setMovable(this.movable);
				}
			}

			if(this.keepLayoutSizedTogether && $.isArray(this.keepLayoutSizedTogether)) {
				if(!this.keepLayoutSizedTogether.includes(this.instance.id)) {
					this.keepLayoutSizedTogether = false;
				}
			}
		},
		refreshFromOriginalInstance: function() {
			var originalVersionId = this.instance.photoVersion;
			var newVersionId = this.origInstance.photoVersion;

			if(originalVersionId !== newVersionId) {
				if($.primaryGraphicPhotoVersion === 'original') {
					this.origInstance.photoVersion = this.instance.photoVersion;
					this.origInstance.existingUrl = this.instance.existingUrl;
				} else {
					this.refreshPhotoVersion = true;
				}
			}

			_refreshFromOriginalInstance.apply(this, arguments);
		},
		isImagePlaceholder: function() {
			return !this.instance.photo;
		},
		setImage: function (id, url, backgroundPhoto, photoName) {
			this.photoId = id;
			var img = this.setupImageElement();
			$(this).removeClass('imageWrapper');

			if(this.wrapper.forcedDPI) {
				url = this.getScaledImageUrlByDPI(url, this.instance.width, this.wrapper.forcedDPI);
			}
			// If we passed in a url, use it instead
			else if (!url) {
				url = this.getScaledImageUrl(this.instance.width, photoName);
			}

			$(img).LoadImage(url, {
				onComplete: function() {
					frame.fixRegisteredAspectRatio();
				}
			});
			this.checkMinimumResolution();
			this.setBackgroundImage(backgroundPhoto);
		},
		setBackgroundImage: function(backgroundPhoto) {
			if(!backgroundPhoto) {
				if(this.backgroundImage) {
					$(this.backgroundImage).remove();
					this.backgroundImage = null;
				}
				return;
			}

			if(!this.backgroundImage) {
				this.backgroundImage = document.createElement('img');
				$(this).find('.imageInnerStyleWrapper').prepend(this.backgroundImage);
			}

			var url;
			if(backgroundPhoto.cdnUrl) {
				url = backgroundPhoto.cdnUrl;
			} else {
				url = $.getPlicThumbnail(backgroundPhoto, {
					w: this.getScaledImageSize()
				});
			}
			$(this.backgroundImage).LoadImage(url);
		},
		fixRegisteredAspectRatio: function() {
			if(this.photoWidth && this.photoHeight && this.imgElem.naturalWidth && this.imgElem.naturalHeight) {
				var naturalAspectRatio = this.imgElem.naturalWidth / this.imgElem.naturalHeight;
				var invertedAspectRatio = this.photoHeight / this.photoWidth;

				// We have a rotated image
				if($.isWithinDiff(naturalAspectRatio, invertedAspectRatio, 0.01)) {
					var temp = this.photoHeight;
					this.photoHeight = this.photoWidth;
					this.photoWidth = temp;

					this.applyCrop();
				}
			}
		},
		setupImageElement: function() {
			var img = $(this).find('img')[0];

			if (!img) {
				var outerWrapper = document.createElement('div');
				outerWrapper.className = 'imageOuterStyleWrapper';
				this.outerWrapper = outerWrapper;

				var middleWrapper = document.createElement('div');
				middleWrapper.className = 'imageMiddleStyleWrapper';

				var innerWrapper = document.createElement('div');
				innerWrapper.className = 'imageInnerStyleWrapper';

				img = document.createElement('img');
				if (this.mask) {
					$(innerWrapper).css(this.mask);
				}
				outerWrapper.appendChild(middleWrapper);
				middleWrapper.appendChild(innerWrapper);
				innerWrapper.appendChild(img);
				this.appendChild(outerWrapper);

				this.imgElem = img;
			}

			return img;
		},
		getPrimarySubject: function(field) {
			var primarySubject;
			if(field == 'individualized image field') {
				primarySubject = wrapper.getLargeCellSubject();
			} else {
				primarySubject = wrapper.getPrimarySubject();
			}
			if(this.instance.subjectIndex) {
				primarySubject = wrapper.getSubjectAtIndex(this.instance.subjectIndex);
			}

			return primarySubject;
		},
		setSubjectField: function(field) {
			if(['image name', 'image file name', 'image field', 'individualized image field'].indexOf(field.toLowerCase()) != -1) {
				var primarySubject = this.getPrimarySubject(field);
				$(this).addClass('subjectFieldImage');

				if(primarySubject && primarySubject.photo) {
					var subjectPose = $.FlowLayoutFrameUtils.getSubjectPose(this.instance, primarySubject);
					if(subjectPose) {
						var photoCdnUrl = null;
						if(subjectPose == primarySubject.yearbookPhoto || subjectPose.id == primarySubject.photo) {
							photoCdnUrl = primarySubject.photoCdnUrl;
						} else if(subjectPose.cdn_url) {
							photoCdnUrl = subjectPose.cdn_url;
						}

						var backgroundPhoto;
						if(subjectPose.background_photo) {
							backgroundPhoto = subjectPose.background_photo;
						} else if(subjectPose.background_photo_id) {
							backgroundPhoto = {
								id: subjectPose.background_photo_id
							};
						} else if(this.instance.useGreenScreenBackground) {
							backgroundPhoto = this.getSubjectBackground(subjectPose);
						}

						this.setImage(subjectPose.id, photoCdnUrl, backgroundPhoto, subjectPose.upload_file_name);

						if(subjectPose) {
							this.photoWidth = subjectPose.width;
							this.photoHeight = subjectPose.height;
						}

						if(this.instance.cropSelection == 'no crop') {
							this.dynamicCrop = null;
						} else if(this.instance.cropSelection === 'primary crop' || !this.instance.cropSelection) {
							this.dynamicCrop = subjectPose.yearbookCrop;
						} else if(subjectPose.photo_crops) {
							var findCropName = this.instance.cropSelection.toLowerCase();
							var matchingCrop = subjectPose.photo_crops.filter(function(crop) {
								return crop.name.toLowerCase() === findCropName;
							})[0];
							this.dynamicCrop = matchingCrop;
						} else {
							this.dynamicCrop = null;
						}

						$(this).attr('subject_pose_id', subjectPose.id);
					} else {
						let img = this.setupImageElement();
						$(img).LoadImage('https://img.plic.io/aNdyGwKkONe95Rn_5vaKHV9v0ng87Da0a3vSBQuPqvY//aHR0cHM6Ly9wbGljLWlvLnMzLmFtYXpvbmF3cy5jb20vcHVibGljL3BvcnRyYWl0X3BsYWNlaG9sZGVyXzgxMC5zdmc.svg');
						$(this).removeAttr('subject_pose_id');
					}
				} else {
					let img = this.setupImageElement();
					$(img).LoadImage('https://img.plic.io/aNdyGwKkONe95Rn_5vaKHV9v0ng87Da0a3vSBQuPqvY//aHR0cHM6Ly9wbGljLWlvLnMzLmFtYXpvbmF3cy5jb20vcHVibGljL3BvcnRyYWl0X3BsYWNlaG9sZGVyXzgxMC5zdmc.svg');
					$(this).removeAttr('subject_pose_id');
				}
			}
		},
		getSubjectBackground: function(subjectPose) {
			if(subjectPose.chroma_key !== 'processed') {
				return null;
			}

			var pageSet = this.wrapper.getPageSet();
			if(!pageSet) {
				return null;
			}

			if(pageSet.projectBackgroundCdnUrl) {
				return {
					id: pageSet.projectBackgroundId,
					cdnUrl: pageSet.projectBackgroundCdnUrl
				};
			} else if(pageSet.projectBackgroundId) {
				return {
					id: pageSet.projectBackgroundId
				};
			} else {
				return null;
			}
		},
		setupDynamicImage: function(photoFieldMap) {
			$(this).removeClass('imageWrapper');

			var primarySubject = wrapper.getPrimarySubject();
			if(!primarySubject) {
				return;
			}

			for(var i = 0; i < photoFieldMap.length; i++) {
				var map = photoFieldMap[i];

				if(this.checkIfFieldMapMatches(map.fieldMap)) {
					var photo = map.photo;
					this.setImage(photo.id, photo.existingUrl, null, photo.name);
					this.photoWidth = photo.photoWidth;
					this.photoHeight = photo.photoHeight;
					break;
				}
			}
		},
		checkIfFieldMapMatches: function(fieldMap) {
			var wrapper = this.wrapper;
			return $.FlowLayoutFrameUtils.checkIfFieldMapMatches(function(name) {
				return wrapper.getDynamicFieldRep(name);
			}, fieldMap);
		},

		getImageUrl: function() {
			if(this.definition.existingUrl) {
				return this.definition.existingUrl;
			} else {
				return this.getScaledImageUrl(this.instance.width);
			}
		},
		getScaledImageSize: function(unitWidth) {
			if(!unitWidth) {
				unitWidth = this.instance.width;

				if(this.instance.crop && this.instance.crop.percentage && this.instance.crop.width) {
					unitWidth = unitWidth * this.instance.crop.width;
				}
			}
			
			var width = 300;
			if(unitWidth > 10) {
				width = 1500;
			} else if(unitWidth > 5) {
				width = 750;
			} else if(unitWidth > 3) {
				width = 450;
			}

			return width;
		},
		getScaledImageUrl: function(unitWidth, photoName) {
			if(this.instance.crop && this.instance.crop.percentage && this.instance.crop.width) {
				unitWidth = unitWidth * this.instance.crop.width;
			}

			var width = this.getScaledImageSize(unitWidth);

			var params = {
				id: this.photoId,
				photo_name: photoName || this.definition.photo_name
			};
			if(this.refreshPhotoVersion) {
				params.photoVersion = this.instance.photoVersion;
				params.forceRefresh = true;
			}

			return $.getPlicThumbnail(params, {
				w: width
			});
		},
		getScaledImageUrlByDPI: function(origUrl, unitWidth, dpi) {
			var imageDimensions = this.instance.width * dpi;

			if(imageDimensions < 300) {
				if(origUrl) {
					return origUrl;
				} else {
					imageDimensions = 300;
				}
			} else if(this.instance.photoWidth) {
				imageDimensions = Math.min(imageDimensions, this.instance.photoWidth);
			}

			return $.getPlicThumbnail({
				id: this.photoId,
				photo_name: this.definition.photo_name
			}, {
				w: Math.round(imageDimensions)
			});
		},
		setupShape: function() {
			this.setupImageElement();
			$(this).removeClass('imageWrapper').addClass('flowLayoutShape');

			$(this).find('.imageInnerStyleWrapper').css('background-color', this.instance.color);

			this.minWidth = 2;
			this.minHeight = 2;
		},
		isShape: function() {
			return !!this.instance.shape;
		},

		editCrop: function () {
			if(!this.photoId) {
				return false;
			}

			var me = this;
			var img = $(this.imgElem);
			var imgSrc = img[0]['src'];
			var modal = $('<div class="ui modal">')[0];
			$(modal).append('<i class="close icon"/>');

			$.extend(modal, {
				initImage: function() {
					this.img = $('<img>').attr('src', this.getUrl()).css({
						height: 400
					})[0];

					var wrapper = $('<div class="ui">').css({
						display: 'inline-block',
						'line-height': 0
					});
					wrapper.append(this.img);

					this.wrapper = wrapper;
					$(this.content).append(wrapper);
				},
				initImageCrop: function() {
					var aspectRatio = this.getAspectRatio();
					var cropper = this.cropper = new Cropper(this.img, {
						checkCrossOrigin: false,
						guides: false,
						background: false,
						autoCropArea: 1,
						rotatable: true,
						zoomable: false,
						aspectRatio: aspectRatio,
						viewMode: 1,
						minCropBoxWidth: 40,
						minCropBoxHeight: 40,
						ready: function() {
							modal.startingContainerData = $.extend(true, {}, cropper.containerData);
							var existingCrop = modal.getScaledCrop();
							if(existingCrop) {
								modal.cropper.setCropBoxData(existingCrop);
							}

							var rotate = modal.getRotation();
							if(rotate) {
								modal.setRotation(rotate, existingCrop);
							}

							$('<div class="ui checkbox" style="margin-top: 1em;"><input class="ui input" type="checkbox" checked="true"><label>Locked Aspect Ratio</label></div>').checkbox({
								onChecked: function() {
									if(!modal.cropper) {
										return;
									}

									var cropBoxData = modal.cropper.getCropBoxData();
									modal.cropper.setAspectRatio(modal.getAspectRatio());
									modal.cropper.setCropBoxData(cropBoxData);
								},
								onUnchecked: function() {
									if(!modal.cropper) {
										return;
									}

									var cropBoxData = modal.cropper.getCropBoxData();
									modal.cropper.setAspectRatio(false);
									modal.cropper.setCropBoxData(cropBoxData);
								}
							}).appendTo(modal.wrapper);
							modal.wrapper.append(' ');

							/*$('<div class="ui icon button" data-tooltip="Rotate left"><i class="undo icon" /></div>').click(function() {
								modal.setRotation(270);
							}).appendTo(modal.wrapper);

							$('<div class="ui icon button" data-tooltip="Rotate right"><i class="horizontally flipped undo icon" /></div>').click(function() {
								modal.setRotation(90);
							}).appendTo(modal.wrapper);*/

							if(frame.instance.mask) {
								$(cropper.cropper).find('.cropper-face, .cropper-view-box').css(frame.instance.mask);
							}

							buttons.find('.primary.button').removeClass('disabled');
							if(modal.onCropReady) {
								modal.onCropReady.call(modal);
							}
						}
					});
				},
				setRotation: function(rotate, existingCrop) {
					this.rotation = rotate;
					var cropper = this.cropper;
					if(!existingCrop) {
						existingCrop = $.extend(true, {}, cropper.getCropBoxData());
					}

					var startWidth = cropper.containerData.width;
					var startHeight = cropper.containerData.height;
					cropper.containerData.width = startHeight;
					cropper.containerData.height = startWidth;
					$(cropper.cropper).css({
						width: startHeight + 'px',
						height: startWidth + 'px'
					});

					modal.cropper.rotateTo(rotate);
					var aspectRatio = cropper.options.aspectRatio;
					var newAspectRatio = modal.getAspectRatio();
					// Not sure I understand why the newAspectRatio < 1 would work with this but > 1 breaks
					if(aspectRatio !== newAspectRatio || newAspectRatio < 1) {
						modal.cropper.setAspectRatio(newAspectRatio);
					}
					if(modal.cropper.canvasData.left < 0 || modal.cropper.canvasData.top < 0) {
						modal.leftPositionFix = modal.cropper.canvasData.left;
						modal.topPositionFix = modal.cropper.canvasData.top;
						modal.cropper.moveTo(0, 0);

						if(existingCrop) {
							existingCrop.left -= modal.leftPositionFix;
							existingCrop.top -= modal.topPositionFix;
							cropper.setCropBoxData(existingCrop);
						}
					}

					$(modal).modal('refresh');
				},
				getAspectRatio: function(allowRotate) {
					var aspectRatio = me.definition.width / me.definition.height;
					var rotate = this.getRotation();
					if(rotate && allowRotate !== false) {
						aspectRatio = me.definition.height / me.definition.width;
					}

					return aspectRatio;
				},
				getRotation: function() {
					if(this.rotation) {
						return this.rotation;
					}

					var rotate = me.getRotation();
					if(Math.abs(rotate) === 90 || Math.abs(rotate) === 270) {
						return rotate;
					} else {
						return 0;
					}
				},
				getUrl: function() {
					var url;
					if(imgSrc) {
						url = imgSrc;
					} else {
						url = 'css/images/error_placeholder.png';
					}

					return url;
				},
				applyUserCrop: function() {
					var crop = this.getPercentCrop();
					var width, height, left, top;
					width = (1 / crop.width);
					height = (1 / crop.height);
					left = -(crop.left * width);
					top = -(crop.top * height);

					var cropBoxData = this.getCropBoxData();
					var newAspectRatio = cropBoxData.width / cropBoxData.height;

					var frameWidth = me.definition.width;
					var frameHeight = me.definition.height;

					if(newAspectRatio >= 1) {
						frameHeight = frameWidth / newAspectRatio;
					} else {
						frameWidth = frameHeight * newAspectRatio;
					}
					frame.updateProperty(['crop', 'width', 'height'], [{
						width: width,
						height: height,
						top: top,
						left: left,
						percentage: true
					}, frameWidth, frameHeight]);

					me.width = frameWidth * me.ratio;
					me.height = frameHeight * me.ratio;
					$(me).css({
						width: me.width + 'px',
						height: me.height + 'px'
					});

					frame.applyCrop();
					frame.checkMinimumResolution();
					frame.updateToolbarDisplay();
				},
				getPercentCrop: function() {
					var rawCrop = this.getCropBoxData(true);
					var container = this.startingContainerData || this.cropper.getContainerData();

					return {
						left: $.reduceFloatPrecision(rawCrop.left / container.width),
						top: $.reduceFloatPrecision(rawCrop.top / container.height),
						width: $.reduceFloatPrecision(rawCrop.width / container.width),
						height: $.reduceFloatPrecision(rawCrop.height / container.height)
					};
				},
				getCropBoxData: function(reverse) {
					var cropBoxData = this.cropper.getCropBoxData();

					var rotate = this.getRotation();
					if(rotate) {
						var container = this.cropper.getContainerData();
						cropBoxData = this.getRotatedCrop(container, cropBoxData, -rotate, reverse);
					}

					return cropBoxData;
				},
				getScaledCrop: function() {
					if(frame.instance.crop) {
						var existingCrop = $.extend(true, {}, frame.instance.crop);

						var width = frame.instance.crop.width;
						var height = frame.instance.crop.height;

						existingCrop.width = 1 / existingCrop.width;
						existingCrop.height = 1 / existingCrop.height;
						existingCrop.x = Math.abs(existingCrop.left / width);
						existingCrop.y = Math.abs(existingCrop.top / height);

						var container = this.cropper.getContainerData();
						var scaledCrop = {
							left: existingCrop.x * container.width,
							top: existingCrop.y * container.height,
							width: existingCrop.width * container.width,
							height: existingCrop.height * container.height
						};

						var rotate = this.getRotation();
						if(rotate) {
							scaledCrop = this.getRotatedCrop(container, scaledCrop, rotate);
						}

						return scaledCrop;
					}
				},
				getRotatedCrop: function(container, scaledCrop, rotate, reverse) {
					var points = [
						{
							x: scaledCrop.left,
							y: scaledCrop.top
						},
						{
							x: scaledCrop.left + scaledCrop.width,
							y: scaledCrop.top + scaledCrop.height
						}
					];

					var pivot;
					if(reverse) {
						pivot = {
							x: (container.width / 2),
							y: (container.height / 2)
						};
					} else {
						var containerData = this.startingContainerData || this.cropper.getContainerData();

						pivot = {
							x: (containerData.width / 2),
							y: (containerData.height / 2)
						};
					}
					var radians = rotate * (Math.PI / 180);
					var cos = Math.cos(radians);
					var sin = Math.sin(radians);

					var rotatedPoints = points.map(function(point) {
						return {
							x: (cos * (point.x - pivot.x)) - (sin * (point.y - pivot.y)) + pivot.x,
							y: (sin * (point.x - pivot.x)) + (cos * (point.y - pivot.y)) + pivot.y
						};
					});

					var left = Math.min(rotatedPoints[0].x, rotatedPoints[1].x);
					var right = Math.max(rotatedPoints[0].x, rotatedPoints[1].x);
					var top = Math.min(rotatedPoints[0].y, rotatedPoints[1].y);
					var bottom = Math.max(rotatedPoints[0].y, rotatedPoints[1].y);

					return {
						left: left + this.leftPositionFix,
						top: top + this.topPositionFix,
						width: right - left,
						height: bottom - top
					};
				},
				show: function() {
					$(this).modal('show');
				},
				leftPositionFix: 0,
				topPositionFix: 0
			});

			var header = $('<div class="header">');
			header.text('Crop Candid');
			$(modal).append(header);

			var content = $('<div class="content">').css({
				'text-align': 'center'
			});
			modal.content = content;
			$(modal).append(content);
			modal.initImage();

			var buttons = $('<div class="actions">');
			buttons.append('<div class="ui deny button">Cancel</div>');
			buttons.append('<div class="ui approve primary disabled button">OK</div>');
			$(modal).append(buttons);

			$(modal).modal({
				closable: false,
				onShow: function() {
					modal.initImageCrop();
				},
				onHidden: function() {
					$(this).remove();
				},
				onApprove: function() {
					modal.applyUserCrop();
				}
			});
			
			$(this.getEditToolTarget()).popup('hide');
			modal.show();
			return modal;
		},
		applyCrop: function (img) {
			if (!img) {
				img = $(this.imgElem);
			}

			if (this.instance.crop) {
				var crop = this.instance.crop;

				var width, height, left, top;
				if (crop.percentage) {
					width = crop.width * 100 + '%';
					height = crop.height * 100 + '%';
					left = Math.min(0, crop.left) * 100 + '%';
					top = Math.min(0, crop.top) * 100 + '%';
				} else {
					width = crop.width * this.ratio;
					height = crop.height * this.ratio;
					left = crop.left * this.ratio;
					top = crop.top * this.ratio;
				}

				if(!this.isNormalImage() && this.photoWidth && this.photoHeight && crop.percentage) {
					var cropWidth = (1 / crop.width);
					var cropHeight = (1 / crop.height);
					this.dynamicCrop = {
						x: -(crop.left * cropWidth),
						y: -(crop.top * cropHeight),
						width: cropWidth,
						height: cropHeight
					};
					img.css($.FlowLayoutFrameUtils.getDynamicFieldCrop(this.instance, this.photoWidth, this.photoHeight, this.dynamicCrop));
				} else {
					img.css({
						width: width,
						height: height,
						left: left,
						top: top
					});
				}
			} else if(!this.isNormalImage() && this.photoWidth && this.photoHeight) {
				img.css($.FlowLayoutFrameUtils.getDynamicFieldCrop(this.instance, this.photoWidth, this.photoHeight, this.dynamicCrop));
			} else {
				img.css({
					width: '',
					height: '',
					top: '',
					left: ''
				});
			}
		},
		moveCrop: function(newPosition, startPosition) {
			if(!this.instance.crop) {
				return;
			}

			var leftDiff = newPosition.left - startPosition.left;
			var topDiff = newPosition.top - startPosition.top;

			var newLeftCrop = this.instance.crop.left + (leftDiff / $(this).getFloatStyle('width'));
			var newTopCrop = this.instance.crop.top + (topDiff / $(this).getFloatStyle('height'));
			var maxLeft = 1 - this.instance.crop.width;
			var maxTop = 1 - this.instance.crop.height;
			this.updateProperty('crop', {
				width: this.instance.crop.width,
				height: this.instance.crop.height,
				left: Math.min(0, Math.max(maxLeft, newLeftCrop)),
				top: Math.min(0, Math.max(maxTop, newTopCrop)),
				percentage: true
			});
			this.applyCrop();
		},
		setMovementLocked: function (movementLocked) {
			if (this.movementLocked == movementLocked) {
				return;
			}
			this.movementLocked = movementLocked;
			this.setEditable(!movementLocked);
		},
		changeSource: function (photo, cachedUrl, imgRatio) {
			if(!photo) {
				this.changeInstanceProperty(
					['photo', 'photo_name', 'existingUrl', 'crop', 'photoWidth', 'photoHeight', 'photoVersion', 'photoVersions', 'useGreenScreenBackground', 'chroma_key'],
					[null, null, null, null, null, null, null, null, null, null]
				);
				this.refreshInstance();

				this.onRemove();
				this.photoId = null;
			} else {
				var startingPhotoId = this.photoId;

				// Set the frame image
				$(this).data('photo', photo.id);
				this.instance.photo_name = photo.upload_file_name;
				let backgroundPhoto;
				if(photo.useGreenScreenBackground) {
					this.instance.useGreenScreenBackground = 'project background';
					this.instance.chroma_key = photo.chroma_key;
					backgroundPhoto = this.getSubjectBackground(this.instance);
				}
				this.setImage(photo.id, cachedUrl, backgroundPhoto);
				this.instance.existingUrl = cachedUrl;

				var frameRect = this.getBoundingClientRect();
				var frameWidth = frameRect.width;
				var frameHeight = frameRect.height;
				var frameRatio = frameWidth / frameHeight;

				// If wider frame, use width
				var width, height, left, top;
				if (frameRatio > imgRatio) {
					width = frameWidth;
					height = frameWidth / imgRatio;
					left = 0;
					top = (frameHeight - height) / 2;
				}
				// If taller frame, use height
				else {
					width = frameHeight * imgRatio;
					height = frameHeight;
					left = (frameWidth - width) / 2;
					top = 0;
				}

				// Convert crop to percentages
				var crop = {
					width: width / this.ratio / this.instance.width,
					height: height / this.ratio / this.instance.height,
					top: top / this.ratio / this.instance.height,
					left: left / this.ratio / this.instance.width,
					percentage: true
				};

				width = $(this).getFloatStyle('width') / this.wrapper.ratio;
				height = $(this).getFloatStyle('height') / this.wrapper.ratio;

				let propNames = ['photo', 'photo_name', 'existingUrl', 'width', 'height', 'crop', 'photoWidth', 'photoHeight', 'photoVersion', 'photoVersions'];
				let propValues = [photo.id, photo.upload_file_name, cachedUrl, width, height, crop, photo.width, photo.height, photo.version_id, photo.version_ids];
				if(photo.useGreenScreenBackground) {
					propNames.push('useGreenScreenBackground');
					propValues.push('project background');

					propNames.push('chroma_key');
					propValues.push(photo.chroma_key);
				}
				if(photo.displayName) {
					propNames.push('displayName');
					propValues.push(photo.displayName);
				} else if(this.instance.displayName) {
					propNames.push('displayName');
					propValues.push(null);
				}

				this.updateProperty(propNames, propValues);

				// Only update stuff if we didn't drag the same candid that we already had
				if(!startingPhotoId || startingPhotoId != this.photoId) {
					this.onAdd(startingPhotoId);
				}
			}

			this.applyCrop();
			this.applyFilters(this.instance);
			this.checkMinimumResolution();

			this.updateEditTools();
		},
		createPhotoFromCandid: function() {
			if(this.instance.photo) {
				return {
					id: this.instance.photo,
					upload_file_name: this.instance.photo_name,
					cdn_url: this.instance.existingUrl,
					width: this.instance.photoWidth,
					height: this.instance.photoHeight,
					version_id: this.instance.photoVersion,
					version_ids: this.instance.photoVersions
				};
			} else {
				return null;
			}
		},

		setEditable: function(editable) {
			if(editable && this.instance && this.instance.locked) {
				// Max(rank, 3) -> so office admins can lock something and regular users can still unlock it
				if(!this.canToggleLock || Math.max(this.instance.locked.userRank, 3) < $.UserRank || (this.instance.locked === true && $.UserRank > 3)) {
					editable = false;
				}
			}

			this._setEditable(editable);
			this.lockedMovement = !!this.instance.locked;
			this.setMovable(this.movable);
		},
		setMovable: function(movable) {
			this.movable = movable;

			if(this.editable && !this.lockedMovement && movable) {
				let page = this.parent.getPage();
				if(!$(this).hasClass('flowLayoutMovable')) {
					$(this).addClass('flowLayoutMovable');

					let isSwapping = false;
					if(page.shouldSwapCandidsWhenDragging(this.instance.id) && !this.isShape()) {
						isSwapping = true;
					}

					var isMovingCrop = false, lastPosition;
					if(isSwapping) {
						$(this).draggable({
							// containment: 'parent',
							helper: function(e) {
								let helper = $(this).clone();

								if(e.ctrlKey && this.instance.crop) {
									helper.css('display', 'none').addClass('no-drop');
								}

								return helper;
							},
							zIndex: 100,
							revert: 'invalid',
							start: function(e, ui) {
								let left = $(this).getFloatStyle('left');
								let top = $(this).getFloatStyle('top');

								let clickData = $(this).data('uiDraggable').offset.click;
								clickData.left += (ui.originalPosition.left - left);
								clickData.top += (ui.originalPosition.top - top);

								$(this).data('startPosition', lastPosition = {
									left: left,
									top: top
								});

								isMovingCrop = e.ctrlKey && this.instance.crop;
							},
							stop: function() {
								this.lastDragTime = new Date().getTime();
							},
							drag: function(event, ui) {
								if(isMovingCrop) {
									let startPosition = $(this).data('startPosition');
									this.moveCrop($.extend(true, {}, ui.position), lastPosition);

									lastPosition = $.extend(true, {}, ui.position);
									ui.position.left = startPosition.left;
									ui.position.top = startPosition.top;
									this.lastDragTime = new Date().getTime();
								}
							}
						}).addClass('shouldSwap');
					} else {
						$(frame).draggable({
							// containment: 'parent',
							start: function(e, ui) {
								if(this.ignoreNextDrag) {
									this.ignoreNextDrag = false;
									return false;
								}

								var left = $(this).getFloatStyle('left');
								var top = $(this).getFloatStyle('top');

								var clickData = $(this).data('uiDraggable').offset.click;
								clickData.left += (ui.originalPosition.left - left);
								clickData.top += (ui.originalPosition.top - top);

								$(this).data('startPosition', lastPosition = {
									left: left,
									top: top
								});

								if($.userEvents) {
									$.userEvents.startGroupedEvents();
								}

								isMovingCrop = e.ctrlKey && frame.instance.crop;
							},
							stop: function(event, ui) {
								var myRect = frame.getBoundingClientRect();

								var removed = false;
								var positionSaved = false;
								if(frame.parentNode) {
									var parentRect = frame.getParentRect();
									// Completely invisible
									removed = frame.removeIfMovedToOtherPage(myRect, parentRect, true);
									let minContentVisible = frame.getMinContentVisible();
									if(frame.allowCompletelyInBleed) {
										minContentVisible = Math.min(minContentVisible, 2);
									}
									// Partially visible
									if(!removed && (myRect.right < (parentRect.left + minContentVisible) || myRect.left > (parentRect.right - minContentVisible) ||
										myRect.bottom < (parentRect.top + minContentVisible) || myRect.top > (parentRect.bottom - minContentVisible))) {
										// Revert back to previous position
										var start = $(this).data('startPosition');

										$(this).animate({
											left: start.left + 'px',
											top: start.top + 'px'
										});

										frame.saveCurrentPosition({
											position: frame.getPositionFromCss(start)
										});
										positionSaved = true;
									}
								}

								if(!removed) {
									if(!positionSaved) {
										frame.saveCurrentPosition();
									}

									// So that adding overflow candid spread have similar timestamps
									this.flushDelayedSaveQueue();
									if(this.linkedOverflowInstance) {
										this.linkedOverflowInstance.elem.flushDelayedSaveQueue();
									}
								}
								frame.stopMoving();
								this.lastDragTime = new Date().getTime();

								if($.userEvents) {
									$.userEvents.stopGroupedEvents();
								}
							},
							drag: function(event, ui) {
								if(isMovingCrop) {
									var startPosition = $(this).data('startPosition');
									frame.moveCrop($.extend(true, {}, ui.position), lastPosition);

									lastPosition = $.extend(true, {}, ui.position);
									ui.position.left = startPosition.left;
									ui.position.top = startPosition.top;
									frame.lastDragTime = new Date().getTime();
								} else {
									frame.movingContent(ui.position);
									frame.updateEditToolbar();
									frame.saveCurrentPosition();
								}
							}
						}).removeClass('shouldSwap');
					}
					
					$(frame).droppable({
						accept: function(drag) {
							return drag.hasClass('subjectMask') || (drag.hasClass('candidPicture') && !frame.isShape() && !frame.isSubjectField() && !frame.isDynamicGraphic()) ||
								(drag.hasClass('project-background-content') && frame.isSubjectField()) || (isSwapping && drag.hasClass('shouldSwap') && drag.hasClass('flowLayoutFrame') && !$(frame).hasClass('flowLayoutShape') && !$(drag).hasClass('flowLayoutShape') && !$(drag).hasClass('no-drop'));
						},
						drop: function(event, ui) {
							var position = {
								x: event.clientX,
								y: event.clientY
							};
							frame.wrapper.removeDraggingOver(true);
							if(frame.movementLocked || ui.helper.hasClass('completedDrop') || !frame.isTopZIndexAtPosition(position, '.flowLayoutFrame') || !frame.isWithinPosition(position)) {
								return;
							}

							// Dragged a picture onto a template placeholder
							if(ui.draggable.hasClass('candidPicture')) {
								if($(this).hasClass('imageWrapper')) {
									if(this.instance.photoFieldMap || this.instance.field) {
										this.parent.addNewFrameFromDragTarget(ui.draggable, ui.position);
									} else {
										this.replaceWithDragTarget(ui.draggable);
									}
								} else {
									this.askIfReplaceOrAddNew(event, ui);
								}

								ui.helper.addClass('completedDrop');
							}
							// Dragged a mask
							else if(ui.draggable.hasClass('subjectMask')) {
								var mask = $.extend(true, {}, ui.draggable.data('apply-mask'));
								frame.setMask(mask);
								ui.helper.addClass('completedDrop');
							} else if(ui.draggable.hasClass('project-background-content')) {
								frame.changeInstanceProperty('useGreenScreenBackground', true);
								frame.refreshInstance();
							} else if(ui.draggable.hasClass('flowLayoutFrame') && !ui.helper.hasClass('no-drop')) {
								let draggedFrame = ui.draggable[0];
								let draggedPhoto = draggedFrame.createPhotoFromCandid();
								let thisPhoto = frame.createPhotoFromCandid();

								if(draggedPhoto) {
									let draggedUrl;
									if(draggedPhoto.cdn_url) {
										draggedUrl = draggedPhoto.cdn_url;
									} else {
										draggedUrl = $.getPlicThumbnail(draggedPhoto.id, {
											w: 300
										});
									}
									frame.changeSource(draggedPhoto, draggedUrl, draggedPhoto.width / draggedPhoto.height);
								} else {
									frame.changeSource(null);
								}

								if(thisPhoto) {
									let thisUrl;
									if(thisPhoto.cdn_url) {
										thisUrl = thisPhoto.cdn_url;
									} else {
										thisUrl = $.getPlicThumbnail(thisPhoto.id, {
											w: 300
										});
									}
									draggedFrame.changeSource(thisPhoto, thisUrl, thisPhoto.width / thisPhoto.height);
								} else {
									draggedFrame.changeSource(null);
								}
							}
						},
						over: function(event, ui) {
							if(frame.movementLocked) {
								$(ui.helper).css('cursor','no-drop');
							} else {
								$(ui.helper).css('cursor','');
							}
						},
						greedy: true
					});
				}
			} else {
				if($(this).hasClass('flowLayoutMovable')) {
					$(this).removeClass('flowLayoutMovable');

					if ($(this).hasClass('ui-draggable')) {
						$(this).draggable('destroy');
					}
					if($(this).hasClass('ui-droppable')) {
						$(this).droppable('destroy');
					}
				}
			}
		},
		removeIfMovedToOtherPage: function(myRect, parentRect, recursive) {
			var removed;
			var secondaryElements = this.getSecondaryFocusedElements();
			if(this.linkedOverflowInstance && frame.isContentHidden(myRect, parentRect)) {
				// Make sure we only return true if the other content is not ALSO hidden (ie: inside of non-editable bleed)
				let linkedElem = this.linkedOverflowInstance.elem;
				if(linkedElem.parentNode && linkedElem.isContentHidden(linkedElem.getBoundingClientRect(), linkedElem.getParentRect())) {
					this.removeOverflowFromLayout();
				} else {
					// If we copied an instance over to next page, get rid of this version
					wrapper.removeFrame(frame);
					removed = true;
				}
			}

			if(recursive) {
				secondaryElements.forEach(function(secondaryElement) {
					if(secondaryElement.removeIfMovedToOtherPage) {
						secondaryElement.removeIfMovedToOtherPage(secondaryElement.getBoundingClientRect(), parentRect);
					}
				});
			}

			return removed;
		},
		replaceWithDragTarget: function(draggable) {
			$(frame).find('.imageOuterStyleWrapper').remove();
			var img = draggable.find('img').clone().removeClass('candidPicture mSSlide ui-draggable mSCoverImage').removeAttr('style');
			var photo = draggable.data('photo');

			// Figure out what the crop should be to maintain both placeholder and image aspect ratios
			var imgRep = draggable.find('img');
			var imgRepRect = imgRep[0].getBoundingClientRect();
			var imgWidth = imgRepRect.width;
			var imgHeight = imgRepRect.height;
			var imgRatio = imgWidth / imgHeight;

			this.changeSource(photo, this.getScaledUrlFromDragTarget(img), imgRatio);
		},
		getScaledUrlFromDragTarget: function(img) {
			var instanceWidth = this.instance.width;

			var scaledWidth = this.getScaledImageSize(instanceWidth);
			if(scaledWidth > 300) {
				return null;
			} else {
				return img.attr('src');
			}
		},
		askIfReplaceOrAddNew: function(event, ui) {
			$.PresentOptions('Replace or Add New', 'Do you want to replace the existing candid image or add a new one?', [
				{
					id: 'replace',
					title: 'Replace',
					addClass: 'negative',
					onClick: function() {
						frame.replaceWithDragTarget(ui.draggable);
					}
				},
				{
					id: 'addNew',
					title: 'Add New',
					addClass: 'approve primary',
					onClick: function() {
						frame.parent.addNewFrameFromDragTarget(ui.draggable, ui.position);
					}
				}
			], {
				rememberChoice: {
					parent: 'composerSettings',
					id: 'replaceOrAddCandids'
				}
			});
		},
		setFocused: function(focused, userClicked) {
			if(this.editable && focused && userClicked) {
				var myRect = this.getBoundingClientRect();
				var parentRect = this.getParentRect();
				if(this.isContentHidden(myRect, parentRect)) {
					this.fixHiddenContent(myRect, parentRect);
				}
			}

			this._setFocused(focused, userClicked);
			this.cachedSplitStartSize = null;
			this.cachedSplitFrames = null;

			if(focused) {
				if(this.resizable && !this.instance.locked && userClicked) {
					this.setResizable(true);
				}
				if(this.rotatable && !this.instance.locked) {
					this.setRotable(true);
				}
			} else {
				if($(this).hasClass('cropping')) {
					$(this).find('.frameCropWrapper').find('.negative.mini.button').click();
				}
				if(this.resizable) {
					this.setResizable(false);
				}
				if(this.rotatable) {
					this.setRotable(false);
				}
			}
		},
		onSetSecondaryFocused: function(focused) {
			if(focused) {
				if(this.resizable) {
					this.setResizable(true);
				}
				if(this.rotatable) {
					this.setRotable(true);
				}
			} else if(!this.focused) {
				if(this.resizable) {
					this.setResizable(false);
				}
				if(this.rotatable) {
					this.setRotable(false);
				}
			}
		},
		swapWidthAndHeight: function() {
			var oldWidth = $(this).getFloatStyle('width');
			var oldHeight = $(this).getFloatStyle('height');
			var newWidth = oldHeight;
			var newHeight = oldWidth;

			var moveLeft = (newWidth - oldWidth) / 2;
			var moveUp = (oldHeight - newHeight) / 2;

			$(this).css({
				width: newWidth,
				height: newHeight,
				left: $(this).getFloatStyle('left') - moveLeft,
				top: $(this).getFloatStyle('top') + moveUp
			});
			this.saveCurrentSize({
				freeRangeAspectRatio: oldWidth / oldHeight,
				freeRangeStartCrop: this.instance.crop,
				swapWidthAndHeightFix: true
			});
			this.saveCurrentPosition();
		},
		saveCurrentSize: function(options) {
			this.width = $(this).getFloatStyle('width');
			this.height = $(this).getFloatStyle('height');

			var unitWidth = this.width / wrapper.ratio;
			var unitHeight = this.height / wrapper.ratio;
			this.saveSize(unitWidth, unitHeight, options);

			if(this.flushPositionChangeImmediately) {
				this.flushDelayedSaveQueue();
				if(this.linkedOverflowInstance) {
					this.linkedOverflowInstance.elem.flushDelayedSaveQueue();
				}
				this.flushPositionChangeImmediately = false;
			}
		},
		saveSize: function(unitWidth, unitHeight, options) {
			var startUnitWidth = this.instance.width;
			var startUnitHeight = this.instance.height;
			frame.updateProperty(['width', 'height'], [unitWidth, unitHeight]);

			if(options.freeRangeAspectRatio) {
				var expectedAspectRatio = options.freeRangeAspectRatio;
				var rawPhotoAspectRatio = expectedAspectRatio;
				var actualAspectRatio = unitWidth / unitHeight;

				var cropX = 0;
				var cropY = 0;
				var cropWidth = 1;
				var cropHeight = 1;
				if(options.freeRangeStartCrop) {
					cropX = options.freeRangeStartCrop.left;
					cropY = options.freeRangeStartCrop.top;
					cropWidth = options.freeRangeStartCrop.width;
					cropHeight = options.freeRangeStartCrop.height;

					rawPhotoAspectRatio *= (cropWidth / cropHeight);
				}
				var aspectRatioDiff = actualAspectRatio - rawPhotoAspectRatio;

				var newWidth = cropWidth * expectedAspectRatio / actualAspectRatio;
				var newHeight = cropHeight / expectedAspectRatio * actualAspectRatio;
				var widthDiff, heightDiff;
				if(aspectRatioDiff > 0) {
					if(cropWidth > 1 && newWidth < 1 && cropWidth > (cropHeight + 0.1)) {
						cropWidth = 1;
						cropX = 0;
						
						// TODO: I have no idea why this gave the wrong dimensions for the swapWidthAndHeight function but not for ctrl resizing
						// I need to figure out why this needs two different formulas or if the ctrl resizing is really just wrong
						if(options.swapWidthAndHeightFix) {
							heightDiff = (cropWidth - newWidth) / newWidth;
						} else {
							heightDiff = actualAspectRatio * (cropWidth - newWidth);
						}
						cropHeight += heightDiff;
						cropY -= heightDiff / 2;
					} else {
						heightDiff = newHeight - cropHeight;
						cropHeight = newHeight;
						cropY -= heightDiff / 2;
					}
				} else {
					if(newHeight < 1 && cropHeight > 1 && (cropWidth + 0.1) < cropHeight) {
						cropHeight = 1;
						cropY = 0;
						
						if(options.swapWidthAndHeightFix) {
							widthDiff = (cropHeight - newHeight) / newHeight;
						} else {
							widthDiff = (cropHeight - newHeight) / actualAspectRatio;
						}
						cropWidth += widthDiff;
						cropX -= widthDiff / 2;
					} else {
						widthDiff = newWidth - cropWidth;
						cropWidth = newWidth;
						cropX -= widthDiff / 2;
					}
				}

				// Make sure that the aspect ratio of the image remains the same
				frame.updateProperty('crop', {
					percentage: true,
					left: cropX,
					top: cropY,
					width: cropWidth,
					height: cropHeight
				});
				this.applyCrop();
			}

			var captionNode = this.getCaptionNode();
			if(captionNode) {
				var diffUnitWidth = unitWidth - startUnitWidth;
				var diffUnitHeight = unitHeight - startUnitHeight;

				captionNode.addPositionDiff({
					x: 0,
					y: diffUnitHeight
				}, {
					completeMovement: false
				});

				var manualSize = captionNode.instance.manualSize;
				if(manualSize && manualSize.width) {
					captionNode.updateManualSize({
						width: manualSize.width + diffUnitWidth,
						height: manualSize.height
					});
				}
			}
		},
		isNormalImage: function() {
			return this.instance && this.instance.photo;
		},
		isPlaceholder: function() {
			if(!this.instance || (!this.instance.photo && !this.instance.field && !this.instance.photoFieldMap)) {
				return true;
			} else {
				return false;
			}
		},
		isDynamicGraphic: function() {
			return this.instance && this.instance.photoFieldMap;
		},
		isSubjectField: function() {
			return !!this.instance.field;
		},
		isSpecialFrame: function() {
			return this.instance && this.instance.field === 'individualized image field';
		},
		setMask: function(mask) {
			this.updateRoundedMask(mask);
			this.applyMask(mask);
			this.updateProperty('mask', mask);
		},
		updateRoundedMask: function(mask) {
			let roundedId = 'Mask-1';
			if($.GlobalRoundedId) {
				roundedId = $.GlobalRoundedId;
			}

			if(mask['clip-path'] && (mask['clip-path'] == 'url(#' + roundedId + ')' || mask['clip-path'].startsWith('url(#' + roundedId + '-'))) {
				let aspectRatio = this.instance.width / this.instance.height;

				let newMaskId = roundedId + '-' + aspectRatio.toFixed(1).replace('.', '_');
				$.extend(mask, {
					'clip-path':'url(#' + newMaskId + ')',
					'-webkit-clip-path':'url(#' + newMaskId + ')'
				});

				return true;
			} else {
				return false;
			}
		},
		applyMask: function(mask) {
			if(mask && !$.isArray(mask) && mask !== {}) {
				for(let prop in mask) {
					if(mask[prop] === null) {
						mask[prop] = '';
					}
				}

				$.FlowLayoutFrameUtils.addRoundedCornerMask(mask);
				$(this).find('.imageInnerStyleWrapper').css(mask);
			} else if(this.mask) {
				var maskClone = $.extend(true, {}, this.mask);
				for(let prop in maskClone) {
					maskClone[prop] = '';
				}

				mask = null;
				$(this).find('.imageInnerStyleWrapper').css(maskClone);
			}
			this.mask = mask;
		},
		openTagEditor: function() {
			if(!this.photoId) {
				return false;
			}

			frame.setFocused(false, false);
			var subjects = $.globalPageSet ? $.globalPageSet.getSubjects() : [];
			$.PhotoPickerUtils.loadTagEditor({
				load: function(options, onSuccess, onError) {
					$.plicAPI({
						method: 'photos/' + frame.definition.photo,
						params: {
							include_album_imports: true,
							include_albums: true
						},
						type: 'GET',
						success: function(data) {
							options.photo = data.photo;
							options.albumImports = data.album_imports;
							options.albums = data.albums;
							options.users = data.users;
							onSuccess();
						},
						error: function() {
							onError();
						}
					});
				},
				subjects: subjects,
				plicProjectId: $.PlicProjectId,
				imageUrl: this.getImageUrl(),
				startLoading: function() {
					frame.startLoading();
				},
				stopLoading: function() {
					frame.stopLoading();
				}
			});
		},
		startLoading: function() {
			$(this).append('<div class="ui active inverted dimmer"><div class="ui text loader"></div></div>');
		},
		stopLoading: function() {
			$(this).children('.active.dimmer').remove();
		},
		isLoading: function() {
			return $(this).children('.dimmer').hasClass('active');
		},
		getPositionProperty: function() {
			var x = this.instance.x;
			if(!$.isInit(x)) {
				x = 0;
			}

			var y = this.instance.y;
			if(!$.isInit(y)) {
				y = 0;
			}

			return {
				x: x,
				y: y
			};
		},
		setPositionProperty: function(x, y) {
			this.changeInstanceProperty(['x', 'y'], [x, y]);
		},

		getEditToolsDistance: function() {
			return this.getRotatableToolDistance();
		},
		checkMinimumResolution: function() {
			var photoDimensions = this.getPhotoDimensions();
			var photoDPI = null;
			if(photoDimensions) {
				photoDPI = photoDimensions.width / this.instance.width;
			}

			// Always destroy the popup since it will always either be updated or removed
			if($(this.outerWrapper).hasClass('hasPopup')) {
				$(this.outerWrapper).removeClass('hasPopup').popup('destroy');
				$(this).off('mouseenter', this.lowResolutionMouseEnterHandler);
				$(this).off('mouseleave', this.lowResolutionMouseLeaveHandler);
			}

			if(photoDPI && photoDPI < $.getStudioSetting('minimumCandidDPI', 100)) {
				$(this).addClass('lowResolutionPhoto');

				$(this.outerWrapper).addClass('hasPopup').popup({
					title: 'Low resolution photo',
					content: Math.round(photoDimensions.width) + ' x ' + Math.round(photoDimensions.height) + ' and ' + Math.round(photoDPI) + ' DPI',
					observeChanges: false,
					lastResort: true,
					on: 'manual'
				});

				this.lowResolutionMouseEnterHandler = function () {
					$(this.outerWrapper).popup('show');
				};
				this.lowResolutionMouseLeaveHandler = function () {
					$(this.outerWrapper).popup('hide');
				};
				$(this).hover(this.lowResolutionMouseEnterHandler, this.lowResolutionMouseLeaveHandler);
			} else {
				$(this).removeClass('lowResolutionPhoto');
			}
		},
		getPhotoDimensions: function() {
			if(!this.instance || !this.instance.photoWidth || !this.instance.photoHeight) {
				return null;
			}

			var photoWidth = this.instance.photoWidth;
			var photoHeight = this.instance.photoHeight;
			if(this.instance.crop) {
				photoWidth = photoWidth * (1 / this.instance.crop.width);
				photoHeight = photoHeight * (1 / this.instance.crop.height);
			}

			return {
				width: photoWidth,
				height: photoHeight
			};
		},
		requestPhotoReplacement: function() {
			var me = this;
			var form = $('<form onsubmit="return false">');
			var input = $('<input type="file"/>').change(function() {
				var file = this.files[0];
				
				$.plicAPI({
					method: 'photos/' + me.instance.photo + '/reupload',
					success: function(data) {
						var formData = new FormData();
						var post = data.presigned_post;

						for(var id in post.fields) {
							formData.set(id, post.fields[id]);
						}
						formData.append('file', file);
						
						$.ajax({
							url: post.url,
							data: formData,
							type: 'POST',
							processData: false,
							contentType: false,
							success: function() {
								$.plicAPI({
									method: 'photos/' + me.instance.photo + '/verify',
									type: 'PATCH',
									success: function() {
										$.Alert('Replaced', 'Photo replaced');
									},
									error: function() {
										$.Alert('Error', 'Failed to verify');
									}
								});
							},
							error: function() {
								$.Alert('Error', 'Failed to POST to S3');
							}
						});
					},
					erorr: function() {
						$.Alert('Error', 'Failed to create reupload record');
					}
				});
			});
			form.append(input).appendTo('body');

			input.click();
		},
		openVersionPicker: function() {
			this.setFocused(false);

			var photo = {
				id: this.instance.photo,
				photo_name: this.instance.photo_name,
				width: this.instance.photoWidth,
				height: this.instance.photoHeight,
				crop: this.instance.crop
			};

			var photos = this.instance.photoVersions.map(function(photoVersion) {
				return $.extend(true, {
					photoVersion: photoVersion
				}, photo);
			});

			var me = this;
			var modal = $.PhotoPickerDialog({
				title: 'Photo Versions',
				photoPicker: {
					selection: false,
					searchable: false,
					photos: photos,
					getTitle: function(photo) {
						var versionIndex = me.instance.photoVersions.indexOf(photo.photoVersion) + 1;
						return 'Version ' + versionIndex;
					}
				},
				onVisible: function() {
					$(this).find('.photoPicker > .ui.card').each(function() {
						if(this.photo.photoVersion == me.instance.photoVersion) {
							$(this).addClass('blue').find('.label').removeClass('hidden');
						}
					}).click(function() {
						$(this).siblings('.ui.blue.card').removeClass('blue').find('.label').addClass('hidden');
						$(this).addClass('blue').find('.label').removeClass('hidden');
					});
				},
				actions: [
					{
						title: 'Cancel',
						classes: 'deny'
					},
					{
						title: 'OK',
						classes: 'approve primary'
					}
				],
				onApprove: function() {
					var selectedVersionCard = $(this).find('.photoPicker > .ui.card.blue')[0];
					if(!selectedVersionCard) {
						return;
					}

					var selectedPhotoVersion = selectedVersionCard.photo.photoVersion;
					if(me.instance.photoVersion != selectedPhotoVersion) {
						me.saveVersionChange(selectedPhotoVersion);
					}
				}
			});
			modal.show();

			return modal;
		},
		saveVersionChange: function(newPhotoVersion) {
			this.startLoading();

			var params = {};
			if(newPhotoVersion) {
				params.version_id = newPhotoVersion;
			}

			var me = this;
			$.plicAPI({
				method: 'photos/' + this.instance.photo + '/restore-version',
				params: params,
				type: 'PATCH',
				success: function(data) {
					me.stopLoading();

					me.refreshPhotoVersion = true;
					me.changeInstanceProperty(['photoVersion', 'photoVersions', 'existingUrl'], [data.photo.version_id, data.photo.version_ids, null]);
					me.refreshInstance();
				},
				error: function() {
					$.Alert('Error', 'Failed to change photo version');
					me.stopLoading();
				}
			});
		},
		applyFilters: function(definition) {
			$.FlowLayoutFrameUtils.applyFilters.call(this, definition, {
				allowOpacity: !this.isPlaceholder() || this.isShape()
			});
		},
		toggleCaption: function() {
			if(this.instance.captionTextId) {
				this.removeCaption();
			} else {
				this.addCaption();
			}
		},
		addCaption: function() {
			var filename = this.instance.photo_name || '';
			if(this.instance.displayName) {
				filename = this.instance.displayName;
			} else if(filename.indexOf('.') != -1) {
				filename = filename.substr(0, filename.lastIndexOf('.'));
			}

			var topPadding = 0.125;
			if(this.instance.dropShadow) {
				topPadding += 0.125;
			}

			var textNode = this.wrapper.createNewText({
				left: 0,
				top: 0
			}, {
				textStyles: {
					align: 'center'
				},
				textSettings: {
					position: {
						left: this.instance.x,
						top: this.instance.y + this.instance.height + topPadding
					},
					lines: {
						text: filename
					},
					manualSize: {
						width: this.instance.width
					},
					captionImageId: this.instance.id
				},
				autoFocus: false
			});

			this.changeInstanceProperty('captionTextId', textNode.instance.id);
		},
		removeCaption: function() {
			var textNode = this.getCaptionNode();
			if(textNode) {
				textNode.removeContent();
			}
			this.changeInstanceProperty('captionTextId', null);
		},
		getCaptionNode: function() {
			if(this.instance.captionTextId) {
				return this.wrapper.getText(this.instance.captionTextId);
			} else {
				return null;
			}
		},

		editDynamicGraphics: function() {
			var modal = $('<div class="ui large modal v-application v-application--is-ltr v-application-dialog-content" style="text-align: left"><i class="close icon"></i></div>')[0];
			var content = $('<div class="content"><div id="editDynamicGraphicsContent"></div></div>');

			var vue = null;
			$.extend(modal, {
				init: function() {
					var fields = frame.wrapper.getDynamicFields();
					fields = fields.filter(function(field) {
						// Filter out Package SKU/Name/etc... because it just returns the first match and won't match any SKU like the customer might expect
						return !field.includes('Order ') || !fields.includes(field + 's');
					});

					vue = new Vue({
						data: {
							photoFieldMap: $.extend(true, [], frame.instance.photoFieldMap),
							fields: fields
						},
						template: '<frame-dynamic-graphics-editor :photo-field-map="photoFieldMap" :fields="fields" />',
						watch: {
							photoFieldMap: {
								deep: true,
								handler: function() {
									this.checkIsValid();
								}
							}
						},
						methods: {
							checkIsValid: function() {
								var error = null;
								this.photoFieldMap.forEach(function(map) {
									if(!map.photo) {
										error = 'Missing photo for map';
									}

									for(var field in map.fieldMap) {
										if(!$.trim(map.fieldMap[field])) {
											error = 'All fields must have a value';
										} else if(!$.trim(field)) {
											error = 'All maps must have a field';
										}
									}
								});

								modal.setSaveError(error);
							}
						},
						components: {
							FrameDynamicGraphicsEditor
						},
						vuetify: window.Vuetify
					}).$mount('#editDynamicGraphicsContent');
				},
				show: function() {
					$(this).modal('show');
				},
				setSaveError: function(error) {
					if(!error) {
						$(this).find('.actions').removeAttr('data-tooltip')
						$(this).find('.primary.dialogButton').removeClass('disabled');
					} else {
						$(this).find('.actions').attr('data-tooltip', error)
						$(this).find('.primary.dialogButton').addClass('disabled');
					}
				}
			}, options);

			$(modal).append($('<div class="header">').text('Dynamic Graphics').prepend('<i class="th list icon"></i>'))
				.append(content)
				.append('<div class="actions" data-position="top right"><div class="ui deny button dialogButton">' + i18n.t('misc.cancel') + '</div><div class="ui approve primary button dialogButton">' + i18n.t('misc.ok') + '</div></div>')
				.modal({
					onApprove: function() {
						if(vue) {
							var photoFieldMap = $.extend(true, [], vue.photoFieldMap);
							frame.changeInstanceProperty('photoFieldMap', photoFieldMap);
							frame.refreshInstance();
						} else {
							return false;
						}
					},
					onShow: function() {
						modal.init();
					},
					onHidden: function() {
						$(this).remove();
					},
					observeChanges: true
				});

			modal.show();
		},
		getCachedRect: function() {
			var rect = {
				x: $(this).getFloatStyle('left'),
				y: $(this).getFloatStyle('top'),
				width: $(this).getFloatStyle('width') + 2,
				height: $(this).getFloatStyle('height') + 2
			};

			if(this.getRotation) {
				var rotation = this.getRotation();
				if(rotation) {
					rect = this.rotateRect(rect.x, rect.y, rect.width, rect.height, rotation);
				}
			}
			
			return rect;
		},

		getToolByName: $.FlowLayoutFrameUtils.getToolByName,
		toolSettingProperties: $.FlowLayoutFrameUtils.toolSettingProperties,
		parent: wrapper,

		movable: true,
		resizable: true,
		rotatable: true,
		overflowToLinkedLayouts: true,
		extraOffset: 0,
		dontAllowNullValues: ['x', 'y', 'width', 'height'],
		keepSizeWhenChangingEffects: false
	});

	var _changeInstanceProperty = frame.changeInstanceProperty;
	var _destroy = frame.destroy;
	var getBlockedElementsFromAlignment = frame.getBlockedElementsFromAlignment;
	$.extend(frame, {
		changeInstanceProperty: function(name, value) {
			if(name == 'border' && !this.keepSizeWhenChangingEffects) {
				if((this.origInstance.border && this.origInstance.border.sizeScaling) ||
						(value && value.sizeScaling)) {
					var startBorderWidth = this.origInstance.borderWidth ? this.origInstance.borderWidth : 0;
					var maxSize = Math.max(this.origInstance.width, this.origInstance.height) - (startBorderWidth * 2);

					let startThickness = this.origInstance.border ? this.origInstance.border.thickness : 0;
					let endThickness = value ? value.thickness : 0;

					if((endThickness - startThickness) != 0) {
						var endBorderWidth = endThickness * maxSize / 80;

						let x = this.origInstance.x + startBorderWidth - endBorderWidth;
						let y = this.origInstance.y + startBorderWidth - endBorderWidth;
						let width = this.origInstance.width - (startBorderWidth * 2) + (endBorderWidth * 2);
						let height = this.origInstance.height - (startBorderWidth * 2) + (endBorderWidth * 2);
						// If we are containing in parent, don't allow this go out of bounds
						if(this.containInParent) {
							let minPosition = (1 - wrapper.canvasBorderWidth) / this.ratio;
							let parentRect = this.getParentRect();
							let maxX = ((parentRect.width - wrapper.canvasBorderWidth * 2) / this.ratio) - width;
							let maxY = ((parentRect.height - wrapper.canvasBorderWidth * 2) / this.ratio) - height;
							x = Math.min(Math.max(minPosition, x), maxX);
							y = Math.min(Math.max(minPosition, y), maxY);
						}
						this.changeInstanceProperty(['x', 'y', 'width', 'height', 'borderWidth'], [
							x,
							y,
							width,
							height,
							endBorderWidth
						]);

						$(this).css({
							left: x * this.parent.ratio - 1 + wrapper.canvasBorderWidth,
							top: y * this.parent.ratio - 1 + wrapper.canvasBorderWidth,
							width: width * this.parent.ratio,
							height: height * this.parent.ratio
						});
					}
				} else {
					// Legacy logic
					let startThickness = this.origInstance.border ? this.origInstance.border.thickness : 0;
					let endThickness = value ? value.thickness : 0;

					var diff = endThickness - startThickness;
					if (diff) {
						var thickness = diff / 60;
						let x = this.origInstance.x - thickness;
						let y = this.origInstance.y - thickness;
						let width = this.origInstance.width + (thickness * 2);
						let height = this.origInstance.height + (thickness * 2);
						// If we are containing in parent, don't allow this go out of bounds
						if(this.containInParent) {
							let minPosition = (1 - wrapper.canvasBorderWidth) / this.ratio;
							let parentRect = this.getParentRect();
							let maxX = ((parentRect.width - wrapper.canvasBorderWidth * 2) / this.ratio) - width;
							let maxY = ((parentRect.height - wrapper.canvasBorderWidth * 2) / this.ratio) - height;
							x = Math.min(Math.max(minPosition, x), maxX);
							y = Math.min(Math.max(minPosition, y), maxY);
						}
						this.changeInstanceProperty(['x', 'y', 'width', 'height'], [
							x,
							y,
							width,
							height
						]);

						$(this).css({
							left: x * this.parent.ratio - 1 + wrapper.canvasBorderWidth,
							top: y * this.parent.ratio - 1 + wrapper.canvasBorderWidth,
							width: width * this.parent.ratio,
							height: height * this.parent.ratio
						});
					}
				}
			}

			if(name === 'dropShadow') {
				if((!this.instance.dropShadow || !value) && this.instance.dropShadow != value) {
					var yDiff = 0;
					if(value) {
						yDiff = 0.125;
					} else {
						yDiff = -0.125;
					}

					var captionNode = this.getCaptionNode();
					if(captionNode) {
						captionNode.addPositionDiff({
							x: 0,
							y: yDiff
						}, {
							completeMovement: false
						});
					}

					if(this.keepSizeWhenChangingEffects) {
						var width = this.origInstance.width - yDiff;
						var height = this.origInstance.height - yDiff;
						this.changeInstanceProperty(['width', 'height'], [
							width,
							height
						]);

						$(this).css({
							width: width * this.parent.ratio,
							height: height * this.parent.ratio
						});
					}
				}
			}

			if(['dropShadow', 'border'].indexOf(name) !== -1) {
				frame.wrapper.onFlowStop(frame);
			}

			_changeInstanceProperty.apply(this, arguments);
		},
		onUpdateCurrentPosition: function(diff) {
			if(this.instance.captionTextId) {
				var captionNode = this.getCaptionNode();
				if(captionNode && this.secondaryFocusedElements.indexOf(captionNode) === -1) {
					captionNode.addPositionDiff(diff, {
						completeMovement: false,
						showTooltip: false
					});
				}
			}
		},
		getBlockedElementsFromAlignment: function() {
			return $.merge(this.getOtherBlockedElementsFromAlignment(), getBlockedElementsFromAlignment.apply(this, arguments));
		},
		getOtherBlockedElementsFromAlignment: function() {
			let otherBlockedElements = [];
			let captionNode = this.getCaptionNode();
			if(captionNode) {
				otherBlockedElements.push(captionNode);
			}
			if(this.keepLayoutSizedTogether) {
				otherBlockedElements.push(...this.parent.getFrames().toArray());
			}

			return otherBlockedElements;
		},
		splitIntoParts: function(columns, rows, userPadding) {
			if(!this.cachedSplitStartSize) {
				this.cachedSplitStartSize = {
					x: this.instance.x,
					y: this.instance.y,
					width: this.instance.width,
					height: this.instance.height
				};
				this.cachedSplitFrames = [];
			}

			var padding = (2 + userPadding) / this.ratio;
			var widthPadding = padding * (columns - 1);
			var heightPadding = padding * (rows - 1);
			var width = (this.cachedSplitStartSize.width - widthPadding) / columns;
			var height = (this.cachedSplitStartSize.height - heightPadding) / rows;

			$(this).css({
				width: width * this.ratio,
				height: height * this.ratio
			});
			// Should not need to maintain aspect ratio since only for placeholders
			this.saveCurrentSize({});

			var x = 0;
			var y = 0;
			var index = 0;
			if(columns > 1) {
				x++;
			} else {
				y++;
			}

			while(y < rows) {
				var left = this.cachedSplitStartSize.x + (x * (width + padding));
				var top = this.cachedSplitStartSize.y + (y * (height + padding));

				if(this.cachedSplitFrames[index]) {
					var frame = this.cachedSplitFrames[index];
					$(frame).css({
						left: (left * this.ratio) - 1 + wrapper.canvasBorderWidth,
						top: (top * this.ratio) - 1 + wrapper.canvasBorderWidth,
						width: width * this.ratio,
						height: height * this.ratio
					});

					frame.saveCurrentPosition({
						propogate: false
					});
					frame.saveCurrentSize({});
				} else {
					this.cachedSplitFrames.push(this.wrapper.addFrame({
						x: left,
						y: top,
						width: width,
						height: height
					}));
				}

				x++;
				if(x >= columns) {
					x = 0;
					y++;
				}
				index++;
			}

			for(; index < this.cachedSplitFrames.length; index++) {
				var removeFrame = this.cachedSplitFrames[index];
				this.wrapper.removeFrame(removeFrame, true);
				this.cachedSplitFrames.splice(index, 1);
				index--;
			}
		},
		editDimensions: function() {
			$.SettingsBuilderDialog([
				{
					id: 'x',
					description: 'X',
					type: 'positiveFloat',
					range: [0, this.wrapper.inchWidth],
					value: this.instance.x
				},
				{
					id: 'y',
					description: 'Y',
					type: 'positiveFloat',
					range: [0, this.wrapper.inchHeight],
					value: this.instance.y
				},
				{
					id: 'width',
					description: 'Width',
					type: 'positiveFloat',
					range: [0, this.wrapper.inchWidth],
					value: this.instance.width
				},
				{
					id: 'height',
					description: 'Height',
					type: 'positiveFloat',
					range: [0, this.wrapper.inchHeight],
					value: this.instance.height
				}
			], {
				title: 'Edit Details',
				onSettingsApplied: function(settings) {
					if(settings.x !== frame.instance.y || settings.y !== frame.instance.y) {
						frame.changeInstanceProperty(['x', 'y'], [settings.x, settings.y]);
					}
					if(settings.width !== frame.instance.width || settings.height !== frame.instance.height) {
						frame.saveSize(settings.width, settings.height, {
							freeRangeAspectRatio: frame.getLockedAspectRatio(),
							freeRangeStartCrop: frame.instance.crop
						});
					}
					frame.refreshInstance();
					frame.wrapper.onFlowStop();
				}
			})
		},
		checkResizedLayout: function(event, resizingHandle, options = {}) {
			// Only change layout when we are resizing a single element
			let resizingFrame = $(resizingHandle).closest('.flowLayoutResizable')[0];
			if(!this.keepLayoutSizedTogether || (resizingFrame && resizingFrame !== this)) {
				return;
			}
			resizingHandle = this.createRotatedResizingHandle(resizingHandle);
			let page = this.parent.getPage();
			let paddingSetting = 1;
			if(page && page.extras && page.extras.collageSettings) {
				paddingSetting = page.extras.collageSettings.padding;
				if(paddingSetting === null || paddingSetting === undefined) {
					paddingSetting = 1;
				}
			}
			let padding = (paddingSetting / 30) * this.ratio * 2;

			let otherFrames = this.parent.getFrames().toArray().filter(frame => frame !== this && frame.keepLayoutSizedTogether);
			this.__cachedResizeRect = this.getBoundingClientRectIncludingShadow();
			this.__otherResizingStartExtraSize = this.getExtraVisibleWidthHeight();
			otherFrames.forEach(frame => {
				frame.__cachedResizeRect = frame.getBoundingClientRectIncludingShadow();
				if(!frame.__otherResizingStartWidth) {
					frame.__otherResizingStartLeft = $(frame).getFloatStyle('left');
					frame.__otherResizingStartTop = $(frame).getFloatStyle('top');
					frame.__otherResizingStartWidth = $(frame).getFloatStyle('width');
					frame.__otherResizingStartHeight = $(frame).getFloatStyle('height');
					frame.__otherResizingStartExtraSize = frame.getExtraVisibleWidthHeight();
					frame.__otherResizingStartRect = frame.getBoundingClientRect();

					frame.__otherResizingAspectRatio = frame.getLockedAspectRatio();
					frame.__otherResizingStartCrop = frame.instance.crop;
				}

				// Reset every resize event since this requires only recursive changes for this case to be accounted for
				frame.__otherResizingIncLeft = 0;
				frame.__otherResizingIncTop = 0;
				frame.__otherResizingIncWidth = 0;
				frame.__otherResizingIncHeight = 0;
			});
			let diffWidth = $(this).getFloatStyle('width') - this.resizingStartWidth;
			let diffHeight = $(this).getFloatStyle('height') - this.resizingStartHeight;
			let radians = this.getRotation(true);
			if(radians) {
				// TODO: Why the heck does 270 degree rotation also need this to be the same as 90 degree rotation to work correctly
				radians = Math.PI / 2;
				let startDiffWidth = diffWidth;
				diffWidth = diffWidth * Math.cos(radians) + diffHeight * Math.sin(radians);
				diffHeight = startDiffWidth * Math.sin(radians) + diffHeight * Math.cos(radians);
			}

			let handledFrames = {
				width: {},
				height: {}
			};
			let oppositeResizingHandle = this.createOppositeResizingHandle(resizingHandle);
			if(diffWidth) {
				if($(resizingHandle).hasClass('subtractWidthHandle')) {
					let incrementWidthFrames = otherFrames.filter(frame => {
						let isCloseToLeftEdge = Math.abs((frame.__otherResizingStartRect.left + frame.__otherResizingStartRect.width - 2 + frame.__otherResizingStartExtraSize) - this.resizingStartRect.left) < padding;
						let isVerticallyOverlapped = frame.__cachedResizeRect.bottom > (this.__cachedResizeRect.top - padding) && this.__cachedResizeRect.bottom > (frame.__cachedResizeRect.top - padding);

						return isCloseToLeftEdge && isVerticallyOverlapped;
					});
					incrementWidthFrames.forEach(frame => {
						this.incrementOtherFrameToKeepLayout(oppositeResizingHandle, frame, -diffWidth, 0, handledFrames, padding, options);
					});
				} else {
					let incrementWidthFrames = otherFrames.filter(frame => {
						let isCloseToRightEdge = Math.abs(frame.__otherResizingStartRect.left - (this.resizingStartRect.left + this.resizingStartRect.width + 2 + this.__otherResizingStartExtraSize)) < padding;
						let isVerticallyOverlapped = frame.__cachedResizeRect.bottom > (this.__cachedResizeRect.top - padding) && this.__cachedResizeRect.bottom > (frame.__cachedResizeRect.top - padding);

						return isCloseToRightEdge && isVerticallyOverlapped;
					});
					incrementWidthFrames.forEach(frame => {
						this.incrementOtherFrameToKeepLayout(oppositeResizingHandle, frame, diffWidth, 0, handledFrames, padding, options);
					});
				}
			}

			if(diffHeight) {
				if($(resizingHandle).hasClass('subtractHeightHandle')) {
					let incrementHeightFrames = otherFrames.filter(frame => {
						let isCloseToTopEdge = Math.abs((frame.__otherResizingStartRect.top + frame.__otherResizingStartRect.height - 2 + frame.__otherResizingStartExtraSize) - this.resizingStartRect.top) < padding;
						let isHorizontallOverlapped = frame.__cachedResizeRect.right > (this.__cachedResizeRect.left - padding) && this.__cachedResizeRect.right > (frame.__cachedResizeRect.left - padding);

						return isCloseToTopEdge && isHorizontallOverlapped;
					});
					incrementHeightFrames.forEach(frame => {
						this.incrementOtherFrameToKeepLayout(oppositeResizingHandle, frame, 0, -diffHeight, handledFrames, padding, options);
					});
				} else {
					let incrementHeightFrames = otherFrames.filter(frame => {
						let isCloseToBottomEdge = Math.abs(frame.__otherResizingStartRect.top - (this.resizingStartRect.top + this.resizingStartRect.height + 2 + this.__otherResizingStartExtraSize)) < padding;
						let isHorizontallOverlapped = frame.__cachedResizeRect.right > (this.__cachedResizeRect.left - padding) && this.__cachedResizeRect.right > (frame.__cachedResizeRect.left - padding);

						return isCloseToBottomEdge && isHorizontallOverlapped;
					});
					incrementHeightFrames.forEach(frame => {
						this.incrementOtherFrameToKeepLayout(oppositeResizingHandle, frame, 0, diffHeight, handledFrames, padding, options);
					});
				}
			}

			if((options.maxWidthOffset || options.maxHeightOffset) && !options.recursive) {
				let { xDiff, yDiff } = this.getResizeEventDiffs(event, resizingHandle);
				if(options.maxWidthOffset) {
					xDiff += options.maxWidthOffset;
				}
				if(options.maxHeightOffset) {
					yDiff += options.maxHeightOffset;
				}

				frame.updateSizeFromResizeEvent(event, resizingHandle, xDiff, yDiff, {
					skipResizeEvent: true
				});
				frame.getSecondaryFocusedElementsForResize().forEach(function(elem) {
					elem.updateSizeFromResizeEvent(event, resizingHandle, xDiff, yDiff, {
						skipResizeEvent: true
					});
				});
				this.saveCurrentSize({
					freeRangeAspectRatio: freeRangeAspectRatio,
					freeRangeStartCrop: freeRangeStartCrop
				});

				frame.checkResizedLayout(event, resizingHandle, {
					recursive: true
				});
				frame.checkResizedLayout(event, resizingHandle, {
					recursive: true
				});
			}
		},
		incrementOtherFrameToKeepLayout: function(resizingHandle, otherFrame, diffWidth, diffHeight, handledFrames, padding, options = {}) {
			if(diffWidth && handledFrames.width[otherFrame.instance.id]) {
				diffWidth = 0;
			}
			if(diffHeight && handledFrames.height[otherFrame.instance.id]) {
				diffHeight = 0;
			}
			if(diffWidth === 0 && diffHeight === 0) {
				return;
			}

			if($(resizingHandle).hasClass('subtractWidthHandle')) {
				otherFrame.__otherResizingIncLeft += diffWidth;
				otherFrame.__otherResizingIncWidth -= diffWidth;
			} else {
				otherFrame.__otherResizingIncWidth += diffWidth;
			}

			if($(resizingHandle).hasClass('subtractHeightHandle')) {
				otherFrame.__otherResizingIncTop += diffHeight;
				otherFrame.__otherResizingIncHeight -= diffHeight;
			} else {
				otherFrame.__otherResizingIncHeight += diffHeight;
			}

			// Keep track of how much we should revert back
			if(!options.handledError && (otherFrame.__otherResizingStartWidth + otherFrame.__otherResizingIncWidth) < this.minWidth) {
				let diff = (otherFrame.__otherResizingStartWidth + otherFrame.__otherResizingIncWidth) - this.minWidth - 1;
				options.maxWidthOffset = Math.min(diff, options.maxWidthOffset || 0);
			}
			if(!options.handledError && (otherFrame.__otherResizingStartHeight + otherFrame.__otherResizingIncHeight) < this.minHeight) {
				let diff = (otherFrame.__otherResizingStartHeight + otherFrame.__otherResizingIncHeight) - this.minHeight - 1;
				options.maxHeightOffset = Math.min(diff, options.maxHeightOffset || 0);
			}

			let incLeft = otherFrame.__otherResizingIncLeft;
			let incTop = otherFrame.__otherResizingIncTop;
			let incWidth = otherFrame.__otherResizingIncWidth;
			let incHeight = otherFrame.__otherResizingIncHeight;
			let radians = otherFrame.getRotation(true);
			if(radians) {
				radians = Math.PI / 2;
				let startIncWidth = incWidth;
				incWidth = incWidth * Math.cos(radians) + incHeight * Math.sin(radians);
				incHeight = startIncWidth * Math.sin(radians) + incHeight * Math.cos(radians);

				let rotation = otherFrame.getRotation();
				var oldPosition = this.rotateRect(otherFrame.__otherResizingStartLeft, otherFrame.__otherResizingStartTop, otherFrame.__otherResizingStartWidth, otherFrame.__otherResizingStartHeight, rotation);
				var newPosition = this.rotateRect(otherFrame.__otherResizingStartLeft, otherFrame.__otherResizingStartTop, otherFrame.__otherResizingStartWidth + incWidth, otherFrame.__otherResizingStartHeight + incHeight, rotation);
				incLeft += oldPosition.x - newPosition.x;
				incTop += oldPosition.y - newPosition.y;
			}

			$(otherFrame).css({
				left: otherFrame.__otherResizingStartLeft + incLeft,
				top: otherFrame.__otherResizingStartTop + incTop,
				width: otherFrame.__otherResizingStartWidth + incWidth,
				height: otherFrame.__otherResizingStartHeight + incHeight
			});
			otherFrame.saveCurrentPosition({
				propogate: false
			});
			otherFrame.saveCurrentSize({
				freeRangeAspectRatio: otherFrame.__otherResizingAspectRatio,
				freeRangeStartCrop: otherFrame.__otherResizingStartCrop,
				swapWidthAndHeightFix: true
			});
			if(diffWidth) {
				handledFrames.width[otherFrame.instance.id] = true;
			}
			if(diffHeight) {
				handledFrames.height[otherFrame.instance.id] = true;
			}


			// See if this has some propogating effects
			let oppositeResizingHandle = this.createOppositeResizingHandle(resizingHandle);
			let otherFrames = this.parent.getFrames().toArray().filter(frame => frame !== this && frame !== otherFrame && frame.keepLayoutSizedTogether);
			if(diffWidth) {
				if($(resizingHandle).hasClass('subtractWidthHandle')) {
					let incrementWidthFrames = otherFrames.filter(frame => {
						let isCloseToLeftEdge = Math.abs((frame.__otherResizingStartRect.left + frame.__otherResizingStartRect.width - 2 + frame.__otherResizingStartExtraSize) - (otherFrame.__otherResizingStartRect.left)) < padding;
						let isVerticallyOverlapped = otherFrame.__cachedResizeRect.top <= frame.__cachedResizeRect.bottom && frame.__cachedResizeRect.top <= otherFrame.__cachedResizeRect.bottom;

						return isCloseToLeftEdge && isVerticallyOverlapped;
					});
					incrementWidthFrames.forEach(otherFrame2 => {
						this.incrementOtherFrameToKeepLayout(oppositeResizingHandle, otherFrame2, diffWidth, 0, handledFrames, padding, options);
					});
				} else {
					let incrementWidthFrames = otherFrames.filter(frame => {
						let isCloseToRightEdge = Math.abs(frame.__otherResizingStartRect.left - (otherFrame.__otherResizingStartRect.left + otherFrame.__otherResizingStartRect.width + 2 + otherFrame.__otherResizingStartExtraSize)) < padding;
						let isVerticallyOverlapped = otherFrame.__cachedResizeRect.top <= frame.__cachedResizeRect.bottom && frame.__cachedResizeRect.top <= otherFrame.__cachedResizeRect.bottom;

						return isCloseToRightEdge && isVerticallyOverlapped;
					});
					incrementWidthFrames.forEach(otherFrame2 => {
						this.incrementOtherFrameToKeepLayout(oppositeResizingHandle, otherFrame2, diffWidth, 0, handledFrames, padding, options);
					});
				}
			}
			if(diffHeight) {
				if($(resizingHandle).hasClass('subtractHeightHandle')) {
					let incrementHeightFrames = otherFrames.filter(frame => {
						let isCloseToTopEdge = Math.abs((frame.__otherResizingStartRect.top + frame.__otherResizingStartRect.height - 2 + frame.__otherResizingStartExtraSize) - (otherFrame.__otherResizingStartRect.top)) < padding;
						let isHorizontallyOverlapped = otherFrame.__cachedResizeRect.left <= frame.__cachedResizeRect.right && frame.__cachedResizeRect.left <= otherFrame.__cachedResizeRect.right;

						return isCloseToTopEdge && isHorizontallyOverlapped;
					});
					incrementHeightFrames.forEach(otherFrame2 => {
						this.incrementOtherFrameToKeepLayout(oppositeResizingHandle, otherFrame2, 0, diffHeight, handledFrames, padding, options);
					});
				} else {
					let incrementHeightFrames = otherFrames.filter(frame => {
						let isCloseToBottomEdge = Math.abs(frame.__otherResizingStartRect.top - (otherFrame.__otherResizingStartRect.top + otherFrame.__otherResizingStartRect.height + 2 + otherFrame.__otherResizingStartExtraSize)) < padding;
						let isHorizontallyOverlapped = otherFrame.__cachedResizeRect.left <= frame.__cachedResizeRect.right && frame.__cachedResizeRect.left <= otherFrame.__cachedResizeRect.right;

						return isCloseToBottomEdge && isHorizontallyOverlapped;
					});
					incrementHeightFrames.forEach(otherFrame2 => {
						this.incrementOtherFrameToKeepLayout(oppositeResizingHandle, otherFrame2, 0, diffHeight, handledFrames, padding, options);
					});
				}
			}
		},
		createRotatedResizingHandle: function(resizingHandle) {
			let newHandle = $('<div>').addClass(resizingHandle.className);
			
			let rotation = this.getRotation();
			if(rotation === 90) {
				let resizeHandles = $(this).find('.resizableHandle').toArray();
				let currentIndex = resizeHandles.findIndex(otherHandle => otherHandle.className == resizingHandle.className);
				let newIndex = (currentIndex + 2) % resizeHandles.length;
				newHandle[0].className = resizeHandles[newIndex].className;
			} else if(rotation === 270) {
				let resizeHandles = $(this).find('.resizableHandle').toArray();
				let currentIndex = resizeHandles.findIndex(otherHandle => otherHandle.className == resizingHandle.className);
				let newIndex = currentIndex - 2;
				if(newIndex < 0) {
					newIndex = resizeHandles.length + newIndex;
				}
				newHandle[0].className = resizeHandles[newIndex].className;
			}

			return newHandle[0];
		},
		createOppositeResizingHandle: function(resizingHandle) {
			let newHandle = $('<div>').addClass(resizingHandle.className);
			if(newHandle.hasClass('subtractWidthHandle')) {
				newHandle.removeClass('subtractWidthHandle');
			} else {
				newHandle.addClass('subtractWidthHandle');
			}
			if(newHandle.hasClass('subtractHeightHandle')) {
				newHandle.removeClass('subtractHeightHandle');
			} else {
				newHandle.addClass('subtractHeightHandle');
			}

			return newHandle[0];
		},
		getExtraCanvasMargins: function() {
			if(this.keepLayoutSizedTogether) {
				let page = this.parent.getPage();
				return page.getLockedLayoutCanvasMargins ? (page.getLockedLayoutCanvasMargins() * this.ratio) : 0;
			} else {
				return 0;
			}
		},
		getExtraVisibleWidthHeight: function() {
			if(this.instance.dropShadow) {
				if(this.instance.dropShadow.sizeScaling) {
					let maxSize = Math.max(this.instance.width, this.instance.height);
					return this.instance.dropShadow.depth * maxSize / 200 * this.ratio;
				} else {
					return this.instance.dropShadow.depth / 60 * this.ratio;
				}
			}

			return 0;
		},
		getBoundingClientRectIncludingShadow: function() {
			let rect = $.extend({}, this.getBoundingClientRect());
			let dropShadow = this.getExtraVisibleWidthHeight();
			if(dropShadow) {
				rect.width += dropShadow;
				rect.right += dropShadow;
				rect.height += dropShadow;
				rect.bottom += dropShadow;
			}

			return rect;
		},

		onAdd: function(startingPhotoId) {
			// Check for existence of a candid node, update if we find it
			var page = this.parent.getPage();
			if (startingPhotoId) {
				let candidNode = $('#candidNode-' + startingPhotoId);
				if (candidNode.length) {
					var removeCandidHandler = candidNode.data('removeCandid');
					if (removeCandidHandler) {
						removeCandidHandler(page);
					}
				}

				if($.candidsCategories && $.candidsCategories.updateCachedDataRemoved) {
					$.candidsCategories.updateCachedDataRemoved(startingPhotoId, page);
				}
			}

			let candidNode = $('#candidNode-' + this.photoId);
			if (candidNode.length) {
				var addCandidHandler = candidNode.data('addCandid');
				if (addCandidHandler) {
					addCandidHandler(page);
				}
			}

			if($.candidsCategories && $.candidsCategories.updateCachedDataAdded) {
				$.candidsCategories.updateCachedDataAdded(this.photoId, page);
			}
		},
		removeContent: function(userPressed) {
			if(!$(this).isAttached()) {
				return;
			}

			// We only want to remove image if this is an explicit user action and not a propogated action
			// NOTE: I think it makes sense to always delete frames if we select a bunch at once, but this definitely questionable and open to change if customers are concerned
			let page = this.parent.getPage();
			let secondaryFocusedElements = $.merge([], this.secondaryFocusedElements);
			if(userPressed && this.instance && this.instance.photo && secondaryFocusedElements.length === 0 && page && page.shouldRemoveImageFromPlaceholder()) {
				this.changeSource(null);
				this.updateEditTools();
				return;
			}

			this.wrapper.removeFrame(this);
			this.onRemovePropogate();

			secondaryFocusedElements.forEach(function(elem) {
				elem.removeContent();
			});
			var captionNode = this.getCaptionNode();
			if(captionNode) {
				captionNode.removeContent();
			}
		},
		onRemove: function(propogate) {
			// Check for existence of a candid node, update if we find it
			if(this.photoId) {
				var candidNode = $('#candidNode-' + this.photoId);
				var page = this.parent.getPage();
				if (candidNode.length) {
					var removeCandidHandler = candidNode.data('removeCandid');
					if (removeCandidHandler) {
						removeCandidHandler(page);
					}
				}

				if($.candidsCategories && $.candidsCategories.updateCachedDataRemoved) {
					$.candidsCategories.updateCachedDataRemoved(this.photoId, page);
				}
			}

			if(propogate) {
				this.onRemovePropogate();
			}
		},
		changeSecondaryInstanceProperty: function(name, value) {
			_changeSecondaryInstanceProperty.apply(this, arguments);

			if(['x', 'y', 'width', 'height', 'duplicateInBleed'].includes(name)) {
				return;
			}

			this.withLinkedElem(function(layout, elem) {
				elem.changeInstanceProperty(name, value, false);
				elem.refreshInstance();
			});
		},
		destroy: function() {
			_destroy.apply(this, arguments);

			if($(this.outerWrapper).hasClass('hasPopup')) {
				$(this.outerWrapper).removeClass('hasPopup').popup('destroy');
				$(this).off('mouseenter', this.lowResolutionMouseEnterHandler);
				$(this).off('mouseleave', this.lowResolutionMouseLeaveHandler);
			}

			this.destroyResizableHandlers();
		},
		visibleType: 'image',
		contentType: 'frame',
		requireCtrlForMovement: false,
		multipleSelect: true,
		multipleSelectProperties: [
			'transform',
			'border',
			'dropShadow',
			'invert',
			'grayscale',
			'blur',
			'opacity',
			'saturate',
			'contrast',
			'brightness',
			'hue',
			'flipHorizontal',
			'flipVertical',
			'locked'
		],
		refreshPhotoVersion: false,
		allowDeleteKey: true,
		disableRotateSwapWidthAndHeight: true,
		hideCompletelyOverlayedCells: true
	}, options);

	$.FlowLayoutResizable(frame, {
		getLockedAspectRatio: function() {
			if(!this.isNormalImage()) {
				return false;
			} else {
				return this.instance.width / this.instance.height;
			}
		},
		getSecondaryFocusedElementsForResize: function() {
			if(this.keepLayoutSizedTogether) {
				return [];
			} else {
				return this.getSecondaryFocusedElements();
			}
		},

		allowCropResizing: true
	});

	var freeRangeAspectRatio = false;
	var freeRangeStartCrop = null;
	frame.registerOnStartResize(function(e) {
		var width = $(frame).getFloatStyle('width') / wrapper.ratio;
		this.startUnitWidth = width;

		// Turn off aspect ratio lock when we have ctrl key pressed
		var aspectRatio = this.getLockedAspectRatio();
		if(e.ctrlKey || (this.keepLayoutSizedTogether && aspectRatio)) {
			if(aspectRatio) {
				freeRangeAspectRatio = aspectRatio;
				freeRangeStartCrop = this.instance.crop;
				aspectRatio = false;
			} else {
				aspectRatio = this.instance.width / this.instance.height;
			}
		} else {
			freeRangeAspectRatio = false;
		}
		this.lockedAspectRatio = aspectRatio;

		if($.userEvents) {
			$.userEvents.startGroupedEvents();
		}
	});

	frame.registerOnResize(function(event, resizingHandle) {
		this.saveCurrentSize({
			freeRangeAspectRatio: freeRangeAspectRatio,
			freeRangeStartCrop: freeRangeStartCrop
		});

		var unitWidth = frame.width / wrapper.ratio;
		if(frame.photoId && ((unitWidth > 10 && this.startUnitWidth < 10) || (unitWidth > 5 && this.startUnitWidth < 5) || (unitWidth > 3 && this.startUnitWidth < 3))) {
			var img = this.imgElem;
			if(img) {
				var newUrl = this.getScaledImageUrl(unitWidth);
				$(img).LoadImage(newUrl);

				frame.changeInstanceProperty('existingUrl', newUrl);
			}

			this.startUnitWidth = unitWidth;
		}

		if(frame.getLockedAspectRatio() === false) {
			frame.applyCrop();
		}
		if(frame.instance.mask && !this.lockedAspectRatio) {
			let mask = $.extend(true, {}, frame.instance.mask);
			if(frame.updateRoundedMask(mask)) {
				this.applyMask(mask);
				this.updateProperty('mask', mask);
			}
		}
		frame.applyFilters(frame.instance);
		frame.checkOverflowToLinkedLayouts();
		frame.checkResizedLayout(event, resizingHandle);
		// TODO: Handling this correctly instead of just double calling will speed up performance a bunch
		// We are double running this so that if we increase width of one element recursively, and then height of another element recursively, elements that were skipped in the width step sometimes really needed to be incremeneted
		// I messed around a little with trying to directly tackle this in the code, but ended up being harder than I thought it would be
		frame.checkResizedLayout(event, resizingHandle);
		frame.wrapper.onFlowChange(frame);
	});
	frame.registerOnStopResize(function(e) {
		this.wrapper.onFlowStop(frame);
		this.checkMinimumResolution();

		if(this.linkedOverflowInstance && this.isContentHidden()) {
			// If we copied an instance over to next page, get rid of this version
			this.wrapper.removeFrame(this);
		}

		let otherFrames = this.parent.getFrames().toArray();
		otherFrames.forEach(frame => {
			if(frame.__otherResizingStartWidth) {
				delete frame.__otherResizingStartLeft;
				delete frame.__otherResizingStartTop;
				delete frame.__otherResizingStartWidth;
				delete frame.__otherResizingStartHeight;
				delete frame.__otherResizingAspectRatio;
				delete frame.__otherResizingStartCrop;
				delete frame.__otherResizingStartExtraSize;
				delete frame.__otherResizingStartRect;
			}
		});

		if($.userEvents) {
			$.userEvents.stopGroupedEvents();
		}
	});

	frame.registerOnStartRotate(function(event) {
		this.rotation = null;
		this.startRotation = this.getRotation();
	});
	frame.registerOnRotate(function(event) {
		this.changeInstanceProperty('transform', this.style.transform);

		var endRotation = this.getRotation();
		if(this.isNormalImage() && (Math.abs(endRotation - this.startRotation) === 90 || Math.abs(endRotation - this.startRotation) === 270) && this.startRotation % 90 === 0 && !this.disableRotateSwapWidthAndHeight) {
			this.swapWidthAndHeight();
			this.startRotation = endRotation;
		}

		if(this.instance.dropShadow) {
			let rotation = this.getRotation();
			let dropShadow = $.extend(true, {}, this.instance.dropShadow);
			dropShadow.rotation = Math.round((rotation + 315) % 360);
			this.changeInstanceProperty('dropShadow', dropShadow);
			this.applyFilters(this.instance);
			this.updateToolbarDisplay();
		}
	});

	frame.setInstance(definition);

	return frame;
};