<template>
	<div v-if="errorMessage">
		<v-alert type="error" >{{ errorMessage }}</v-alert>
		<v-btn raised @click="cancel" class="cancel-button">Cancel</v-btn>
	</div>
	<v-progress-circular v-else-if="loading" indeterminate />
	<div v-else>
		<v-btn raised @click="cancel">Cancel</v-btn>
		<v-btn color="primary" raised @click="selectFiles" class="continue-button" :disabled="selectedImages.length <= 0">Continue {{ selectedText }}</v-btn>
	</div>
</template>

<script>
import { client, shouldRetryAll } from '../utils/axios';
import { sleep } from '../utils/sleep';
import replaceFileExtension from './utils/replace-file-extension';
const SCOPES = 'https://www.googleapis.com/auth/photospicker.mediaitems.readonly';
import moment from 'moment';

// How will the scopes be used - Users will be able to upload their photos from Google Photos as candids to be put into their school yearbook

export default {
	props: ['acceptedFileMimeTypes', 'allowMultiple'],
	data() {
		return {
			loading: true,
			errorMessage: null,
			accessToken: null,
			session: null
		};
	},
	computed: {
		
	},
	methods: {
		selectFiles(items) {
			// We are accepting videos and filtering them out in next step like a normal file picker - the app let them pick them so it is confusing if they just magically disappear with no warning
			let files = items.map(item => {
				// HEIC files are auto converted to jpeg even when uploaded from website
				let filename = item.mediaFile.filename;
				let mimeType = item.mediaFile.mimeType;
				if(['image/heic', 'image/heif'].includes(mimeType)) {
					filename = replaceFileExtension(filename, 'jpg');
					mimeType = 'image/jpeg';
				}

				const baseUrl = item.mediaFile.baseUrl;
				return {
					id: item.id,
					name: filename,
					type: mimeType,
					// Docs: "If you want to download the image retaining all the Exif metadata except the location metadata, concatenate the base URL with the d parameter."
					downloadUrl: `${baseUrl}=d`,
					// Need to download since this flow requires a token we can't set to the image src
					viewUrl: async function() {
						if(this.downloadingViewUrl) {
							return;
						}

						this.downloadingViewUrl = true;
						let response = await client.get(`${baseUrl}=w800-h800`, {
							responseType: 'blob',
							shouldRetry: shouldRetryAll,
							headers: {
								Authorization: 'Bearer ' + this.authToken
							}
						});

						this.viewUrl = URL.createObjectURL(response.data);
						this.downloadingViewUrl = false;
					},
					downloadingViewUrl: false,
					size: 1000,
					source: 'google-photos',
					authToken: this.accessToken
				};
			});
			this.$emit('filesSelected', files);
			this.$emit('returnToMain');
		},
		cancel() {
			this.$emit('returnToMain');
		},

		initGoogleAuth() {
			this.loading = true;
			let tokenClient = window.google.accounts.oauth2.initTokenClient({
				client_id: process.env.VUE_APP_GOOGLE_API_CLIENT_ID,
				scope: SCOPES,
				callback: (response) => {
					if(response.error !== undefined) {
						this.errorMessage = response.error;
					} else {
						this.accessToken = window.gapi.cachedPhotosAccessToken = response.access_token;
						this.initSession();
					}
				}
			});

			tokenClient.requestAccessToken({prompt: 'consent'});
		},

		async initSession() {
			this.loading = true;
			try {
				let response = await client.post('https://photospicker.googleapis.com/v1/sessions', null, {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + this.accessToken
					}
				});

				this.session = response.data;

				window.open(this.session.pickerUri, 'Pick photos');
				this.pollSession();
			} catch(e) {
				this.errorMessage = `Failed to initialize session: ${e.message}`;
				this.loading = false;
			}
		},
		async pollSession() {
			try {
				let sleepTime = (parseInt(this.session.pollingConfig.pollInterval) ?? 1) * 1_000;
				await sleep(sleepTime);

				let response = await client.get(`https://photospicker.googleapis.com/v1/sessions/${this.session.id}`, {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + this.accessToken
					}
				});

				if(response.data.mediaItemsSet) {
					this.getSelectedItems();
				} else if(moment().isAfter(this.session.expireTime)) {
					this.errorMessage = 'Timed out';
				} else {
					this.pollSession();
				}
			} catch(e) {
				this.errorMessage = `Failed to wait for session: ${e.message}`;
			}
		},

		async getSelectedItems() {
			const PAGE_SIZE = 100;
			let response = await client.get(`https://photospicker.googleapis.com/v1/mediaItems?sessionId=${this.session.id}&pageSize=${PAGE_SIZE}`, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + this.accessToken
				}
			});

			let items = response.data.mediaItems;
			while(response.data.nextPageToken) {
				response = await client.get(`https://photospicker.googleapis.com/v1/mediaItems?sessionId=${this.session.id}&pageSize=${PAGE_SIZE}&pageToken=${response.data.nextPageToken}`, {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + this.accessToken
					}
				});

				items.push(...response.data.mediaItems);
			}

			this.selectFiles(items);
		}
	},
	mounted() {
		if(window.gapi.cachedPhotosAccessToken) {
			this.accessToken = window.gapi.cachedPhotosAccessToken;
			this.initSession();
		} else if(this.accessToken) {
			this.initSession();
		} else {
			this.initGoogleAuth();
		}
	}
};
</script>

<style scoped>
.continue-button {
	margin-left: 0.5em;
}
.continue-button, .cancel-button {
	margin-bottom: 0.5em;
}
</style>