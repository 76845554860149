$.defaultEffectSizeScaling = false;

$.DEFAULT_CLASS_LAYOUT = {
	cell:  {
		default: 'Small',
		alignCells: 'outside',
		subjectSwappable: true
	},
	name:  {
		size: 10,
		align: 'center',
		order: '%first%',
		order2: '%last%',
		nameHeightFix: true
	},
	schema: 4
};

$.dynamicStaticGlobalVariables = $.extend({}, $.dynamicStaticGlobalVariables, {
	'Cover Personal Name': 'John Doe'
});

$.saveSpellingMistakes = true;
$.defaultShowCandidStats = true;