$.YearbookClassPage = function(classObj) {
	var obj = new $.FlowPageClass(classObj);
	obj._setLayout = obj.setLayout;
	var _addExtraClass = obj.addExtraClass;
	var _removeClassObj = obj.removeClassObj;
	var addComment = obj.addComment;

	$.extend(obj, {
		getLayout: function () {
			// Used to be a bug where we didn't sanitize grid when dropping a saved class layout
			if(this.layout?.grid?.width) {
				['width', 'height', 'bleed', 'bleedOutsideDimensions', 'safeSpace'].forEach(prop => {
					delete this.layout.grid[prop];
				});
			}

			return this.layout;
		},
		setLayout: function (layout, forceUpdate, sendEvent) {
			// Wipe out the layouts for all overflow pages
			let page = this.getOverflowPage();
			while (page) {
				page.layout = null;
				if (layout != this.layout || forceUpdate) {
					page.setViableCells(null);
				}
				page = page.getOverflowPage();
			}

			if (layout != this.layout || forceUpdate) {
				this.setViableCells(null);
				this.layoutChanged = true;
			}
			this._setLayout(layout, forceUpdate, sendEvent);
		},
		setSize: function (size) {
			// Wipe out the layouts for all overflow pages
			let page = this.getOverflowPage();
			while (page) {
				page.layout = null;
				page = page.getOverflowPage();
			}

			if (this.size != size) {
				this.layout.cellSize = size;
				this.layoutChanged = true;
				this.setViableCells(null);
				let page = this.getOverflowPage();
				while (page) {
					page.setViableCells(null);
					page = page.getOverflowPage();
				}

				if (this.db) {
					this.db.queueChange({
						scope: 'pages',
						name: this.id,
						value: {layout: this.layout}
					});
				}
			}

			this.size = size;
		},
		setOverflowPage: function (page, save) {
			// Keep track of existing candids/texts if we are removing information
			if(save !== false) {
				if(this.overflowPage && !page) {
					let cachedState = this.overflowPage.getCachedState();
					
					var lastCachedState = cachedState;
					var lastOverflowPage = this.overflowPage;
					while(lastOverflowPage.overflowPage) {
						lastOverflowPage = lastOverflowPage.overflowPage;
						lastCachedState = lastCachedState.cachedOverflowState = lastOverflowPage.getCachedState();
					}

					var existingCachedState = lastOverflowPage.getExtraProperty('cachedOverflowState');
					if(existingCachedState) {
						lastCachedState = lastCachedState.cachedOverflowState = existingCachedState;
					}

					// Don't save completely empty cached states
					// TODO: We could also check for tail empty cached states - don't try to remove any middle cached states as it will restore in a different order
					if(!$.objectEquals(cachedState, {
						theme: undefined,
						backgroundSettings: undefined,
						texts: {},
						candids: {},
						userLabel: undefined
					}, null, true)) {
						this.setExtraProperty('cachedOverflowState', cachedState);
					}
				} else if (!this.overflowPage && page) {
					let cachedState = this.getExtraProperty('cachedOverflowState');
					if(cachedState) {
						if($.isArray(cachedState.candids)) {
							cachedState.candids = {};
						}
						if($.isArray(cachedState.texts)) {
							cachedState.texts = {};
						}

						$.extend(page, cachedState);
						if(cachedState.backgroundSettings) {
							page.setExtraProperty('backgroundSettings', cachedState.backgroundSettings);
						}
						if(cachedState.userLabel) {
							page.setExtraProperty('userLabel', cachedState.userLabel);
						}
						this.deleteExtraProperty('cachedOverflowState');

						if(cachedState.cachedOverflowState) {
							page.setExtraProperty('cachedOverflowState', cachedState.cachedOverflowState);
						}
					}
				}
			}

			this.overflowPage = page;
		},
		getCachedState: function() {
			let cachedState = {
				theme: this.getTheme(),
				backgroundSettings: this.getExtraProperty('backgroundSettings'),
				texts: this.getTexts(),
				candids: this.getCandids(),
				userLabel: this.getExtraProperty('userLabel')
			};
			this.sanitizeCachedUrls(cachedState.theme);
			this.sanitizeCachedUrls(cachedState.candids);

			return cachedState;
		},
		sanitizeCachedUrls: function(definition) {
			if(definition && $.isPlainObject(definition)) {
				for (var id in definition) {
					var part = definition[id];
					if(part) {
						if (part.cdnUrl) {
							delete part.cdnUrl;
						} else if (part.existingUrl) {
							delete part.existingUrl;
						}
					}
				}
			}
		},
		getOverflowPage: function () {
			return this.overflowPage;
		},
		getAllPagesInClass: function() {
			let pages = [this];
			let page = this.getOverflowPage();
			while(page) {
				pages.push(page);
				page = page.getOverflowPage();
			}
			
			return pages;
		},

		getRootPage: function () {
			let page = this;
			while (page.parentPage) {
				page = page.parentPage;
			}

			return page;
		},
		getViableCells: function () {
			return this.viableCells;
		},
		setViableCells: function (viableCells) {
			this.setProperty('viableCells', viableCells, false, true, true);
		},
		getMaxCells: function () {
			return this.maxCells;
		},
		setMaxCells: function (maxCells) {
			this.maxCells = maxCells;
		},
		addExtraClass: function(classObj) {
			_addExtraClass.apply(this, arguments);

			// Want to add to cached kids of last page
			var overflowPage = this.getOverflowPage();
			while(overflowPage && overflowPage.getOverflowPage()) {
				overflowPage = overflowPage.getOverflowPage();
			}

			if(overflowPage && overflowPage.subjects) {
				this.mergeExtraBatchIntoSubjects(overflowPage.subjects, classObj);
			}
		},
		removeClassObj: function(classObj) {
			// Remove all subjects from overflow page's cache
			var overflowPage = this.getOverflowPage();
			while(overflowPage && overflowPage.subjects) {
				var classBreakIndex = overflowPage.getClassBreakInPage(classObj);
				if(classBreakIndex != -1) {
					// Keep going until the next classBreak
					this.getRootPage().removeCachedSubjects();
					break
				}

				overflowPage = overflowPage.getOverflowPage();
			}

			return _removeClassObj.apply(this, arguments);
		},
		removeCachedSubjects: function(classObj, startIndex, ignoreFirstClassBreak) {
			var overflowPage = this.getOverflowPage();
			while(overflowPage && overflowPage.subjects) {
				overflowPage.subjects = null;
				overflowPage = overflowPage.getOverflowPage();
			}
		},

		isClassBreakInPage: function(classObj) {
			return this.getClassBreakInPage(classObj) != -1;
		},
		getClassBreakInPage: function(classObj) {
			for(var i = 0; i < this.subjects.length; i++) {
				var subject = this.subjects[i];
				if(subject.classBreak && subject.classBreak.id == classObj.id) {
					return i;
				}
			}

			return -1;
		},

		updatePageLabel: function () {
			this.lockOption = {
				name: this.getFreeMovementLocked() ? 'Unlock' : 'Lock',
				icon: 'lock',
				onClick: function (page, layout) {
					var locked = !page.getFreeMovementLocked();
					page.setFreeMovementLocked(locked);

					$(this).html('<i class="' + (locked ? 'unlock' : 'lock') + ' icon"></i>' + (locked ? 'Unlock' : 'Lock'));
					layout.updateMovementLocked();
					layout.updateLabel();
				}
			}

			var sidebar = [];
			if($.userPermissions.lockPages) {
				var locked = this.getFreeMovementLocked();

				// Allow locking/unlocking if one of the following is true:
				// 1) Not locked
				// 2) Legacy locked where it is just true
				// 3) Studio users should be able to unlock stuff Office Admins unlock
				// 4) User rank is more then or equal to the user that locked it
				//     a) School Advisor can unlock a School Advisors
				//     b) School Advisor cannot unlock a Studio/Lab user
				//     c) Studio/Lab can unlock a School Advisors
				if(!locked || !$.isPlainObject(locked) || !$.getStudioSetting('studiosCanLockCustomersOutOfPages', false) || !$.isInit(locked.userRank) || Math.max($.UserRank, 2) <= Math.max(locked.userRank, 2)) {
					sidebar.push(this.lockOption);
				}
			}

			if(this.type === 'class') {
				sidebar.push({
					name: 'Make Candid',
					icon: 'recycle',
					popup: 'Change from class page to a candid page',
					onClick: function(page, layout) {
						page.convertToCandidPage();
						layout.parent.updatePagesAfterChange();
					}
				});
			}
			if(this.type === 'class') {
				sidebar.push({
					name: 'Apply Layout To All',
					icon: '',
					popup: 'Apply layout, theme, etc to all other pages',
					onClick: function(page, layout) {
						page.pageSet.openApplyToAllDialog(page, {
							candids: false,
							texts: false
						}, layout.parent);
					}
				});
			}

			// This block is duplicated for Candids!
			if(this.theme && this.isThemeValid()) {
				sidebar.push({
					name: 'Backgrounds',
					icon: 'setting',
					popup: 'Change settings on backgrounds',
					onClick: function (page, layout) {
						layout.openThemeSettings();
					}
				});

				sidebar.push({
					name: 'Apply Background To All',
					icon: '',
					popup: 'Apply background to all other pages',
					onClick: function(page, layout) {
						$.Confirm('Confirm', 'This will apply this background to every page in the book', function() {
							page.pageSet.applyBackgroundToAllPages(page, layout);
						});
					}
				});

				sidebar.push({
					name: 'Revert Background',
					icon: 'picture',
					popup: 'Revert background back to theme',
					onClick: function (page, layout) {
						layout.clearBackground();
					}
				});
			} else if (this.pageSet && this.pageSet.getTheme() && this.pageSet.hasThemePart(this.getThemePartName())) {
				sidebar.push({
					name: 'Themes',
					icon: 'setting',
					popup: 'Change settings on theme',
					onClick: function (page, layout) {
						layout.openThemeSettings();
					}
				});
				sidebar.push({
					name: 'Clear Theme',
					icon: 'picture',
					popup: 'Clear theme on book',
					onClick: function (page, layout) {
						layout.clearTheme();
					}
				});
			}

			sidebar.push({
				name: 'Save Layout',
				icon: 'paint brush',
				popup: 'Save this layout for use on other pages',
				onClick: function() {
					obj.saveClassLayoutTemplate();
				}
			});

			var previewOption = {
				name: 'Preview Page',
				icon: 'location arrow',
				popup: 'Preview PDF of just this page',
				onClick: function() {
					obj.pageSet.renderSinglePage(obj);
				}
			};
			if($.userPermissions.productionPDF) {
				$.extend(previewOption, {
					name: 'Render Page',
					popup: 'Render a production copy of just this page',
				});
			}
			sidebar.push(previewOption);
			let rootPage = this.getRootPage();
			if($.userPermissions.productionPDF && rootPage && rootPage.classObj) {
				sidebar.push({
					name: 'Render Class',
					icon: 'location arrow',
					popup: 'Render whole class including overflow pages',
					onClick: () => {
						var missingSubjectsError = this.pageSet.getMissingSubjectsError();
						if(missingSubjectsError) {
							$.Alert('Error', missingSubjectsError);
							return;
						}

						let rootPage = this.getRootPage();
						let isProduction = !!$.userPermissions.productionPDF;
						let render = new $.PageRender($.FlowPageSubSet(this.pageSet, rootPage.getAllPagesInClass()), {
							pageOffset: (rootPage.getPageNumber() % 2),
							production: isProduction,
							flattenPDF: $.getProjectSetting('renderFlatPdf', false) || !isProduction,
							batchName: 'Class pages for ' + rootPage.classObj.name,
							blockEmail: true,
							displayCropMarks: (isProduction ? $.getStudioSetting('renderCropMarks', false) : false),
							backgroundRender: (!$.getGETParams().debugPrince && !$.getGETParams().debugPrinceStart),
							includeBatches: true,
							extraPostData: this.pageSet.getRenderExtraPostData(),
							includeAllBatches: rootPage.type.indexOf('index') !== -1,
							hideBleed: !isProduction && this.pageSet.layoutDimensions && this.pageSet.layoutDimensions.hideBleed,
							onError: function () {
								$.Alert('Error', 'Failed to render class');
							}
						});
						render.showPDFDialog();
					}
				});
			}

			if (sidebar.length) {
				this.pageLabel = {
					display: '',
					popup: 'Options' + this.getLockedPopupMessage(),
					sidebar: sidebar,
					icon: this.getFreeMovementLocked() ? 'lock' : 'setting'
				};
			} else {
				this.pageLabel = null;
			}
		},
		isCommentsEditable: function () {
			return this.commentsEditable && (!this.pageSet || !this.pageSet.isReducedUI || !this.pageSet.isReducedUI('comments'));
		},
		onCheckComment: function(comment) {
			this.arrayPropertyChange('comments');
		},
		addComment: function(comment) {
			comment.seen = this.db.getCurrentUserIds();
			addComment.call(this, comment);
		},
		markCommentSeen: function(comment) {
			this.arrayPropertyChange('comments');
		},
		createOverflowPage: function(subjects) {
			var overflowPage = new $.YearbookClassPageOverflow(this, subjects, true);
			if(this.theme) {
				overflowPage.theme = $.extend(true, {}, this.theme);
			}

			return overflowPage;
		},
		showSubjectLabelFontSizeMultiplier: function() {
			return true;
		},
		convertToCandidPage: function() {
			var candidPage = new $.YearbookCandidPage({
				candids: $.extend(true, {}, this.candids),
				texts: $.extend(true, {}, this.texts)
			});
			this.pageSet.replacePage(this, candidPage);
		},
		getMissingSubjectsError: function() {
			if(!this.classObj || !this.classObj.subjects) {
				return false;
			}

			var subjectsCount = this.classObj.subjects.length;

			// NOTE: Make sure to only count teachers in front since only those are shown as large cells
			let teachersCount = 0;
			for(let i = 0; i < this.classObj.subjects.length; i++) {
				let subject = this.classObj.subjects[i];
				if(subject && subject['Teacher Priority'] > 0) {
					teachersCount++;
				} else {
					break;
				}
			}
			(this.extraClasses || []).forEach(function(extraBatch) {
				subjectsCount += extraBatch.subjects.length;

				// TODO: Figure out how to handle a merged batch with the large cell on it's own bottom row
				/*teachersCount += extraBatch.subjects.filter(function(subject) {
					return subject && subject['Teacher Priority'] > 0;
				}).length;*/
			});

			var overflowPage = this;
			var lastOverflowPage = this;
			var viableCells = 0;
			var cachedGridSpots = 0;
			var hasCachedGrid = true;
			var hasViableCells = true;
			while(overflowPage) {
				lastOverflowPage = overflowPage;
				if(!$.isInit(overflowPage.viableCells)) {
					hasViableCells = false;
				}

				viableCells += overflowPage.viableCells;
				if(overflowPage.extras && overflowPage.extras.cachedGrid && overflowPage.extras.cachedGrid.vertical && overflowPage.extras.cachedGrid.horizontal) {
					cachedGridSpots += overflowPage.extras.cachedGrid.vertical * overflowPage.extras.cachedGrid.horizontal;
				} else {
					hasCachedGrid = false;
				}
				overflowPage = overflowPage.getOverflowPage();
			}

			if(hasViableCells && viableCells > 0 && viableCells < subjectsCount) {
				let missingSubjects = subjectsCount - viableCells;
				return 'Missing space for ' + missingSubjects + ' subjects on ' + lastOverflowPage.getPageNumberDisplay();
			}

			var gridSubjectsCount = subjectsCount;
			if(this.layout && this.layout.largeCell) {
				var largeCellSpaces = (this.layout.largeCell.colSpan || 2) * (this.layout.largeCell.rowSpan || 2) - 1;
				gridSubjectsCount += largeCellSpaces * teachersCount;
			}
			if(hasCachedGrid && cachedGridSpots > 0 && cachedGridSpots < gridSubjectsCount) {
				let missingSubjects = gridSubjectsCount - cachedGridSpots;
				return 'Missing space for ' + missingSubjects + ' subjects on ' + lastOverflowPage.getPageNumberDisplay();
			}

			return false;
		},

		saveClassLayoutTemplate: function() {
			let categories;
			$.SettingsBuilderDialog([
				{
					id: 'name',
					type: 'text',
					description: 'Layout Name',
					defaultValue: 'My Layout'
				},
				{
					id: 'saveWithCandids',
					type: 'checkbox',
					description: 'Save with candids'
				},
				{
					id: 'layoutCategory',
					type: 'dropdown',
					description: 'Layout Category',
					defaultFirst: 'Your Class Layouts',
					loadFunction: (chain, onComplete) => {
						this.getCustomerLayoutCategories(chain, (data) => {
							categories = data;
							onComplete(data);
						});
					}
				}
			], {
				title: 'Save Layout Template',
				onSettingsApplied: (settings) => {
					let category = categories.find(category => category.id == settings.layoutCategory);
					let layout = this.createLayoutTemplate({
						stripCandids: !settings.saveWithCandids
					});
					this.createClassLayoutTemplate(category, layout, settings.name);
				}
			});
		},
		createClassLayoutTemplate: function(category, layout, layoutName) {
			$.ajax({
				url: 'ajax/createLayout.php',
				data: {
					categoryId: category.id,
					layoutName: layoutName,
					definition: JSON.stringify(layout),
					type: 'Class'
				},
				type: 'POST',
				dataType: 'json',
				success: function(data) {
					// If already cached, add this layout to the cache
					$.layoutCategories.addItemToCategoriesCache(category, data.layout);

					// Force reload
					$.layoutCategories.categories.currentCategory = null;
					$.layoutCategories.slider.currentType = null;
					$.layoutCategories.setCategory(category);
				},
				error: function(jqXHR) {
					if(jqXHR.responseJSON && jqXHR.responseJSON.reason && jqXHR.responseJSON.reason.indexOf('name already exists') != -1) {
						$.Alert('Warning', 'Layout name is already taken.  Please choose a different name.');
					} else {
						$.Alert('Error', 'Failed to save layout template');
					}
				}
			});
		},
		getCustomerLayoutCategories: function(chain, onComplete) {
			let categories = $.layoutCategories.getCurrentCategories();
			let classLayoutCategory = categories.getMatch({}, 'name', 'Class Layouts');

			let subCategories = [];
			for(let i = 0; i < classLayoutCategory.subCategories?.length; i++) {
				let subCategory = classLayoutCategory.subCategories[i];

				if(subCategory.load.posts.customerLayout) {
					subCategories.push(subCategory);
				}
			}
			if(subCategories.length) {
				onComplete(subCategories);
				return;
			}

			let bookFormatIds = null;
			if($.bookFormat && $.bookFormat.formatId) {
				bookFormatIds = [$.bookFormat.formatId];
			}

			// Otherwise we need to create it
			chain.add({
				url: 'ajax/createLayoutCategory.php',
				data: {
					orgId: $.OrgId,
					name: 'Your Class Layouts',
					bookFormatIds: bookFormatIds,
					type: 'Class',
					returnDuplicate: true
				},
				type: 'POST',
				dataType: 'json',
				success: function(data) {
					let category = data.category;
					category.load = {
						url: 'getLayouts.php',
						posts: {
							type: 'Class',
							customerLayout: true,
							category: category.id
						},
						createNode: window.createLayout,
						onBeforeFirstLoad: function (data) {
							return data.results.sort(function (a, b) {
								if (a.title == b.title) {
									return 0;
								} else {
									return a.title < b.title ? -1 : 1;
								}
							});
						}
					};

					$.layoutCategories.addCategoryAlphabetical(category, ['Class Layouts']);
					onComplete([category]);
				}
			});
		},

		viableCells: null,
		commentsEditable: true
	});

	return obj;
};