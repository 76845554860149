$.ArtWorkBook = function() {
	var obj = new $.FlowPageSet({});

	$.extend(obj, {
		setupArtWorkPages: function() {
			var perPage = this.artColumns * this.artRows;

			this.categories.forEach(function(category) {
				for(var i = 0; i < category.artwork.length; i += perPage) {
					var artworks = category.artwork.slice(i, i + perPage);
					obj.createPage(category, artworks);
				}
			});
		},
		createPage: function(category, artworks) {
			var title = 'Captura Yearbooks ' + this.title;
			if(category.name) {
				title += ' (' + category.name + ')';
			}

			var page = new $.FlowPage({
				title: {
					lines: {
						text: title
					}
				},
				theme: {
					type: 'Preview',
					Background: {
						id: 'test',
						cdnUrl: 'https://s3.amazonaws.com/plic-books/public/font-book-background.png'
					}
				},
				layout: {}
			});

			var height = 10.5;
			var startHeight = 1;
			var rowHeight = (height - startHeight) / this.artRows;

			var width = 8;
			var startWidth = 0.25;
			var columnWidth = width / this.artColumns;

			let texts = [];
			var photos = artworks.map(function(artwork, i) {
				var x = i % obj.artColumns;
				var y = Math.floor(i / obj.artColumns);

				var width = obj.defaultWidth, height = obj.defaultHeight;
				var ratio = artwork.width / artwork.height;
				if(isNaN(ratio)) {
					height = 1;
				} else {
					if(ratio < 1) {
						width = height * ratio;
					} else {
						height = width / ratio;
					}
				}

				var xPadd = 0;
				var yPadd = 0;
				if(width < columnWidth) {
					xPadd = (columnWidth - width) / 2;
				}
				if(height < rowHeight) {
					yPadd = (rowHeight - height) / 2;
				}

				if(artwork.displayedText) {
					texts.push({
						lines: artwork.displayedText,
						position: {
							left: x * columnWidth + startWidth + xPadd,
							top: y * rowHeight + startHeight + yPadd + height + 0.05
						}
					});
				}

				return {
					photo: artwork.id,
					existingUrl: artwork.existingUrl,
					width: width,
					height: height,
					x: x * columnWidth + startWidth + xPadd,
					y: y * rowHeight + startHeight + yPadd
				};
			});
			page.setCandidArray(photos);
			page.setTextArray(texts);

			this.addPage(page, null, false);
		}
	});

	return obj;
};