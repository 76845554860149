import '../../js/pages/ad-page-set';
import '../../js/pages/ad-page';
import '../../js/pages/artwork-book';
import '../../js/pages/layout-book';
import '../../js/pages/autograph-page';
import '../../js/pages/book-page-numbers';
import '../../js/pages/candid-page';
import '../../js/pages/class-overflow-page';
import '../../js/pages/class-page';
import '../../js/pages/cover-page';
import '../../js/pages/font-book';
import '../../js/pages/index-overflow-page';
import '../../js/pages/index-page';
import '../../js/pages/inside-cover-page';
import '../../js/pages/layout-page';
import '../../js/pages/layout-set';
import '../../js/pages/personalized-page-set';
import '../../js/pages/personalized-page';
import '../../js/pages/placeholder-page';
import '../../js/pages/subject-directory';
import '../../js/pages/yearbook-subset';
import '../../js/pages/yearbook';