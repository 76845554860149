$.getChangelog = function() {
	var changes = [];

	if($.userPermissions.manageCustomers) {
		if($.OrgRole === 'lab_role') {
			$.merge(changes, [
				{
					date: '09/14/2021',
					title: 'Managing studio licenses',
					content: 'Publishers can now manage studio licenses directly from PLIC Books. ' +
						'The create studio workflow has been updated to allow you to create a sub-license while creating a new studio. ' +
						'<p/><img src="css/images/changelog/publisher-create-studio.png" style="width: 100%"/><p/>' +
						'When viewing a studios information, you can now view and edit their sub license as well. ' +
						'<p/><img src="css/images/changelog/publisher-edit-license.png" style="width: 100%"/><p/>'
				},
				{
					date: '07/08/2021',
					title: 'Publishers can view projects by studio',
					content: 'Along the top bar you will now see an option to filter your projects by studio. ' +
						'You will default to viewing all projects. ' +
						'If you click on the dropdown circled below, you will be able to change which studio you are viewing projects for. ' +
						'<p/><img src="css/images/changelog/publishers-studios.jpg" style="width: 100%"/><p/>'
				}
			]);
		}

		$.merge(changes, [
			{
				date: '11/04/2024',
				title: 'Content Books: Candid Layouts',
				content: 'There is a new type of book you can generate from the Content Books tab: Candid Layouts. ' +
					'You can select the list of layout categories you want to show, including the global category provided by PLIC Books. '
			},
			{
				date: '02/27/2023',
				title: 'New setting to keep large subject names uniform on panel pages',
				content: 'There is a new project setting called "Reduce space between letters in overflowing portrait labels". ' +
					'When this setting is turned on, instead of lowering the font size for large names, first we attempt to remove space between the letters. ' +
					'Extremely large names will still need to be shrunk in order to keep the names from looking too squished together. ' +
					'You can turn this setting on one at a time under Project Settings or turn it on for all of your Books at once under Studio/Lab Settings. ' +
					'In the example below, Edwards goes from being slightly smaller than the rest of the names to being the same size but with slightly less space between the letters. ' +
					'Switzerr instead went from being quite a bit smaller to being more squished together.  ' +
					'Under Project Settings -> Advanced -> "Max portrait space reduction %" you can control how squished together the names are allowed to be. ' +
					'It defaults to 20%.  If you set it to 100% all names will be squished together instead of being shrunk. ' +
					'<p/><img src="css/images/changelog/shrink-cell-whitespace.jpg" style="width: 100%"/><p/>'
			},
			{
				date: '02/15/2023',
				title: 'Custom page descriptions now show up above the page. ',
				content: 'When looking at the Book Preview, you have an option to give a short description for each page. ' +
					'This description now shows up in a small green label in the corner of each page. ' +
					'These labels can be used to help organize your design of the Book. '
			},
			{
				date: '01/30/2023',
				title: 'Toolbar location options',
				content: 'When desiging your Book, you can now change where the toolbar is opened from My Settings -> Toolbar Location. ' +
					'The default remains to popup above what you have selected (Floating above selection). ' +
					'There are two new options you can choose from to better control where the toolbar opens at: "Above Page" and "Page Side". ' +
					'<p/><img src="css/images/changelog/toolbar-location.png" style="width: 100%"/><p/>'
			},
			{
				date: '01/10/2023',
				title: 'Album folders',
				content: 'Image albums can now be grouped into folders. ' +
					'Customers can organize their candid albums into folders for use in their Book. ' +
					'As a studio you can organize your clip art into folders that will show in a sub folder in the Clip Art folder. ' +
					'You can also organize Background albums into folders as well. ' +
					'When creating or editing an album, you will have option called "Folder Name" where you can set the folder this album should appear under. ' +
					'When left as the default of "None", the album will appear as a top level album like before. ' +
					'<p/><img src="css/images/changelog/candid-folders.png" style="width: 100%"/><p/>'
			},
			{
				date: '11/16/2022',
				title: 'Locking content on a page',
				content: 'You can now lock images and text nodes in a page so they cannot be accidentally moved around. ' +
					'If a Studio/Lab user locks content on a page, a school user will not be able to unlock or moved it around. ' +
					'Other Studio/Lab users will be able to unlock it in order to move it around. ' +
					'If a School Advisor locks a page, a user with role User will not be able to unlock it, but other School Advisors and studio level users will. ' +
					'<p/><img src="css/images/changelog/lock-content-on-page.png" style="width: 100%"/><p/>'
			},
			{
				date: '09/29/2022',
				title: 'Two page background spreads',
				content: 'If you drag a two page background spread onto a page, it will now apply half to each side of the spread automatically. '
			},
			{
				date: '09/07/2022',
				title: 'Re-Using a book as a template',
				content: 'There are two new options from the book project dropdown: Create Template and Apply Template. ' +
					'You can now take a book you have setup the way you like and create a template from it.  You can then apply that template to other book projects. ' +
					'This template copies the pages from your book without that book\'s specific candids and subjects so another school can keep the same general look and feel but customize it how they want. ' +
					'These menu options are available only at the studio/lab level and not accessible to school users. ' +
					'<p/><img src="css/images/changelog/book-template.png" style="height: 30em"/><p/>'
			},
			{
				date: '08/08/2022',
				title: 'Single guest candid album link',
				content: 'Now your schools can send out a single guest candid album link and the parents can select which album they want to send photos to. ' +
					'When approving the photos, School Advisors can continue to override the suggested album and send them to a different album. ' +
					'They can also continue to send out one link per album if they want. '
			},
			{
				date: '08/02/2022',
				title: 'Cropping backgrounds',
				content: 'You can now crop backgrounds if they are uploaded with the wrong dimensions. ' +
					'You will get a warning if the background you are using is too low resolution or the wrong aspect ratio. ' +
					'You can crop backgrounds from the orange gear icon in the corner of the page and then selecting the Backgrounds option. ' +
					'Backgrounds which are part of a book theme cannot be cropped at this time. ' +
					'Studio/Lab users can change the recommended background DPI from the new setting: "Minimum background DPI before warning"'
			},
			{
				date: '06/20/2022',
				title: 'Editing collage layouts',
				content: 'How you interact with our random collage layouts has been updated. ' +
					'You can drag to swap images in a collage around. ' +
					'When resizing images, the entire layout is updated to make room for it. ' +
					'When deleting an image from the layout, it will turn back into a placeholder unless you click remove a second time. ' +
					'Please note that these options only apply to new collages created or shuffled going forward. '
			},
			{
				date: '03/07/2022',
				title: 'New Collage Options',
				list: [
					'Option to add space for the page number at the bottom (on by default)',
					'Option to add a title block at the top of the collage page',
					'Option to set candid stroke and drop shadow for all images at once',
					'You can now set negative padding to get overlap between the images',
					'Selecting candids to use now obeys the "Show not in Book" toggle from the sidebar'
				]
			},
			{
				date: '10/12/2021',
				title: 'Hard rotating candid and subject images',
				content: 'Sometimes customers upload images which are rotated incorrectly. ' +
					'You can can still rotate the images in the Book to fix them. ' +
					'But now from the Candids tab you can also correct the rotation before you drop them into a Book. ' +
					'Click on the yellow Edit tags button, and underneath the image will be an option to rotate the image left or right. ' +
					'After clicking ok, the image will show up with the correct orientation. ' +
					'You can also rotate subject images from the cropping screen in Subject Management. ' +
					'<p/><img src="css/images/changelog/hard-rotate-candids.jpg" style="width: 100%"/><p/>'
			},
			{
				date: '09/21/2021',
				title: 'Default not pictured image',
				content: 'Subjects can now be added without uploading a photo, and will use the default Not Pictured image. ' +
					'Subjects without an image will by default show up in the Trash until added to a batch. ' +
					'If you want to use a custom Not Pictured image, you can continue to upload a different one when creating subjects. ' +
					'Subjects without images will continue to show up on the Quality Report so that they can be double checked before submitting their Books. '
			},
			{
				date: '08/23/2021',
				title: 'Spellcheck right click menu',
				content: 'Now when you right click on a misspelled word, you will now get a different menu with our spelling suggestions. ' +
					'You will also be able to select "Add to dictionary" which will add the word to the book\'s dictionary. ' +
					'Everyone who works on that book will share a common dictionary so that it only needs to be added once. ' +
					'You can view the words added to your book\'s dictionary from the sidebar menu -> Project Management -> School Dictionary. ' +
					'If there are common words you are seeing multiple times, you can go into Studio/Lab Settings -> Custom Dictionary to add words for all of your schools. '
			},
			{
				date: '08/11/2021',
				title: 'Default class layout',
				content: 'You can now change the default class layout from Studio/Lab Settings. ' +
					'The starting default will remain as the two lines under layout. ' +
					'You can change the default to any of the standard class layouts. ' +
					'Next time one of your customers goes to start a new class page, it will use this layout unless they explicitly change it.'
			},
			{
				date: '07/26/2021',
				title: 'Denied candids',
				content: 'From the Candid Photos tab, when you deny candids awaiting approval, they will now be moved to a denied candids album instead of being deleted. ' +
					'If you want to undo some denied candids, you can click on the yellow View Denied Candids button, and then click on the Undo denied candids button. ' +
					'This will put the candids back into the awaiting approval album for you to look over and later approve. ' +
					'Note that this will only apply to candids denied going forward, and not previously denied candids. '
			},
			{
				date: '07/06/2021',
				title: 'Flyers',
				content: 'You and your customers can now create and print flyers directly from within PLIC Books. ' +
					'There is a new sub-tab for Flyers on both the School Layouts and Studio Layout tab. ' +
					'You can create both generic flyers and special flyers for the Books Store and the guest candids uploader. ' +
					'Special flyers will have the link to the Store or uploader available as content types and can be embedded into QR codes. ' +
					'The Store flyer can be printed directly from the Orders tab and the guest candid uploader flyer can be printed directly from the Candids tab. ' +
					'For more information about how to use flyers, check out <a href="https://iqplsupport.com/en/articles/5360574-plic-books-flyers" target="_blank">this article</a>' +
					'<p/><img src="css/images/changelog/flyers.jpg" style="width: 100%"/><p/>'
			},
			{
				date: '06/16/2021',
				title: 'Duplicating pages',
				content: 'You can now duplicate a page when hovering over it in the Book Preview from the Composer. ' +
					'This option will only show up if you are under your page limit. '
			},
			{
				date: '06/15/2021',
				title: 'Selecting content books',
				content: 'You can now select what you want printed in your Content Books. ' +
					'By default everything is selected, but you can pick and choose which content to include. ' +
					'You can also easily switch between Elementary and High School content via a Project Type dropdown at the top. ' +
					'<p/><img src="css/images/changelog/content-book-selection.png" style="width: 100%"/><p/>'
			},
			{
				date: '03/03/2021',
				title: 'Uploading PDFs',
				content: 'You can now upload a PDF and it will automatically be converted to an image for use in your Book.'
			},
			{
				date: '01/11/2021',
				title: 'New subject uploader',
				content: 'When you go to upload subjects, you will be using a new uploader. ' +
					'This new uploader features a brand new subject mapping screen and other key enhancements. ' +
					'While mapping fields to columns in your data CSV, you can now see all rows in a table as well as the image which it is mapped to. ' +
					'If there are multiple images for a single subject, they are grouped together in the preview table. ' +
					'If there are subjects missing an image, they are listed in a warning before continuing to upload. ' +
					'Automatic mapping of fields has been improved to read from a PSPA CD\'s ReadMe.txt file as well as auto detecting header lines. ' +
					'You can also create new subject fields while mapping data before uploading. ' +
					'<p/><img src="css/images/changelog/new-subject-uploader.png" style="width: 100%"/><p/>'
			},
			{
				date: '11/23/2020',
				title: 'New uploader and Instagram integration',
				content: 'For a while we have been rolling out a new uploader to the Guest Uploader. ' +
					'This new uploader has some major improvements such as being able to preview the images you selected and uploading from Google Drive. ' +
					'We are now bringing this same uploader to the Candids tab and when uploading images directly from the Composer. '	+
					'In addition, you can now upload from Instagram as well. ' +
					'<p/><img src="css/images/changelog/new-uploader.png" style="width: 100%"/><p/>'
			},
			{
				date: '12/06/2018',
				title: 'Limit customers deleting pages',
				content: 'School Advisors and below no longer have the ability to delete pages from a Book unless it is over their page limit. ' +
					'This is to prevent customers from ending up with smaller Books than they were supposed to have. ' + 
					'Yearbook Representative users and above still have the ability to delete pages from full Books. ' +
					'School users still have the ability to replace any page with a different one at any time by dragging a new layout onto the page.'
			},
			{
				date: '10/08/2018',
				title: 'Material Design',
				content: 'PLIC Books has been redesigned with a new Material Design look and feel! ' +
					'Everything should still be familiar with your tabs on the left side and your content on the right.' +
					'<p/><img src="css/images/changelog/material-drawer-management.png"/><p/>'
			}
		]);
	} else if($.userPermissions.allowViewing) {
		$.merge(changes, [
			{
				date: '08/02/2022',
				title: 'Cropping backgrounds',
				content: 'You can now crop backgrounds if they are uploaded with the wrong dimensions. ' +
					'You will get a warning if the background you are using is too low resolution or the wrong aspect ratio. ' +
					'You can crop backgrounds from the orange gear icon in the corner of the page and then selecting the Backgrounds option. ' +
					'Backgrounds which are part of a book theme cannot be cropped at this time. '
			},
			{
				date: '08/23/2021',
				title: 'Spellcheck right click menu',
				content: 'Now when you right click on a misspelled word, you will now get a different menu with our spelling suggestions. ' +
					'You will also be able to select "Add to dictionary" which will add the word to the book\'s dictionary. ' +
					'Everyone who works on that book will share a common dictionary so that it only needs to be added once. ' +
					'You can view the words added to your book\'s dictionary from the sidebar menu -> Project Management -> School Dictionary. '
			}
		]);
	}

	if($.userPermissions.manageCustomers) {
		$.merge(changes, [
			{
				date: '01/31/2022',
				title: 'New Advisor Dashboard',
				content: 'The screen your School Advisors see when they first login has changed. ' +
					'The message you have set to display under Studio/Lab Settings -> "Customer Dashboard Message" is bigger and now supports multiple paragraphs. ' +
					'They can also see their Book status, deadlines, and basic store information (if setup) directly from the Dashboard. ' +
					'They can also click Go To Book directly from within the tab to make getting into their Book simpler. ' +
					'<p/><img src="css/images/changelog/advisor-dashboard.png" style="width: 100%"/><p/>'
			}
		]);
	} else if($.userPermissions.accessDashboard) {
		$.merge(changes, [
			{
				date: '01/31/2022',
				title: 'New Dashboard',
				content: 'The screen you see when you first login has changed. ' +
					'You can now see Book status, deadlines, and basic store information (if setup) directly from your Dashboard. ' +
					'You can also click Go To Book directly from within the tab to make getting to your Book design simpler. ' +
					'<p/><img src="css/images/changelog/advisor-dashboard.png" style="width: 100%"/><p/>'
			}
		]);
	}

	if($.userPermissions.manageCustomers && $.isManager) {
		if($.userPermissions['generateYearbookProof']) {
			changes.push({
				date: '02/26/2018',
				title: 'Generate proof option',
				content: 'An option from the Book Preview dialog has been added for Studios to generate proofs.  The proof will be a high resolution, watermarked PDF and will be emailed to the user who generated it.' +
					'<p/><img src="css/images/changelog/generate-proof-button.png" style="height: 20em"/><p/>'
			});
		}

		if($.userPermissions.editStudioSettings) {
			if($.globalData && $.globalData.merchantAccountType == 'stripe') {
				$.merge(changes, [
					{
						date: '02/04/2025',
						title: 'Store: Default Add-Ons',
						content: 'Under Studio/Lab Settings -> Store Settings you can now setup default add-ons. ' +
							'You can change the default labels and prices for each basic add-on type. ' +
							'You can also set add-ons to be enabled for new stores by default. ' +
							'In addition, you can now set a last purchase date for add-ons so you can be sure to fulfill personalizations in a timely manner. '
					},
					{
						date: '01/14/2025',
						title: 'Store: Limit Ad Sales',
						content: 'There is a new setting to limit how many Ad pages to sell in the Yearbook Store. ' +
							'Use this setting to make sure you do not sell more Ads then you have space for! '
					},
					{
						date: '01/06/2025',
						title: 'New setting to include subjects without images in batches by default',
						content: 'There is a new project setting called "Include Subjects Without Images By Default". ' +
							'Some customers want students without a picture included in the Book by default. ' +
							'For these customers, you can turn this option on and then re-filtering or uploading new subjects into the project will sort them into the yearbook automatically. ' +
							'Please note that turning this setting on will NOT automatically sort already uploaded subjects into the existing batches without re-filtering the project. ' +
							'If you want to turn this on for all of your projects by default, there is also a Studio/Lab Setting with the same name you can turn on. '
					},
					{
						date: '11/18/2024',
						title: 'Reports: Job Order Summary',
						content: 'There are two new reports: "Job Order Summary" and "Job Store Order Summary". ' +
							'The first one shows a summary of all orders including cash orders, and the second one only includes orders placed through the Store. ' +
							'Both reports will show one line per project in the currently selected seaason. ' +
							'You can view the order count, totals, and total minus fees by default. ' +
							'You can also drill down into the merchant fee totals, shipping fees, taxes, and handling fees for each job.'
					},
					{
						date: '09/19/2024',
						title: 'Users with the User role no longer can delete photos by default',
						content: 'Users with the User role can no longer delete candid photos by default. ' +
							'There is an option under Studio/Lab Settings -> "User Role Can Delete Photos" that can be checked to go back to allowing them to delete photos. ' +
							'School Advisors continue to be able to delete candid photos like before. '
					},
					{
						date: '09/03/2024',
						title: 'Store: Org default handling fee',
						content: 'Under Studio/Lab Settings -> Store Settings you can now set a default handling fee and label. ' +
							'This setting will change the default values when first setting up each store. '
					},
					{
						date: '02/26/2024',
						title: 'Store: New options for the 23-24 sales season',
						list: [
							'You can now set add-ons to be required and included with each yearbook sale. Required add-ons can be set to be free or still have a price associated with them. ',
							'Handling fees can now be setup as a flat fee or continue to be a percentage of the sale. ',
							'The message shown to customers when past the last purchase date can now be customized on a studio wide basis or per school. ',
							'Customers can set a new grade or teacher if the one they are looking for is not in the dropdown. ',
							'Customers can now enter the 6 character access codes at store.plicbooks.com to get to their school\'s store page. ' +
								'The access code is now shown next to the short urls on the Orders tab. '
						]
					},
					{
						date: '10/31/2023',
						title: 'Store: New customizable labels',
						list: [
							'Checkout Buyer: During checkout you can change the label asking who is purchasing the Book from student to a custom title under Book Checkout Personal Info. ',
							'Handling Fee: The label for the handling fee can be customized under Taxes and Fees.',
							'Add-On Notes: The label for gathering customer notes can be changed from the default of "Type your personalization notes here".'
						]
					},
					{
						date: '10/30/2023',
						title: 'Users tab: "Show Store Store Designer" toggle',
						content: 'On the Users tab, there is now a toggle near the search bar called "Show Store Store Designer". ' +
							'If you toggle this off, then all of the users with the role "Store Designer" who have purchased Ads or Personalizations will be hidden. ' +
							'The toggle defaults to on. '
					},
					{
						date: '10/12/2022',
						title: 'Books Store: Custom checkout fields',
						content: 'You can now setup different fields to be collected during checkout in the Books Store. ' + 
							'First Name and Last Name are required fields. ' +
							'Teacher and Grade can be customized or removed altogether. ' +
							'You can also control whether these custom fields are required or optional during checkout. ' +
							'You can use this to collect additional information such as Name on Cover if you have it included in the price of the Book. ' +
							'There are separate fields for Books vs Ads sales so you can collect student information for Book sales and business information for Ad sales. ' +
							'The defaults remain as First Name, Last Name, Teacher, and Grade as required fields for Books sales and First/Last Name as required and Teacher/Grade optional for Ad sales. ' +
							'<p/><img src="css/images/changelog/custom-store-fields.png" style="width: 100%"/>' +
							'<p/><img src="css/images/changelog/custom-store-fields-checkout.png" style="width: 100%"/>'
					},
					{
						date: '10/10/2022',
						title: 'Books Store: Ad Templates',
						content: 'In the Studio Layouts tab, there is a new sub tab for Ad Templates. ' +
							'From here, you can design templates for each Ad size. ' +
							'When your customers are designing their Ads, they will have a new tab called Layouts where they can drag one of your pre-built templates onto the page. ' +
							'You can also mark one template of each size as the default template. ' +
							'When a customer goes to start work on their Ad for the first time, this default will automatically be applied to their Ad so they can start work immediately. ' +
							'If you have already designed an Ad Template, it remains as the default template and will show up in this new tab. ' + 
							'Additionally, Ads also now support backgrounds. '
					},
					{
						date: '09/22/2022',
						title: 'Books Store: Reports',
						content: 'There is a new tab on the sidebar called Reports. ' +
							'It can be used to generate reports for orders across all of your projects. ' +
							'You can filter orders based on the past x days or by an explicit date range. ' +
							'The default is to show the past 30 days. ' +
							'The default report called "All Order Summaries" and will include both cash and online Store orders. ' +
							'If you only want to see a report of online orders, use the report called "Store Order Summaries" or "Store Order Details". ' +
							'Order Summary reports show one line per payment from a customer. ' +
							'Order Detail reports show one line per line item ordered, and will break down into yearbook vs ad sales. ' +
							'You can view more information on how to use reports from this <a class="ui primary tiny button" target="_blank" href="https://iqplsupport.com/en/articles/6578360-plic-books-reporting">Article</a>' +
							'<p/><img src="css/images/changelog/reports.png" style="width: 100%"/>'
					},
					{
						date: '12/22/2021',
						title: 'Books Store: Seprate book vs ad sales',
						content: 'There is a new setting in the Ads section called "Separate Ad Sales Page". ' +
							'When enabled, you will have the option to send out different Store links to your customers. ' +
							'Instead of one Store link, you will be given the option to choose from 3. ' +
							'One link is like before and will allow customers to purchase both Books and ads. ' +
							'One link will be for Book only sales. ' +
							'The last link will be for Ad only sales. ' +
							'For example, you can now give an Ad only link to businesses, the ad and books sales link to seniors, and the book only link to everyone else. ' +
							'If you do not want to control who can buy what, no action is required on your part. '
					},
					{
						date: '12/01/2021',
						title: 'Books Store: Settings broken down into sections',
						content: 'Setting Store settings has been broken down into sections for easier management. ' +
							'<p/><img src="css/images/changelog/books-store-sections.png" style="width: 100%"/>'
					},
					{
						date: '11/03/2021',
						title: 'Books Store: Soft Cover vs Hard Cover sales',
						content: 'Orders now track whether it is for a soft cover vs hard cover sale. ' +
							'If you sell Hard Cover as an add-on, please set the Add-On type to Hard Cover. ' +
							'This will allow us to show you how many of each type of Book were purchased. ' +
							'School users can also set Soft Cover vs Hard Cover for manual cash orders. ' +
							'You can set the default binding type for each project under Project Settings -> Default Binding Type. '
					},
					{
						date: '09/27/2021',
						title: 'Books Store: Ad and Personalized page deadlines',
						content: 'In a project\'s Store Settings, there is a new option called "User Design Deadline" at the bottom. ' +
							'When you set this option, users will see a deadline for designing their Ads and Personalized Pages. ' +
							'This warning will be shown both in the Store after they pay and when they login. ' +
							'Email reminders will be sent out 7 days in advance of the deadline and again the day of the deadline for any designs which have not been submitted. ' +
							'If you have a yearbook submission deadline but not a user design deadline, the system will fallback to use the yearbook deadline instead. ' +
							'The separate design deadline should be used to make sure that parents have submitted their designs well before the yearbook is due to be submitted. ' +
							'Customers will not be able to edit or submit their Ads after the deadline unless you increase their deadline. '
					},
					{
						date: '04/06/2021',
						title: 'Books Store: Refunded part of an order',
						content: 'You can now refund a specific line item in an order. ' +
							'After clicking on the info button for an order with more than one line item in it, you will see an option to refund one line item at a time. ' +
							'This can be useful for refunding just an ad when a parent purchased both an ad and a yearbook. ' +
							'It can also be used to refund a single yearbook when a parent accidentally purchased two. ' +
							'A portion of the platform fees, taxes, and handling fees will also be refunded proportional to how much of the order that line item accounted for. ' +
							'If you refund an order with an ad or personalized pages, access to those designs will be removed from the parent. '
					},
					{
						date: '03/30/2021',
						title: 'Books Store: Yearbook checkbox label',
						content: 'There is a new option to change the default yearbook checkbox label. ' +
							'It still defaults to "Yearbook", but can now be changed to something like "Soft Cover Yearbook" if you want to distinguish it from selling hard covers. '
					},
					{
						date: '02/24/2021',
						title: 'Books Store: Reporting',
						content: 'We have made some enhancements to how you can get money information from store orders. ' +
							'On the Orders tab, when you hover over a the Total Paid column, you will now see a popup with information about sub totals and fees paid. ' +
							'When generating a CSV export, there are now columns for Sub Total for that line item, Discounts (ie: Tiered or coupons), Shipping Fee, Handling Fee, and Sales Tax. ' +
							'In addition, the purchase email has been added to the data export. ' +
							'Sub Total, Handling Fee, and Sales Tax have been added to the data exports from the Ads tab as well. '
					},
					{
						date: '02/08/2021',
						title: 'Books Store: Adopt A Book purchases with add-ons',
						content: 'There is now an option to turn on add-on sales with Adopt A Book sales. ' +
							'They are turned off by default.'
					},
					{
						date: '01/15/2021',
						title: 'Books Store: Adopt A Book Program',
						content: 'We are introducing a new Adopt A Book program your schools can participate in. ' +
							'When enabled, parents can opt in to donate a yearbook to a child in need. ' +
							'Adopt a Book purchases will show in the Orders tab and your schools can choose who they get distributed to. ' +
							'To get started, go to the Orders tab and click on the Manage Book Store button. ' + 
							'Next, scroll down to the Adopt A Book Program section and make sure Enabled is checked. ' +
							'<p/><div style="display: flex;">' +
								'<img src="css/images/changelog/adopt-a-book-setup.jpg" style="width: 80%"/>' +
								'<img src="css/images/changelog/adopt-a-book-store.png" style="width: 20%"/>' +
							'</div>'
					},
					{
						date: '12/12/2020',
						title: 'Books Store: Option to allow schools to manage their own Store settings',
						content: 'Under Studio/Lab Settings there is a new option: "Schools Can Manage Store". ' +
							'When enabled, School Advisors will see the Store Settings button on the Orders tab and be able to administer their own settings. ' +
							'They will be able to control the price, cut off dates, sales tax, and anything else you can normally control from here. ' +
							'This setting is turned off by default. '
					},
					{
						date: '12/10/2020',
						title: 'Adding Ads to pages',
						content: 'Flowing Ads onto pages has been improved when there are multiple Ads to put on a page at a time. ' +
							'When there is more than a single Ad of the same size a new option "Flow multiple ads onto page" will be given when placing the Ad on a page. ' +
							'If you select it, other Ads of the same size will be flowed onto the page together. ' +
							'In addition, the student info is shown in the Ad popup so you can better track which Ad should be placed where. '
					},
					{
						date: '12/04/2020',
						title: 'Books Store: Custom Ads descriptions',
						content: 'In the Books Store settings you can now change the description shown for Ad sales. '
					},
					{
						date: '12/03/2020',
						title: 'Ads and personalized pages: Getting started templates',
						content: 'Under Studio/Lab Settings there is a new button called "Ad Templates". ' +
							'After clicking on it, you will see a list of all of the various possible Ad sizes. ' +
							'You can create templates for each Ad size or only the ones your customers are planning on selling. ' +
							'The first time a Store Designer logs in, they will see your template instead of the normal blank space. ' +
							'Similarly, there is now a button called "Personalized Page Template" on the Studio/Lab Settings page that you can click on. ' +
							'Any newly purchased Personalized Pages will start with your template design. ' +
							'<p/><img src="css/images/changelog/ad-templates.png" style="width: 100%"/><p/><br/>' +
							'<strong>NOTE: </strong>The Ads tab now only shows when Ad sales are turned on to prevent confusion for your customers that are not using it. '
					},
					{
						date: '11/24/2020',
						title: 'Free Parent Designed Content Pages',
						content: 'Due to COVID restrictions and for this year only, we are adding a new free way to gather content from parents. ' +
							'Under Project Settings there is a new option called "Free Content Pages".  It defaults to off. ' +
							'When set to one of the various page sizes, a new link will appear in the Ads tab titled "Parent Designer". ' +
							'When anyone goes to that page, they will be able to sign up to design a piece of the yearbook for free. ' +
							'The design they come up with will show up on the Ads tab but will be labeled as a Free Content Design. ' +
							'They can be managed and dropped into the Yearbook the same as Ads are currently done. ' +
							'This new feature can dramatically increase the size of your Yearbooks as parents can design pages that highlight their at home learning. ' +
							'Please note that this new feature is completely optional, only turned on by the studio, and will not give parents any direct access to your yearbook. ' +
							'Documentation about to use this feature can be found at <a href="https://iqplsupport.com/en/articles/4658965-plic-books-parent-designed-free-ad">this link</a>'
					},
					{
						date: '11/12/2020',
						title: 'Books Store: New add-on type to combine personalized pages with names on cover',
						content: 'There are two new add-on types which group personalized pages with names on cover. ' +
							'Use these types if you want to require your customers to have their names on the covers for any orders with personalized pages. '
					},
					{
						date: '11/11/2020',
						title: 'Books Store: Option for handling fee',
						content: 'A new option has been added to allow you to charge a handling fee that shows as a separate line item to your customers. ' +
							'It is setup as a percentage of the sale sub total. ' +
							'Taxes will not be collected on the handling fee. '
					},
					{
						date: '09/22/2020',
						title: 'Personalized cover renders',
						content: 'A new tab for personalization renders has been added for only Yearbook Reps and above. ' +
							'From the Manage Book Store page you can set the add-on type to "Name on cover". ' +
							'<p/><img src="css/images/changelog/personalized-renders-store.jpg" style="height: 20em"/><p/>' +
							'Any orders placed with that add-on will now show up on the Personalizations tab. ' +
							'From here you can render and download the covers with the personalization choices that have been purchased. ' +
							'<p/><img src="css/images/changelog/personalized-renders.png" style="height: 20em"/><p/>'
					},
					{
						date: '06/10/2020',
						title: 'Books Store: Search for orders by order #',
						content: 'To help with customer service, you can now search for orders by order id or #. ' +
							'On the Orders tab there is a new search button that you can use to search for orders. ' +
							'This will search for a matching order among all of your customer\'s orders. ' +
							'<p/><img src="css/images/changelog/books-store-search-order.png" style="width: 100%"/>'
					},
					{
						date: '05/18/2020',
						title: 'Changing school logos from Books',
						content: 'If you want to change a school logo that shows in the Book Store, you can now do it directly from within PLIC Books. ' +
							'From the Yearbooks tab, click on one of the blue organization names to the left. ' +
							'From there you can view the current logo and update it.'
					},
					{
						date: '05/06/2020',
						title: 'Books Store: Reporting changes',
						content: 'From the orders tab, only book sales will be included in the displayed line items. ' +
							'Exporting the orders as CSV or to print now only includes the displayed orders. ' +
							'If you are filtering to Paid orders, it will only include paid orders. ' +
							'If you are filtering to Refunded orders, it will only include refunded orders. ' +
							'From the Ads tab you can now filter between paid and refunded orders the same as you already could from the Orders tab. ' +
							'You can also export a list of ads and how much people paid for them from the Ads tab now. ' +
							'Tooltips have been added to the Total Collected and Store Totals stats to clarify that they include both book and ad sales. '
					},
					{
						date: '05/05/2020',
						title: 'Books Store: Shipping can now be optional',
						content: 'In the Store settings, you can now control whether shipping is required when direct shipping is enabled. ' +
							'By default when shipping is enabled, it is required. ' +
							'After turning Shipping Required off, your customers will have the option during checkout to pay to have their books shipped to their homes. '
					},
					{
						date: '04/30/2020',
						title: 'Books Store: Order add-on notes and shipping',
						content: 'You can now edit the add-on notes from a customer as well as the shipping information they put in. ' +
							'From the Orders tab, click on the i button to the right of an order. ' +
							'Each line item will have an Edit Info button by it you can use to edit the add-on notes for that part of the order. ' +
							'If you require shipping, then there will be an Edit Info button next to the shipping information to fix any typos there as well.'
					},
					{
						date: '04/13/2020',
						title: 'Books Store: Tiered discounts',
						content: 'In the Books Store settings, there is a new section for tiered discounts based on the number of purchased books. ' +
							'You can set it based on how much of a discount you want to give for a certain number of books purchased. ' +
							'For example: pretend you are selling your yearbooks at $20 each.  If you want buying 2 yearbooks to cost $35, 3 yearbooks to to cost $50, etc... ' +
							'You would put a line for Quantity: 2, Amount Off: 5; Quantity: 3, Amount Off: 10; Quantity 4, Amount Off: 15, etc...'
					},
					{
						date: '04/09/2020',
						title: 'Books Store: Coupons',
						content: 'You can now add coupon codes that your customers can apply to their orders. ' +
							'You can add as many coupons in the Store Settings as you want, but customers are limited to using a single coupon at a time. '
					},
					{
						date: '03/31/2020',
						title: 'Books Store: Optional shipping sales tax',
						content: 'Under Studio/Lab Settings -> Store Settings there is an option to control whether sales tax is charged on shipping fees. ' +
							'It defaults to turned on.'
					},
					{
						date: '03/30/2020',
						title: 'Books Store: Sales tax',
						content: 'You can now charge sales tax in the Books Store. ' +
							'You can set a global percentage from Studio/Lab Settings -> Store Settings or a per school sales tax from the Orders tab. '
					},
					{
						date: '03/23/2020',
						title: 'New ad page sizes',
						content: 'We have added significantly more ad size options.  ' +
							'We have added support for 1/8th, 1/9th, 1/10th, 1/12th, 1/14th, and 1/16th of a page ads. ' +
							'You can turn on only the sizes that you want in the Books Store. '
					},
					{
						date: '01/17/2020',
						title: 'Dashboard stat for Book Stores',
						content: 'On your Dashboard there is now a stat for how many projects have the Books Store enabled in the current season. ' +
							'Click on Book Stores to filter to only projects with the store enabled. ' +
							'On the Yearbooks tab, it will show a green cart indicator for the projects that have the Books Store enabled. ' +
							'You can click on that indicator to go directly to the Orders tab for that Book. ' +
							'<p/><img src="css/images/changelog/books-store-indicator.png" style="height: 30em"/><p/>'
					},
					{
						date: '12/17/2019',
						title: 'Books Store: Minor Ad changes',
						content: 'When purchasing an Ad from the Books Store, the user is now prompted for a student name. ' +
							'Only the first and last names are required so businesses do not have to enter a teacher or grade. ' +
							'If you have the Store setup, you can now create Ads without buying it through the Store. ' +
							'This allows you to create ads for users who paid by cash. You cannot track how much they paid through PLIC Books at this time. '
					},
					{
						date: '09/05/2019',
						title: 'Books Store: Date based pricing',
						content: 'The price of yearbooks can now be set to be dynamic based on the date. ' +
							'From your Store Settings, you can click New Price Rule to add a new date based price. ' +
							'You will then set the price and what the last day is to purchase at that price. ' +
							'<p/><img src="css/images/changelog/store-dynamic-pricing.png" style="height: 20em"/><p/>'
					},
					{
						date: '08/26/2019',
						title: 'Books Store: Autocompleting teachers and grades',
						content: 'After you have uploaded subject data to your projects, customers will be able to select the teacher and/or grade from a dropdown. ' +
							'Before you have subjects uploaded to PLIC Books they will still just have a plain text box. ' +
							'Please note that you have to load the Book in Subject Management or the Composer at least once for old projects to work. ' +
							'<p/><img src="css/images/changelog/store-autocomplete-teacher.png" style="height: 30em"/><p/>'
					},
					{
						date: '07/03/2019',
						title: 'Books Store: Summer Sales!',
						content: 'For those of you who can print and ship individual Books to users, we have added a couple of options. ' +
							'The first is on the Manage Book Store page you can set Direct Shipping to be enabled. ' +
							'You can set a flat fee that the user will be charged per order. ' +
							'When the user goes to checkout they will be asked where they want to ship all of their ordered Books to. ' +
							'The second feature is being able to get a daily summary of all of the new orders emailed to you. ' +
							'Under Studio/Lab Settings, go to Store Settings and enter all of the emails you want to receive this summary under the option "Daily Order Summary Emails". ' +
							'The order summary is sent out around 1 AM PST every night. ' +
							'<p/><img src="css/images/changelog/store-shipping.png" style="height: 25em"/><p/>'
					},
					{
						date: '05/24/2019',
						title: 'Books Store: Maximum Books Sales',
						content: 'There is a new option to specify the maximum number of Books that can be sold. ' +
							'After that many Books have been sold, the Store will automatically be turned off so customers can\'t order more then you have printed. ' +
							'Note that the maximum includes both orders through the Store and manually entered orders from the Orders tab. '
					},
					{
						date: '04/02/2019',
						title: 'Books Store: Edit Subject Data',
						content: 'After orders have been placed through the Books Store, Studios now have the ability to edit the subject name, teacher, and grade. ' +
							'From the order information page, each line item in the order will have a Edit Info button for you to make changes to fix mispelled data. ' +
							'Add-Ons have also been moved to show directly under each Subject on the order information page. ' +
							'<p/><img src="css/images/changelog/store-edit-orders.png" style="height: 30em"/><p/>'
					},
					{
						date: '10/11/2018',
						title: 'Books Store: Multiple students per order',
						content: 'Now when ordering more then one Yearbook at a time, customers will be prompted to fill out multiple students information. ' +
							'On the Orders tab they will have their data broken out into multiple lines so you can see which add-ons go to which student.'
					}
				]);
			} else {
				$.merge(changes, [
					{
						date: '11/03/2021',
						title: 'Orders: Soft Cover vs Hard Cover sales',
						content: 'Orders now track whether it is for a soft cover vs hard cover sale. ' +
							'When creating or editing a order, your customers can now set either Soft Cover or Hard Cover as the binding type. '
					}
				]);
			}

			if($.plicLicenseFeatures && $.plicLicenseFeatures['parent-subject-uploader']) {
				$.merge(changes, [
					{
						date: '03/26/2021',
						title: 'Parent Subject Uploader: Turning link off',
						content: 'Now when the project setting for the Parent Subject Uploader is turned off, customers will not be able to use the link anymore. ' +
							'Schools will be able to view existing subjects waiting for verification if there are any still not approved or denied, but the link will not be there anymore. ' +
							'<p/><img src="css/images/changelog/parent-subject-uploader-disabling.png" style="width: 100%"/><p/>'
					},
					{
						date: '02/03/2021',
						title: 'Parent Subject Uploader: Review denied subjects',
						content: 'There is now a button when viewing parent uploaded subjects to review previously denied subjects. ' +
							'You can review and approve them if you did not mean to deny them originally. '
					},
					{
						date: '10/21/2020',
						title: 'Parent Subject Uploader: Optional or required email/phone numbers',
						content: 'Email and phone number is as an optional field for the parent subject uploader. ' +
							'There is also now a Project Setting to make the email or phone number a required field when parent\'s upload their kids photos. '
					},
					{
						date: '09/30/2020',
						title: 'Parent Subject Uploader: Turn off and on per project',
						content: 'There is now a Studio/Lab Setting to control whether this feature defaults to on or off for all of your projects (defaults to on). ' +
							'Next, in Project Settings you can toggle the parent subject uploader on and off for each project individually.'
					}
				]);
			}
			
			$.merge(changes, [
				{
					date: '02/05/2025',
					title: 'Subject Management: Mark subjects in review as correct',
					content: 'For some subject issues that you are prompted to review, you can now mark them as correct. ' +
						'Issues such as duplicate names, a name of test, or not having a photo can be marked as fine so you will not be prompted to correct them anymore. ' +
						'Other issues such as low resolution or the wrong aspect ratio cannot be marked as correct. ' +
						'In order to mark issues as correct, hover over the red exclamation mark on a subject and click on the checkbox in the resulting popup. ' +
						'From the QA Report, you can also now mark simple spelling errors as correct. ' +
						'This will be particularly useful for name spelling errors so you can narrow down the list of results down to only the real issues you still need to correct. ' +
						'<p/><img src="css/images/changelog/subject-review-button.png" style="max-height: 24em"/>'
				},
				{
					date: '09/23/2024',
					title: 'School Advisors can now edit whether page numbers are hidden in their Books',
					content: 'There has long been a Project Setting called "Hide page numbers in yearbook" that only the studio could set. ' +
						'We have added a section for Project Settings on the Composer under the Project Management menu option. ' +
						'School Advisors can now go into there to configure whether page numbers are shown in their yearbook without needing to reach out to the studio. ' +
						'<p/><img src="css/images/changelog/composer-project-settings.png" style="width: 100%"/>'
				},
				{
					date: '07/22/2024',
					title: 'Deleting old Book Formats',
					content: 'You can now delete old Book Formats that you do not want anymore under Studio/Lab Settings -> Book Formats.  ' +
						'Deleting a Book Format already in use will not change existing projects. '
				},
				{
					date: '10/24/2023',
					title: 'Subject Management: Editing primary pose',
					content: 'You can now change between multiple subject poses from Subject Management. ' +
						'When editing a subject, there will be a new button to select the pose if the subject has multiple poses to select from. ' +
						'<p/><img src="css/images/changelog/subject-management-pose-selection.png" style="max-height: 24em;"/>'
				},
				{
					date: '09/12/2023',
					title: 'Setting book deadline when creating project',
					content: 'You can now set the book deadline when creating a new project from the Yearbook Settings sub tab. ' +
						'It defaults to blank and you can still create a yearbook without specifying a deadline. ' +
						'In addition, there is a new Studio/Lab Setting called "Default Book Deadline. ' +
						'If most of your books share a common deadline, you can set this once so you do not need to set it for every book you create. '
				},
				{
					date: '08/28/2023',
					title: 'Saving class layouts',
					content: 'You can now save how you setup your class layout for re-use later. ' +
						'Save Layout will show as an option for class pages when clicking on the orange gear in the outer corner of a page. ' +
						'These saved layouts will show up under a new category in the left sidebar Class Layouts -> Your Class Layouts after you create one. ' +
						'Applying these layouts to a class page is as simple as dragging it onto the page. ' +
						'You can rename or delete these class layouts from the Class Layouts subtab of the School Layouts management tab. ' +
						'<p/><img src="css/images/changelog/save-class-layout.png" style="height: 20em;"/>'
				},
				{
					date: '04/04/2022',
					title: 'Custom project templates',
					content: 'Under Studio/Lab Settings, there is a new button along the top called "Project Templates". ' +
						'From here you can configure which fields are editable under Subject Management based on project type. ' +
						'By default one template is created for Yearbooks, and separate ones are created for Sports Book and Church Directory books. ' +
						'You can set different fields to be editable for different types of yearbooks. ' +
						'You can also create new fields for each project type so you do not have to create them each time you are uploading subjects into the system. ' +
						'For more information, check out <a href="https://iqplsupport.com/en/articles/6102067-plic-books-templates" target="_blank">this article</a>.'
				},
				{
					date: '09/28/2021',
					title: 'Bulk changing a project\'s PLIC app list',
					content: 'Under Project Settings you can control which PLIC apps are able to see this project. ' +
						'You can now set projects in bulk from the Advanced sub-menu on the Composites tab. ' +
						'This can be useful for hiding Flow projects from apps which do not need to see them. ' +
						'<p/><img src="css/images/changelog/bulk-edit-project-apps.jpg" style="width: 100%;"/>'
				},
				{
					date: '09/07/2021',
					title: 'Multiple covers',
					content: 'You can now set your Book to have more than one cover. ' +
						'You can set this up by going to Project Settings -> Alternative Cover Formats. ' +
						'You can choose from any of the Book Formats you already have setup. ' +
						'After you have set an additional cover format, your customers will see new options under the cover options menu to edit the other covers. ' +
						'When they go to submit, the other covers will be rendered at the same time and emailed over for to you download and print. ' +
						'<p/><img src="css/images/changelog/multiple-covers-starting.jpg" style="height: 40em"/><p/>'
				},
				{
					date: '04/22/2021',
					title: 'Studio/Lab Setting: Show bleed/safe space color in preview renders',
					content: 'There is a new option to view the preview/proof PDF with the bleed and safe space colored the same as it is when they are designing the Book. ' +
						'This option is off by default. The setting is called "Preview Render Bleed Color" in the Studio/Lab Settings tab. '
				},
				{
					date: '04/20/2021',
					title: 'Manual orders with shipping',
					content: 'You can now specify shipping information for manual orders. '
				},
				{
					date: '04/15/2021',
					title: 'Bleed vs safe space color',
					content: 'You can now have the safe space in a book displayed in a different color than the bleed. ' +
						'You will find the color options under Studio/Lab Settings. '
				},
				{
					date: '12/07/2020',
					title: 'Send welcome back emails to users',
					content: 'You can now send welcome back emails for your School Advisors from the actions dropdown on the Users tab. ' +
						'This can be used to let your customers know that they can start working on their new yearbook project. '
				},
				{
					date: '10/20/2020',
					title: 'Personalized page sales',
					content: 'The Books Store now supports selling personalized pages to parents! ' +
						'This will only show as an option if you have the PLIC Books Store setup already. ' +
						'From the Orders tab you can go into Manage Book Store and then set the add-on type to "2 Personalized pages" or "4 Personalized pages". ' +
						'<p/><img src="css/images/changelog/personalized-page-setup.png" style="width: 100%"/><p/>' +
						'When someone orders that add-on, they will be prompted to join PLIC Books as a Store Designer user role to design their pages. ' +
						'When they are done with their pages, they can click Finalized and Submit to lock them. ' +
						'The deadline shown will be the same deadline given to School Advisor\'s to finish designing their Book. ' +
						'<p/><img src="css/images/changelog/personalized-page-composer.png" style="width: 100%"/><p/>' +
						'You as a Studio can view, manage, and render purchased personalizations from the Personalizations tab (only shows after setting up the Books Store). ' +
						'The personalized pages will be rendered with the page number at the end of the Book. ' +
						'For example, in a 40 page Book the personalized pages will render with the page numbers 41 and 42. ' +
						'<p/><img src="css/images/changelog/personalized-page-tab.png" style="width: 100%"/><p/>' +
						'NOTE: The Ad Creator user role has been renamed to Store Designer since it is used for both ads and personalized page designs now. '
				},
				{
					date: '08/31/2020',
					title: 'Organization specific Google fonts',
					content: 'Under Studio/Lab Settings, you can now add Google fonts for just your own organization. ' +
						'These fonts will show up as choices on the Composer when desigining your composites or layouts.'
				},
				{
					date: '07/15/2020',
					title: 'Candid layouts shown in a grid',
					content: 'The Studio and School Layouts tabs now show layouts in a grid. ' +
						'Before you could only preview one candid layout at a time. ' +
						'Now you can see up to 15 at a time in a single category. ' +
						'<p/><img src="css/images/changelog/candid-layouts-tab.png" style="width: 100%"/><p/>'
				},
				{
					date: '07/13/2020',
					title: 'Setting project types',
					content: 'In Project Settings you can now set the project type to Elementary/High School Yearbook or Church Directory. ' +
						'You can set your Themes, Clip Art, and Background albums to be for only specific project types. ' +
						'This will allow you to show certain images as options to only your Elementary or only your High School customers. ' +
						'By default they will show for all of your projects. ' +
						'All existing projects default to the "Elementary School Yearbook" type. ' +
						'There were also some church directory specific layouts that were previously shown to all customers. ' +
						'They will now only show if you have set your project to the "Church Directory" type.'
				},
				{
					date: '07/09/2020',
					title: 'Guest Uploader: Uploading from Google Drive',
					content: 'Your customers can now upload photos directly from Google Drive when using the Guest Uploader. ' +
						'Below the Upload your photos button is now a Google Drive button. ' +
						'When clicked, you will be asked to sign in with Google, and then allowed to choose any JPGs or PNGs in your Google Drive folders. ' +
						'<p/><img src="css/images/changelog/guest-uploader-google-drive.jpg" style="width: 100%"/><p/>'
				},
				{
					date: '06/25/2020',
					title: 'Quick resorting batches',
					content: 'If anyone has moved subjects around to not be in their original order, there is now a quick button to resort the batch. ' +
						'In Subject Management, any time the order is not the same as the default order, a button titled "Resort subjects" will appear. ' +
						'Simply click it to get the subjects back in the right order.'
				},
				{
					date: '03/19/2020',
					title: 'Dashboard messages to schools',
					content: 'There is a new setting "Customer Dashboard Message" in Studio/Lab Settings. ' +
						'By default there is no message shown on the dashboard. ' +
						'When you add a message, your School Advisors will see that message when they first login to PLIC Books or view the Dashboard. ' +
						'Users with the role "User" are automatically directed to the Composer and do not see the message. ' +
						'In addition, you can download a list of users by role from the Users tab now. ' +
						'If you need to email all of your School Advisors for example, you can get a list of all of the emails for each of them in one list. ' +
						'<p/><img src="css/images/changelog/dashboard-message.png" style="width: 100%"/><p/>'
				},
				{
					date: '11/25/2019',
					title: 'Hard Covers: Editable spine text',
					content: 'PLIC Books now support spine text for hard covers. ' +
						'In order to get started, go into Studio/Lab Settings and edit your hard cover book format. ' +
						'Near the bottom of the Book Format page is a new option "Allow Text In Spine".  ' +
						'Turn this on and save the book format to get started. ' +
						'After you do that, your schools will be able to put text and images in the spine of the cover pages. ' +
						'The spine area will show as white so school users know they can put stuff there. ' +
						'<p/><img src="css/images/changelog/spine-text.png" style="height: 30em"/><p/>'
				},
				{
					date: '11/12/2019',
					title: 'Studio Setting: Allow schools to upload their own PSPA CDs',
					content: 'There is a new setting to allow schools to upload their own PSPA exports/CDs. ' +
						'The option is labeled "Schools Can Upload PSPA" on the Studio/Lab Settings tab. ' +
						'When this option is enabled, all of your customers will see a yellow "Upload PSPA CD" button on Subject Management. ' +
						'From there they will see the standard subject uploader that studios are used to using. ' +
						'<p/><img src="css/images/changelog/school-pspa-upload.png" style="height: 24em"/><p/>'
				},
				{
					date: '10/07/2019',
					title: 'Bleed/Safe Space Display',
					content: 'How safe space and bleed is displayed has been changed slightly. ' +
						'If you have set the bleed color to something besides the default white, you will now see the bleed show as a darker color then the safe space. ' +
						'If you want to set the bleed to be a different color, go into Studio/Lab Settings and look for the setting called "Bleed Highlight Color". ' +
						'When hovering over the bleed, it will continue to warn the user that content in that area will be cut off. ' +
						'When hovering over the safe space, now it will give a different warning about content possibly being cut off if it gets too close to the edge. ' +
						'<p/><img src="css/images/changelog/bleed-safe-space-guide.png" style="height: 30em"/><p/>'
				},
				{
					date: '08/21/2019',
					title: 'Ad Page Sales',
					content: 'PLIC Books now supports selling ads through the Store! ' +
						'This will only show as an option if you have the PLIC Books Store setup already. ' +
						'From the Orders tab you can go into Manage Book Store and then go to the bottom to turn on ad sales. ' +
						'Ad sales can be turned on while yearbook sales are stilled turned off. ' +
						'<p/><img src="css/images/changelog/ads-store-setup.png" style="height: 12em"/><p/>' +
						'After enabling this you can distribute the store url to your schools the same way you do for yearbook sales. ' +
						'When they go to the store they will see an additional option to purchase ad space in the yearbook. ' +
						'They can buy ads with or without a yearbook. ' +
						'<p/><img src="css/images/changelog/ads-store-purchase.png" style="height: 30em"/><p/>' +
						'Once anyone buys an ad they will get an invite to design their ad in PLIC Books. ' +
						'After they accept their invite, they will be directed into the Composer. ' +
						'Once they are done designing their ad they can click Finalize and Submit at the bottom to be done. ' +
						'<p/><img src="css/images/changelog/ads-designer.png" style="height: 30em"/><p/>' +
						'Both you and the School Advisor will have access to the Ads tab. ' +
						'The Ads tab will only show up when Stripe has been setup so you can use the PLIC Books Store. ' +
						'From the Ads tab you will be able to view any existing ad sales, whether they have been placed in the Book, and order information about the sale. ' +
						'<p/><img src="css/images/changelog/ads-tab.png" style="height: 20em"/><p/>' + 
						'Once an ad has been submitted, School Advisors will get notified that there are ads to place in the Book. ' +
						'From the left sidebar there will be a new dropdown menu for Purchased Ads. ' +
						'From there advisors can simply drop the ad onto any page in the Book that they want. ' +
						'The ads will show whether they have been placed already and on what page they were placed. ' +
						'School Advisors will be strongly warned if they are trying to submit a Book with ads that have not been placed in the Book yet.' +
						'<p/><img src="css/images/changelog/ads-quality-report.png" style="height: 14em"/><p/>'
				},
				{
					date: '12/17/2018',
					title: 'Auto merging Clip Art/Backgrounds albums',
					content: 'If you have your own Clip Art and Backgrounds albums with the exact same name as PLIC Book\'s global albums, they will automatically be merged together. ' +
						'For example, if you have a Clip Art album called "Animals/Insects", then your customers will only see a single album called "Animals/Insects" that will contain all of the images from our album combined with our album.'
				},
				{
					date: '10/09/2017',
					title: 'Content Books',
					content: 'From the Content Books tab you can now generate Content Books to distribute to Schools'
				},
				{
					date: '07/25/2018',
					title: 'Yearbook Store and Orders tab',
					content: 'From your Studio/Lab Settings, you can now enable the PLIC Books Store for your customers. ' + 
						'Just select Store Settings to setup your Stripe account to get started. ' +
						'From this tab you will also be able to see the totals of what you collected from your clients.  ' +
						'If you do not want to show the Orders tab at all, you can disable it from this tab as well. ' +
						'<p/><img src="css/images/changelog/store-studio-settings.png" style="height: 20em"/><p/>' +

						'We are also introducing the Orders tab for customers to manage money collected from parents. ' +
						'Schools can use this tab to manage orders imported from other sites, manually added orders, and orders placed from the Books Store. ' +
						'Schools can also get their unique url to their Books Store from this tab. ' +
						'<p/><img src="css/images/changelog/store-school-dashboard.png" style="height: 20em"/><p/>' +

						'From the Orders tab, Studios are able to setup a School\'s Store. ' +
						'You can specify the price, description, last purchase date, as well as what personalization add-ons are available for purchase. ' +
						'<p/><img src="css/images/changelog/store-school-settings.png" style="height: 20em"/><p/>' +

						'Once your school\'s distribute their Store url, any purchases will go directly to your Stripe accounts. ' +
						'<p/><img src="css/images/changelog/store-front.png" style="height: 20em"/><p/>'
				}
			]);
		}

		if($.UserPlicOrgId && $.UserPlicOrgId == $.PlicOrgId) {
			$.merge(changes, [
				{
					date: '03/13/2019',
					title: 'User App Access',
					content: 'For those using multiple PLIC Apps, you now have the ability to control which users can access which apps. ' +
						'By default users created from PLIC Books will only be able to access PLIC Books. ' +
						'You can give users access to all apps when creating them, or later from the Actions -> Edit Access menu on the Users tab. '
				},
				{
					date: '03/02/2018',
					title: 'App Visibility Settings',
					content: 'For those using multiple PLIC Apps, you now have the ability to control where projects show up. ' +
						'If you want to have a project show up only for Books, or for Books and Elements, or for all your PLIC Apps, the choice is up to you! ' +
						'Go to Project Settings to edit your project\'s visibility.'
				},
				{
					date: '09/19/2017',
					title: 'Book Format Lite UI Options',
					content: 'Under Book Formats, the Lite UI option has been expanded to include all of features you can turn on or off.'
				}
			]);
		}

		if($.userPermissions.productionPDF) {
			changes.push({
				date: '04/05/2018',
				title: 'Render Single Pages',
				content: 'Under page options you now have the ability to render single pages at a time. ' +
					'This is useful for those times where you are making page specific changes and do not want to re-render the entire Book. ' +
					'After the PDF is rendered you will be able to download it from the button in the top right corner. ' +
					'<p/><img src="css/images/changelog/render-single-page.png" style="height: 20em"/>'
			});
		}

		if($.plicLicenseFeatures && $.plicLicenseFeatures['order-management']) {
			changes.push({
				date: '06/08/2018',
				title: 'Subject Orders Improvements',
				list: [
					'Select from list of subjects when creating orders',
					'Deduplicate orders during import so you can import changes from a full CSV',
					'Print orders directly from Orders tab',
					'Added date to orders and allow importing it via the "Date" field in your CSV',
					'Improved data in CSV export'
				]
			});
		}

		if($.plicLicenseFeatures && !$.plicLicenseFeatures['capturelife-story-integration']) {
			$.merge(changes, [
				{
					date: '04/11/2019',
					title: 'Events With Candids',
					content: 'Events can now have an album of candids associated with them.  When creating new events this is turned on by default. ' +
						'Users can use these events to better categorize their candid photos. ' +
						'<p/><img src="css/images/changelog/events-with-candids.png" style="height: 20em"/>' +
						'When you click on View Photos, you can see all of the photos in this album. ' +
						'There is also a guest URL for them to share with others to upload to this event. ' +
						'Anonymously uploaded photos still require advisor approval. ' +
						'All photos uploaded to events show up in normal albums on the Composer for them to use in their Book. ' +
						'<p/><img src="css/images/changelog/events-with-candid-url.png" style="height: 6em"/>'
				}
			]);
		}

		if($.userPermissions.viewSeasons) {
			$.merge(changes, [
				{
					date: '07/31/2019',
					title: 'Season switching',
					content: 'You can now switch between seasons directly from the top menu on the Management screen. ' +
						'If you have multiple seasons the season display will automatically change to show as a dropdown. ' +
						'This option will not show up for your customers. ' +
						'<p/><img src="css/images/changelog/season-picker.jpg" style="height: 20em"/>'
				}
			]);
		}

		$.merge(changes, [
			{
				date: '10/09/2023',
				title: 'View proof of specific pages',
				content: 'There is a new option in the Page Preview dialog that allows viewing the proof in app instead of waiting for an email: View Proof. ' +
					'After clicking View Proof the user will be prompted to enter a page range or leave the default to view every inside page of the Book. ' +
					'Users can enter specific pages like "4-5, 8, 10-13". '
			},
			{
				date: '08/24/2020',
				title: 'School logo on books',
				content: 'If you have uploaded a school logo on PLIC, it is available under content to be used when desiging your books.'
			},
			{
				date: '08/11/2020',
				title: 'Demo projects',
				content: 'There is a new project setting to mark a project as a demo project. ' +
					'Customers cannot render or submit demo projects and they do not take a license until demo mode is turned off. '
			},
			{
				date: '08/03/2020',
				title: 'Locking content in layouts',
				content: 'You can now lock an image or text node such as your logo from being edited when they use one of your layouts. ' +
					'When designing a layout, there is a new toolbar option to lock the item. ' +
					'When a customer drags that layout onto a page, they will not longer be able to edit it or move it. '
			},
			{
				date: '04/16/2020',
				title: 'Rotated image improvements',
				content: 'There are some improvements to handling rotated images. ' +
					'If you have candids that were uploaded into the system rotated, you can now rotate them on their side and they will maintain their position and width/height. ' +
					'This is particularly useful for candids that are on a collage page or dropped onto an existing candid layout. ' +
					'When you go to resize the candid that is now on it\'s side, it will resize correctly. ' +
					'When you go to crop the candid, it will crop the same way it is displayed on the page. ' +
					'Please note that these improvements only work if you have the candid image fully rotated on one side or the other. ' +
					'They will not apply to partially rotated candids. '
			},
			{
				date: '03/12/2020',
				title: 'Subject name font size tweaks',
				content: 'When selecting a subject name on the Composer, you can now change the font size by 2% increments. ' +
					'When you have inconsistent font sizes, try changing them all to -10% or something smaller until they do not need to be resized to fit anymore. ' +
					'If they are all too small but you still have space available for them to be bigger, try increasing them to +10%. ' +
					'Please note that this normally is only changing the size before they are still shrunk to fit the space available. ' +
					'If you are using the "Threshold percentage for automatic text resize" option under advanced Project Settings, then it will allow the labels to go past the edges of the cell. '
			},
			{
				date: '01/15/2020',
				title: 'Layout composer redesign',
				content: 'The layout composer has been redesigned with a new Material Design look and feel! ' +
					'<p/><img src="css/images/changelog/layout-material-composer.png" style="height: 20em"/>'
			},
			{
				date: '01/07/2020',
				title: 'New guest uploader',
				content: 'The guest uploader has been redesigned with more clear instructions on what kinds of files it will accept, ' +
					'and previews of what the user is going to be uploading. ' +
					'When the user tries to select images that are too large or the wrong format, they will be told why they won\'t upload. ' +
					'<p/><img src="css/images/changelog/new-guest-uploader.png" style="height: 20em"/>'
			},
			{
				date: '01/06/2020',
				title: 'Page Assignments',
				content: 'Assigning users to pages has been tweaked to be assigning a user to a specific page instead of to a page number. ' +
					'If the school advisor moves a user\'s pages around, they will still only see the pages they were assigned to. ' + 
					'If the page they are assigned to is removed they will not be able to see it anymore. ' +
					'Example: A user is assigned to pages 12 and 13.  Previously, if another user removed page 8, then the user would be seeing what is now page 12 and 13 but was previously page 13 and 14. ' +
					'Now the user will continue to view what was pages 12 and 13 and is now pages 11 and 12. '
			},
			{
				date: '07/22/2019',
				title: 'Simplified yearbook creation',
				content: 'The previous process of creating a school organization, then creating a project, then creating a school advisor account has been simplified. ' +
					'The Create Organization button has been replaced with Create Studio button if you are a lab and have permission to create studio organizations. ' +
					'The Create Project button has been replaced by a Create Yearbook button. ' +
					'When you click on it, you will be walked through the process of creating a yearbook project. ' +
					'During this process you will be prompted to create a new school organization if one did not exist already. ' +
					'If you are creating a new organization, you will also be prompted to automatically create a user with the school\'s contact info. ' +
					'<p/><img src="css/images/changelog/create-yearbook-wizard.png" style="height: 20em"/>'
			},
			{
				date: '06/21/2019',
				title: 'Limited Yearbook Representative Role',
				content: 'A new role for Yearbook Representatives with limited permissions has been added. ' +
					'A Limited Yearbook Representative user can do everything a School Advisor can do, as well as see the Dashboard and a read-only view of the Yearbooks tab.'
			},
			{
				date: '04/15/2019',
				title: 'Submission Workflow',
				content: 'The submission workflow has been modified to show the results of the Quality Report during submission. ' +
					'The Quality Report can be accessed at any time from the Composer\'s green button in the top right and selecting Quality Report. ' +
					'The results have been built into the submission workflow to help make sure schools catch any potential issues before sending the Book for printing. ' +
					'Currently the Quality Report checks for low resolution images, wrong aspect ratios, and common curse words.'
			},
			{
				date: '04/09/2019',
				title: 'Candid Downloader',
				content: 'When downloading candids from the Candid tab, you can now choose whether to download a single album or all of the albums at once. ' +
					'Clicking on the download button will popup the two choices you can pick from.'
			},
			{
				date: '02/26/2019',
				title: 'Candid Image Info',
				content: 'The Edit tags button for candids has been changed to Image Info & Tags. ' +
					'You can see basic image information such as filename and width & height as well as edit tags from here now. ' +
					'<p/><img src="css/images/changelog/candid-image-info.png" style="height: 20em"/>'
			},
			{
				date: '01/21/2019',
				title: 'Clear Subject Trash',
				content: 'Yearbook Reps and above can now clear the Trash in Subject Management. ' +
					'Since this is a destructive action School Advisors and below do not have permission to delete subjects.' +
					'<p/><img src="css/images/changelog/subject-management-clear-trash.png" style="height: 20em"/>'
			},
			{
				date: '12/10/2018',
				title: 'Duplicate Candid Layouts',
				content: 'Candid Layouts can now be duplicated between Studio Layouts and School Layouts. ' +
					'Click on the copy button to save your School Layout to be in your Studio Layouts and vice versa. ' +
					'<p/><img src="css/images/changelog/save-as-studio-layout.png" style="height: 20em"/><p/>' +
					'When saving Layouts from the Composer, you can now select Save with candids to save the full layout. ' +
					'<p/><img src="css/images/changelog/save-layout-with-candids.png" style="height: 20em"/>'
			},
			{
				date: '11/06/2018',
				title: 'School Content Books',
				content: 'School Advisors can now see the Content Books tab and generate them directly'
			},
			{
				date: '09/20/2018',
				title: 'QA User Role',
				content: 'A new role for Lab/Studio QA has been added.  QA users are able to view, edit, and unlock Yearbooks as part of their QA.  ' +
					'They cannot create projects, change Project Settings, or upload subjects.'
			},
			{
				date: '08/30/2018',
				title: 'Organization/Project Short Codes',
				content: 'Organization and Project now support short codes. ' + 
					'These codes are a maximum of 9 characters, and can be used for the unique identifier you use in your own internal record keeping. ' +
					'When projects or organizations have a short code, it will show at the end of the name in Management.  Short codes are not required.'
			},
			{
				date: '08/28/2018',
				title: 'Subject Green Screen Backgrounds',
				content: 'Green screen projects will now automatically render with the default project background. ' +
					'Subject images must be marked with the chroma key `processed` and the project must have a default project background image set on PLIC.'
			},
			{
				date: '07/27/2018',
				title: 'Per School Themes',
				content: 'You can now create Themes on a per school basis.  The School Themes tab is only visible for Studio/Lab users.  ' + 
					'This functionality will be exposed to School users at a future date.'
			},
			{
				date: '06/07/2018',
				title: '',
				content: 'Your schools can now download all of their candid images directly from the Candids tab. ' +
					'Only School Advisors will have this capability. ' +
					'<p/><img src="css/images/changelog/candids-download.png" style="height: 10em"/>'
			},
			{
				date: '05/29/2018',
				title: 'Black/White Mode',
				content: 'Under Project Settings you can now enable black/white mode to restrict users to exactly what will be printed'
			},
			{
				date: '05/21/2018',
				title: 'Default Clip Art',
				content: 'You can now control which Clip Art album loads by default for your customers.  ' + 
					'On your Clip Art tab under Management, you can click the link button to set that album as the default album to display first.' +
					'<p/><img src="css/images/changelog/set-default-clip-art.png" style="height: 10em"/>'
			},
			{
				date: '05/08/2018',
				title: 'Bleed Changes',
				content: 'In Book Format settings, you can now specify safe space so users do not try to put stuff too close to the edge of the page. ' +
					'Content in the bleed area will be now masked so it is more obvious to users that content in the bleed will be cut off. ' +
					'There is also a reminder tooltip every time they try to hover over the bleed area. ' +
					'<p/><img src="css/images/changelog/bleed-mask.png" style="height: 20em"/>'
			},
			{
				date: '03/22/2018',
				title: 'Home Room Support',
				content: 'For projects with Home Room data and no Teacher data, the Home Room will be shown instead of the Teacher on Subject Management'
			},
			{
				date: '11/27/2017',
				title: 'Subject Photos as Candids',
				content: 'Customers can now use Subject Photos as candids in their Books if you turn it on under Studio/Lab Settings'
			},
			{
				date: '11/10/2017',
				title: 'Subject Management Improvements',
				list: [
					'Filter and sort by multiple fields.  For example, you can now create batches by both the Teacher and the Grade of subjects',
					'View and edit crops directly from Subject Management',
					'Ability to add Grades and Teachers which did not previously exist',
					'Studios can now start creating subjects from scratch.  Note that school users will still be required to wait for subjects to be uploaded first',
					'Customers are given warnings when they are uploading low resolution photos which will not look good'
				]
			},
			{
				date: '10/19/2017',
				title: 'Per Batch Teacher Priority',
				content: 'Subjects can have a different Teacher Priority in each batch now.  You can copy a subject from one batch to another and have them marked as only a teacher in Batch A but not in Batch B.'
			},
			{
				date: '08/18/2017',
				title: 'Sub Accounts for students',
				content: 'When creating users you can now select what type of user account you wish to create. ' +
					'Sub Accounts are for students who do not have an email address to create an account.  These accounts will only exist within PLIC Books. ' +
					'Both your Studio users or School Advisors will be able to create Sub Accounts. '  +
					'Sub Accounts will require the presence of a PLIC School Advisor on the School Organization in order to work. ' +
						'<p/><img src="css/images/changelog/sub-accounts.png" style="height: 20em"/>'
			},
			{
				date: '08/11/2017',
				title: 'Summer Update',
				list: [
					'Global Undo: From the Composer press Ctrl + Z to undo your most recent action.  Press Ctrl + Y to redo.',
					'Subject Images: Can now be cropped and user submitted images will be required to be cropped to an 0.8 aspect ratio.',
					'Candid Layout Designer: Use an easy drag and drop interface to design your custom Candid Templates from the Candid Layouts tab.',
					'Seasons: Edit and delete seasons.',
					'Text Editing: Can now edit Drop Shadow\'s depth and intensity and Stroke\'s width.',
					'Customer Chat Integration: Setup Intercom or Zendesk chat widgets for your customers',
					'More easily see which organization projects belong to.'
				]
			},
			{
				date: '05/04/2017',
				title: 'Quality Assurance',
				content: 'We have made checking the quality of your books easier then ever!  From Subject Management both you and your schools can quickly check to make sure your yearbook photos are up to snuff.' +
					'<p/><img src="css/images/changelog/subject-management-review.jpg" style="height: 20em"/><p/>' +
					'From the Composer subjects and images will be highlighted if we detect any issues which need to be corrected.  We also provide you with a Quality Report in the side tab for you to check that everything in your Book is in tip top shape and ready to print!' +
					'<p/><img src="css/images/changelog/composer-quality-report.png" style="height: 18em"/><p/>' +
					'To ensure you have full control over the rendered quality of your Books the minimum DPI for subjects and candids can be controlled inside of your Studio/Lab Settings.'
			},
			{
				date: '03/27/2017',
				title: 'Book Format Enhancements',
				list: [
					'Per side bleed settings',
					'Candid Layout Categories can now be associated with specific Book Formats',
					'Background Albums can now be associated with specific Book Formats',
					'Themes automatically filter out aspect ratios which don\'t match your Book Format'
				]
			},
			{
				date: '10/24/2016',
				title: 'Book Formats: Variable Spine Widths and Custom Cover Bleed',
				content: 'Under Book Formats settings, you can now define the spine width of a Book as one of:<br>1) A constant value (ex: Always have a spine of 0.1") <br>2) A math formula based on the number of pages (ex: 0.2 + 50 pages / 500 = 0.3") <br>3) Different widths based on how many pages are your Books (ex: 0-40 pages = 0.1", 41-80 pages = 0.2", etc...) <br><br> In addition you can now set a custom bleed size to be used specifically for the cover pages. <br/><br/> Note: The Studio/Lab Setting "Combine cover pages in rendered PDF" must be set for the spine to be used in your Books.'
			},
			{
				date: '10/7/2016',
				title: 'Subject Tagging',
				content: 'You can now tag subjects in candid photos. When viewing candid photos use the yellow button in the bottom right corner which allows you to edit both the regular description tags and tag individual subjects. From the Subject Management tab you can now view all of the candid photos a subject is tagged in. There is also a new role called "Candid Tagger" which is only allowed to edit the tags for Candids.'
			},
			{
				date: '9/26/2016',
				title: 'New and improved Themes',
				content: 'You can now specify how text looks by default for miscellaneous text, subject labels, and class titles on a per Theme basis.  Use these tools to tweak how each of your themes looks and make them into something truly special!'
			},
			{
				date: '9/12/2016',
				title: 'Workflows and custom Book Formats',
				content: 'Workflows are now integrated into every project.  A default workflow called "Yearbook Progress" has been created for everyone which you can customize under Studio/Lab\'s Settings.  You can now customize the dimensions of your yearbooks with Book Formats under Studio/Lab\'s Settings.  You can edit the width, height, bleed, and spine of your Books.'
			},
			{
				date: '8/30/2016',
				title: 'Yearbook Representative',
				content: 'There is a new user role called "Yearbook Representative" which allows you to view and manage multiple schools without being able to access Studio/Lab settings'
			},
			{
				date: '8/22/2016',
				title: 'Get more done in version 2!',
				list: [
					'Multiple users can edit projects at the same time and you will see their changes as they make them!',
					'We have reworked our text and candids to give you the options you need without any of the fuss',
					'In the Events tab users can now use the CaptureLife app to seamlessly take and upload Candids for use in their Books',
					'You can now easily switch which School you are looking at from the top of the Management page',
					'Manage Seasons and which Schools are currently visible from Studo/Lab\'s Settings',
					'Control a page\'s margins on a page by page basis',
					$.userPermissions.subjectOrders ? 'Let your customers track who ordered Books' : null,
					'From the Book Preview, you can now edit the page labels',
					'Books and covers are now submitted as separate PDFs',
					'Tons more subtle tweaks and enhancements!'
				]
			}
		]);

		changes.push({
			date: '11/20/2017',
			title: 'Subject Directories',
			content: 'From Subject Management customers can now generate and download a Subject Directory.  They can use this to print out and have teachers proof their subject data.'
		});
	}

	if($.userPermissions.manageGuestUploads) {
		changes.push({
			date: '02/08/2018',
			title: 'Per Album Guest URLs',
			content: 'The Guest URL functionality has been reworked to allow guests to upload candids for a specific album.  From the Candids tab when you create a Guest URL you are creating it for the currently selected album. ' +
				'After candid photos are anonymously uploaded, you can approve photos and our system will automatically sort them into the correct album. ' +
				'Please note that any candids uploaded from previously generated Guest URLs will still need to be manually sorted into the correct album.'
		});
	}

	if($.userPermissions.productionPDF) {
		if(!$.getStudioSetting('coverLocked', false)) {
			changes.push({
				date: '12/20/2018',
				title: 'Preview Covers',
				content: 'School Advisors now have the ability to preview their cover pages. ' +
					'Studios will be able to render high resolution cover renders and schools will only be able to render previews. ' +
					'Also when doing a high resolution preview from the Zoom button, the cover pages will be shown together for a more immediate view. ' +
					'<p/><img src="css/images/changelog/render-cover-pages_LI.jpg" style="height: 30em"/>'
			});
		}

		changes.push({
			date: '04/10/2018',
			title: 'Preview Single Page',
			content: 'School Advisors now have the ability to preview a single page at a time. ' +
				'Studios will be able to render high resolution pages and School users will only be able to render previews'
		});
	} else if($.userPermissions.allowViewing) {
		if(!$.getStudioSetting('coverLocked', false)) {
			changes.push({
				date: '12/20/2018',
				title: 'Preview Covers',
				content: 'Under page options you now have the ability to preview your cover pages together like they will be printed. ' +
					'Also when doing a high resolution preview from the Zoom button, the cover pages will be shown together for a more immediate view. ' +
					'<p/><img src="css/images/changelog/render-cover-pages_LI.jpg" style="height: 30em"/>'
			});
		}

		changes.push({
			date: '04/10/2018',
			title: 'Preview Single Page',
			content: 'Under page options you now have the ability to preview single pages at a time. ' +
				'<p/><img src="css/images/changelog/render-single-page.png" style="height: 20em"/>'
		});
	}

	if($.userPermissions.assignRoles) {
		$.merge(changes, [
			{
				date: '09/09/2024',
				title: 'Class Titles: Padding',
				content: 'You can now edit the padding for the title block on a class page.  The default remains 0.125 inches on the top and bottom. '
			},
			{
				date: '01/20/2023',
				title: 'Hide Used toggle for the Candids tab',
				content: 'You can now use the Hide Used toggle in the Candids tab as well. ' +
					'If toggled on, all of your candids that have already been unsed in the Book are hidden just like on the Composer.'
			},
			{
				date: '08/10/2020',
				title: 'Change user name',
				content: 'You can now change a user\'s name from the Users tab under the Actions dropdown. '
			},
			{
				date: '08/05/2020',
				title: 'Book snapshots',
				content: 'You can now create and rollback to snapshots of your Book. ' +
					'The Users menu option on the Composer has been renamed to Project Management because it now contains more than just user options. ' +
					'From this dialog a new tab has been added to view and manage your snapshots. ' +
					'By default a new snapshot is created every night if there have been any changes made that day. ' +
					'You can manually create new snapshots whenever you want. ' +
					'Only book data and batches are saved in the snapshots.  Subject names/photos are not part of the snapshot and cannot be rolled back. ' +
					'<p/><img src="css/images/changelog/book-snapshots.png" style="width: 100%"/>'
			},
			{
				date: '04/20/2020',
				title: 'Assigning pages to user from the Composer',
				content: 'After clicking the green button in the top right, there is a new option called Users. ' +
					'From there you can edit user page assignments for this Book. ' +
					'If you hover over the page, you can also see the list of other users that are assigned to each page. ' +
					'<p/><img src="css/images/changelog/composer-user-page-assignments1.jpg" style="height: 20em"/>' +
					'<p/><img src="css/images/changelog/composer-user-page-assignments2.png" style="height: 30em"/>'
			},
			{
				date: '10/01/2018',
				title: 'Assigning pages to user',
				content: 'On the Users tab, a new option for Page Assignments has been added to the Actions menu for school users. ' +
					'From there you can edit the pages a user is assigned to and allowed to edit.' +
					'<p/><img src="css/images/changelog/user-page-assignments.png" style="height: 30em"/>'
			}
		]);
	}

	if($.userPermissions.lockPages) {
		$.merge(changes, [
			{
				date: '06/11/2019',
				title: 'User Locking Restrictions',
				content: 'Up until today anyone and everyone could lock and unlock pages.  ' +
					'Now anyone with the User role and below cannot unlock or lock pages.  ' +
					'School Advisors and above are still able to control the locking of pages without students interfering with them.  ' +
					'There is a new Studio/Lab Setting to allow studios to lock customers out of pages. ' +
					'If a Studio/Lab user locks a page, a School Advisor is not able to unlock it.  ' +
					'The user that locked the page is also shown now on the options menu popup. ' +
					'Both the role based locking and showing which user locked the page will only work for pages locked after today.'
			}
		]);
	}

	if(!$.getStudioSetting('coverLocked', false)) {
		$.merge(changes, [
			{
				date: '10/31/2019',
				title: 'Cover Spread Design',
				content: 'We have added an option to work on the covers as a single spread. ' +
					'The standard view is still looking at a book from front to back. ' +
					'To go into design mode, click the Front Cover button in the top right of the front cover page. ' +
					'Next, click on Edit Spread. Now you can work on a single cover spread and drag a single cover image across both sides. ' +
					'<p/><img src="css/images/changelog/cover-spread-design.png" style="height: 30em"/>'

			}
		]);
	}

	if($.userPermissions.previewPDF) {
		$.merge(changes, [
			{
				date: '08/27/2020',
				title: 'Book Preview Render',
				content: 'When looking at a rendered book preview, the inside pages are now shown side by side like you are looking at a printed book.' +
					'<p/><img src="css/images/changelog/book-preview-render.png" style="height: 40em"/>'
			}
		]);
	}

	// Common to both
	if($.userPermissions.allowViewing) {
		$.merge(changes, [
			{
				date: '01/31/2024',
				title: 'Class Behaviors: Titles',
				content: 'From the left sidebar, under Class Behaviors, is a new section for Page Titles. ' +
					'Simply drag one of our built in behaviors onto a class page to replace the title with a new one. ' +
					'<p/><img src="css/images/changelog/title-behaviors.png" style="height: 30em;"/>'
			},
			{
				date: '01/03/2024',
				title: 'Curved Text',
				content: 'We have added support for curved text boxes! ' +
					'You can add curve and control how steep of a curve the text box has from the U icon on the toolbar. ' + 
					'Text can be curved both up and down. ' +
					'Please note that using justified alignment with curved text does not currently work. ' +
					'<p/><img src="css/images/changelog/curved-text.png" style="width: 100%;"/>'
			},
			{
				date: '11/13/2023',
				title: 'Upload from Google Photos',
				content: 'When uploading images into PLIC Books, you now have an option to upload from Google Photos. ' + 
					'<p/><img src="css/images/changelog/google-photos.png" style="width: 100%;"/>'
			},
			{
				date: '08/14/2023',
				title: 'QR codes in Books',
				content: 'You can now add QR codes to your Books. ' +
					'A QR code can be added by dragging from the QR code in the right sidebar under Design Elements. ' +
					'Links in QR codes will be shortened in the generated pdf so even a long link can still be scanned by a smartphone. '
			},
			{
				date: '05/12/2023',
				title: 'New class behaviors for padding',
				content: 'Under Class Behaviors there is a new section for "Cell Padding". ' +
					'From here you can increase or decrease the amount of padding between each cell on a class page. ' +
					'The group of behaviors previously called "Subject Cells" has been split into two categories: "Cell Alignment" and "Side Padding". '
			},
			{
				date: '02/09/2023',
				title: 'Custom subject/teacher name labels',
				content: 'We have many different built in name label options under Class Behaviors -> Subject/Teacher Names. ' +
					'At the bottom we have added an option to specify your own custom name displays. ' +
					'You can control the display of up to 3 lines with fields that pull from student data. ' + 
					'<p/><img src="css/images/changelog/custom-name-behaviors.png" style="width: 100%;"/>'
			},
			{
				date: '11/07/2022',
				title: 'Prompts about creating overflow pages',
				content: 'Previously, moving images or text nodes around on a class page will create overflow pages automatically. ' +
					'Now when you move a node and it would create an overflow page, you are prompted about it. ' +
					'If you press cancel on the prompt, your change will be undone so the overflow page is no longer created. ' +
					'If you press OK on the prompt, the move or resize will stay and a new overflow page will be created. ' +
					'If you do not want to be asked every time an overflow page is going to be created, check the box "Don\'t ask me again" and overflow pages will automatically be created like before. ' + 
					'<p/><img src="css/images/changelog/overflow-page-prompt.png" style="width: 100%;"/>'
			},
			{
				date: '10/20/2022',
				title: 'Rotating drop shadow',
				content: 'You can now control which direction the drop shadow points towards for images. ' +
					'The drop shadow continues to default to pointing to the bottom right. ' +
					'There is a slider under the drop shadow menu to control it\'s direction. ' +
					'When rotating an image, it will now auto rotate to stay pointing to the bottom right instead of rotating with the image. ' +
					'<p/><img src="css/images/changelog/drop-shadow-rotation.png" style="width: 100%;"/>'
			},
			{
				date: '09/15/2022',
				title: 'Favorite backgrounds',
				content: 'We have added a star button which you can click to mark a background as a favorite. ' +
					'The next time you load the Favorites background set, you will see all of the backgrounds you marked as favorites at the top. ' +
					'All of the backgrounds you have already used in the Book will continue to show up below the backgrounds you explicitly marked as favorites. ' +
					'<p/><img src="css/images/changelog/favorite-star-backgrounds.png" style="height: 30em"/>'
			},
			{
				date: '05/10/2022',
				title: 'Group content borders',
				content: 'You can now add a shared border around a group of images or text boxes. ' +
					'First you must select a few images or text nodes and then select the group option to the right of the toolbar. ' +
					'Now you will see an option to specify the group\'s border next to the stroke and drop shadow options. ' +
					'You can specify the thickness of the border, as well as how much padding it has. ' + 
					'This new option will be useful to have a distinct border between ads placed in a Book. ' +
					'<p/><img src="css/images/changelog/group-borders.png" style="width: 100%"/>'
			},
			{
				date: '04/06/2022',
				title: 'Changing page number color/font on different pages',
				content: 'You can now set different page number styles on a per page basis. ' +
					'By default all of the page numbers will continue to look the same. ' +
					'If you have specific pages that need to be different, you can click on the page number and then on the right edit button. ' +
					'Now any color or font style changes you make to that page number will only apply to that page. ' +
					'<p/><img src="css/images/changelog/per-page-number-styles.png" style="width: 100%"/>'
			},
			{
				date: '10/27/2021',
				title: 'Applying class layout and styles to all pages',
				content: 'You can now apply class layout and styles to other pages in bulk. ' +
					'You can select whether you want to copy the layout, image/label styles, background, and content on the page to the other class pages in your Book. ' +
					'You can also select which pages you want to apply these changes to.  You will not be able to apply changes to locked pages. ' +
					'This new option can be accessed from the Apply to all buttons when clicking on a subject image, label, or by going into the class options in the corner of the page. ' +
					'<p/><img src="css/images/changelog/apply-to-all-selection.png"/>'
			},
			{
				date: '10/20/2021',
				title: 'Toggle to show only candids not placed in Book yet',
				content: 'On the Composer, there is now a toggle to show only images which have not been placed in your Book yet. ' +
					'When you add images to your Book from this list, they will be automatically taken off. ' +
					'<p/><img src="css/images/changelog/hide-not-in-book-toggle.png"/>'
			},
			{
				date: '09/29/2021',
				title: 'Dragging text across pages',
				content: 'You can now drag text across pages to move from one page to another. ' +
					'Please note that you still cannot leave a text box in the center of two pages. '
			},
			{
				date: '05/12/2021',
				title: 'Drag to select content',
				content: 'You can now click and drag to select image and text nodes on a page. '
			},
			{
				date: '08/06/2020',
				title: 'Updated default class layout',
				content: 'The default class layout when you drag a class onto a page has been changed from "Names Side" to "Names Under Two Lines"'
			},
			{
				date: '08/03/2020',
				title: 'Color Emoji',
				content: 'Color emoji\'s are now supported in your books. ' +
					'The new emoji pack also supports a much more full set of emoji characters. '
			},
			{
				date: '06/30/2020',
				title: 'Text box borders',
				content: 'You can now add borders around the outside of a text box. ' +
					'The new option is in between the stroke and drop shadow buttons on the text toolbar.' +
					'<p/><img src="css/images/changelog/text-box-border.jpg"/>'
			},
			{
				date: '06/04/2020',
				title: 'Book comments updates',
				content: 'There is a new button on the Book Composer in the top right corner to access all of the comments in your Book. ' +
					'When you click on it, you can see all of the comments made on any page. ' +
					'You can also add new comments that are not for a specific page from the comments feed. ' +
					'School Advisors and above can mark comments as completed for requested changes. ' +
					'When anyone else makes a comment you will get notified about it on the Composer so requested changes will not slip through the cracks. ' +
					'In addition, users with the role Proofer are now able to make comments in their Book. ' +
					'<p/><img src="css/images/changelog/global-comments-feed.png" style="height: 30em"/>'
			},
			{
				date: '03/04/2020',
				title: 'Split candid placeholders into a grid',
				content: 'When selecting a placeholder, there is now an option on the toolbar to split it into a grid of placeholders. ' +
					'You can control how many columns and rows it is split into as well as the padding between each candid placeholder. ' +
					'This can be helpful when you are trying to keep candid spots the same width/height and distance apart. ' +
					'<p/><img src="css/images/changelog/split-placeholders.png" style="height: 30em"/>'
			},
			{
				date: '02/27/2020',
				title: 'Group alignment tools',
				content: 'When more then one image is selected, a new option for group alignment shows up on the toolbar. ' +
					'You can now align a group of images to the left, right, top, bottom, or center vertically or horizontally. ' +
					'You can also space them evenly horizontally or vertically. ' +
					'The last new option is to make all of the images the same size as the main one you have selected. ' +
					'<p/><img src="css/images/changelog/alignment-tools.png" style="width: 100%"/>'
			},
			{
				date: '02/18/2020',
				title: 'Active class/batch highlighting',
				content: 'The classes/batches that have been placed in a Book on the Composer are now shown in blue instead of light gray. ' +
					'Some monitors did not show the light gray highlight very well. ' + 
					'This change should make it easier to tell what has been placed in the Book already. ' +
					'This change also affects the active class selected in Subject Management. ' +
					'<p/><img src="css/images/changelog/batch-active-highlight.png" style="width: 100%"/>'
			},
			{
				date: '10/25/2019',
				title: 'Image Captions',
				content: 'We have added a quick way to add captions to an image. ' +
					'From the image toolbar, look for the letter A button.  Press it and a caption will be added underneath the image. ' +
					'Now when you move or resize the image around, it will automatically follow it around. ' +
					'The caption defaults to the filename of the image, but can easily be customized to say anything you would like. ' +
					'<p/><img src="css/images/changelog/image-captions.jpg" style="height: 30em"/>'
			},
			{
				date: '07/09/2019',
				title: 'Turning off individual page numbers',
				content: 'There is a new page behavior under Class Behaviors -> Page Behaviors to turn off page numbers for individual pages. ' +
					'You can drag it onto specific pages that you don\'t want page numbers to show up for. ' +
					'Class Behaviors has also been split into 4 sections to make it easier to navigate.'
			},
			{
				date: '05/28/2019',
				title: 'First/Last Page Buttons',
				content: 'While designing your Books, there are buttons to go to the first and last pages to skip around faster then ever!' +
					'<p/><img src="css/images/changelog/page-first-last-buttons.png" style="width: 100%"/>'
			},
			{
				date: '03/08/2019',
				title: 'Shapes',
				content: 'You can now create basic colored shapes for your Books. ' +
					'Go to the Content section on the right sidebar, and drag the blue shape onto a page to get started. ' +
					'You can resize the shape to make lines, or drag shapes from the left sidebar to get the desired look. ' +
					'<p/><img src="css/images/changelog/custom-shapes.png" style="height: 30em"/>'
			},
			{
				date: '02/21/2019',
				title: 'Title Alignment',
				content: 'Title alignment has been changed so that choosing left or right alignment will align to the left and the right sides of the page. ' +
					'Existing titles will not be changed. If you change the alignment of a title text block, it will be updated to the new behavior. ' +
					'<p/><img src="css/images/changelog/title-alignment.png" style="height: 30em"/>'
			},
			{
				date: '12/21/2018',
				title: 'Favorite Backgrounds',
				content: 'A new background category has been added that is all of the backgrounds you have already used in your Book. ' +
					'This makes it easier than ever to find and re-use the same backgrounds across many of your pages. ' +
					'<p/><img src="css/images/changelog/favorite-backgrounds.png" style="height: 20em"/>'
			},
			{
				date: '12/12/2018',
				title: 'Cut/Move Content',
				content: 'A checkbox has been added to the Duplicate text/image popup so you can move instead of creating a copy. ' + 
					'You can also use Ctrl + X to cut and Ctrl + V to paste on a different page in the same Book.'
			},
			{
				date: '12/06/2018',
				title: 'Content Category',
				content: 'From the Composer, on the right side bar there is a new category called Content. ' +
					'From here you can drag empty photo placeholders onto your Books and place the actual candids later. ' +
					'You can also drag the text element instead of double clicking on a page to add text to your pages now. ' +
					'<p/><img src="css/images/changelog/content-placeholder-category.png" style="height: 20em"/>'
			},
			{
				date: '09/13/2018',
				title: 'Text Justify Alignment',
				content: 'Under the text alignment options, you can now specify Justify.' +
					'<p/><img src="css/images/changelog/text-alignment-justify.png" style="height: 20em"/>'
			},
			{
				date: '07/12/2018',
				title: 'Black/White pages',
				content: 'Under Class Behaviors you can now change individual pages to be black and white and back to full color.'
			},
			{
				date: '07/02/2018',
				title: 'Improved Resizing',
				content: 'We have improved how you resize image and text content so you can resize from any side and more easily grab the handles.' +
					'<p/><img src="css/images/changelog/reworked-resizing.png" style="height: 12em"/>'
			},
			{
				date: '05/25/2018',
				title: 'Always show candid usage stats',
				content: 'From the Composer, you can now set candid usage stats to always show instead of requiring you to hover over them to see it.  ' +
					'From the top right actions menu, go to My Settings and the toggle on "Show Candid Stats".  You can turn it back off at any time the same way.'
			},
			{
				date: '05/23/2018',
				title: 'Zoom Controls',
				content: 'Zoom is here!  You can zoom up to 300% in order to get a closer look at your Book. ' +
					'The ability to view high resolution versions of your pages is located in the Zoom toolbar. ' +
					'<p/><img src="css/images/changelog/zoom-controls.png" style="height: 12em"/>'
			},
			{
				date: '05/17/2018',
				title: 'Collage Builder',
				content: 'More options have been added to the Collage Builder to make tweaking the layouts easier then ever. ' +
					'Use tags to quickly filter down to just the photos you want, or let us choose for you based on which candids haven\'t been used anywhere else in the Book. ' +
					'Tweak the padding and outside margins to get looking just how you want! ' +
					'<p/><img src="css/images/changelog/collage-options.png" style="height: 20em"/><p/>'
			},
			{
				date: '05/11/2018',
				title: 'Text resizing',
				content: 'When resizing text boxes, the text will automatically re-flow itself to fit in the box'
			},
			{
				date: '04/17/2018',
				title: 'Apply to All for subject labels',
				content: 'You can now use the Apply Styles To All Page button for subject labels. ' +
					'<p/><img src="css/images/changelog/subject-label-apply-to-all.png" style="height: 20em"/><p/>'
			},
			{
				date: '04/16/2018',
				title: 'Group content together',
				content: 'You can now group content together so they all default to being moved and edited together. ' +
					'<p/><img src="css/images/changelog/lock-elements-together.png" style="height: 20em"/><p/>'
			},
			{
				date: '04/09/2018',
				title: 'Duplicate content between pages',
				content: 'You now can duplicate images and text between different pages. ' +
					'<p/><img src="css/images/changelog/duplicate-content-pages.png" style="height: 20em"/><p/>'
			},
			{
				date: '03/28/2018',
				title: 'Adding pages from the Book Preview',
				content: 'If you are under your page limit, you can quickly add empty pages to the back of your Book from the Book Preview.'
			},
			{
				date: '03/14/2018',
				title: 'Multiple element selection',
				content: 'You can now select multiple image and text nodes at once to make bulk changes'
			},
			{
				date: '03/09/2018',
				title: 'High resolution page preview',
				content: 'You can now view a high resolution preview of your pages from the Composer' + 
					'<p/><img src="css/images/changelog/high-resolution-preview.jpg" style="height: 20em"/><p/>'
			},
			{
				date: '02/22/2018',
				title: 'Duplicate image',
				content: 'You can now duplicate an image/placeholder on a page' +
					'<p/><img src="css/images/changelog/duplicate-image.jpg" style="height: 20em"/><p/>'
			},
			{
				date: '02/19/2018',
				title: 'Class Lock',
				content: 'You can now lock class pages when you are done with them<p/>' +
					'<p/><img src="css/images/changelog/class-lock-page.png" style="height: 14em"/><p/>'
			},
			{
				date: '02/15/2018',
				title: 'Remove image/text change',
				content: 'The remove images/texts button has been moved from a hover button into the toolbar after you click on it'
			},
			{
				date: '02/15/2018',
				title: 'Setting for Alignment Lines',
				content: 'In My Settings you can now turn on and off automatic Alignment Lines'
			},
			{
				date: '02/13/2018',
				title: 'Image stroke/drop shadow changes',
				content: 'By default when applying stroke or drop shadow to an image, the stroke size is based on how large the image is.  Now that is an option you can turn off.  ' +
					'When using Apply to All it is defaulted to off so that all of the images on the page have the same size stroke and drop shadow.'
			},
			{
				date: '01/22/2018',
				title: 'Content Behind Subjects',
				content: 'By using the forward/backwards buttons on images and text, you can now move content to be behind subjects. ' +
					'Put a piece of clip art on top of a subject and select "Move image back" to try it out! ' +
					'You can also hold down ctrl while dragging a new piece of clip art onto the page to drop it behind the subjects'
			},
			{
				date: '01/10/2018',
				title: 'Class Layout Behaviors',
				content: 'You can now tweak class layout behaviors.  Whether you want to change subject labels to be "Last Name, First Name" or want to change all subjects to left aligned, it is as simple as dragging and dropping a behavior onto the class page.' +
					'<p/><img src="css/images/changelog/class-layout-behaviors.png" style="height: 30em"/><p/>'
			},
			{
				date: '01/05/2018',
				title: 'Custom Candid Layouts',
				content: 'You can now create your own candids layouts and re-use them in your Book.  You can create them from scratch from the Candid Layouts tab:' +
					'<p/><img src="css/images/changelog/customer-layout-management.png" style="height: 30em"/><p/>' +
					'You can also save layouts you have already set up for re-use on other pages: ' +
					'<p/><img src="css/images/changelog/candid-layout-saving.png" style="height: 30em"/><p/>'
			},
			{
				date: '01/03/2018',
				title: 'Global Undo/Redo',
				content: 'From the Composer you can undo any action.  Buttons for undo/redo are in the bottom left of your screen.  You can also use the standard keyboard shortcut of ctrl + z.' +
					'<p/><img src="css/images/changelog/global-undo-buttons.png" style="height: 30em"/><p/>'
			},
			{
				date: '01/02/2018',
				title: 'Customizable Row Count',
				content: 'You can now specify how many rows you want in a layout along with how many columns' +
					'<p/><img src="css/images/changelog/custom-row-count.png" style="height: 30em"/><p/>'
			},
			{
				date: '10/18/2017',
				title: 'Candid Spread',
				content: 'Candid images can now be spread across two pages'
			},
			{
				date: '09/08/2017',
				title: 'Grid Lines',
				content: 'From the Composer you can now toggle on and off grid lines.  From the sidebar go to My Settings and you can turn grid lines on or off, as well as tweak their frequency and color.' +
					'<p/><img src="css/images/changelog/grid-lines-option.png"/>'
			},
			{
				date: '09/06/2017',
				title: 'Global candid search',
				content: 'You can now search across all candid and clip art albums from the Composer!'
			},
			{
				date: '4/27/2017',
				title: 'New and improved cropping',
				content: 'Cropping candids now opens up into a full dialog for easier manipulation'
			},
			{
				date: '3/7/2017',
				title: 'Option to replace or add new candids',
				content: 'When dragging candids over an existing candid image, you will now be asked if you want to replace the existing candid or add a new one.  You can choose to remember this choice and not be asked again.'
			},
			{
				date: '3/1/2017',
				title: 'Subject Drop Shadow',
				content: 'When adding Drop Shadow to subjects, space will now be added between the image and the label for optimal effect.  Please note you will need to change the Drop Shadow once for this to take effect.  We have also added the ability to delete the title from your page.'
			},
			{
				date: '2/22/2017',
				title: 'Word wrap',
				content: 'Manually sized text boxes now word wrap so they adjust within the box specified.'
			},
			{
				date: '2/1/2017',
				title: 'Overflow page error',
				content: 'The overflow page error has been removed so you can continue working without interruption.  You still will not be able to submit a Book with too many pages!'
			},
			{
				date: '11/8/2016',
				title: 'Candids with stroke',
				content: 'Candid border has been changed to stroke.  Now when you add a stroke to a candid or subject it shows up correctly even with transparency and masks applied. You can also access subject effects more directly by clicking on a subject image.'
			}
		]);
	}

	return changes;
};
