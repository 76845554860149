$.PersonalizedPageDB = function(personalization, settings) {
	var obj = new $.ComposerDBQueue($.extend({
		loadUrl: 'ajax/getPersonalizedPages.php',
		saveUrl: 'ajax/savePersonalizedPages.php',
		postData: {
			personalizationId: personalization.personalizationId
		},
		pageSet: personalization,
		userEvents: $.userEvents,
		loadSubjects: false,
		getSocket: function() {
			var url = 'wss?composer=' + personalization.personalizationId + '&token=' + $.PlicToken + '&type=personalization';
			if($.LoggedInAsUser) {
				url += '&originalToken=' + $.OriginalUserToken;
			}

			return url;
		}
	}, settings));

	$.extend(obj, {
		loadSinglePage: function (definition) {
			var page = $.PersonalizedPage();
			this.loadStandardPageData(definition, page);

			return page;
		},
		getEntirePageData: function(page) {
			return {
				id: page.id,
				pageNumber: page.pageNumber,
				candids: page.candids,
				texts: page.texts,
				type: 'personalized-page',
				extras: page.extras ? page.extras : null
			};
		}
	});

	$.UserActivityModule(obj, {
		showLastSeen: false,
		activitySubjectLabel: 'Personalized Pages'
	});
	return obj;
};