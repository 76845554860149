$.LayoutPage = function(settings) {
	var obj = new $.FlowPage($.extend({
		id: 'layout',
		type: 'layout',
		getTitle: function() {
			return null;
		},
		setTitle: function() {},
		updatePageLabel: function () {
			var sidebar = [];

			if (this.theme) {
				sidebar.push({
					name: 'Backgrounds',
					icon: 'setting',
					popup: 'Change settings on backgrounds',
					onClick: function (page, layout) {
						layout.openThemeSettings();
					}
				});

				sidebar.push({
					name: 'Remove Background',
					icon: 'picture',
					onClick: function (page, layout) {
						layout.clearBackground();
					}
				});
			}

			if(sidebar.length) {
				this.pageLabel = {
					display: '',
					popup: 'Options',
					sidebar: sidebar
				};
			} else {
				this.pageLabel = null;
			}
		},
		getLayout: function() {
			if(this.layout.grid && this.layout.grid.bleed === undefined) {
				this.layout.grid.bleed = {
					left: 0.125,
					right: 0.125,
					top: 0.125,
					bottom: 0.125
				};
			}
			if(this.layout.grid && this.layout.grid.bleed && $.isPlainObject(this.layout.grid.bleed)) {
				this.layout.grid.bleedOutsideDimensions = true;
			}

			return this.layout;
		},
		getCanHaveBarcodes: function() {
			return this.layoutType === 'Flyer' || this.layoutType === 'Flyer Template';
		},
		setLayout: function(layout) {
			// Don't save since saveLayout will take that and ignore passed candids/texts from update
			this.layout = layout;
		},
		
		getPageMargins: null,
		setPageMargins: null
	}, settings));

	if($.isArray(obj.layout.images)) {
		obj.setCandidArray(obj.layout.images);
	} else if($.isPlainObject(obj.layout.images)) {
		obj.candids = obj.layout.images;
	}
	if($.isArray(obj.layout.texts)) {
		obj.setTextArray(obj.layout.texts);
	} else if($.isPlainObject(obj.layout.texts)) {
		obj.texts = obj.layout.texts;
	}

	if(obj.layout.theme) {
		obj.theme = obj.layout.theme;
	}

	if(obj.layout.extras) {
		if($.isArray(obj.layout.extras)) {
			obj.extras = obj.layout.extras = {};
		}
		obj.extras = obj.layout.extras;
	}

	// Delete so $.FlowLayout doesn't auto add them from layout definition
	delete obj.layout.images;
	delete obj.layout.texts;
	delete obj.layout.theme;

	return obj;
};
