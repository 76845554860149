$.extend(true, window.locales['en-CA'], {
	composer: {
		behaviors: {
			alignSubjectsToCenter: 'Align subjects to centre',
			colorPage: 'Colour page',
			classBehaviors: 'Class Behaviours',
			pageBehaviors: 'Page Behaviours'
		},
		licenseNotEnough: 'You do not have enough Licences to activate this book.',
		licenseNotValid: 'This yearbook is not licenced for use.  Contact %{org} for assistance.',
		licenseUsed: 'A licence has been used from your account for this book'
	},
	management: {
		licenseCount: '%{count} Licences',
		noLicenses: 'No Licence'
	}
});