$.BookPageNumbersModule = function(obj, options) {
	$.extend(obj, {
		setPageNumberPosition: function (pageNumberPosition) {
			if (pageNumberPosition != this.pageNumberPosition && this.db) {
				this.db.queueChange({
					scope: 'yearbook',
					name: 'pageNumberPosition',
					value: pageNumberPosition
				});

				if(this.userEvents) {
					this.userEvents.addEvent({
						context: [this, 'pageNumberPosition'],
						action: 'update',
						args: [this.pageNumberPosition, pageNumberPosition]
					});
				}
			}

			this.pageNumberPosition = pageNumberPosition;
		},
		getPageNumberPosition: function () {
			return this.pageNumberPosition;
		},
		setPageNumberCSSStyles: function(styles) {
			var oldStyles = $.extend(true, {}, this.pageNumberCSS.css);
			this.pageNumberCSS.css = styles;

			obj.db.queueChange({
				scope: 'yearbook',
				name: 'pageNumberCSS',
				value: JSON.stringify(styles)
			});

			if(obj.userEvents) {
				obj.userEvents.addEvent({
					context: [obj, 'pageNumberCSS', 'css'],
					action: 'update',
					args: [oldStyles, styles]
				});
			}
		},
		getPageNumberCSS: function () {
			return this.pageNumberCSS;
		},
		getPageNumberFontSize: function() {
			var pageNumberCSS = this.getPageNumberCSS();
			var defaultPageNumberFont = this.getDefaultPageNumberFont();
			var fontSize = defaultPageNumberFont;
			if (pageNumberCSS.css['font-size']) {
				fontSize = parseFloat(pageNumberCSS.css['font-size'].replace('pt', ''));
			}

			return fontSize;
		},
		getPageNumberPadding: function() {
			// We get this again for legacy reasons -> there really is no point in doing a $.convertToPt
			var pageNumberCSS = this.getPageNumberCSS();
			var defaultPageNumberFont = this.getDefaultPageNumberFont();
			var fontSize = defaultPageNumberFont;
			if (pageNumberCSS.css['font-size']) {
				fontSize = $.convertToPt(parseFloat(pageNumberCSS.css['font-size'].replace('px', '').replace('pt', '')));
			}

			// We want margins that shrink as we go away from default size
			// As we get bigger there is a larger implicit margin based on font
			// As we get smaller we don't as large of a margin
			var divider = fontSize / this.getDefaultPageNumberFont();
			if (divider > 1) {
				divider = 1 - Math.log(divider);
			}
			return this.getDefaultPageNumberMargins() * divider;
		},
		getDefaultPageNumberFont: function() {
			return 8;
		},
		getDefaultPageNumberMargins: function() {
			return 0.10;
		},

		pageNumberPosition: 'center bottom',
		pageNumberCSS: new $.CSSBundle(function(name, value, oldValue) {
			obj.db.queueChange({
				scope: 'yearbook',
				name: 'pageNumberCSS',
				value: JSON.stringify(this.css)
			});

			if(obj.userEvents) {
				var oldObj = {};
				oldObj[name] = oldValue;
				var newObj = {};
				newObj[name] = value;

				obj.userEvents.addEvent({
					context: [obj, 'pageNumberCSS', 'css'],
					action: 'update',
					args: [oldObj, newObj]
				});
			}
		}),
	}, options);
};