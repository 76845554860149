$.YEARBOOK_STATUSES = {
	'In Work': 1,
	'Submitted': 2,
	'Proof Out': 3,
	'Printed': 4,
	'Shipped': 5
};

$.Yearbook = function(progressIndicator, usersLoggedIn) {
	var obj = new $.FlowPageClassSet({});
	var _getPageForClass = obj.getPageForClass;

	$.extend(obj, {
		load: function (events) {
			var me = this;
			this.db.load($.extend({
				validateResponse: function (data) {
					if (!data.userPermissions['allowViewing']) {
						$.Alert('Error', 'You are not allowed to view this page', function () {
							window.location = '/login';
						});
						return false;
					} else {
						return true;
					}
				},
				onLoadGlobals: function (data) {
					me.classes = data.classes;
					me.alternativeCoverFormats = data.alternativeCoverFormats;
					me.favoriteBackgrounds = data.favoriteBackgrounds;
					if(data.reviewedItems) {
						data.reviewedItems.forEach(reviewedItem => {
							if(!me.reviewedItems[reviewedItem.referenceType]) {
								me.reviewedItems[reviewedItem.referenceType] = {};
							}
							let reviewedOfType = me.reviewedItems[reviewedItem.referenceType];
							if(!reviewedOfType[reviewedItem.referenceId]) {
								reviewedOfType[reviewedItem.referenceId] = {};
							}
							reviewedOfType[reviewedItem.referenceId][reviewedItem.issue] = true;
						});
					}
				},
				onError: function (response) {
					try {
						response = JSON.parse(response);
						if (response.reason == 'Not activated') {
							var errorMessage = window.i18n.t('composer.licenseNotEnough');
							
							if(response.details && response.details.message) {
								errorMessage = response.details.message;
							} else if (response.details && response.details.parentOrgName) {
								errorMessage = window.i18n.t('composer.licenseNotValid', {
									org: response.details.parentOrgName
								});
							}

							$.Alert('Error', errorMessage, function () {
								if(response.details) {
									window.location = response.details.redirectHome ? '/' : '/login';
								} else {
									window.location = '/';
								}
							});

							return true;
						}
					} catch (e) {
						return false;
					}
				}
			}, events));
		},
		onLoadGlobalData: function(data) {
			if(data.theme) {
				this.theme = data.theme;
			}
			if(data.pageNumberPosition) {
				this.pageNumberPosition = data.pageNumberPosition;
			}
			if(data.pageNumberCSS && !$.isArray(data.pageNumberCSS)) {
				this.pageNumberCSS.css = data.pageNumberCSS;
			}
			if(data.pageMargins) {
				this.pageMargins = data.pageMargins;
			}
			if($.projectSettings.pageNumberHidden && $.projectSettings.pageNumberHidden.value) {
				this.getPageNumberPosition = null;
			}
			if(data.comments && $.isPlainObject(data.comments)) {
				this.comments = data.comments;
			}
			this.status = data.status;
			if(this.status == 'Rework') {
				this.status = 'In Work';
			} else if(this.status == 'Submitted') {
				this.db.setOnlyCommentsAllowed(true);
			} else if(this.status == 'Locked') {
				this.db.setOnlyCommentsAllowed(true);
			}

			if(data.licenseActivated) {
				$.Alert('Info', window.i18n.t('composer.licenseUsed'));
			}
			this.coverLocked = $.projectSettings.coverLocked && $.projectSettings.coverLocked.value;

			if(data.bookFormat) {
				$.bookFormat = this.bookFormat = data.bookFormat;
				this.layoutDimensions = data.bookFormat.definition;
			}

			if(data.primarySubject) {
				this.globalPrimarySubjectData = data.primarySubject;
			}
		},

		canSubjectsOverlapPageNumber: function() {
			return $.getProjectSetting('subjectOverlapPageNumber', false);
		},

		getStatus: function () {
			if (this.status) {
				return this.status;
			} else {
				return 'In Work';
			}
		},
		setStatus: function (status) {
			if (status != this.status && this.db) {
				this.db.queueChange({
					scope: 'yearbook',
					name: 'status',
					value: $.YEARBOOK_STATUSES[status]
				});

				if(this.userEvents) {
					this.userEvents.addEvent({
						context: [this, 'status'],
						action: 'update',
						args: [this.status, status]
					});
				}

				if(status == 'Submitted') {
					$.plicAPI({
						method: 'projects/' + $.PlicProjectId + '/workflow-actions',
						params: {
							workflow_action: {
								plic_app_id: $.plicSlug,
								action: 'book_submitted'
							}
						},
						success: function (data) {

						}
					});
				}
			}

			this.status = status;
		},
		setCoverLocked: function (coverLocked) {
			this.coverLocked = coverLocked;

			for(var i = 0; i < this.pages.length; i++) {
				var page = this.pages[i];
				if(page && (page.type == 'cover' || page.type == 'insideCover')) {
					page.setLocked(coverLocked);

					for(var id in page.alternativeVersions) {
						page.alternativeVersions[id].setLocked(coverLocked);
					}
				}
			}
		},
		getCoverLocked: function () {
			return this.coverLocked;
		},
		getCoreBookPageSet: function() {
			return new $.FlowPageSubSet(this, this.getCoreBookPages());
		},
		getCoreBookPages: function() {
			return this.pages.slice(2, this.pages.length - 2);
		},
		getSubmissionRenderTargets: function() {
			var renderTargets;

			if($.getStudioSetting('mergeCoversIntoBookRender', false)) {
				renderTargets = {
					book: {
						pageSet: this
					}
				};

				// Set specialPages for cover sizes since usually will be different
				let extraPostData = this.getRenderExtraPostData();
				let pageDimensions = JSON.parse(extraPostData.pageDimensions);

				let frontCover = this.getFrontCoverPage();
				let restoreVersion = frontCover.viewingVersion;
				if(frontCover.viewingVersion) {
					restoreVersion = frontCover.viewingVersion;
					delete frontCover.viewingVersion;
				}
				let coverLayout = frontCover.getLayout({
					includeWhiteSpace: true
				});
				if(coverLayout && coverLayout.grid) {
					let coverWidth = coverLayout.grid.width;
					let coverHeight = coverLayout.grid.height;
					let coverPageDimensions = {
						inchWidth: coverWidth,
						inchHeight: coverHeight,
						pixelWidth: coverWidth * $.PDF_PIXEL_RATIO,
						pixelHeight: coverHeight * $.PDF_PIXEL_RATIO
					};
					pageDimensions.specialPages = {
						0: coverPageDimensions,
						[this.pages.length - 1]: coverPageDimensions
					}

					extraPostData.pageDimensions = JSON.stringify(pageDimensions);
				}
				renderTargets.book.extraPostData = extraPostData;
				if(restoreVersion) {
					frontCover.viewingVersion = restoreVersion;
				}
			} else {
				renderTargets = {
					book: {
						pageSet: this.getCoreBookPageSet()
					}
				};

				// When we have webhooks we can to always include cover in submission so automations can use the cover
				if (!this.getCoverLocked() || $.getStudioSetting('onSubmitWebhook')) {
					if ($.getStudioSetting('separateCoverRenders', false)) {
						let extraPostData = this.getRenderExtraPostData();
						var coverLayout = this.getFrontCoverPage().getLayout();
						if(coverLayout && coverLayout.grid) {
							extraPostData.pageDimensions = JSON.stringify({
								inchWidth: coverLayout.grid.width,
								inchHeight: coverLayout.grid.height
							});
						}

						renderTargets.frontCover = {
							pageSet: new $.FlowPageSubSet(this, [this.getFrontCoverPage()]),
							batchName: 'Front Cover',
							extraPostData: extraPostData
						};

						renderTargets.backCover = {
							pageSet: new $.FlowPageSubSet(this, [this.getBackCoverPage()]),
							batchName: 'Back Cover',
							extraPostData: extraPostData
						};

						renderTargets.book.waitFor = ['frontCover', 'backCover'];
					} else {
						renderTargets.book.waitFor = [];
						var coverRenderTargets = this.getCoverSubmitTargets();
						for(var id in coverRenderTargets) {
							renderTargets.book.waitFor.push(id);
							renderTargets[id] = coverRenderTargets[id];
							if(renderTargets[id].waitFor) {
								delete renderTargets[id].waitFor;
							}
						}
					}
				}
			}

			return renderTargets;
		},
		getCoverRenderTarget: function(versionId) {
			if(this.pages.length < 4) {
				throw 'At least 4 pages are required.  Found ' + this.pages.length;
			}

			var layoutsPerPage = this.getLayoutsPerPage();
			var coverPages;
			if(layoutsPerPage > 1) {
				if($.getProjectSetting('allowContentInInsideCovers', false)) {
					coverPages = [this.getBackCoverPage(), this.getFrontInsideCoverPage(), this.getBackInsideCoverPage(), this.getFrontCoverPage()];
				} else {
					coverPages = this.getOutsideCoverPages();
				}
			} else {
				coverPages = this.getCoverPages();
			}

			var extraPostData = this.getRenderExtraPostData();
			var frontCover = this.getFrontCoverPage();
			var restoreVersion = frontCover.viewingVersion;
			if(versionId && frontCover.alternativeVersions[versionId]) {
				frontCover = frontCover.alternativeVersions[versionId];
			} else if(frontCover.viewingVersion) {
				restoreVersion = frontCover.viewingVersion;
				delete frontCover.viewingVersion;
			}
			var coverLayout = frontCover.getLayout({
				includeWhiteSpace: true
			});
			var insideCoverDimensions = JSON.parse(extraPostData.pageDimensions);
			if(coverLayout && coverLayout.grid) {
				var coverWidth = coverLayout.grid.width * 2;
				var coverHeight = coverLayout.grid.height;

				var coverPageDimensions = {
					inchWidth: coverWidth,
					inchHeight: coverHeight,
					pixelWidth: coverWidth * $.PDF_PIXEL_RATIO,
					pixelHeight: coverHeight * $.PDF_PIXEL_RATIO
				};

				if($.getProjectSetting('allowContentInInsideCovers', false) && layoutsPerPage > 1) {
					coverPageDimensions.specialPages = {
						1: {
							inchWidth: insideCoverDimensions.inchWidth * 2,
							inchHeight: insideCoverDimensions.inchHeight,
							pixelWidth: (insideCoverDimensions.inchWidth * 2) * $.PDF_PIXEL_RATIO,
							pixelHeight: insideCoverDimensions.inchHeight * $.PDF_PIXEL_RATIO
						}
					};
				}

				extraPostData.pageDimensions = JSON.stringify(coverPageDimensions);
			} else if(layoutsPerPage > 1 && extraPostData.pageDimensions) {
				extraPostData.pageDimensions = JSON.stringify({
					inchWidth: insideCoverDimensions.inchWidth * 2,
					inchHeight: insideCoverDimensions.inchHeight,
					pixelWidth: (insideCoverDimensions.inchWidth * 2) * $.PDF_PIXEL_RATIO,
					pixelHeight: insideCoverDimensions.inchHeight * $.PDF_PIXEL_RATIO
				});
			}

			if(restoreVersion) {
				frontCover.viewingVersion = restoreVersion;
			}
			
			return {
				pageSet: new $.FlowPageSubSet(this, coverPages),
				batchName: 'Covers',
				layoutsPerPage: layoutsPerPage,
				extraPostData: extraPostData
			};
		},
		getCoverSubmitTargets: function() {
			var renderTargets = {
				covers: this.getCoverRenderTarget()
			};
			(this.alternativeCoverFormats || []).forEach(function(format) {
				var versionId = format.formatId;
				var versionRenderTarget = obj.getCoverRenderTarget(versionId);
				versionRenderTarget.versionId = versionId;
				versionRenderTarget.batchName = format.name;
				renderTargets[versionId] = versionRenderTarget;
				renderTargets.covers.batchName = 'Main Covers';

				if(!renderTargets.covers.waitFor) {
					renderTargets.covers.waitFor = [];
				}
				renderTargets.covers.waitFor.push(versionId);
			});

			return renderTargets;
		},
		getFrontCoverRenderTarget: function() {
			if(this.pages.length < 4) {
				throw 'At least 4 pages are required.  Found ' + this.pages.length;
			}

			return {
				pageSet: new $.FlowPageSubSet(this, [this.pages[0]]),
				batchName: 'Front Cover'
			};
		},
		getCoverPageSet: function() {
			return new $.FlowPageSubSet(this, this.getCoverPages());
		},
		getCoverPages: function () {
			return [this.getFrontCoverPage(), this.getFrontInsideCoverPage(), this.getBackInsideCoverPage(), this.getBackCoverPage()];
		},
		getOutsideCoverPageSet: function() {
			return new $.FlowPageSubSet(this, this.getOutsideCoverPages());
		},
		getOutsideCoverPages: function() {
			return [this.getBackCoverPage(), this.getFrontCoverPage()];
		},
		getFrontCoverPage: function() {
			return this.pages[0];
		},
		getFrontInsideCoverPage: function() {
			return this.pages[1];
		},
		getBackCoverPage: function() {
			return this.pages[this.pages.length - 1];
		},
		getBackInsideCoverPage: function() {
			return this.pages[this.pages.length - 2];
		},
		getLayoutsPerPage: function() {
			if($.studioSettings && $.studioSettings.combineCoverPages && $.studioSettings.combineCoverPages.value) {
				return 2;
			} else {
				return 1;
			}
		},
		getSpineLength: function() {
			var dimensions = this.getLayoutDimensions();
			if(dimensions) {
				return dimensions.spine;
			} else {
				return 0.25;
			}
		},
		removeEmptyPage: function() {
			for(var i = this.pages.length - 1; i >= 0; i--) {
				var page = this.pages[i];

				if(page.type == 'empty') {
					this.removePageCascade(page);
					return true;
				}
			}

			return false;
		},
		isReducedUI: function(option) {
			var layoutDimensions = this.getLayoutDimensions();
			if(!layoutDimensions) {
				return false;
			}

			return layoutDimensions['ui-' + option] === false || layoutDimensions.reducedUI === true;
		},
		getPageForClass: function (classId) {
			var batch = $.isPlainObject(classId) ? classId : {
				id: classId
			};

			var page = _getPageForClass.apply(this, arguments);
			if(page) {
				var overflowPage = page.getOverflowPage();
				while(overflowPage && overflowPage.subjects) {
					if(overflowPage.isClassBreakInPage(batch)) {
						return overflowPage;
					}

					overflowPage = overflowPage.getOverflowPage();
				}
			}

			return page;
		},
		generateProductionPdf: function(backgroundRender) {
			if(backgroundRender === undefined) {
				backgroundRender = true;
			}

			var render = new $.PageRender(this.getSubmissionRenderTargets(), {
				pageOffset: 1,
				production: true,
				completeAfterWorkerStarts: backgroundRender,
				watermarkText: 'PROOF COPY',
				flattenPDF: $.getProjectSetting('renderFlatPdf', false),
				batchName: 'Proof',
				backgroundRender: true,
				extraPostData: this.getRenderExtraPostData(),
				previewPages: 2,
				startOffset: 1,
				showBleedColor: $.getStudioSetting('bleedColorInPreviews'),
				hideBleed: this.layoutDimensions && this.layoutDimensions.hideBleed,
				onError: function () {
					$('#pageLoading').removeClass('active');
					$.Alert('Error', 'Failed to generate proof');
				}
			});

			if(backgroundRender) {
				$('#pageLoading').addClass('active');
				render.generatePDFFromPages(function () {
					$('#pageLoading').removeClass('active');
					$.Alert('Proof submitted', 'You will receive the proof by email when it is done generating', null, {
						headerIcon: 'trophy'
					});
				});
			} else {
				render.showPDFDialog();
			}

			return render;
		},
		renderMultiplePages: function(pageNumbers) {
			let missingSubjectsError = this.getMissingSubjectsError();
			if(missingSubjectsError) {
				$.Alert('Error', missingSubjectsError);
				return;
			}

			let pages = pageNumbers.map(pageNumber => this.getPageByPageNumber(pageNumber));
			if(pages.find(page => !page)) {
				$.Alert('Error', 'Failed to render pages: ' + pageNumbers.join(', '));
				return;
			}

			let render = new $.PageRender($.FlowPageSubSet(this, pages), {
				pageOffset: (pageNumbers[0] % 2),
				production: true,
				watermarkText: 'PROOF COPY',
				flattenPDF: $.getProjectSetting('renderFlatPdf', false),
				batchName: 'Proof Pages ',
				blockEmail: true,
				backgroundRender: true,
				includeBatches: true,
				extraPostData: this.getRenderExtraPostData(),
				previewPages: 2,
				startOffset: (pageNumbers[0] % 2),
				includeAllBatches: !!pages.find(page => page.type.includes('index')),
				hideBleed: this.layoutDimensions && this.layoutDimensions.hideBleed,
				onError: function () {
					$.Alert('Error', 'Failed to render page');
				}
			});
			render.showPDFDialog();

			return render;
		},
		getRenderExtraPostData: function() {
			var pageDimensions = this.getOuterDimensions('Right', {
				includeWhiteSpace: true
			});

			let data = {
				pageDimensions: JSON.stringify({
					inchWidth: pageDimensions.width,
					inchHeight: pageDimensions.height,
					pixelWidth: pageDimensions.width * $.PDF_PIXEL_RATIO,
					pixelHeight: pageDimensions.height * $.PDF_PIXEL_RATIO
				})
			};

			let snapshotId = $.getGETParams().snapshotId;
			if(snapshotId) {
				data.snapshotId = snapshotId;
			}

			return data;
		},
		renderSinglePage: function(page) {
			var missingSubjectsError = this.getMissingSubjectsError();
			if(missingSubjectsError) {
				$.Alert('Error', missingSubjectsError);
				return;
			}

			var isProduction = !!$.userPermissions.productionPDF;
			var render = new $.PageRender($.FlowPageSubSet(this, [page]), {
				pageOffset: (page.getPageNumber() % 2),
				production: isProduction,
				flattenPDF: $.getProjectSetting('renderFlatPdf', false) || !isProduction,
				batchName: 'Page ' + (page.getPageNumber() - $.PAGE_OFFSET),
				blockEmail: true,
				displayCropMarks: (isProduction ? $.getStudioSetting('renderCropMarks', false) : false),
				backgroundRender: (!$.getGETParams().debugPrince && !$.getGETParams().debugPrinceStart),
				includeBatches: true,
				extraPostData: this.getRenderExtraPostData(),
				includeAllBatches: page.type.indexOf('index') !== -1,
				hideBleed: !isProduction && this.layoutDimensions && this.layoutDimensions.hideBleed,
				onError: function () {
					$.Alert('Error', 'Failed to render page');
				}
			});
			render.showPDFDialog();

			return render;
		},
		isSubmitted: function() {
			return this.status == 'Submitted';
		},
		forceGrayscale: function() {
			return $.getProjectSetting('forceGrayscale', false);
		},
		showErrorIfPagesNotCorrect: function() {
			var pageLimit = this.getPageLimit();
			var totalPages = this.getTotalPages();

			var missingSubjectsError = this.getMissingSubjectsError();
			if(missingSubjectsError) {
				$.Alert('Error', missingSubjectsError);
				return true;
			}

			if(pageLimit > 0 && (totalPages - 4) > pageLimit) {
				$.Alert('Error', 'You are past your allocated page limit and will not be able to submit this book.  You need to either remove candid pages or fit more subjects onto each class page.');
				return true;
			} else if(pageLimit > 0 && (totalPages - 4) === pageLimit) {
				// Allow odd numbers if it is exactly the same as the specified page limit
				return false;
			} else if(this.requireMultipleOfFour() && totalPages % 4 != 0) {
				$.Alert('Error', 'Pages must be a multiple of 4');
				return true;
			} else if(!this.requireMultipleOfFour() && totalPages % 2 != 0) {
				$.Alert('Error', 'Pages must be a multiple of 2');
				return true;
			} else if(totalPages <= 4) {
				$.Alert('Error', 'You cannot submit an empty book');
				return true;
			}
			
			return false;
		},
		requireMultipleOfFour: function() {
			var layoutDimensions = this.getLayoutDimensions();
			if(layoutDimensions) {
				return layoutDimensions.requireMultiplesOfFour !== false && layoutDimensions.requireMultiplesOfFour !== 0;
			} else {
				return true;
			}
		},
		getMissingSubjectsError: function() {
			for(var i = 0; i < this.pages.length; i++) {
				var page = this.pages[i];
				if(page.getMissingSubjectsError) {
					var error = page.getMissingSubjectsError();
					if(error) {
						return error;
					}
				}
			}

			return null;
		},
		getActiveUsersPageSet: function() {
			var currentUser = $.LoggedInAsUser || $.CurrentUser;
			
			if(currentUser.pageAssignments && $.isArray(currentUser.pageAssignments) && this.pages.length) {
				// Get list of valid pages based on page numbers
				var pages = currentUser.pageAssignments.map(function(pageId) {
					return obj.getPageById(pageId);
				}).filter(function(page) {
					return !!page;
				});
				for(let i = 0; i < pages.length; i++) {
					let checkPage = pages[i];
					while(checkPage.getOverflowPage?.()) {
						let overflowPage = checkPage.getOverflowPage();
						if(pages.indexOf(overflowPage) === -1) {
							pages.push(overflowPage);
						}

						checkPage = overflowPage;
					}
				}
				pages.numberSort('pageNumber');

				// Make sure that any loner pages are displayed by themselves
				let i = 0;
				if(pages.length && pages[i].pageNumber % 2 === 1) {
					i++;
				}
				for(; i < pages.length; i += 2) {
					// First page should always be even
					if(pages[i].pageNumber % 2 === 1) {
						pages.splice(i, 0, null);
						continue;
					}

					// Last page is single already
					// eslint-disable-next-line
					if((i + 1) === pages.length) {
					} else if(pages[i].pageNumber !== (pages[i + 1].pageNumber - 1)) {
						pages.splice(i + 1, 0, null);
					}
				}

				if(window.Bugsnag && window.Bugsnag.user) {
					window.Bugsnag.user.pageAssignments = pages.map(function(page) {
						if(page) {
							return {
								id : page.id,
								pageNumber: page.pageNumber - $.PAGE_OFFSET
							}
						} else {
							return null;
						}
					});
				}

				return new $.YearbookSubSet(this, pages);
			} else {
				return this;
			}
		},
		getActiveUsersPageOffset: function() {
			var currentUser = $.LoggedInAsUser || $.CurrentUser;

			if(currentUser.pageAssignments && $.isArray(currentUser.pageAssignments) && currentUser.pageAssignments.length) {
				var firstPageNumber = null;
				currentUser.pageAssignments.forEach(function(pageId) {
					var page = obj.getPageById(pageId);
					if(page && (!firstPageNumber || page.pageNumber < firstPageNumber)) {
						firstPageNumber = page.pageNumber;
					}
				});

				return (firstPageNumber || 1) % 2;
			} else {
				return 1;
			}
		},
		getMaxInsertIndex: function() {
			// If 40 pages, then index 38/39 are back covers so we don't want to allow anything past index 37
			return this.pages.length - 3;
		},
		addComment: function (comment) {
			var id = comment.id = $.getUniqueId();
			this.jsonPropertyChange('comments', id, comment, {
				permanent: true
			});
		},
		onCheckComment: function(comment) {
			this.jsonPropertyChange('comments', comment.id, comment, {
				permanent: true
			});
		},
		markCommentSeen: function(comment) {
			this.jsonPropertyChange('comments', comment.id, comment, {
				permanent: true,
				extras: {
					seen: true
				}
			});
		},
		getComments: function () {
			var comments = [];
			for(var id in this.comments) {
				// eslint-disable-next-line
				if(this.comments.hasOwnProperty(id) && this.comments[id]) {
					comments.push(this.comments[id]);
				}
			}

			return comments;
		},
		shouldPageExistInSet: function(pageId) {
			let currentUser = $.LoggedInAsUser || $.CurrentUser;
			
			if(currentUser.pageAssignments && $.isArray(currentUser.pageAssignments) && this.pages.length) {
				return currentUser.pageAssignments.includes(pageId);
			} else {
				return true;
			}
		},

		addFavoriteBackground: function(theme) {
			this.favoriteBackgrounds[theme.Background.id] = theme.Background;

			this.db.queueChange({
				scope: 'favoriteBackgrounds',
				name: theme.Background.id,
				value: theme.Background
			});

		},
		deleteFavoriteBackground: function(theme) {
			delete this.favoriteBackgrounds[theme.Background.id];

			this.db.queueChange({
				scope: 'favoriteBackgrounds',
				name: theme.Background.id,
				value: null
			});
		},
		rewriteQRCode: function(text) {
			if(text.startsWith('http') || (text.includes('.') && !text.includes(' '))) {
				if(text.includes('/go/')) {
					return text;
				} else {
					let host = window.location.origin + '/';
					if(typeof process !== 'undefined') {
						if(process?.env?.VUE_APP_DEFAULT_HOST) {
							host = process.env.VUE_APP_DEFAULT_HOST;
						}
					}
					return host + 'qr?link=' + encodeURIComponent(text);
				}
			} else {
				return text;
			}
		},

		applyBackgroundToAllPages: function(page, layout) {
			$.userEvents.startGroupedEvents();
			try {
				this.pages.forEach(otherPage => {
					if(otherPage === page || otherPage?.type?.toLowerCase().includes('cover')) {
						return;
					}

					otherPage.copyFromPage(page, {
						theme: true,

						layout: false,
						candids: false,
						texts: false,
						title: false,
						labelStyles: false,
						portraitEffects: false,
						margins: false
					});
				});
			} finally {
				$.userEvents.stopGroupedEvents();
			}

			layout?.parent?.updatePagesAfterChange(true);
		},

		jobId: $.getGETParams().jobId,
		status: 0,
		pageMargins: {},
		bleedMask: true,
		comments: {},
		changeScope: 'yearbook',
		allowPartialUpdates: true,
		favoriteBackgrounds: {},
		reviewedItems: {}
	});
	$.BookPageNumbersModule(obj);

	obj.db = new $.YearbookDB(obj, progressIndicator, usersLoggedIn);
	// Store again so if we remove it we can re-add it when setting is changed
	obj._getPageNumberPosition = obj.getPageNumberPosition;

	return obj;
};