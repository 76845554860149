$.LayoutSet = function(settings) {
	var obj = new $.FlowPageClassSet($.extend({
		load: function (events) {
			this.db.load(events);
		},

		layoutId: $.getGETParams().layoutId,
		bleedMask: true
	}, settings));

	obj.db = new $.LayoutsDB(obj, settings);

	return obj;
};