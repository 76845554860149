$.FlowPageClassSet = function(settings) {
	var obj = new $.FlowPageSet(settings);

	$.extend(obj, {
		getClasses: function () {
			return this.classes;
		},
		getClass: function (index) {
			return this.classes[index];
		},
		getClassById: function(id) {
			return this.classes.getMatch({id: parseInt(id)}, 'id');
		},
		getPageForClass: function (classId) {
			if (classId && classId.id) {
				classId = classId.id;
			}

			for(var i = 0; i < this.pages.length; i++) {
				var page = this.pages[i];
				if(!page) {
					continue;
				}

				if (page.classObj && page.classObj.id == classId) {
					return page;
				} else if (page.extraClasses && page.extraClasses.length) {
					for (var j = 0; j < page.extraClasses.length; j++) {
						if (page.extraClasses[j].id == classId) {
							return page;
						}
					}
				}
			}

			return null;
		},

		getProgress: function () {
			var count = 0;
			for (var i = 0; i < this.classes.length; i++) {
				if (this.classes[i].active) {
					count++;
				}
			}
			return count;
		},
		getTotalProgress: function () {
			return this.classes.length;
		},
		removeClassFromPage: function (batch) {
			for(var i = 0; i < this.pages.length; i++) {
				var page = this.pages[i];
				if(!page) {
					continue;
				}

				if (page.classObj && page.classObj.id == batch.id) {
					page.removeClassObj(page.classObj);
					return true;
				} else if (page.extraClasses && page.extraClasses.length) {
					for (var j = 0; j < page.extraClasses.length; j++) {
						var extraBatch = page.extraClasses[j];
						if (extraBatch.id == batch.id) {
							// Just remove this extraClass
							page.removeClassObj(extraBatch);
							return true;
						}
					}
				}
			}

			return false;
		},
		copySubjectLabelStylesToOthers: function(fromPage) {
			if (fromPage.getStudentLabelCSSStyles) {
				var studentLabelCSS = fromPage.getStudentLabelCSSStyles();
				
				this.pages.forEach(function(toPage) {
					if(toPage == fromPage || !toPage) {
						return;
					}

					if(toPage.setStudentLabelCSSStyles) {
						toPage.setStudentLabelCSSStyles(studentLabelCSS);
					}
				});
			} else {
				throw 'Page does not have getStudentLabelCSS to copy';
			}
		},
		isClassSizeAvailable: function() {
			return this.classSizeAvailable;
		},
		setSubjectTemplate: function(subjectTemplate) {
			this.subjectTemplate = subjectTemplate;
		},
		getSubjectTemplate: function() {
			return this.subjectTemplate;
		},
		getTemplateFields: function() {
			if(this.subjectTemplate) {
				var fields = [];
				for (var i = 0; i < this.subjectTemplate.subject_fields.length; i++) {
					var field = this.subjectTemplate.subject_fields[i];
					fields.push(field.field_key || field.label);
				}

				return fields;
			} else {
				return null;
			}
		},
		getTemplateLabelMap: function() {
			var map = {};

			if(this.subjectTemplate) {
				this.subjectTemplate.subject_fields.forEach(function(field) {
					if(field.field_key && field.field_key != field.label) {
						map[field.label] = field.field_key;
					}
				});
			}

			return map;
		},
		getTemplateFieldMap: function() {
			if(this.subjectTemplate) {
				return $.SubjectManagement.createFieldMap(this.subjectTemplate);
			} else {
				return {};
			}
		},
		getTemplateIdMap: function() {
			var fieldMap = this.getTemplateFieldMap();
			var idMap = {};
			for(var id in fieldMap) {
				idMap[fieldMap[id]] = id;
			}

			return idMap;
		},

		getApplyToAllToolbar(copyPage, options, flowLayoutSet) {
			// Main tests for this are under cells.js -> Subject image effects -> apply to all
			if(!copyPage) {
				return null;
			}
			options = $.extend({
				popup: 'Apply class layout to all pages'
			}, options);

			var classPages = this.pages.filter(function(page) {
				return page && page.setStudentLabelCSS && page != copyPage && (!page.getParentPage || !page.getParentPage()) && !page.getFreeMovementLocked();
			});

			if(classPages.length) {
				return {
					icon: 'edit',
					popup: options.popup,
					singleSelection: true,
					closeOnClick: true,
					onClick: function() {
						obj.openApplyToAllDialog(copyPage, options, flowLayoutSet);
					}
				}
			} else {
				return null;
			}
		},
		openApplyToAllDialog(copyPage, options, flowLayoutSet) {
			var classPages = this.pages.filter(function(page) {
				return page && page.setStudentLabelCSS && page != copyPage && (!page.getParentPage || !page.getParentPage()) && !page.getFreeMovementLocked();
			});
			if(!classPages.length) {
				$.Alert('Error', 'No other class pages to apply to');
				return;
			}

			options = $.extend({
				layout: true,
				title: false,
				portraitEffects: true,
				labelStyles: true,
				theme: true,
				candids: true,
				texts: true,
				margins: true
			}, options);

			let settings = [
				{
					id: 'layout',
					type: 'checkbox',
					description: 'Layout',
					defaultValue: options.layout
				},
				{
					id: 'title',
					type: 'checkbox',
					description: 'Title',
					defaultValue: options.title
				},
				{
					id: 'portraitEffects',
					type: 'checkbox',
					description: 'Portrait Image Effects',
					defaultValue: options.portraitEffects
				},
				{
					id: 'labelStyles',
					type: 'checkbox',
					description: 'Portrait Label Styles',
					defaultValue: options.labelStyles
				},
				{
					id: 'theme',
					type: 'checkbox',
					description: 'Background',
					defaultValue: options.theme
				},
				{
					id: 'candids',
					type: 'checkbox',
					description: 'Images on page',
					defaultValue: options.candids
				},
				{
					id: 'texts',
					type: 'checkbox',
					description: 'Texts on page',
					defaultValue: options.texts
				}
			];
			if($.getProjectSetting('allowMarginChanges', true)) {
				settings.push({
					id: 'margins',
					type: 'checkbox',
					description: 'Margins',
					defaultValue: options.margins
				});
			} else {
				options.margins = false;
			}

			$.SettingsBuilderDialog([
				{
					id: 'what',
					type: 'section',
					description: 'What to apply',
					settings
				},
				{
					id: 'who',
					type: 'section',
					description: 'Pages to apply to (Excludes locked pages)',
					settings: [
						{
							id: 'pageIds',
							type: 'dropdown',
							options: classPages.map(function(page) {
								return {
									id: page.id,
									name: page.getTitleText()
								};
							}),
							multiple: true,
							defaultValue: classPages.map(function(page) {
								return page.id
							}).join(',')
						}
					]
				}
			], {
				title: 'Apply To All Class Pages',
				onSettingsApplied: function(settings) {
					obj.applyToAllWithSettings(copyPage, classPages, settings);
					if(flowLayoutSet) {
						flowLayoutSet.updatePagesAfterChange(true);
					}
				},
				onShow: function(content) {
					content.find('#settings-who .checkboxField.disabled')
						.css('opacity', '1')
						.find('label')
						.css('opacity', '1')
				}
			});
		},
		applyToAllWithSettings: function(copyPage, classPages, settings) {
			$.userEvents.startGroupedEvents();
			try {
				classPages.forEach(function(page) {
					if(page == copyPage || !settings.pageIds || !settings.pageIds.includes(page.id)) {
						return;
					}
					
					page.copyFromPage(copyPage, settings);
				});
			} catch(e) {
				console.warn('Failed to apply to all: ', e);
			} finally {
				$.userEvents.stopGroupedEvents();
			}
		},
		
		classSizeAvailable: true,
		classes: []
	});

	return obj;
};