$.YearbookDB = function(yearbook, progressIndicator, usersLoggedInLabels) {
	let postData = {
		jobId: yearbook.jobId,
		composer: true
	};
	let snapshotId = $.getGETParams().snapshotId;
	if(snapshotId) {
		postData.snapshotId = snapshotId;
	}

	var obj = new $.ComposerDBQueue({
		loadUrl: 'ajax/getYearbook.php',
		saveUrl: 'ajax/saveYearbook.php',
		postData: postData,
		pageSet: yearbook,
		onlyComments: false,
		progressIndicator: progressIndicator,
		usersLoggedInLabels: usersLoggedInLabels,
		getSocket: function() {
			var url = 'wss?composer=' + yearbook.jobId + '&token=' + $.PlicToken + '&type=job';
			if($.LoggedInAsUser) {
				url += '&originalToken=' + $.OriginalUserToken;
			}

			return url;
		},
		userEvents: $.userEvents
	});
	var _loadPages = obj.loadPages;

	$.extend(obj, {
		loadPages: function() {
			_loadPages.apply(this, arguments);

			if(this.pageSet.theme && this.pageSet.theme.register) {
				this.pageSet.updateTextThemeStyles({}, this.pageSet.theme);

				this.pageSet.setProperty('theme', $.extend(true, {}, this.pageSet.theme, {
					register: false
				}), true, true);
			}

			// Link cover pages together so editing content in center will open spread
			this.pageSet.pages[0].openSpreadOnEditCenterContent = this.pageSet.pages[this.pageSet.pages.length - 1];
			this.pageSet.pages[this.pageSet.pages.length - 1].openSpreadOnEditCenterContent = this.pageSet.pages[0];
		},
		loadModel: function(data) {
			if($.getProjectSetting('forceGrayscale', false)) {
				$.primarySubjectPhotoVersion = 'original';
				$.primaryGraphicPhotoVersion = 'original';
			}

			if(!$.userPermissions.allowEditing) {
				if($.userPermissions.addComments) {
					this.setOnlyCommentsAllowed(true);
				} else {
					this.disableSaving();
				}
			}

			// Reload license so we don't have to log out and back in to fix this
			if($.plicLicenseFeatures['is-demo']) {
				obj.includeLicenseData = true;
			}
			// Check studio license for stuff like is-demo if we are a lab user looking at a studio's book
			else if(data.parentOrgPlicId !== data.userPlicOrgId && data.userPlicOrgId === data.plicOrgId && data.role === 'lab_role') {
				obj.includeStudioLicenseData = data.parentOrgPlicId;
			}
		},
		loadSinglePage: function (definition, previousPage, options = {}) {
			var page;
			switch (definition.type) {
				case 'cover':
					page = new $.YearbookCoverPage(null, definition.page <= 2 ? 'front' : 'back');
					if (this.pageSet.coverLocked) {
						page.setLocked(true);
					}
					break;
				case 'class':
					page = new $.YearbookClassPage(definition.classObj);

					if (definition.extraClasses) {
						page.extraClasses = definition.extraClasses;
					}
					if (definition.extraTitles) {
						if(typeof definition.extraTitles == 'string') {
							page.extraTitles = JSON.parse(definition.extraTitles);
						} else {
							page.extraTitles = definition.extraTitles;
						}

						if ($.isArray(page.extraTitles)) {
							var extraTitles = page.extraTitles;
							page.extraTitles = {};
							for (var i = 0; i < extraTitles.length; i++) {
								page.extraTitles[i] = extraTitles[i];
							}
						}
					}

					// Fix if we get stranded with no classObj
					if (!definition.classObj && definition.extraClasses && definition.extraClasses.length) {
						var newMainClass = page.extraClasses.splice(0, 1)[0];

						if (page.extraTitles[newMainClass.id]) {
							page.title = page.extraTitles[newMainClass.id];
							delete page.extraTitles[newMainClass.id];
						}
						page.changeClass(newMainClass);
					}
					if (definition.largeCellPosition) {
						page.largeCellPosition = definition.largeCellPosition;
					}
					page.viableCells = definition.viableCells;
					break;
				case 'classOverflow':
					page = new $.YearbookClassPageOverflow(previousPage);
					page.viableCells = definition.viableCells;
					break;
				case 'title':
					definition.type = 'candid';
					// eslint-disable-next-line
				case 'candid': case 'personalized-page':
					page = new $.YearbookCandidPage();
					break;
				case 'autograph':
					page = new $.YearbookAutographPage();
					break;
				case 'insideCover':
					page = new $.YearbookInsideCoverPage();
					break;
				case 'empty': case 'generic':
					if (definition.texts && (definition.texts.length || $.getObjectCount(definition.texts) > 0) ||
						definition.candids && (definition.candids.length || $.getObjectCount(definition.candids) > 0)) {
						page = new $.YearbookCandidPage();
					} else {
						page = new $.YearbookPlaceholder();
					}
					break;
				case 'index':
					page = new $.YearbookIndexPage();
					break;
				case 'indexOverflow':
					page = new $.YearbookIndexOverflowPage(previousPage);
					break;
			}

			if(definition.type == 'cover') {
				// PHP likes to do blank arrays for {}
				if(($.isArray(definition.layout) && !definition.layout.length) || !$.isInit(definition.layout)) {
					definition.layout = {};
				}

				if($.isPlainObject(definition.layout)) {
					if (definition.layout.grid) {
						delete definition.layout.grid;
					}
				}
			}

			if (page) {
				if (definition.theme) {
					page.theme = definition.theme;
				}

				// Work around for old candid (and by extension 'empty') layouts not having layout defined by default
				if (!definition.layout && definition.type != 'insideCover') {
					definition.layout = {};
				}

				this.loadStandardPageData(definition, page);

				if(definition.type === 'candid' && options.load === true) {
					if(page.extras && page.extras.layoutProperties && page.extras.layoutProperties.adIds && page.getAdIdsInPage) {
						let pageAdIds = page.extras.layoutProperties.adIds.filter((id, index, self) => self.indexOf(id) === index);
						let actualAdIds = page.getAdIdsInPage();
						
						pageAdIds.sort();
						actualAdIds.sort();

						if(!$.arrayEquals(pageAdIds, actualAdIds)) {
							let layoutProperties = $.extend(true, {}, page.extras.layoutProperties);
							layoutProperties.adIds = actualAdIds;
							page.setExtraProperty('layoutProperties', layoutProperties);

							try {
								console.warn('Auto fixed broken adIds list');
								Bugsnag.notify(new Error('Auto fixed broken adIds list'), function(event) {
									event.addMetadata('data', {
										startAdIds: pageAdIds,
										expectedAdIds: actualAdIds,
										pageNumber: page.pageNumber - 2,
										pageId: page.id
									});
								});
							} catch(e) {
								console.error(e);
							}
						}
					}
				}
			}

			return page;
		},
		getEntirePageData: function(page) {
			var extraClasses = null;
			if (page.extraClasses) {
				extraClasses = [];
				for (var i = 0; i < page.extraClasses.length; i++) {
					extraClasses.push(page.extraClasses[i].id);
				}
			}

			// Don't save overflow layout
			if (page.type == 'classOverflow') {
				page.layout = null;
			}

			return {
				id: page.id,
				candids: page.candids,
				classObj: page.classObj ? {
					'id': page.classObj['id']
				} : null,
				extraClasses: extraClasses,
				layout: page.layout ? page.layout : null,
				pageNumber: page.pageNumber,
				studentLabelCSS: page.studentLabelCSS ? JSON.stringify(page.studentLabelCSS.css) : null,
				texts: page.texts,
				theme: page.theme ? page.theme : null,
				title: page.title ? page.title : null,
				extraTitles: page.extraTitles,
				type: page.type,
				largeCellPosition: page.largeCellPosition ? page.largeCellPosition : null,
				viableCells: page.viableCells ? page.viableCells : null,
				extras: page.extras ? page.extras : null,
				comments: page.comments,
				subjectCellData: page.subjectCellData,
				pageNumberCSS: page.pageNumberCSS ? JSON.stringify(page.pageNumberCSS.css) : null
			};
		},
		formatLastPageSeen: function(page) {
			if(isNaN(page)) {
				return page;
			} else if(page < 2) {
				return 'the front cover';
			} else if(page > (this.pageSet.getTotalPages() - 2)) {
				return 'the back cover';
			} else {
				return 'page ' + Math.max(1, (page - 2));
			}
		},
		includeOrganizationData: true
	});

	return obj;
};