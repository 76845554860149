$.extend(true, window.locales.en, {
	ad: {
		type: 'Ad',
		sizes: {
			'Full Page': 'Full Page',
			'Half Page': 'Half Page',
			'Quarter Page': 'Quarter Page',
			'1/8th Page': '1/8th Page',
			'1/9th Page': '1/9th Page',
			'1/10th Page': '1/10th Page',
			'1/12th Page': '1/12th Page',
			'1/14th Page': '1/14th Page',
			'1/16th Page': '1/16th Page'
		}
	},
	composer: {
		behaviors: {
			alignSubjectsToCenter: 'Align subjects to center',
			colorPage: 'Color page',
			classBehaviors: 'Class Behaviors',
			pageBehaviors: 'Page Behaviors'
		},
		licenseNotEnough: 'You do not have enough Licenses to activate this book.',
		licenseNotValid: 'This yearbook is not licensed for use.  Contact %{org} for assistance.',
		licenseUsed: 'A license has been used from your account for this book',
		qualityReport: 'Quality Report',
		projectManagement: 'Project Management',
		subjectIndex: 'Subject Index'
	},
	management: {
		advisorDashboard: {
			adOrdersOpen: 'Ad Orders Open',
			batches: 'Batches In Book',
			blankPages: '%{pages} blank pages',
			bookDetails: 'Book Details',
			booksOrdersOpen: 'Book Orders Open',
			booksOrdered: 'Books Ordered (Includes cash)',
			coverSubmitted: 'Cover Submitted',
			currentPrice: 'Current Price',
			daysLeft: 'Days Left',
			daysLeftPastDue: '%{days} days past due',
			daysLeftRemaining: '%{days} days remaining',
			myDesigns: 'My Designs',
			pages: 'Pages',
			purchasedDesigns: 'Purchased Designs',
			store: 'Store',
			welcomeTitle: 'Message from %{studio}'
		},
		backgrounds: {
			defaultToAll: 'Defaults to all',
			editDetails: 'Edit Details',
			showForBookFormats: 'Show on these Book Formats'
		},
		candids: {
			approveCandids: 'Approve for album',
			awaitingApproval: '%{count} Awaiting Approval',
			candidsAwaitingApproval: '%{count} candids awaiting approval',
			denyCandids: 'Deny candids',
			downloadAllAlbums: 'Download All Albums',
			downloadCandidsTooltip: 'Download candid images to a zip file.  This may take a while.',
			downloadSingleAlbum: 'Download Single Album',
			guestUrlTitle: 'Guest URL',
			guestUrlTooltip: 'Guest upload page for the selected album',
			guestUrlAllowAll: 'Allow changing selected album',
			guestUrlAllowAllTooltip: 'When checked, the generated url will allow users to change which album they are uploading to',
			incomingCandids: 'Incoming Candids',
			undoDeniedCandids: 'Undo denied candids',
			viewDeniedCandids: 'View Denied Candids'
		},
		contentBooks: {
			artworkBooks: 'Artwork Books',
			contentBook: 'Content Book',
			failedToGenerate: 'Failed to generate Content Book',
			fontBook: 'Font Book',
			generateBackgroundsBook: 'Generate Backgrounds Book',
			generateClipArtBook: 'Generate Clip Art Book',
			generateFontBook: 'Generate Font Book',
			generateThemesBook: 'Generate Themes Book',
			generatedBookMessage: 'Your Content Book is complete and available for <a target="_blank" href="%{downloadUrl}">download</a>',
			generatingBookTitle: 'Generating Book',
			generatingBookMessage: 'We have started generating your Content Book.  This could take a while so we will send you an email when it is complete if you do not wish to wait.'
		},
		dashboard: {
			bookStores: 'Book Stores',
			title: 'Dashboard',
			totalYearbookProjects: 'Total Yearbook Projects',
			yearbooksInProgress: 'Yearbooks in Progress',
			yearbooksPastDue: 'Yearbooks Past Due Date',
			yearbooksSubmitted: 'Yearbooks Submitted'
		},
		downloadFlyer: 'Download Flyer',
		goToProjectButton: 'Go to Book',
		licenseCount: '%{count} Licenses',
		noLicenses: 'No License',
		orders: {
			accountType: 'Account Type',
			addOn: 'Add-On',
			adFields: 'Ad Checkout Personal Info',
			amountCollected: 'Amount Collected',
			bindingType: 'Binding Type',
			bookFields: 'Book Checkout Personal Info',
			booksSold: 'Books Sold',
			chargeId: 'Charge ID',
			connectedAccount: 'Connected!',
			connectAccount: 'Connect Account',
			createOrderButton: 'Create Order',
			customerNotes: 'Customer Notes',
			customerNotesRequired: 'Required',
			customerNotesNotRequired: 'No notes',
			deleteOrderButton: 'Delete Order',
			editInfo: 'Edit Info',
			email: 'Email',
			exportOrdersButton: 'Export as CSV',
			fieldLabel: 'Field Label',
			filterDispute: 'Filter: Disputed',
			filterPaid: 'Filter: Paid',
			filterRefunded: 'Filter: Refunded',
			importOrdersButton: 'Import orders from CSV',
			informationButton: 'Order Information',
			manageStoreButton: 'Manage Book Store',
			merchantAccount: 'Merchant Account',
			merchantFee: 'Merchant Fee',
			newAddOn: 'New Add-On',
			newField: 'New Field',
			personalization: 'Personalization',
			personalizationNotes: 'Personalization Notes',
			platformFee: 'Platform Fee',
			openPrintWindowButton: 'Open window to print',
			orderNumber: 'Order #',
			ordersTab: 'Orders Tab',
			quantity: 'Quantity',
			refundOrder: 'Refund Order',
			refundOrderConfirmationTitle: 'Do you really want to refund this order?',
			refundOrderConfirmationMessage: 'This will take money out of your account.  The Captura Yearbooks Store fee will be refunded, but Stripe\'s fee will not.  Your account will still be charged for $%{amount}.',
			removeAddOn: 'Remove Add-On',
			removeField: 'Remove field',
			shipping: 'Shipping',
			shippingAddress1: 'Address Line 1',
			shippingAddress2: 'Address Line 2',
			shippingCity: 'City',
			shippingState: 'State/Province',
			shippingPostal: 'Zip/Postal Code',
			state: 'State',
			stateDisputed: 'Disputed',
			'stateIn-Dispute': 'In Dispute',
			statePaid: 'Paid',
			stateOpen: 'Open',
			stateRefunded: 'Refunded',
			storeAddOns: 'Add-Ons',
			storeDescription: 'Description',
			storeLastPurchaseDate: 'Last Purchase Date',
			storeOrders: 'Store Orders',
			storeTotal: 'Store Totals',
			storeLinkTitle: 'Store',
			storeLinkTooltip: 'Link and code to your schools Books store',
			storePrice: 'Price',
			storeTitle: 'Title',
			storeMaximumBooks: 'Maximum Sold Books',
			storeMaximumBooksTooltip: 'Access to the Store will be automatically turned off once this many Books have been sold.  Leave blank or 0 to allow unlimited sales.',
			stripe: 'Stripe',
			studioUnderLabAccountWarning: 'This school is using the Stripe account from it\'s studio instead of the Stripe account setup for %{lab}',
			subTotal: 'Sub Total',
			total: 'Total Paid',
			totalCollected: 'Total Collected',
			type: 'Order Type',
			typeManual: 'Manual',
			typeImported: 'Imported',
			typeStore: 'Store'
		},
		studioSettings: {
			contactInformationHeader: 'Contact Information',
			contactInformationSpecificHeader: 'Captura Yearbooks Contact Information'
		},
		subjectManagement: {
			editPrimaryPose: 'Edit Yearbook Pose'
		},
		users: {
			userRoleFilterTooltip: 'If checked %{role} users who have purchased an Ad or Personalization will be shown'
		},
		yearbooks: {
			createYearbookButton: 'Create Yearbook',
			goToYearbookButton: {
				title: 'Yearbook',
				tooltip: 'Go to yearbook'
			},
			school: 'School'
		}
	},
	projects: {
		name: 'Yearbook Name',
		project: 'Yearbook',
		projectInformation: 'Basic yearbook information',
		projects: 'Yearbooks',
		projectSettings: 'Yearbook Settings',
		projectSettingsDescription: 'Advanced yearbook settings'
	},
	organizations: {
		name: 'School Name',
		newOrganization: 'Create new school',
		organization: 'School',
		organizationChoice: 'Choose your school'
	},
	tabs: {
		ads: 'Ads',
		adPageWizard: 'Create Ad',
		adTemplates: 'Ad Templates',
		albumUploader: 'Album Uploader',
		bookFormats: 'Book Formats',
		bookFormatInformation: 'Book Format Information',
		bookOverview: 'Book Overview',
		candidPhotos: 'Candid Photos',
		candidsIncoming: 'Candid Review',
		candidsIncomingDenied: 'Denied Candids',
		contentBooks: 'Content Books',
		createStudio: 'Create Studio',
		events: 'Events',
		eventApproval: 'Event Approval',
		eventInformation: 'Event Information',
		eventStory: 'Event Photos',
		globalLayoutEditor: 'Global Layout Editor',
		globalLayouts: 'Global Layouts',
		globalLegacyEditor: 'Global Legacy Editor',
		globalThemes: 'Global Themes',
		layouts: 'Layouts',
		needsReview: 'Yearbooks In Review',
		orders: 'Orders',
		organizationInformation: 'Organization Information',
		parentSubjectUploads: 'Parent Subject Uploads',
		partnerAccounts: 'Partner Accounts',
		partnerAccountInformation: 'Partner Account Information',
		personalizations: 'Personalizations',
		yearbookPersonalizationEditCover: 'Edit Cover Personalization',
		projectSettings: 'Project Settings',
		projectWizard: 'Create Yearbook',
		publisherOrderWizard: 'Create Yearbook Order',
		publisherOrders: 'Publisher Orders',
		schoolLayouts: 'School Layouts',
		schoolThemes: 'School Themes',
		seasonInformation: 'Season Information',
		studioLayouts: 'Studio Layouts',
		subjectOrderEmailInformation: 'Order Email Information',
		subjectOrderInformation: 'Order Information',
		subjectOrderLineItem: 'Order Line Item',
		subjectOrderReceipt: 'Order Receipt',
		subjectOrderShippingInformation: 'Order Shipping Information',
		subjectUploader: 'Subject Uploader',
		themes: 'Themes',
		workflows: 'Workflows',
		workflowInformation: 'Workflow Information',
		userPageAssignments: 'User Page Assignments',
		yearbooks: 'Yearbooks',
		yearbookPersonalizationWizard: 'Create Personalization',
		yearbookStoreInformation: 'Yearbook Store Information',
		yearbookStoreSettings: 'Yearbook Store Settings'
	},
	uploader: {
		guest: {
			completeMessage: 'Your images have been successfully uploaded. Your school has received them and are now in the approval process.',
			selectAlbum: 'Select yearbook album to upload to',
			subtitle: 'Upload your candids for the %{schoolName} yearbook album %{albumName}',
			tags: 'Use photo tags to describe the image(s) you are uploading. This will help organization significantly when adding to your yearbook.'
		}
	}
});