import HeicWorker from './heic.worker.js';

let running = 0;
const MAX = 5;
const queue = [];
export default function runHeicConversion(blob) {
	return new Promise((resolve, reject) => {
		queue.push({
			blob,
			resolve,
			reject
		});

		if(running < MAX) {
			runNextQueue();
		}
	});
}

function runNextQueue() {
	const waitingJob = queue.pop();
	if(waitingJob) {
		running++;
		startWorker(waitingJob.blob).then(waitingJob.resolve).catch(waitingJob.resolve).finally(() => {
			running--;

			runNextQueue();
		});
	}
}

function startWorker(blob) {
	return new Promise((resolve, reject) => {
		const worker = new HeicWorker();
		worker.onmessage = (message) => {
			if(message.data.imageData) {
				const canvas = document.createElement('canvas');
				canvas.width = message.data.imageData.width;
				canvas.height = message.data.imageData.height;
				canvas.getContext('2d').putImageData(message.data.imageData, 0, 0);

				canvas.toBlob((blob) => {
					resolve(blob);
				}, message.data.toType);
			} else if(message.data.arrayBuffer) {
				const blob = new Blob([message.data.arrayBuffer], {
					type: message.data.toType
				});
				resolve(blob);
			} else if(message.data.error) {
				reject(message.data.error);
			} else {
				reject('Unknown response from worker');
			}

			worker.terminate();
		};
		blob.arrayBuffer().then(buffer => {
			worker.postMessage({
				buffer
			}, [buffer]);
		}).catch(reject);
	});
}