$.AdDB = function(ad, settings) {
	var obj = new $.ComposerDBQueue($.extend({
		loadUrl: 'ajax/getAdPage.php',
		saveUrl: 'ajax/saveAdPage.php',
		postData: {
			adId: ad.adId
		},
		pageSet: ad,
		userEvents: $.userEvents,
		loadSubjects: false,
		getSocket: function() {
			var url = 'wss?composer=' + ad.adId + '&token=' + $.PlicToken + '&type=ad';
			if($.LoggedInAsUser) {
				url += '&originalToken=' + $.OriginalUserToken;
			}

			return url;
		}
	}, settings));

	$.extend(obj, {
		loadSinglePage: function (definition, previousPage) {
			var page = $.AdPage();
			this.loadStandardPageData(definition, page);

			return page;
		},
		getEntirePageData: function(page) {
			return {
				id: page.id,
				pageNumber: page.pageNumber,
				candids: page.candids,
				texts: page.texts,
				type: 'ad',
				extras: page.extras ? page.extras : null
			};
		}
	});

	$.UserActivityModule(obj, {
		showLastSeen: false,
		activitySubjectLabel: 'Ad'
	});
	return obj;
};