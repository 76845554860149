$.YearbookCandidPage = function(startSettings) {
	var obj = new $.FlowPage();
	obj._setFreeMovementLocked = obj.setFreeMovementLocked;
	var _setExtraProperty = obj.setExtraProperty;
	var _onRemove = obj.onRemove;
	var getLayout = obj.getLayout;
	var addComment = obj.addComment;
	var addCandid = obj.addCandid;
	var addText = obj.addText;
	var removeCandid = obj.removeCandid;
	var removeText = obj.removeText;

	$.extend(obj, {
		getLayout: function() {
			var layout = getLayout.apply(this, arguments);

			if(layout && layout.grid) {
				delete layout.grid;
			}

			return layout;
		},
		setFreeMovementLocked: function (locked) {
			this._setFreeMovementLocked(locked);

			this.lockOption.name = locked ? 'Unlock' : 'Lock';
		},
		updatePageLabel: function () {
			if(this.type == 'cover') {
				// Don't change
			} else if(this.type == 'insideCover') {
				this.pageLabelDisplay = 'Inside Cover';
			} else {
				this.pageLabelDisplay = '';
			}

			this.lockOption = {
				name: this.getFreeMovementLocked() ? 'Unlock' : 'Lock',
				icon: 'lock',
				onClick: function (page, layout) {
					var locked = !page.getFreeMovementLocked();
					page.setFreeMovementLocked(locked);

					$(this).html('<i class="' + (locked ? 'unlock' : 'lock') + ' icon"></i>' + (locked ? 'Unlock' : 'Lock'));
					layout.updateMovementLocked();
					layout.updateLabel();
				}
			};

			var sidebar = [];
			if($.userPermissions.lockPages) {
				var locked = this.getFreeMovementLocked();

				// Allow locking/unlocking if one of the following is true:
				// 1) Not locked
				// 2) Legacy locked where it is just true
				// 3) Studio users should be able to unlock stuff Office Admins unlock
				// 4) User rank is more then or equal to the user that locked it
				//     a) School Advisor can unlock a School Advisors
				//     b) School Advisor cannot unlock a Studio/Lab user
				//     c) Studio/Lab can unlock a School Advisors
				if(!locked || !$.isPlainObject(locked) || !$.getStudioSetting('studiosCanLockCustomersOutOfPages', false) || !$.isInit(locked.userRank) || Math.max($.UserRank, 2) <= Math.max(locked.userRank, 2)) {
					sidebar.push(this.lockOption);
				}
			}
			if (this.getExtraProperty('collage', false) && window.recreateCollageFromCandids) {
				sidebar.push({
					name: 'Collage',
					icon: 'photo',
					popup: 'Change candids and recreate collage',
					onClick: window.recreateCollageFromCandids
				});

				sidebar.push({
					name: 'Shuffle Collage',
					icon: 'photo',
					popup: 'Keep the same pictures and shuffle the collage',
					onClick: window.shuffleCollageFromCandids
				});

				if(this.shouldKeepLayoutSizedTogether()) {
					sidebar.push({
						name: 'Unlock Collage',
						icon: 'photo',
						popup: 'Turn off rigid placement and allow moving anywhere. Cannot be undone.',
						onClick: function(page, layout) {
							let collageSettings = $.extend(true, {}, page.extras.collageSettings);
							if(collageSettings.lockedLayout) {
								collageSettings.lockedLayout = false;

								page.setExtraProperty('collageSettings', collageSettings);
								layout.refreshPage();
							}
						}
					});
				}
			}

			// This block is duplicated for Classes!
			if (this.theme && this.isThemeValid()) {
				if(this.theme.type !== 'empty') {
					sidebar.push({
						name: 'Backgrounds',
						icon: 'setting',
						popup: 'Change settings on backgrounds',
						onClick: function (page, layout) {
							layout.openThemeSettings();
						}
					});
				}

				sidebar.push({
					name: 'Apply Background To All',
					icon: '',
					popup: 'Apply background to all other pages',
					onClick: function(page, layout) {
						$.Confirm('Confirm', 'This will apply this background to every page in the book', function() {
							page.pageSet.applyBackgroundToAllPages(page, layout);
						});
					}
				});

				sidebar.push({
					name: 'Revert Background',
					icon: 'picture',
					popup: 'Revert background back to theme',
					onClick: function (page, layout) {
						layout.clearBackground();
					}
				});
			} else if (this.pageSet && this.pageSet.getTheme() && this.pageSet.hasThemePart(this.getThemePartName())) {
				sidebar.push({
					name: 'Themes',
					icon: 'setting',
					popup: 'Change settings on theme',
					onClick: function (page, layout) {
						layout.openThemeSettings();
					}
				});
				sidebar.push({
					name: 'Clear Theme',
					icon: 'picture',
					popup: 'Clear theme on book',
					onClick: function (page, layout) {
						layout.clearTheme();
					}
				});
			}

			// If there are empty frames, add option to fill them
			var empty = false;
			for(var i in this.candids) {
				if (this.candids[i] && !this.candids[i].photo && !this.candids[i].shape) {
					empty = true;
					break;
				}
			}
			if (empty) {
				sidebar.push({
					name: 'Random Fill',
					icon: 'photo',
					popup: 'Fill empty frames with random candids',
					onClick: window.fillEmptyFrames
				});
			}

			sidebar.push({
				name: 'Save Layout',
				icon: 'paint brush',
				popup: 'Save this layout for use on other pages',
				onClick: function() {
					obj.saveCandidLayoutTemplate();
				}
			});

			if(this.type != 'cover' && this.type != 'insideCover') {
				var previewOption = {
					name: 'Preview Page',
					icon: 'location arrow',
					popup: 'Preview PDF of just this page',
					onClick: function() {
						obj.pageSet.renderSinglePage(obj);
					}
				};
				if($.userPermissions.productionPDF) {
					$.extend(previewOption, {
						name: 'Render Page',
						popup: 'Render a production copy of just this page',
					});
				}
				
				sidebar.push(previewOption);
			}

			this.pageLabel = {
				display: this.pageLabelDisplay,
				popup: this.pageLabelPopup + this.getLockedPopupMessage(),
				sidebar: sidebar,
				icon: this.getFreeMovementLocked() ? 'lock' : 'setting'
			};
		},
		getTitle: function () {
			return null;
		},
		setTitle: function () {

		},
		isCommentsEditable: function () {
			return this.commentsEditable && (!this.pageSet || !this.pageSet.isReducedUI || !this.pageSet.isReducedUI('comments'));
		},
		onCheckComment: function(comment) {
			this.arrayPropertyChange('comments');
		},
		addComment: function(comment) {
			comment.seen = this.db.getCurrentUserIds();
			addComment.call(this, comment);
		},
		markCommentSeen: function(comment) {
			this.arrayPropertyChange('comments');
		},
		saveCandidLayoutTemplate: function() {
			let categories;
			$.SettingsBuilderDialog([
				{
					id: 'name',
					type: 'text',
					description: 'Layout Name',
					defaultValue: 'My Layout'
				},
				{
					id: 'saveWithCandids',
					type: 'checkbox',
					description: 'Save with candids'
				},
				{
					id: 'layoutCategory',
					type: 'dropdown',
					description: 'Layout Category',
					defaultFirst: 'Your Layouts',
					loadFunction: (chain, onComplete) => {
						this.getCustomerLayoutCategories(chain, (data) => {
							categories = data;
							onComplete(data);
						});
					}
				}
			], {
				title: 'Save Layout Template',
				onSettingsApplied: function(settings) {
					let category = categories.find(category => category.id == settings.layoutCategory);
					let layout = obj.createLayoutTemplate({
						stripCandids: !settings.saveWithCandids
					});
					obj.createCandidLayoutTemplate(category, layout, settings.name);
				}
			});
		},
		createCandidLayoutTemplate: function(category, layout, layoutName) {
			$.ajax({
				url: 'ajax/createLayout.php',
				data: {
					categoryId: category.id,
					layoutName: layoutName,
					definition: JSON.stringify(layout)
				},
				type: 'POST',
				dataType: 'json',
				success: function(data) {
					// If already cached, add this layout to the cache
					$.layoutCategories.addItemToCategoriesCache(category, data.layout);

					// Force reload
					$.layoutCategories.categories.currentCategory = null;
					$.layoutCategories.slider.currentType = null;
					$.layoutCategories.setCategory(category);
				},
				error: function(jqXHR) {
					if(jqXHR.responseJSON && jqXHR.responseJSON.reason && jqXHR.responseJSON.reason.indexOf('name already exists') != -1) {
						$.Alert('Warning', 'Layout name is already taken.  Please choose a different name.');
					} else {
						$.Alert('Error', 'Failed to save layout template');
					}
				}
			});
		},
		getCustomerLayoutCategories: function(chain, onComplete) {
			var categories = $.layoutCategories.getCurrentCategories();
			var candidLayoutCategory = categories.getMatch({}, 'name', 'Candid Layouts');

			let subCategories = [];
			for(var i = 0; i < candidLayoutCategory.subCategories.length; i++) {
				var subCategory = candidLayoutCategory.subCategories[i];

				if(subCategory.load.posts.customerLayout) {
					subCategories.push(subCategory);
				}
			}
			if(subCategories.length) {
				onComplete(subCategories);
				return;
			}

			var bookFormatIds = null;
			if($.bookFormat && $.bookFormat.formatId) {
				bookFormatIds = [$.bookFormat.formatId];
			}

			// Otherwise we need to create it
			chain.add({
				url: 'ajax/createLayoutCategory.php',
				data: {
					orgId: $.OrgId,
					name: 'Your Layouts',
					bookFormatIds: bookFormatIds,
					returnDuplicate: true
				},
				type: 'POST',
				dataType: 'json',
				success: function(data) {
					let category = data.category;
					category.load = {
						url: 'getLayouts.php',
						posts: {
							type: 'Candid',
							customerLayout: true,
							category: category.id
						},
						createNode: window.createLayout,
						onBeforeFirstLoad: function (data) {
							return data.results.sort(function (a, b) {
								if (a.title == b.title) {
									return 0;
								} else {
									return a.title < b.title ? -1 : 1;
								}
							});
						}
					};

					$.layoutCategories.addCategoryAlphabetical(category, ['Candid Layouts']);
					onComplete([category]);
				}
			});
		},
		getAdIdsInPage: function() {
			return [
				...Object.values(this.candids),
				...Object.values(this.texts)
			].map(obj => obj.adId).filter(adId => !!adId).unique();
		},
		setExtraProperty: function(name, value, options) {
			if(name === 'layoutProperties' && this.pageNumber != 1) {
				if(value && value.adIds) {
					var newAdIds = value.adIds;
					if(value.adIdsMerge) {
						// Change value instead of deleting from original so a repeat action doesn't break merge logic
						var adIds = [];
						if(this.extras && this.extras.layoutProperties && this.extras.layoutProperties.adIds) {
							adIds = $.merge([], this.extras.layoutProperties.adIds);
						}
						newAdIds = newAdIds.filter(function(adId) {
							return !adIds.includes(adId);
						});

						value = {
							adIds: $.merge(adIds, newAdIds)
						};
					} else {
						this.removeAllAdsFromPage();
					}

					newAdIds.forEach(function(adId) {
						obj.adAdToPage(adId);
					});
				}
			}

			_setExtraProperty.apply(this, arguments);
		},
		onRemove: function() {
			this.removeAllAdsFromPage();

			_onRemove.apply(this, arguments);
		},

		adAdToPage: function(adId) {
			var ad = ($.purchasedSubmittedAds || {})[adId];
			if(ad) {
				ad.placements.push(obj.pageNumber);

				if(ad.cachedNode) {
					ad.cachedNode[0].updateCountDisplay();
				}
			}
		},
		removeAllAdsFromPage: function() {
			if(this.extras && this.extras.layoutProperties && this.extras.layoutProperties.adIds) {
				this.extras.layoutProperties.adIds.forEach(function(adId) {
					obj.removeAdFromPage(adId);
				});
			}
		},
		addAdFromPage: function(adId) {
			var ad = ($.purchasedSubmittedAds || {})[adId];
			if(ad && ad.placements.indexOf(this.pageNumber) === -1) {
				ad.placements.push(this.pageNumber);

				if(ad.cachedNode) {
					ad.cachedNode[0].updateCountDisplay();
				}
			}
		},
		removeAdFromPage: function(adId) {
			var ad = ($.purchasedSubmittedAds || {})[adId];
			if(ad) {
				ad.placements.removeItem(obj.pageNumber);

				if(ad.cachedNode) {
					ad.cachedNode[0].updateCountDisplay();
				}
			}
		},

		addCandid: function(candid) {
			addCandid.apply(this, arguments);
			this.checkAddContentPartOfAd(candid);
		},
		removeCandid: function(id) {
			var candid = this.candids[id];
			removeCandid.apply(this, arguments);
			this.checkRemoveContentPartOfAd(candid);
		},
		addText: function() {
			var newObj = addText.apply(this, arguments);
			this.checkAddContentPartOfAd(newObj);

			return newObj;
		},
		removeText: function(id) {
			var text = this.texts[id];
			removeText.apply(this, arguments);
			this.checkRemoveContentPartOfAd(text);
		},
		checkAddContentPartOfAd(obj) {
			if(!obj) {
				return;
			}

			if(obj.adId) {
				var layoutProperties = $.extend(true, {}, this.getExtraProperty('layoutProperties'));
				if(layoutProperties && (layoutProperties.adIds || []).indexOf(obj.adId) === -1) {
					if(layoutProperties.adIds) {
						layoutProperties.adIds.push(obj.adId);
					} else {
						layoutProperties.adIds = [obj.adId];
					}
					this.setExtraProperty('layoutProperties', layoutProperties);
					this.addAdFromPage(obj.adId);
				}
			}
		},
		checkRemoveContentPartOfAd(obj) {
			if(!obj) {
				return;
			}

			if(obj.adId) {
				if(!this.hasContentWithAdId(this.getCandids(), obj.adId) && !this.hasContentWithAdId(this.getTexts(), obj.adId)) {
					var layoutProperties = $.extend(true, {}, this.getExtraProperty('layoutProperties'));
					if(layoutProperties && layoutProperties.adIds && layoutProperties.adIds.indexOf(obj.adId) !== -1) {
						while(layoutProperties.adIds.includes(obj.adId)) {
							layoutProperties.adIds.removeItem(obj.adId);
						}
						this.setExtraProperty('layoutProperties', layoutProperties);
						this.removeAdFromPage(obj.adId);
					}
				}
			}
		},
		hasContentWithAdId: function(objs, adId) {
			for(var id in objs) {
				if(objs[id] && objs[id].adId == adId) {
					return true;
				}
			}

			return false;
		},
		getContentTooltip: function(content) {
			if(content.adId) {
				var placedAd = ($.allAds || []).filter(function(ad) {
					return ad.adId == content.adId;
				})[0];
				var adIdentifier = '';
				if(placedAd) {
					adIdentifier = '<br/>User: ' + placedAd.userEmail;
					if(placedAd.orderId) {
						adIdentifier += '<br/>Order #: ' + placedAd.orderId;
					}

					if(placedAd.order && placedAd.order.lineItems && $.isInit(placedAd.order.lineItemIndex) && placedAd.order.lineItems[placedAd.order.lineItemIndex]) {
						let lineItem = placedAd.order.lineItems[placedAd.order.lineItemIndex];
						if(lineItem.firstName) {
							adIdentifier += '<br/>Student: ' + lineItem.firstName + ' ' + lineItem.lastName;
					
							let keys = Object.keys(lineItem);
							keys = keys.filter(key => typeof lineItem[key] === 'string' && lineItem[key] && !['firstName', 'lastName', 'name'].includes(key));
							keys.forEach(field => {
								adIdentifier += '<br/>' + field.toUpperWordSpaceCase() + ': ' + lineItem[field];
							});
						}
					}
				}

				return {
					icon: 'shopping cart',
					tooltip: 'Part of an ad. Icon will not show in print.' + adIdentifier
				};
			}
		},
		doesAllowTextToCrossLinkedLayouts: function() {
			return true;
		},
		shouldKeepSizeWhenChangingEffects: function() {
			return !!this.getExtraProperty('collage', false);
		},
		shouldRemoveImageFromPlaceholder: function() {
			return this.type === 'candid';
		},
		shouldSwapCandidsWhenDragging: function(instanceId) {
			let isLockedLayout = this.getExtraProperty('collage', false) && this.extras && this.extras.collageSettings && this.extras.collageSettings.lockedLayout;
			if(isLockedLayout) {
				if(this.extras.collageSettings.candidIds) {
					return this.extras.collageSettings.candidIds.includes(instanceId);
				} else {
					return true;
				}
			} else {
				return false;
			}
		},
		shouldKeepLayoutSizedTogether: function() {
			let keepLayoutSizedTogether =  this.getExtraProperty('collage', false) && this.extras && this.extras.collageSettings && this.extras.collageSettings.lockedLayout;
			if(keepLayoutSizedTogether) {
				if(this.extras.collageSettings.candidIds) {
					return $.merge([], this.extras.collageSettings.candidIds);
				} else {
					return true;
				}
			} else {
				return false;
			}
		},
		getLockedLayoutCanvasMargins: function() {
			let collageSettings = this.getExtraProperty('collageSettings', false) || {};
			let padding = collageSettings.padding || 1;
			return padding / 30;
		},
		disableRotateSwapWidthAndHeight: function() {
			let keepLayoutSizedTogether =  this.getExtraProperty('collage', false) && this.extras && this.extras.collageSettings && this.extras.collageSettings.lockedLayout;
			return !keepLayoutSizedTogether;
		},

		type: 'candid',
		pageLabelDisplay: '',
		pageLabelPopup: 'Options',
		commentsEditable: true,
		layout: {}
	}, startSettings);

	obj.updatePageLabel();

	return obj;
};