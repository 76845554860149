$.YearbookSubSet = function(pageSet, pages) {
	var obj = new Object;
	obj.wrapperPageSet = pageSet;
	for(var i in pageSet) {
		obj[i] = pageSet[i];
	}
	obj.pages = pages;

	$.extend(obj, {
		addPage: function(page) {
			// Should not ever try to add to a subset
			page.pageSet = this;

			return false;
		},
		removePageCascade: function() {
			// Should not ever try to remove from a subset
			return false;
		},
		replacePage: function(oldPage, newPage) {
			this.wrapperPageSet.replacePage(oldPage, newPage);

			var index = this.pages.indexOf(oldPage);
			if(index !== -1) {
				this.pages[index] = newPage;
			}
		},
		isAllowedToAddPages: function() {
			return false;
		}
	});

	return obj;
};