import runHeicConversion from './libheif/run.js';

let acceptedFileExtensions = [
	
];
let photoConverters = {};

let canvas = document.createElement('canvas');
if(canvas && canvas.toBlob) {
	acceptedFileExtensions.push('heic');
		photoConverters.heic = {
			converter: (blob) => {
				return runHeicConversion(blob);
			},
			signatures: [
				{
					4: 0x66,
					5: 0x74,
					6: 0x79,
					7: 0x70,
					8: 0x68
				},
				{
					4: 0x66,
					5: 0x74,
					6: 0x79,
					7: 0x70,
					8: 0x6D
				}
			]
	};
}

export {
	acceptedFileExtensions,
	photoConverters
};