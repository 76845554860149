$.YearbookIndexOverflowPage = function(parentPage) {
	var obj = new $.YearbookCandidPage();

	$.extend(obj, {
		setOverflowStats: function(overflowStats, ignoreFirstHeader) {
			if(overflowStats) {
				if (!this.overflowPage) {
					this.overflowPage = new $.YearbookIndexOverflowPage(this);
					this.pageSet.addPage(this.overflowPage, this.pageNumber - 1);
				}

				this.overflowPage.ignoreFirstHeader = ignoreFirstHeader;
				this.overflowPage.setStats(overflowStats);
			} else if(this.overflowPage) {
				this.pageSet.removePage(this.overflowPage);
				this.overflowPage = null;
			}
		},
		setStats: function(stats) {
			this.stats = stats;

			// Need to make sure this iterates down the line so we immediately get how many pages we need
			this.getLockedTexts();
		},
		getLockedTexts: function(side) {
			var rootPage = this.getRootPage();
			if(!this.stats) {
				rootPage.getLockedTexts();
			}

			return rootPage.getTextsForStats(this, this.stats || [], side);
		},
		getLockedOptions: function(type) {
			if(type == 'label') {
				return [
					'font-family',
					'font-size',
					'text-colors'
				];
			} else {
				return [
					'font-family',
					'text-colors'
				];
			}
		},
		setLockedFontStyle: function(instance, name, value) {
			if(this.labelTexts.indexOfMatch(instance, 'id') != -1) {
				this.getRootPage().setLabelStyle(name, value);
				if(name == 'font-size') {
					return 'forceRefresh';
				} else {
					return 'label';
				}
			} else if(this.indexHeaderTexts.indexOfMatch(instance, 'id') != -1) {
				this.getRootPage().setIndexHeaderStyle(name, value);
				return 'indexHeader';
			} else {
				return null;
			}
		},
		parentPage: parentPage,
		overflowPage: null,
		type: 'indexOverflow'
	});
	$.FlowPageOverflowModule(obj);
	parentPage.overflowPage = obj;

	return obj;
};