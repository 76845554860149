$.ArtWorkBookDB = function(settings) {
	var obj = new $.DBQueue($.extend({
		loadGlobals: function(data) {
			$.extend(this.pageSet, data);

			this.pageSet.setupArtWorkPages();
		}
	}, settings));

	return obj;
};