window.locales.enSports = {
	management: {
		studioSettings: {
			contactInformationSpecificHeader: 'PLIC Sports Contact Information'
		},
		yearbooks: {
			school: 'School/Team'
		}
	},
	organizations: {
		name: 'School/Team Name',
		newOrganization: 'Create new school/team',
		organization: 'School/Team',
		organizationChoice: 'Choose your school/team'
	},
	tabs: {
		createYearbook: 'Create Book',
		schoolLayouts: 'School/Team Layouts',
		schoolThemes: 'School/Team Themes',
		yearbookStoreInformation: 'Book Store Information',
		yearbookStoreSettings: 'Book Store Settings'
	}
};