$.YearbookIndexPage = function() {
	var obj = new $.YearbookCandidPage();
	var _updateTextThemeStyles = obj.updateTextThemeStyles;

	$.extend(obj, {
		setOverflowStats: function(overflowStats, ignoreFirstHeader) {
			if(overflowStats) {
				if(!this.overflowPage) {
					if(this.pageSet.db.enabled) {
						this.overflowPage = new $.YearbookIndexOverflowPage(this);
						this.pageSet.addPage(this.overflowPage, this.pageNumber - 1);
					} else {
						throw 'Missing subjects on index page!';
					}
				}

				this.overflowPage.ignoreFirstHeader = ignoreFirstHeader;
				this.overflowPage.setStats(overflowStats);
			} else if(this.overflowPage) {
				this.pageSet.removePage(this.overflowPage);
			}
		},
		getSubjectStats: function() {
			var stats = this.pageSet.getStatisticsForSubjects({
				includePlaceholderSubjects: this.layout.includePlaceholderSubjects,
				includePageUsage: this.layout.pageNumbers !== false
			});
			for(let i = 0; i < stats.length; i++) {
				let stat = stats[i];
				if (!stat.classPage && (!stat.candids || !stat.candids.length) && !this.layout.includeSubjectsNotOnPages) {
					stats.splice(i, 1);
					i--;
				}
				// If we have an exact duplicate name don't bother showing it twice - some poeple upload multiple subjects for 1 kids to get multi-pose layouts working
				else if(i > 0 && stats[i - 1].name === stat.name) {
					let lastStat = stats[i - 1];
					lastStat.classPages.push(...stat.classPages);
					lastStat.classPages = lastStat.classPages.unique();
					lastStat.candids.push(...stat.candids);

					stats.splice(i, 1);
					i--;
				}
			}

			return stats;
		},
		getPagesForStat: function(stat) {
			let pages = $.merge([], stat.classPages || [stat.classPage]);
			for(let j = 0; j < stat.candids.length; j++) {
				pages.push(stat.candids[j].page);
			}

			return pages;
		},
		getLockedTexts: function(side) {
			var stats = this.getSubjectStats();

			// If nothing we want to show a preview instead of just a blank page
			if(!stats.length) {
				stats = this.generateBogusSubjectStats();
			}

			return this.getTextsForStats(this, stats, side);
		},
		generateBogusSubjectStats: function() {
			var stats = [];

			var names = [
				'Shaniya Madden',
				'Kingston Garrison',
				'Eden Fields',
				'Leandro Marshall',
				'Amirah Reeves',
				'Tabitha Moreno',
				'Ryan Ruiz',
				'Gunnar Wilcox',
				'Jesse Ali',
				'Kiersten Oliver',
				'Nikolas Allison',
				'Anaya Yates',
				'Cade Bernard',
				'Hallie Bartlett',
				'Jake Fletcher',
				'Tyrese Rosario',
				'Aspen Gilmore',
				'Michaela Boyle',
				'Jude Blair',
				'Nyla Fischer',
				'Avery Bowman',
				'Demarcus Bradshaw',
				'Elena Sharp',
				'Matias Waters',
				'Kimora Baker',
				'Yoselin Li',
				'Gabriela Bowen',
				'Amelia Davidson',
				'Julie Rowe',
				'Oliver Cuevas',
				'Kaitlin Vaughn',
				'Quinten Martinez',
				'Naima Mccall',
				'Amelie Christian',
				'Parker Cochran',
				'Darion Jefferson',
				'Andre May',
				'Camila Davenport',
				'Karley Hester',
				'Audrey Bray',
				'Cheyanne Williamson',
				'Macey Griffith',
				'Madelynn Escobar',
				'Laura Travis',
				'Max Orr',
				'Blake Knapp',
				'Abbigail Austin',
				'Devon Bautista',
				'Jagger Roberson',
				'Averie Preston',
				'Stanley Booth',
				'Skyler Steele',
				'Clare Wilkinson',
				'Gary Conley',
				'Mariana Aguirre',
				'Diamond Robinson',
				'Jaeden Reed',
				'Dereon Valencia',
				'Urijah Bryant',
				'Thomas George',
				'Myah Christensen',
				'Erik Brock',
				'Sofia Rose',
				'Harold Allen',
				'Ernesto Bishop',
				'Cale Delacruz',
				'Jordan Bass',
				'Richard Adkins',
				'Kelsie Stephenson',
				'Kyla Walls',
				'Nehemiah Oconnell',
				'Kiana Levy',
				'Kaia Wilkerson',
				'Aedan Gordon',
				'Matthias Avery',
				'Cherish Fitzgerald',
				'Dorian Frank',
				'Noah Silva',
				'Amare Pruitt',
				'Kallie Curtis',
				'Darrell Sampson',
				'Karissa Trujillo',
				'Ashtyn Lamb',
				'Heidi Skinner',
				'Giada Kelly',
				'Francisco Spencer',
				'Payton Short',
				'Angeline Solomon',
				'Sophia Mcintyre',
				'Clay Lam',
				'Tyshawn Pollard',
				'Keshawn Larsen',
				'Kareem Ellis',
				'Kathleen Hebert',
				'Antwan Schwartz',
				'Sam Hardy',
				'Roderick Randolph',
				'Bryan Burnett',
				'Melany Montes',
				'Kadyn Reilly',
				'Leo Ramsey',
				'Courtney Blevins',
				'Walter Wilkins',
				'Yareli Holt',
				'Scarlett Landry',
				'Sherlyn Norton',
				'James Weaver',
				'Aliya Bullock',
				'Mireya Dorsey',
				'Leyla Mejia',
				'Tiara Dunlap',
				'Brodie Shepard',
				'Zander Carpenter',
				'Amya Kirby',
				'Colin Pacheco',
				'Jenny Mercado',
				'Cornelius Francis',
				'Rosa Mcmillan',
				'Theresa Holmes',
				'Patricia Turner',
				'Rocco Acosta',
				'Lorelei Frederick',
				'Amanda Armstrong',
				'Robert Rice',
				'Harrison Decker',
				'Trent Pitts',
				'Maurice Mills',
				'Bailey Barajas',
				'Marley Evans',
				'Layne Wu',
				'Georgia Zhang',
				'Abram Black',
				'Aidan Beltran',
				'Clara Horne',
				'Zaiden Dalton',
				'Abigail English',
				'Haven Singh',
				'Ali Williams',
				'Gordon Gould',
				'Valerie Rocha',
				'Anya Hudson',
				'Charity Mora',
				'Dominik Hensley',
				'Alfonso Lopez',
				'Ramon Murphy',
				'Jasmin Moran',
				'Karina Lozano',
				'Elianna Farrell',
				'Demarion Luna',
				'Moriah Browning',
				'Catherine Lloyd',
				'Janiyah Ayala',
				'Heath Chung',
				'Kade Coffey',
				'Tess Hatfield',
				'Alejandra Small',
				'Alisha Oconnor',
				'Russell Maynard',
				'Aracely Rios',
				'Mackenzie Hinton',
				'Madelyn Morrow',
				'Hailie Herrera',
				'Jacquelyn Santos',
				'Perla Spears',
				'Jada Martin',
				'Amaya Cross',
				'Jakayla Cunningham',
				'Rolando Rush',
				'Wilson Yang',
				'Rhianna Osborne',
				'Kadence Marks',
				'Allyson Ortiz',
				'Aydin Dunn',
				'Mia Holland',
				'Reyna Gomez',
				'Kenneth Miller',
				'Niko Robles',
				'Andreas Whitehead',
				'Zoey Compton',
				'Bridger Kidd',
				'Issac Lynn',
				'Camryn Grant',
				'Matteo Lucero',
				'Alessandro Hendricks',
				'Reed Saunders',
				'Dalton Stout',
				'Mohammed Mayo',
				'Darien Wade',
				'Sariah Rollins',
				'Gianni Morris',
				'Christian Wolfe',
				'Amber Herman',
				'Tania Logan',
				'Coby Cooke',
				'Ariana Shaw',
				'Mathias Patrick',
				'Ronan Dominguez',
				'Dangelo Hampton',
				'Elijah Henderson',
				'Deacon Lane'
			];
			names.forEach(function(name, i) {
				var split = name.split(' ');
				names[i] = split[1] + ', ' + split[0];
			});

			names.forEach(function(name) {
				var classPage = Math.floor(Math.random() * 100);
				stats.push({
					name: name,
					classPage: classPage,
					classPages: [classPage],
					candids: []
				});
			});
			stats.caseInsensitiveSort('name');

			return stats;
		},
		getTextsForStats: function(page, stats, side) {
			stats = stats.filter(function(stat) {
				return !!stat.name;
			});

			var layoutDimensions = this.pageSet.getInnerDimensions();

			var pageNumberFontSize = this.pageSet.getPageNumberFontSize();
			var pageNumberPadding = this.pageSet.getPageNumberPadding();
			var pageNumberHeight = (pageNumberFontSize / 72 * 1.33) + (pageNumberPadding * 2);

			var startLeft = 0.125;
			var startTop = 0.125;
			var pageRight = layoutDimensions.width;
			if(side == 'Right') {
				startLeft += 0.2;
				pageRight += 0.2;
			}
			var pageBottom = layoutDimensions.height - pageNumberHeight - 0.125;

			var columns = 4;
			var columnWidth = (layoutDimensions.width - 0.325) / columns;

			var labelTexts = [];
			var indexHeaderTexts = [];
			var textLeft = startLeft;
			var textTop = startTop;
			var lastIndex = this.hasLetterIndex();
			var labelStyle = this.getLabelStyle();
			var indexHeaderStyle = this.getIndexHeaderStyle();
			var fontSize = 8;
			if(labelStyle && labelStyle['font-size']) {
				fontSize = parseFloat(labelStyle['font-size'].replace('pt', ''));
			} else {
				labelStyle['font-size'] = fontSize + 'pt';
			}
			var maxLettersPerColumn = this.getMaxLettersPerColumn(columnWidth, fontSize);

			let i = 0;
			var ignoreFirstHeader = page.ignoreFirstHeader;
			for(; i < stats.length; i++) {
				var stat = stats[i];
				var letterIndex = stat.name[0].toUpperCase();

				let pages = this.getPagesForStat(stat);

				for(let j = 0; j < pages.length; j++) {
					pages[j] = Math.max(0, pages[j] - $.PAGE_OFFSET);
				}
				pages.numberSort();
				pages = pages.unique();
				var pagesText = pages.join(', ');
				var maxPagesLetters = Math.min(pagesText.length, maxLettersPerColumn / 2);

				var maxSubjectCharacters = maxLettersPerColumn - Math.floor(maxPagesLetters);
				if(this.layout.pageNumbers === false) {
					maxSubjectCharacters = maxLettersPerColumn;
				}
				var requiredHeight = fontSize / 72 * 1.33;
				var labelLines = this.getLabelTextForSubject(stat, maxSubjectCharacters, labelStyle);
				var baseMaxPagesLetters = maxPagesLetters;
				if($.isArray(labelLines) && labelLines.length > 1) {
					maxPagesLetters = [
						Math.max(maxPagesLetters, maxPagesLetters + maxSubjectCharacters - labelLines[0].text.length),
						Math.max(maxPagesLetters, maxPagesLetters + maxSubjectCharacters - labelLines[1].text.length)
					];
				}

				var pagesLines = this.getLabelTextForPages(pagesText, maxPagesLetters, baseMaxPagesLetters, labelStyle, labelLines, fontSize);
				var lines = Math.max(labelLines.length, $.isArray(pagesLines) ? pagesLines.length : 0);
				requiredHeight *= lines;

				// Check if we should be going to next column or page
				var totalRequiredHeight = requiredHeight;
				if(lastIndex && letterIndex != lastIndex && !ignoreFirstHeader) {
					let indexFontSize = fontSize * 2;
					var indexRequiredHeight = indexFontSize / 72 * 1.33;
					totalRequiredHeight += indexRequiredHeight;
				}
				if((textTop + totalRequiredHeight) > pageBottom) {
					textLeft += columnWidth;
					textTop = startTop;

					if((textLeft + columnWidth) > pageRight) {
						break;
					}
				}

				// Add index header
				if(ignoreFirstHeader) {
					ignoreFirstHeader = false;
					lastIndex = letterIndex;
				} else if(lastIndex && letterIndex != lastIndex) {
					let indexFontSize = fontSize * 2;
					indexHeaderTexts.push({
						id: 'index-' + letterIndex,
						type: 'indexHeader',
						position: {
							left: textLeft,
							top: textTop
						},
						lines: $.extend({
							text: letterIndex,
							'font-size': indexFontSize + 'pt',
							'font-weight': 'bold'
						}, indexHeaderStyle)
					});

					textTop += indexRequiredHeight;
					if(this.layout.headers?.paddingBottom) {
						textTop += this.layout.headers.paddingBottom;
					}

					lastIndex = letterIndex;
				}

				// Add subject label
				var subjectLabel = {
					id: stat.id + '-label',
					type: 'label',
					position: {
						left: textLeft,
						top: textTop
					},
					subject: stat.subject,
					lines: labelLines
				};

				labelTexts.push(subjectLabel);

				if(pagesLines) {
					var pageNumberRight = layoutDimensions.width - (textLeft + columnWidth - 0.3);
					var pageLabel = {
						id: stat.id + '-page',
						type: 'label',
						position: {
							right: pageNumberRight,
							top: textTop
						},
						lines: pagesLines
					};
					labelTexts.push(pageLabel);
				}

				textTop += requiredHeight;
				if(this.layout.paddingBottom) {
					textTop += this.layout.paddingBottom;
				}
			}

			if(i < stats.length) {
				page.setOverflowStats(stats.slice(i), lastIndex && letterIndex == lastIndex);
			} else {
				page.setOverflowStats(null);
			}

			page.labelTexts = labelTexts;
			page.indexHeaderTexts = indexHeaderTexts;

			var texts = $.merge([], labelTexts);
			$.merge(texts, indexHeaderTexts);
			return texts;
		},
		getLabelTextForSubject: function(stat, maxSubjectCharacters, labelStyle) {
			var labelLines;
			if(stat.name.length > maxSubjectCharacters) {
				labelLines = [
					$.extend({
						text: stat.lastName + ','
					}, labelStyle),
					$.extend({
						text: '   ' + stat.firstName
					}, labelStyle)
				];
			} else {
				labelLines = [
					$.extend({
						text: stat.name
					}, labelStyle)
				];
			}

			if(this.layout.extraLabelLines && stat.subject) {
				this.layout.extraLabelLines.forEach(function(extraLine) {
					var lineText = extraLine;
					if(lineText.text) {
						lineText = lineText.text;

						if((lineText.match(/%/g) || []).length === 2) {
							let field = lineText.replace(/%/g, '').toTitleCase();
							if(stat.subject[field]) {
								lineText = stat.subject[field];
							}
						}
					} else if(lineText.array) {
						var parts = [];
						for(let i = 1; i < 1000; i++) {
							let field = lineText.array.replace('x', i);
							if(stat.subject[field]) {
								parts.push(stat.subject[field]);
							} else {
								break;
							}
						}

						lineText = parts.join(', ');
					}

					if(extraLine.requireField) {
						if(!stat.subject[extraLine.requireField]) {
							return;
						}
					}

					if(lineText.indexOf('%') === -1 && lineText.length > maxSubjectCharacters) {
						// Find a good space character to break on
						var texts = [];
						while(lineText.length > maxSubjectCharacters) {
							for(let i = maxSubjectCharacters; i >= 0; i--) {
								if(lineText[i] === ' ') {
									texts.push(lineText.substr(0, i));
									lineText = lineText.substr(i + 1);
									break;
								}

								if(i === 0) {
									texts.push(lineText.substr(0, maxSubjectCharacters));
									lineText = lineText.substr(maxSubjectCharacters);
								}
							}
						}
						texts.push(lineText);

						texts.forEach(function(text) {
							var line = $.extend({
								text: text
							}, labelStyle);
							labelLines.push(line);
						});
					} else {
						var line = $.extend({
							text: lineText
						}, labelStyle);
						labelLines.push(line);
					}
				});
			}

			return labelLines;
		},
		getLabelTextForPages: function(pagesText, maxPagesLetters, baseMaxPagesLetters, labelStyle, labelLines, fontSize) {
			var pagesLines = null;
			var allowedLength = maxPagesLetters;
			if($.isArray(maxPagesLetters)) {
				allowedLength = Math.min(maxPagesLetters[0], maxPagesLetters[1]);
			}

			if(this.layout.pageNumbers !== false) {
				if(pagesText.length > allowedLength) {
					var lines = Math.ceil(pagesText.length / allowedLength);

					pagesLines = [];
					var startIndex = 0;
					for(let j = 0; j < lines; j++) {
						allowedLength = maxPagesLetters;
						if(j >= labelLines.length) {
							allowedLength = baseMaxPagesLetters * 2;
						} else if($.isArray(maxPagesLetters)) {
							allowedLength = maxPagesLetters[j];
						} else {
							allowedLength = maxPagesLetters;
						}

						var nextCheckIndex = startIndex + Math.floor(allowedLength);
						var previousComma;
						if(pagesText[nextCheckIndex] === ' ') {
							previousComma = nextCheckIndex - 1;
						} else {
							previousComma = pagesText.indexOf(', ', nextCheckIndex);
						}
						if(previousComma === -1) {
							previousComma = undefined;
						}

						pagesLines.push($.extend({
							text: pagesText.substring(startIndex, previousComma),
							'font-size': fontSize + 'pt',
							align: 'right'
						}, labelStyle));

						if(previousComma) {
							startIndex = previousComma + 2;
						} else {
							break;
						}
					}
				} else {
					pagesLines = $.extend({
						text: pagesText,
						'font-size': fontSize + 'pt'
					}, labelStyle);
				}
			}

			return pagesLines;
		},
		getMaxLettersPerColumn: function(columnWidth, fontSize) {
			var averageLetterLength;
			switch(fontSize) {
				case 6:
					averageLetterLength = 0.054;
					break;
				case 7:
					averageLetterLength = 0.06;
					break;
				case 8:
					averageLetterLength = 0.068;
					break;
				case 9:
					averageLetterLength = 0.078;
					break;
				case 10:
					averageLetterLength = 0.088;
					break;
				case 11:
					averageLetterLength = 0.1;
					break;
				case 12:
					averageLetterLength = 0.11;
					break;
				default:
					averageLetterLength = 50;
			}

			return Math.floor(columnWidth / averageLetterLength);
		},
		hasLetterIndex: function() {
			return !!this.layout.headers;
		},
		getLockedOptions: function(type) {
			if(type == 'label') {
				return [
					'font-family',
					'font-size',
					'text-colors'
				];
			} else {
				return [
					'font-family',
					'text-colors'
				];
			}
		},
		setLockedFontStyle: function(instance, name, value) {
			if(this.labelTexts.indexOfMatch(instance, 'id') != -1) {
				this.setLabelStyle(name, value);
				if(name == 'font-size') {
					return 'forceRefresh';
				} else {
					return 'label';
				}
			} else if(this.indexHeaderTexts.indexOfMatch(instance, 'id') != -1) {
				this.setIndexHeaderStyle(name, value);
				return 'indexHeader';
			} else {
				return null;
			}
		},

		getLabelTexts: function() {
			return this.labelTexts
		},
		setLabelStyle: function(name, value) {
			this.jsonSubPropertyChange('extras', 'labelStyle', name, value);
		},
		getLabelStyle: function() {
			var labelStyle = this.getExtraProperty('labelStyle');
			return $.extend({}, labelStyle);
		},
		getIndexHeaderTexts: function() {
			return this.indexHeaderTexts;
		},
		setIndexHeaderStyle: function(name, value) {
			this.jsonSubPropertyChange('extras', 'indexHeaderStyle', name, value);
		},
		getIndexHeaderStyle: function() {
			var labelStyle = this.getExtraProperty('indexHeaderStyle');
			return $.extend({}, labelStyle);
		},
		updateTextThemeStyles: function(oldStyles, newStyles) {
			_updateTextThemeStyles.apply(this, arguments);

			if(oldStyles.indexHeader || newStyles.indexHeader) {
				let newStyle = this.updateTextObjectThemeStyles(this.extras.indexHeaderStyle, oldStyles.indexHeader, newStyles.indexHeader);
				if(newStyle) {
					this.extras.indexHeaderStyle = newStyle;
				}
			}

			if(oldStyles.subjectIndex || newStyles.subjectIndex) {
				let newStyle = this.updateTextObjectThemeStyles(this.extras.labelStyle, oldStyles.subjectIndex, newStyles.subjectIndex);
				if(newStyle) {
					this.extras.labelStyle = newStyle;
				}
			}
		},
		extras: {
			labelStyle: {},
			indexHeaderStyle: {}
		},
		type: 'index',
		overflowPage: null
	});
	$.FlowPageOverflowRootModule(obj);

	return obj;
};